import { ROUTES } from '~app/constants';
import { ITabRoute } from '../../../types';

export const BILL_GROUP_TAB_ROUTES: ITabRoute[] = [
  {
    title: 'Details',
    route: ROUTES.BILL_GROUP_DETAILS,
    id: 'bill-group-details',
  },
  {
    title: 'Billing Schedule',
    route: ROUTES.BILL_GROUP_BILLING_SCHEDULE,
    id: 'bill-group-billing-schedule',
    requiresFeatureFlag: 'allowBillingScheduleV2',
  },
  {
    title: 'Subscriptions',
    route: ROUTES.BILL_GROUP_SUBSCRIPTIONS,
    id: 'bill-group-subscriptions',
    requiresFeatureFlag: 'showSubscriptionOnAccount',
  },
  {
    title: 'Usage',
    route: ROUTES.BILL_GROUP_USAGE,
    id: 'bill-group-usage',
    requiresFeatureFlag: 'showUsageTabOnAccount',
  },
  {
    title: 'Payments',
    route: ROUTES.BILL_GROUP_PAYMENTS,
    id: 'bill-group-payments',
  },
  {
    title: 'Credits',
    route: ROUTES.BILL_GROUP_CREDITS,
    id: 'bill-group-credits',
  },
  {
    title: 'Quotes',
    route: ROUTES.BILL_GROUP_QUOTES,
    id: 'bill-group-quotes',
  },
  {
    title: 'Contracts',
    route: ROUTES.BILL_GROUP_CONTRACTS,
    id: 'bill-group-contracts',
  },
  {
    title: 'Invoices',
    route: ROUTES.BILL_GROUP_INVOICES,
    id: 'bill-group-invoices',
  },
];
