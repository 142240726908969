import { atom } from 'recoil';

export const quoteOfferingLoadingPromises = atom<Promise<unknown>[]>({
  key: 'quote-offering-loading-promises',
  default: [],
});

export const quoteLoadingPromises = atom<Promise<unknown>[]>({
  key: 'quote-loading-promises',
  default: [],
});
