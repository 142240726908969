import { usePrintCreditToHtml } from '../../../../api/accountsService';
import { HtmlIFrameContentRenderer } from '../../../../components/Monetize/HtmlIFrameContentRenderer';

export const ViewCreditHtml = ({
  creditId,
  modifyDate,
}: {
  creditId: string;
  modifyDate: string | undefined;
}) => {
  const { data, isLoading, isRefetching, error, isPending } =
    usePrintCreditToHtml({
      creditId: creditId,
      lastModifiedTimestamp: modifyDate,
    });

  if (error) {
    // TODO: add error state
    return null;
  }
  return (
    <HtmlIFrameContentRenderer
      id="credit-content"
      html={data}
      isLoading={isLoading}
      isReFetching={isPending || isRefetching}
    ></HtmlIFrameContentRenderer>
  );
};
