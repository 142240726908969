import { useState } from 'react';
import { MBox, MButton, MText } from '~app/components/Monetize';
import { copyToClipboard } from '~app/utils';

interface QuoteCollaborationCopyLinkProps {
  canCopyClipboard: boolean;
  link: string;
}

export function QuoteCollaborationCopyLink({
  canCopyClipboard,
  link,
}: QuoteCollaborationCopyLinkProps) {
  const [isCopied, setIsCopied] = useState(false);
  const [showUrl, setShowUrl] = useState(false);

  const handleCopyLinkToClipboard = async (
    ev: React.MouseEvent<HTMLElement>,
  ) => {
    ev.stopPropagation();
    const didCopy = await copyToClipboard(link);
    if (!didCopy) {
      setShowUrl(true);
      return;
    }
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  if (!link) {
    return null;
  }

  if (!canCopyClipboard || showUrl) {
    return (
      <MBox>
        <MText>{link}</MText>
      </MBox>
    );
  }

  return (
    <MButton
      variant="tertiary"
      size="sm"
      onClick={handleCopyLinkToClipboard}
      fontStyle={isCopied ? 'italic' : undefined}
      minW="86.1px"
    >
      {isCopied ? 'Copied' : 'Copy Link'}
    </MButton>
  );
}
