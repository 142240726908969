import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useGetById } from '~app/api/queryUtils';
import ContractModal from '~app/components/Contracts/ContractModal';
import {
  MBox,
  MCustomLinkTab,
  MFlex,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MTabList,
  MTabs,
  MText,
} from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { BILL_GROUP_STATUS_DISPLAY } from '~app/constants/billGroups';
import { PrivateRoute } from '~app/routes/AppPrivateRoute';
import {
  AccountTabTypes,
  BillGroupTabTypes,
  IAccountRespSchema,
  IBillGroupResp,
} from '~app/types';
import { billGroupDetailsTabs } from '../../../constants/accounts';
import {
  BILL_GROUP_BILLING_SCHEDULE,
  BILL_GROUP_DETAILS,
} from '../../../constants/routes';
import { FeatureFlag, useFlags } from '../../../services/launchDarkly';
import { AccountDetailsBillGroupFilter } from '../AccountDetailsBillGroupFilter';
import ContractListAccount from '../Contracts/ContractListAccount';
import { CreditFormCreateModal } from '../Credits/Credits/CreditFormCreateModal';
import { CreditsList } from '../Credits/CreditsList';
import AccountInvoices from '../Invoices/AccountInvoices';
import { PaymentList } from '../Payments/PaymentList';
import QuoteListAccount from '../Quotes/QuoteListAccount';
import { SubscriptionList } from '../Subscriptions/SubscriptionList';
import { UsagePage } from '../Usage/UsagePage';
import { BillGroupGenerateInvoicePopover } from './components/BillGroupGenerateInvoicePopover';
import { BILL_GROUP_TAB_ROUTES } from './constants';
import { BillGroupBillingScheduleTab } from './tabs/BillGroupBillingScheduleTab';
import { BillGroupDetailsTab } from './tabs/BillGroupDetailsTab/BillGroupDetailsTab';

interface BillGroupPageProps {
  isReadOnly?: boolean;
}
export const BillGroupPage = ({ isReadOnly = false }: BillGroupPageProps) => {
  const navigate = useNavigate();
  const featureFlags = useFlags();
  const { billGroupId = '' } = useParams();
  const { pathname } = useLocation();
  const { data: billGroup, isLoading: isBillGroupLoading } =
    useGetById<IBillGroupResp>('billGroups', billGroupId, {
      enabled: !!billGroupId,
    });

  const { data: account } = useGetById<IAccountRespSchema>(
    'accounts',
    billGroup?.accountId!,
    {
      enabled: !!billGroup?.accountId,
    },
  );

  const [activeTabIndex, setActiveTabIndex] = useState<number>();
  const [invoiceDelay, setInvoiceDelay] = useState<number>(0);

  useEffect(() => {
    setInvoiceDelay(billGroup?.invoiceDelay || 0);
  }, [billGroup?.invoiceDelay]);

  useLayoutEffect(() => {
    const index = BILL_GROUP_TAB_ROUTES.filter(
      ({ requiresFeatureFlag }) =>
        !(
          requiresFeatureFlag &&
          !featureFlags[requiresFeatureFlag as keyof FeatureFlag]
        ),
    ).findIndex(({ route }) => pathname.includes(route));
    setActiveTabIndex(index);
  }, [pathname]);

  const accountTabName = useMemo(() => {
    const currentPath = pathname.split('/').reverse()[0];
    return !currentPath ||
      [BILL_GROUP_DETAILS, BILL_GROUP_BILLING_SCHEDULE].includes(currentPath)
      ? 'bill-groups'
      : (currentPath as AccountTabTypes);
  }, [pathname]);

  const billGroupTabName = useMemo(() => {
    const currentPath = pathname.split('/').reverse()[0];
    return !currentPath || billGroupDetailsTabs.has(currentPath)
      ? BILL_GROUP_DETAILS
      : (currentPath as BillGroupTabTypes);
  }, [pathname]);

  if (!billGroup || !account || isBillGroupLoading) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer alignItems="stretch">
      <MPageHeader
        hasBackButton
        status={BILL_GROUP_STATUS_DISPLAY[billGroup.status]}
        backButtonLink={ROUTES.getAccountDetailsRouteWithTabName(
          billGroup.accountId,
          accountTabName,
        )}
        title={
          <MText fontWeight="700" fontSize="2xl">
            Bill Group:{' '}
            <MText as="span" fontWeight="400" fontSize="2xl">
              {billGroup.name}
            </MText>
          </MText>
        }
        id={billGroup.id}
        copyUrl
        parentLink={ROUTES.getAccountDetailsRouteWithTabName(
          billGroup.accountId,
          accountTabName,
        )}
        parentLinkTitle={account?.accountName}
      >
        <MFlex flexDir="column" justifyContent="flex-end" maxW="25rem">
          <AccountDetailsBillGroupFilter
            billGroupId={billGroup.id}
            accountId={billGroup.accountId}
            onChange={(value) => {
              if (!value) {
                navigate(
                  ROUTES.getAccountDetailsRouteWithTabName(
                    billGroup.accountId,
                    accountTabName,
                  ),
                );
              } else {
                navigate(
                  ROUTES.getBillGroupPageRoute(`${value}`, billGroupTabName),
                );
              }
            }}
          />
          {billGroup && billGroup.nextInvoiceDate && (
            <BillGroupGenerateInvoicePopover
              accountId={billGroup.accountId}
              billGroupId={billGroup.id}
              billGroupStatus={billGroup.status}
              nextInvoiceDate={billGroup.nextInvoiceDate}
              errorStatus={billGroup.errorStatus}
              invoiceDelay={invoiceDelay}
              invoiceDelayDirty={invoiceDelay !== billGroup.invoiceDelay}
            />
          )}
        </MFlex>
      </MPageHeader>
      <MBox flex="1" ml={8} minH="0" display="flex" flexDir="column">
        <MTabs
          data-testid="account-detail-tabs"
          size="sm"
          index={activeTabIndex}
        >
          <MTabList alignItems="flex-end">
            {BILL_GROUP_TAB_ROUTES.filter(
              ({ requiresFeatureFlag }) =>
                !(
                  requiresFeatureFlag &&
                  !featureFlags[requiresFeatureFlag as keyof FeatureFlag]
                ),
            ).map(({ route, title }, index) => {
              return (
                <MCustomLinkTab to={route} key={index}>
                  {title}
                </MCustomLinkTab>
              );
            })}
          </MTabList>
        </MTabs>

        <Routes>
          <Route
            path="/"
            element={
              <BillGroupDetailsTab
                billGroup={billGroup}
                setInvoiceDelay={setInvoiceDelay}
              />
            }
          />
          <Route
            path={ROUTES.BILL_GROUP_DETAILS}
            element={
              <BillGroupDetailsTab
                billGroup={billGroup}
                setInvoiceDelay={setInvoiceDelay}
              />
            }
          />
          <Route
            path={ROUTES.BILL_GROUP_BILLING_SCHEDULE}
            element={<BillGroupBillingScheduleTab billGroup={billGroup} />}
          />
          <Route
            path={`${ROUTES.BILL_GROUP_BILLING_SCHEDULE}/${ROUTES.CONTRACT_EDIT}`}
            element={
              <PrivateRoute
                acls={[['account_quotes', 'read']]}
                component={
                  <ContractModal
                    isOpen
                    onClose={() =>
                      navigate(
                        ROUTES.getBillGroupPageRoute(
                          billGroup.id,
                          'billing-schedule',
                        ),
                      )
                    }
                  />
                }
              />
            }
          />
          {featureFlags.showSubscriptionOnAccount && (
            <Route
              path={ROUTES.BILL_GROUP_SUBSCRIPTIONS}
              element={
                <PrivateRoute
                  acls={[['billing', 'read']]}
                  component={
                    <SubscriptionList
                      account={account}
                      billGroupId={billGroupId}
                    />
                  }
                />
              }
            />
          )}
          {featureFlags.showUsageTabOnAccount && (
            <Route
              path={ROUTES.BILL_GROUP_USAGE}
              element={
                <PrivateRoute
                  acls={[['billing', 'read']]}
                  component={
                    <UsagePage billGroupId={billGroupId} account={account} />
                  }
                />
              }
            />
          )}
          <Route
            path={ROUTES.BILL_GROUP_PAYMENTS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <PaymentList account={account} billGroupId={billGroupId} />
                }
              />
            }
          />

          <Route
            path={`${ROUTES.BILL_GROUP_CREDITS}/*`}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <CreditsList account={account} billGroupId={billGroupId} />
                }
              />
            }
          />

          <Route
            path={`${ROUTES.BILL_GROUP_CREDITS}/${ROUTES.CREDITS_CREATE}`}
            element={
              <PrivateRoute
                acls={[['billing', 'create']]}
                component={
                  <CreditFormCreateModal
                    isOpen
                    account={account}
                    billGroupId={billGroup.id}
                    onClose={() =>
                      navigate(
                        ROUTES.getBillGroupPageRoute(billGroup.id, 'credits'),
                      )
                    }
                  />
                }
              />
            }
          />

          <Route
            path={ROUTES.BILL_GROUP_QUOTES}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <QuoteListAccount
                    account={account}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />

          <Route
            path={`${ROUTES.BILL_GROUP_CONTRACTS}/*`}
            element={
              <PrivateRoute
                acls={[['account_quotes', 'read']]}
                component={
                  <ContractListAccount
                    account={account}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          >
            <Route
              path={ROUTES.CONTRACT_EDIT}
              element={
                <PrivateRoute
                  acls={[['account_quotes', 'read']]}
                  component={
                    <ContractModal
                      isOpen
                      onClose={() =>
                        navigate(
                          ROUTES.getBillGroupPageRoute(
                            billGroup.id,
                            'contracts',
                          ),
                        )
                      }
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path={ROUTES.BILL_GROUP_INVOICES}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <AccountInvoices
                    account={account}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />
        </Routes>
      </MBox>
    </MPageContainer>
  );
};
