import { useEffect } from 'react';
import {
  MBox,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MVStack,
} from '~app/components/Monetize';
import { useInvoiceSettings } from '~app/hooks';
import { useDocumentHead } from '~app/services/documentHead';
import { IInvoiceSettings } from '~app/types/InvoiceSettingsTypes';
import { handleApiErrorToast } from '../../../../api/axios';
import { InvoiceAutomation } from './InvoiceAutomation';
import { InvoiceEmailTiming } from './InvoiceEmailTiming';
import { InvoiceTerms } from './InvoiceTerms';

export const InvoiceSettings = () => {
  const { setDocTitle } = useDocumentHead();

  const { fetchLoading, invoiceSettings, doUpdateInvoiceSettings } =
    useInvoiceSettings();

  useEffect(() => {
    setDocTitle('Settings', 'Invoices');
  }, []);

  /**
   * Handle save and show error message if there is an error
   * Returns boolean if the save was unsuccessful
   */
  const handleSubmit = async (payload: IInvoiceSettings): Promise<void> => {
    try {
      await doUpdateInvoiceSettings(payload);
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Invoices" />
      <MBox w={'100%'} mt={0} overflow="auto">
        <MVStack maxW="600px" spacing={4}>
          {fetchLoading ? (
            <MSkeleton height={200} width="100%" />
          ) : (
            <>
              <InvoiceAutomation
                invoiceAutomation={invoiceSettings?.invoiceAutomation!}
                onSubmit={(invoiceAutomation) =>
                  handleSubmit({ ...invoiceSettings!, invoiceAutomation })
                }
              />
              <InvoiceEmailTiming
                emailTiming={invoiceSettings?.invoiceEmailingConfig!}
                onSubmit={(invoiceEmailingConfig) =>
                  handleSubmit({ ...invoiceSettings!, invoiceEmailingConfig })
                }
              />
              <InvoiceTerms
                invoiceTerms={invoiceSettings?.invoiceTerms!}
                onSubmit={(invoiceTerms) =>
                  handleSubmit({ ...invoiceSettings!, invoiceTerms })
                }
              />
            </>
          )}
        </MVStack>
      </MBox>
    </MPageContainer>
  );
};
