import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  MN_BLACKLISTED_FIELD_API_NAMES,
  MN_CRM_FIELDS,
  MN_CRM_FIELDS_FOR_SALESFORCE,
} from '~app/constants/crmFieldMapping';
import { CustomFieldEntityEnum, ICustomFieldResSchema } from '~app/types';
import {
  FieldMappingCreateReqType,
  FieldMappingObjectType,
  FieldMappingsResType,
  FieldMappingSystemType,
  FieldMappingType,
} from '~app/types/fieldMappingTypes';
import {
  convertCustomFieldTypeToCrmType,
  transformToFieldMappingBatchUpsertPayloadType,
} from '~app/utils/crmFieldMapping';
import { MN_ACC_FIELDS } from '../constants/accountingFieldMappings';
import { transformAccToFieldMappingBatchUpsertPayloadType } from '../utils/accountingFieldMapping';
import { apiGet, apiGetAllList, apiPost } from './axios';
import { fieldMappingServiceQueryKeys } from './queryKeysService';
import { settingsQueryKeys } from './settingsService';

function getFieldConfig(system: string) {
  switch (system) {
    case 'salesforce':
      return MN_CRM_FIELDS_FOR_SALESFORCE;
    case 'netsuite':
      return MN_ACC_FIELDS;
    default:
      return MN_CRM_FIELDS;
  }
}
export const useGetCrmFields = (
  system: FieldMappingSystemType,
  options: Partial<
    UseQueryOptions<FieldMappingType, unknown, FieldMappingType>
  > = {},
) => {
  const url =
    system === 'monetizenow'
      ? '/connector/monetizenow/fields'
      : `/connector/crm/${system}/fields`;

  return useQuery({
    queryKey: fieldMappingServiceQueryKeys.crmFields(system),
    select: (data) => {
      if (system === 'monetizenow') {
        const legalEntityFields =
          data.LegalEntity?.map(({ fieldApiName, ...rest }) => ({
            fieldApiName: `legalEntityId.${fieldApiName}`,
            ...rest,
          })) ?? [];
        const quoteFields = data.Quote?.filter(
          ({ fieldApiName }) =>
            !MN_BLACKLISTED_FIELD_API_NAMES.has(fieldApiName),
        ).concat(legalEntityFields);
        return { ...data, Quote: quoteFields } as FieldMappingType;
      }
      return data;
    },
    queryFn: async () => {
      const { data } = await apiGet<FieldMappingType>(url);
      return data;
    },
    ...options,
    refetchOnWindowFocus: false,
  });
};

export const useGetMonetizeNowCustomFields = (
  system: FieldMappingSystemType,
) => {
  const { data: monetizeNowFields, isLoading } = useQuery({
    queryKey: [
      ...settingsQueryKeys.customFieldsList(),
      'Account',
      'Contact',
      'Quote',
      'CreditNote',
    ],
    queryFn: async () => {
      const res = await apiGetAllList<ICustomFieldResSchema>(
        '/api/configurations/customFields',
        {
          filters: {
            entity: {
              in: [
                CustomFieldEntityEnum.ACCOUNT,
                CustomFieldEntityEnum.CONTACT,
                CustomFieldEntityEnum.QUOTE,
                CustomFieldEntityEnum.CREDIT_NOTE,
              ],
            },
          },
        },
      );
      return res;
    },
    refetchOnWindowFocus: false,
    select: (data) => {
      /**
       * Combine custom fields with hard-coded MN fields
       */
      const mnFields = getFieldConfig(system);

      return data?.reduce((result, { displayLabel, entity, key, type }) => {
        let dataType = convertCustomFieldTypeToCrmType(type);
        switch (entity) {
          case CustomFieldEntityEnum.ACCOUNT: {
            const existingField = result.Account?.find(
              ({ fieldApiName }) => fieldApiName === key,
            );
            if (!existingField || existingField.displayLabel !== displayLabel) {
              result.Account?.push({
                fieldApiName: key,
                dataType,
                displayLabel,
              });
            }
            break;
          }
          case CustomFieldEntityEnum.CONTACT: {
            const existingField = result.Contact?.find(
              ({ fieldApiName }) => fieldApiName === key,
            );
            if (!existingField || existingField.displayLabel !== displayLabel) {
              result.Contact?.push({
                fieldApiName: key,
                dataType,
                displayLabel,
              });
            }
            break;
          }
          case CustomFieldEntityEnum.QUOTE: {
            const existingField = result.Quote?.find(
              ({ fieldApiName }) => fieldApiName === key,
            );
            if (!existingField || existingField.displayLabel !== displayLabel) {
              result.Quote?.push({
                fieldApiName: key,
                dataType,
                displayLabel,
              });
            }
            break;
          }
          case CustomFieldEntityEnum.CREDIT_NOTE: {
            const existingField = result.CreditNote?.find(
              ({ fieldApiName }) => fieldApiName === key,
            );

            if (!existingField || existingField.displayLabel !== displayLabel) {
              result.CreditNote?.push({
                fieldApiName: key,
                dataType,
                displayLabel,
              });
            }
            break;
          }
          default:
            break;
        }
        return result;
      }, mnFields);
    },
  });
  return { isLoading, monetizeNowFields };
};

export const useGetUserDefinedCrmFieldMappings = (
  system: FieldMappingSystemType,
  options: {
    enabled: boolean;
  },
) => {
  return useQuery({
    queryKey: fieldMappingServiceQueryKeys.crmFieldMappings(system),
    queryFn: async () => {
      const { data } = await apiGet<FieldMappingsResType>(
        `/connector/crm/${system}/fieldMappings`,
      );
      return data;
    },
    refetchOnWindowFocus: false,
    ...options,
  });
};

const getTransformedPayload = (
  connector: 'accounting' | 'crm',
  targetSystem: FieldMappingSystemType,
  sourceEntity: FieldMappingObjectType,
  targetEntity: FieldMappingObjectType,
  payloads: FieldMappingCreateReqType[],
) => {
  switch (connector) {
    case 'accounting':
      return transformAccToFieldMappingBatchUpsertPayloadType(
        targetSystem,
        sourceEntity,
        targetEntity,
        payloads,
      );
    case 'crm':
      return transformToFieldMappingBatchUpsertPayloadType(
        targetSystem,
        sourceEntity,
        targetEntity,
        payloads,
      );
    default:
      throw new Error(`Unsupported connector type: ${connector}`);
  }
};

export const useCreateConnectorFieldMapping = (
  targetSystem: FieldMappingSystemType,
  connector: 'accounting' | 'crm',
  options: Partial<
    Omit<
      UseMutationOptions<
        unknown,
        unknown,
        {
          sourceEntity: FieldMappingObjectType;
          targetEntity: FieldMappingObjectType;
          payloads: FieldMappingCreateReqType[];
        }
      >,
      'onSuccess'
    >
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    {
      sourceEntity: FieldMappingObjectType;
      targetEntity: FieldMappingObjectType;
      payloads: FieldMappingCreateReqType[];
    }
  >({
    mutationFn: async ({ sourceEntity, targetEntity, payloads }) => {
      const transformedPayload = getTransformedPayload(
        connector,
        targetSystem,
        sourceEntity,
        targetEntity,
        payloads,
      );

      const { data } = await apiPost(
        `/connector/${connector}/${targetSystem}/fieldMappings`,
        transformedPayload,
      );
      return data;
    },
    onSuccess: (data, payload, ctx) => {
      queryClient.invalidateQueries({
        queryKey:
          fieldMappingServiceQueryKeys.accountingFieldMappings(targetSystem),
      });
      queryClient.invalidateQueries({
        queryKey: fieldMappingServiceQueryKeys.crmFieldMappings(targetSystem),
      });
    },
    ...options,
  });
};

export const useGetAccountingFields = (
  system: FieldMappingSystemType,
  options: Partial<
    UseQueryOptions<FieldMappingType, unknown, FieldMappingType>
  > = {},
) => {
  const url =
    system === 'monetizenow'
      ? '/connector/monetizenow/fields'
      : `/connector/accounting/${system}/fields`;

  return useQuery({
    queryKey: fieldMappingServiceQueryKeys.accountingFields(system),
    select: (data) => {
      return data;
    },
    queryFn: async () => {
      const { data } = await apiGet<FieldMappingType>(url);
      return data;
    },
    refetchOnWindowFocus: false,
    ...options,
  });
};

export const useGetUserDefinedAccountingFieldMappings = (
  system: FieldMappingSystemType,
  options: Partial<
    UseQueryOptions<FieldMappingsResType, unknown, FieldMappingsResType>
  > = {},
) => {
  return useQuery({
    queryKey: fieldMappingServiceQueryKeys.accountingFieldMappings(system),
    queryFn: async () => {
      const { data } = await apiGet<FieldMappingsResType>(
        `/connector/accounting/${system}/fieldMappings`,
      );
      return data;
    },
    refetchOnWindowFocus: false,
    ...options,
  });
};
