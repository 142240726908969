import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  AggregatedTransactablesResponse,
  ApplyUnapplyRequest,
  AppTrxnType,
  CreditAndRebillRequest,
  CreditAndRebillWithApplicationResponse,
  CreditStatusEnum,
  CreditTypeEnum,
  GetListApiConfig,
  GetListApiFilter,
  ICreditAndRebillRespSchema,
  ICreditNoteSchema,
  ICustomFieldRecordSchema,
  IGetCreditSchema,
  IInvoiceAddressDataSchema,
  IInvoiceRespSchema,
  IInvoiceRevRecognitionScheduleResponse,
  IInvoiceUpdateSchema,
  InvoiceSummaryResp,
  IUpsertCreditSchema,
  MassCreditInvoiceRow,
  MassCreditInvoicesResults,
  MassEmailCustomerInvoicesResults,
  TransactableSourceType,
  UnpaidInvoiceShareLink,
} from '~app/types';
import { IOneTimeInvoiceRequestSchema } from '../types/oneTimeInvoiceTypes';
import { sortByDate } from '../utils/dates';
import { splitArrayToMaxSize } from '../utils/misc';
import { apiGet, apiGetAllList, apiPost, apiPut, isAxiosError } from './axios';
import { ApiQueryItem } from './queryUtils';
import { asQueryUtil } from './utils';

export const invoiceServiceQueryKeys = {
  base: ['invoices'] as const,
  invoiceList: () => [...invoiceServiceQueryKeys.base, 'list'] as const,
  invoiceListAll: () => [...invoiceServiceQueryKeys.base, 'list-all'] as const,
  invoiceDetail: (id: string) => [...invoiceServiceQueryKeys.base, id] as const,
  invoiceRevenueSchedule: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'revenueSchedule'] as const,
  invoiceUnpaidInvoices: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'unpaidInvoices'] as const,
  creditAndRebill: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'creditAndRebill'] as const,
  htmlTemplate: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'html'] as const,
};

export const queryKeysInvoice: Required<
  Omit<ApiQueryItem, 'create' | 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/api/invoices`,
    queryKey: invoiceServiceQueryKeys.invoiceList(),
    // byIdQueryKey - API response for list and detail is not the same
  },
  byId: {
    endpoint: (id: string) => `/api/invoices/${id}`,
    queryKey: (id: string) => invoiceServiceQueryKeys.invoiceDetail(id),
  },
  update: {
    endpoint: (id: string) => `/api/invoices/${id}`,
    invalidateKeys: [invoiceServiceQueryKeys.invoiceList()],
    setDataKey: (id: string) => invoiceServiceQueryKeys.invoiceDetail(id),
    skipListUpdate: true,
  },
};

export const INVOICE_SERVICE_API = asQueryUtil({
  invoices: queryKeysInvoice,
});

/**
 * Paginate to fetch all invoices
 * Since volume can be high, ensure to use filters to limit the data
 */
export function useGetAllInvoices<SelectData = InvoiceSummaryResp[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<
    UseQueryOptions<InvoiceSummaryResp[], unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...invoiceServiceQueryKeys.invoiceListAll(), config, filters],
    queryFn: () =>
      apiGetAllList<InvoiceSummaryResp>('/api/invoices', {
        rows: 100,
        config,
        filters,
        onProgress,
      }),
    ...options,
  });
}

export function useInvoiceRevenueSchedule(
  invoiceId: string,
  options: Partial<
    UseQueryOptions<IInvoiceRevRecognitionScheduleResponse>
  > = {},
) {
  return useQuery({
    queryKey: [...invoiceServiceQueryKeys.invoiceRevenueSchedule(invoiceId)],
    queryFn: () =>
      apiGet<IInvoiceRevRecognitionScheduleResponse>(
        `/api/revenueRecognition/invoice/${invoiceId}`,
      ).then((res) => res.data),
    ...options,
  });
}

export function useSetAddressOnInvoice(
  options: Partial<
    UseMutationOptions<
      IInvoiceAddressDataSchema,
      unknown,
      {
        invoiceId: string | undefined;
        data: IInvoiceAddressDataSchema;
      }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IInvoiceAddressDataSchema,
    unknown,
    {
      invoiceId: string | undefined;
      data: IInvoiceAddressDataSchema;
    }
  >({
    mutationFn: ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoices/${invoiceId}/addresses`, data).then(
        (res) => res.data,
      ),
    ...restOptions,
  });
}

export function useUpdateInvoiceDetails(
  options: Partial<
    UseMutationOptions<
      IInvoiceUpdateSchema,
      unknown,
      {
        invoiceId: string;
        data: IInvoiceUpdateSchema;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IInvoiceUpdateSchema,
    unknown,
    {
      invoiceId: string;
      data: IInvoiceUpdateSchema;
    }
  >({
    mutationFn: ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoices/${invoiceId}`, data).then((res) => res.data),
    onSuccess: (response, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...invoiceServiceQueryKeys.invoiceDetail(variables.invoiceId),
        ],
      });
      onSuccess && onSuccess(response, variables, context);
    },
    ...restOptions,
  });
}

export function useCreateCreditAndRebill(
  options: Partial<
    UseMutationOptions<
      CreditAndRebillWithApplicationResponse,
      unknown,
      CreditAndRebillRequest
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    CreditAndRebillWithApplicationResponse,
    unknown,
    CreditAndRebillRequest
  >({
    mutationFn: async ({
      invoice: oldInvoice,
      payload,
      applyCreditToOldInvoice,
    }) => {
      const output: CreditAndRebillWithApplicationResponse = {
        applyCreditToInvoiceError: null as Error | null,
        creditAndRebillResponse: await apiPost<ICreditAndRebillRespSchema>(
          `/api/invoices/${oldInvoice.id}/creditAndRebill`,
          payload,
        ).then((res) => res.data),
      };

      const { creditNoteId } = output.creditAndRebillResponse;

      if (applyCreditToOldInvoice && oldInvoice.amountDue > 0) {
        try {
          // Fetch credit note so we have access to the credit id
          const creditNote = await apiGet<ICreditNoteSchema>(
            `/api/creditNotes/${creditNoteId}`,
          ).then((res) => res.data);
          // Apply the credit to the old invoice up to the total amount due
          const applicationPayload: ApplyUnapplyRequest = {
            applications: [
              {
                amount: oldInvoice.amountDue,
                invoiceId: oldInvoice.id,
                type: AppTrxnType.APPLICATION,
              },
            ],
          };
          await apiPost(
            `/api/applications/${TransactableSourceType.credit}/${creditNote.credit.id}`,
            applicationPayload,
          ).then((res) => res.data);
        } catch (ex) {
          output.applyCreditToInvoiceError = ex as Error;
        }
      }
      return output;
    },

    onSuccess: (response, input, context) => {
      // Put new invoice in query cache
      queryClient.setQueryData(
        invoiceServiceQueryKeys.invoiceDetail(
          response.creditAndRebillResponse.invoice.id,
        ),
        response.creditAndRebillResponse.invoice,
      );
      // invalidate cache for old invoice / invoice list
      queryClient.invalidateQueries({
        queryKey: invoiceServiceQueryKeys.htmlTemplate(input.invoice.id),
      });
      queryClient.invalidateQueries({
        queryKey: invoiceServiceQueryKeys.invoiceDetail(input.invoice.id),
      });
      queryClient.invalidateQueries({
        queryKey: invoiceServiceQueryKeys.invoiceList(),
      });

      onSuccess && onSuccess(response, input, context);
    },
    ...restOptions,
  });
}

export function useCreateOneTimeInvoice(
  options: Partial<
    UseMutationOptions<
      IInvoiceRespSchema,
      unknown,
      {
        billGroupId: string;
        invoice: IOneTimeInvoiceRequestSchema;
      }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<
    IInvoiceRespSchema,
    unknown,
    {
      billGroupId: string;
      invoice: IOneTimeInvoiceRequestSchema;
    }
  >({
    mutationFn: ({ billGroupId, invoice }) =>
      apiPost<any>(
        `/api/billGroups/${billGroupId}/invoices/onetime`,
        invoice,
      ).then((res) => res.data),

    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        invoiceServiceQueryKeys.invoiceDetail(data.id),
        data,
      );
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useGetInvoiceUnpaidInvoice(
  invoiceId: string,
  options: Partial<UseQueryOptions<UnpaidInvoiceShareLink[]>> = {},
) {
  return useQuery({
    queryKey: [...invoiceServiceQueryKeys.invoiceUnpaidInvoices(invoiceId)],
    queryFn: () =>
      apiGet<UnpaidInvoiceShareLink[]>(
        `/api/invoices/${invoiceId}/unpaidInvoices`,
      ).then((res) =>
        res.data.sort((a, b) =>
          sortByDate(a.invoice.dueDate, b.invoice.dueDate),
        ),
      ),
    refetchOnWindowFocus: false,
    ...options,
  });
}

/**
 * Email invoices to customers for a list of provided invoices
 * onProgress is called after each invoice is processed so results can be displayed to the user
 */
export function useMassEmailCustomerInvoices(
  options: Partial<
    UseMutationOptions<
      MassEmailCustomerInvoicesResults[],
      unknown,
      {
        invoiceIds: string[];
        abortSignal?: AbortSignal;
      }
    >
  > & {
    onProgress?: (props: {
      result: MassEmailCustomerInvoicesResults;
      results: MassEmailCustomerInvoicesResults[];
    }) => void;
  } = {},
) {
  const { onProgress, ...restOptions } = options;
  return useMutation<
    MassEmailCustomerInvoicesResults[],
    unknown,
    {
      invoiceIds: string[];
      abortSignal?: AbortSignal;
    }
  >({
    mutationFn: async ({ abortSignal, invoiceIds }) => {
      const results: MassEmailCustomerInvoicesResults[] = [];
      const CONCURRENCY = 5;

      for (const invoiceIdsGroup of splitArrayToMaxSize(
        invoiceIds,
        CONCURRENCY,
      )) {
        await Promise.all(
          invoiceIdsGroup.map(async (invoiceId) => {
            const result: MassEmailCustomerInvoicesResults = {
              progress: Math.floor((results.length / invoiceIds.length) * 100),
              invoiceId,
              success: true,
            };
            if (abortSignal?.aborted) {
              result.success = false;
              result.error = 'Cancelled by user';
              return;
            }
            try {
              await apiPost(`/api/invoices/${invoiceId}/sendBillingEmail`, {
                overrideCcEmails: null,
              });
            } catch (ex) {
              if (isAxiosError(ex) && (ex.response?.data as any)?.message) {
                result.error = (ex.response?.data as any)?.message;
              } else {
                result.error =
                  ex instanceof Error ? ex.message : JSON.stringify(ex);
              }
              result.success = false;
            }
            result.progress = Math.floor(
              (results.length / invoiceIds.length) * 100,
            );
            results.push(result);
            onProgress?.({ result, results });
          }),
        );
      }
      return results;
    },
    ...restOptions,
  });
}

export function useMassCreditInvoices(
  options: Partial<
    UseMutationOptions<
      MassCreditInvoicesResults[],
      unknown,
      {
        name: string;
        reason: string;
        customFields?: ICustomFieldRecordSchema;
        rows: MassCreditInvoiceRow[];
        abortSignal?: AbortSignal;
      }
    >
  > & {
    onProgress?: (props: {
      result: MassCreditInvoicesResults;
      results: MassCreditInvoicesResults[];
    }) => void;
  } = {},
) {
  const { onProgress, ...restOptions } = options;
  return useMutation<
    MassCreditInvoicesResults[],
    unknown,
    {
      name: string;
      reason: string;
      customFields?: ICustomFieldRecordSchema;
      rows: MassCreditInvoiceRow[];
      abortSignal?: AbortSignal;
    }
  >({
    mutationFn: async ({ abortSignal, name, reason, customFields, rows }) => {
      const results: MassCreditInvoicesResults[] = [];
      const CONCURRENCY = 5;

      for (const invoiceIdsGroup of splitArrayToMaxSize(rows, CONCURRENCY)) {
        await Promise.all(
          invoiceIdsGroup.map(async (row) => {
            const result: MassCreditInvoicesResults = {
              progress: Math.floor((results.length / rows.length) * 100),
              invoiceId: row.invoiceId,
              success: true,
            };
            if (abortSignal?.aborted) {
              result.success = false;
              result.error = 'Cancelled by user';
              return;
            }
            try {
              // Create Credit
              const creditPayload: IUpsertCreditSchema = {
                billGroupId: row.billGroupId,
                status: CreditStatusEnum.ACTIVE,
                type: CreditTypeEnum.SERVICE,
                currency: row.currency,
                amount: row.amount,
                refundable: false,
                expirationDate: null,
                reason,
                name,
                customFields,
              };
              const credit = await apiPost<IGetCreditSchema>(
                `/api/accounts/${row.accountId}/billGroups/${row.billGroupId}/credits`,
                creditPayload,
              ).then((res) => res.data);

              result.creditId = credit.id;

              // Apply Credit to Invoice
              const applicationRequest: ApplyUnapplyRequest = {
                applications: [
                  {
                    amount: row.amount,
                    invoiceId: row.invoiceId,
                    type: AppTrxnType.APPLICATION,
                  },
                ],
              };
              result.allocations =
                await apiPost<AggregatedTransactablesResponse>(
                  `/api/applications/${TransactableSourceType.credit}/${credit.id}`,
                  applicationRequest,
                ).then((res) => res.data);
            } catch (ex) {
              if (isAxiosError(ex) && (ex.response?.data as any)?.message) {
                result.error = (ex.response?.data as any)?.message;
              } else {
                result.error =
                  ex instanceof Error ? ex.message : JSON.stringify(ex);
              }
              result.success = false;
            }
            result.progress = Math.floor((results.length / rows.length) * 100);
            results.push(result);
            onProgress?.({ result, results });
          }),
        );
      }
      return results;
    },
    ...restOptions,
  });
}
