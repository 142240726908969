import { DPPropGetter, useDatePicker } from '@rehookify/datepicker';
import { parse as parseDate } from 'date-fns/parse';
import { FaCaretDown } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import MCustomIconButton from '../MCustomIconButton';
import MText from '../MText';
import { MFlex } from '../chakra';

interface DateHeaderProps {
  month: string;
  year: string;
  isDisabled: boolean;
  isYearViewActive: boolean;
  mode?: 'DATE' | 'MONTH' | 'YEAR';
  toggleYear: () => void;
  nextMonthBtnProps: DPPropGetter;
  previousMonthBtnProps: DPPropGetter;
  nextYearBtnProps: ReturnType<
    typeof useDatePicker
  >['propGetters']['nextYearsButton'];
  previousYearBtnProps: ReturnType<
    typeof useDatePicker
  >['propGetters']['previousYearsButton'];
  setYear: (d: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

/**
 * Displays the month and year and the previous and next month buttons
 */
export const DateHeader = ({
  month,
  year,
  isDisabled,
  isYearViewActive,
  mode,
  toggleYear,
  previousMonthBtnProps,
  nextMonthBtnProps,
  previousYearBtnProps,
  nextYearBtnProps,
  setYear,
  minDate,
  maxDate,
}: DateHeaderProps) => {
  const prevButtonProps = isYearViewActive
    ? previousYearBtnProps()
    : previousMonthBtnProps;
  const nextButtonProps = isYearViewActive
    ? nextYearBtnProps()
    : nextMonthBtnProps;

  return (
    <MFlex
      as="header"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
      minH="32px"
    >
      <MFlex cursor={isDisabled ? undefined : 'pointer'} onClick={toggleYear}>
        <MText fontSize="md" pl={3} fontWeight={600}>
          {month} {year}
        </MText>
        {mode === 'DATE' && (
          <MCustomIconButton
            aria-label="Toggle year view"
            bg="none"
            ml={1}
            btnSize={4}
            icon={FaCaretDown}
            isDisabled={isDisabled}
          />
        )}
      </MFlex>
      {mode === 'MONTH' ? (
        <MFlex>
          <MCustomIconButton
            aria-label={'Previous year'}
            bg="none"
            btnSize={6}
            p="4"
            mr={1}
            color="tPurple.base"
            icon={MdChevronLeft}
            {...prevButtonProps}
            isDisabled={
              isDisabled || year === minDate?.getFullYear().toString()
            }
            onClick={() =>
              setYear(
                parseDate(`${+year - 1} ${month}`, `yyyy MMMM`, new Date()),
              )
            }
          />
          <MCustomIconButton
            aria-label={'Next year'}
            bg="none"
            btnSize={6}
            p="4"
            icon={MdChevronRight}
            {...nextButtonProps}
            isDisabled={
              isDisabled || year === maxDate?.getFullYear().toString()
            }
            onClick={() =>
              setYear(
                parseDate(`${+year + 1} ${month}`, `yyyy MMMM`, new Date()),
              )
            }
          />
        </MFlex>
      ) : (
        <MFlex>
          <MCustomIconButton
            aria-label={isYearViewActive ? 'Previous year' : 'Previous month'}
            bg="none"
            btnSize={6}
            p="4"
            mr={1}
            color="tPurple.base"
            icon={MdChevronLeft}
            isDisabled={isDisabled}
            {...prevButtonProps}
          />
          <MCustomIconButton
            aria-label={isYearViewActive ? 'Next year' : 'Next month'}
            bg="none"
            btnSize={6}
            p="4"
            icon={MdChevronRight}
            isDisabled={isDisabled}
            {...nextButtonProps}
          />
        </MFlex>
      )}
    </MFlex>
  );
};
