import { MBox, MGrid } from '~app/components/Monetize';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import { formatInteger } from '~app/utils';
import { hasVariableAmountProduct } from '~app/utils/quotes';
import { QuoteFormTotalRow } from '../../Quote/components/QuoteFormTotalRow';
import { QuoteFormTotalRowAmendV2 } from '../../Quote/components/QuoteFormTotalRowAmendV2';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteInternalOfferingTable } from './QuoteInternalOfferingTable';

interface QuoteInternalOfferingsProps {}

export const QuoteInternalOfferings = ({}: QuoteInternalOfferingsProps) => {
  const { quoteData, useAmendmentV2 } = useQuoteContext();
  const { quote } = quoteData;

  const anyVariableAmountProducts = hasVariableAmountProduct(quote);
  const offeringCount = quote?.quoteOfferings.filter(
    (quoteOffering) => quoteOffering.parentQuoteOfferingId === null,
  ).length;

  return (
    <QuoteBodyAccordion
      title={`Offerings (${formatInteger(offeringCount ?? 0)} included)`}
    >
      <QuoteInternalOfferingTable />

      <MGrid
        gridTemplateColumns={useAmendmentV2 ? '1fr 2fr' : '8fr 3.2fr'}
        gridTemplateRows="1fr"
        columnGap={4}
        w="full"
      >
        <MBox />

        {useAmendmentV2 ? (
          <QuoteFormTotalRowAmendV2
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        ) : (
          <QuoteFormTotalRow
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
      </MGrid>
    </QuoteBodyAccordion>
  );
};
