import { useDisclosure } from '@chakra-ui/hooks';
import { useEffect, useState } from 'react';
import { useGetAccountContacts } from '~app/api/contactsService';
import { buildFilterParamsRequestObject } from '~app/utils';
import {
  DEFAULT_API_RESPONSE,
  DEFAULT_PAGER,
  FilterType,
  GetListApiFilter,
  IContactRespSchema,
  TDataTablePager,
} from '~types';

export const useContacts = (accountId: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  // might be dynamic in the future. Keep static for now
  const [searchKey] = useState<string>('fullName');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const {
    isOpen: isModalOpen,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  useEffect(() => {
    if (filters || searchTerm || pager) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager, searchKey]);

  const {
    isLoading,
    isFetched,
    isRefetching,
    data: listData,
  } = useGetAccountContacts(
    {
      accountId,
      config: pager,
      filters: filterParams,
    },
    { meta: { showErrorToast: true } },
  );

  const [selected, setSelected] = useState<null | IContactRespSchema>(null);

  const onResetFilter = () => {
    setFilters([]);
  };

  const openCreateModal = () => {
    onOpenModal();
  };

  const openEditModal = (row: IContactRespSchema) => {
    setSelected(row);
    onOpenModal();
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    searchKey,
    searchTerm,
    setSearchTerm,
    totalRecords: listData?.totalElements || 0,
    loading: isLoading || (isRefetching && !isFetched),
    isModalOpen,
    onOpenModal,
    onCloseModal: () => {
      setSelected(null);
      onCloseModal();
    },
    openCreateModal,
    openEditModal,
    listData: listData || DEFAULT_API_RESPONSE,
    selected,
    setSelected,
  };
};
