import { Heading } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  MBox,
  MCustomSelect,
  MFlex,
  MFormField,
  MText,
} from '~app/components/Monetize';
import { getQuoteBaseRoute } from '~app/constants/routes';
import { useNavigatorPermission } from '~app/hooks/useNavigatorPermission';
import {
  COLLABORATION_ACCESS_DISPLAY,
  CollaborationAccessEnum,
  CollaborationAccessEnumZ,
  CollaborationAccessLevelEnum,
  QuoteStatusEnum,
} from '~app/types';
import { CAN_SHARE_QUOTE_INTERNALLY_STATUSES } from '../../../constants/quotes';
import { objectToSelectOptions } from '../../../utils/misc';
import { QuoteCollaborationCopyLink } from './QuoteCollaborationCopyLink';

interface QuoteCollaborationInternalProps {
  quoteId: string;
  quoteExpirationDate: string;
  quoteStatus: QuoteStatusEnum;
  collaborationAccess: CollaborationAccessEnum;
  accessLevel: CollaborationAccessLevelEnum | null;
  onCollaborationAccessChange: (
    collaborationAccess: CollaborationAccessEnum,
  ) => void;
}

const QuoteCollaborationFormSchema = z.object({
  collaborationAccess: CollaborationAccessEnumZ,
});

export const internalCollaborationOptions = objectToSelectOptions(
  COLLABORATION_ACCESS_DISPLAY,
);

export const QuoteCollaborationInternal = ({
  quoteId,
  quoteExpirationDate,
  collaborationAccess,
  accessLevel,
  quoteStatus,
  onCollaborationAccessChange,
}: QuoteCollaborationInternalProps) => {
  const { canCopyClipboard } = useNavigatorPermission();

  const quoteLink = useMemo(
    () => `${location.origin}${getQuoteBaseRoute(quoteId)}`,
    [quoteId],
  );

  const {
    control: collaborationControl,
    setValue: setCollaborationControlValue,
    formState: { errors: collaborationErrors },
  } = useForm({
    resolver: zodResolver(QuoteCollaborationFormSchema),
    defaultValues: {
      collaborationAccess,
    },
  });

  useEffect(() => {
    if (collaborationAccess) {
      setCollaborationControlValue('collaborationAccess', collaborationAccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaborationAccess]);

  return (
    <>
      <Heading as="h4" fontSize="sm" mb={2}>
        Internally
      </Heading>
      <MText mb={2}>
        Allow members from your organization to view or edit this Quote.
        Approvers already have access.
      </MText>

      <MFlex alignItems="center" justifyContent="space-between">
        <MBox>
          <MFormField
            error={collaborationErrors.collaborationAccess}
            label="Access Type"
          >
            <Controller
              name="collaborationAccess"
              control={collaborationControl}
              render={({ field: { value, onChange, ...rest } }) => (
                <MCustomSelect
                  items={internalCollaborationOptions}
                  itemTitle="title"
                  itemValue="value"
                  value={value}
                  onChange={(value) => {
                    onChange(value);
                    onCollaborationAccessChange(value as any);
                  }}
                  {...rest}
                  isDisabled={
                    accessLevel === CollaborationAccessLevelEnum.READ ||
                    !CAN_SHARE_QUOTE_INTERNALLY_STATUSES.has(quoteStatus)
                  }
                />
              )}
            />
          </MFormField>
        </MBox>
        <MBox mt="7">
          <QuoteCollaborationCopyLink
            canCopyClipboard={canCopyClipboard}
            link={quoteLink}
          />
        </MBox>
      </MFlex>
    </>
  );
};
