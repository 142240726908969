import { InputProps } from '@chakra-ui/react';
import { FunctionComponent as FC, useState } from 'react';
import { useGetListData } from '~app/api/queryUtils';
import {
  MCustomSelect,
  MFlex,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MainSearchInput } from '~app/components/Monetize/MCustomSelect/components/MainSearchInput';
import {
  ONE_TIME_PRODUCT_TYPES,
  PRODUCT_TYPE_DISPLAY_SHORT,
  SUBSCRIPTION_OFFERING_TYPE_PRODUCT_TYPES,
} from '~app/constants/products';
import { useDebounce } from '~app/hooks/useDebounce';
import {
  DEFAULT_PAGER,
  IProductResSchema,
  IUsageTypeResSchema,
  OfferingTypesEnum,
  ProductStatusEnum,
  ProductTypeEnum,
  TDataTablePager,
} from '~app/types';
import { StandardOptionRenderProps } from '~app/types/mCustomSelectTypes';
import { sortByProductType } from '~app/utils';

const pager: TDataTablePager = {
  ...DEFAULT_PAGER,
  rows: 50,
  sortField: 'name',
  sortOrder: 1,
};

interface ProductSearchProps extends InputProps {
  selected: IProductResSchema[];
  offeringType: OfferingTypesEnum;
  onSelect?: (e: any) => void;
}

export interface ProductSearchRenderItemProps
  extends StandardOptionRenderProps {
  isDisabled: boolean;
  tooltipLabel?: string;
  rightLabel?: string;
}

export const ProductSearchRenderItem = ({
  content,
  isSubtitle,
  isSelected,
  isHighlight,
  isDisabled,
  tooltipLabel = '',
  rightLabel,
}: ProductSearchRenderItemProps) => {
  return (
    <MTooltip label={tooltipLabel} placement="bottom-start">
      <MFlex
        justifyContent={'space-between'}
        alignItems="center"
        w="full"
        position="relative"
        role="group"
        columnGap={1}
      >
        <MText
          color="inherit"
          isTruncated
          noOfLines={1}
          fontSize={isSubtitle ? '12px' : 'sm'}
          mt="0px"
          fontWeight={400}
          display="block"
        >
          {content}
        </MText>

        <MText
          fontSize={'xs'}
          color={isDisabled ? 'tGray.disabledText' : 'tGray.darkPurple'}
        >
          {rightLabel}
        </MText>
      </MFlex>
    </MTooltip>
  );
};

const ProductSearch: FC<ProductSearchProps> = ({
  offeringType,
  isDisabled,
  selected,
  onSelect = (i: any) => {},
}: ProductSearchProps) => {
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 300);

  // Only query from server products that match selected type
  let productTypesToInclude = Array.from(
    SUBSCRIPTION_OFFERING_TYPE_PRODUCT_TYPES,
  );
  if (offeringType === OfferingTypesEnum.ONETIME) {
    productTypesToInclude = Array.from(ONE_TIME_PRODUCT_TYPES);
  } else if (offeringType === OfferingTypesEnum.MIN_COMMIT) {
    productTypesToInclude = [ProductTypeEnum.MIN_COMMIT];
  } else if (offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL) {
    productTypesToInclude = [ProductTypeEnum.ADVANCE];
  }

  const { data, isFetching: loading } = useGetListData<
    IProductResSchema,
    {
      products: IProductResSchema[];
      items: any[];
      disabledItems: any[];
    }
  >(
    'productCatalogProducts',
    {
      config: pager,
      filters: {
        status: ProductStatusEnum.ACTIVE,
        name: { contains: debouncedQuery },
        productType: {
          in: productTypesToInclude,
        },
      },
    },
    {
      select: (currData) => {
        let disabledItems: any[] = [];
        if (!currData?.content) {
          return {
            disabledItems: [],
            items: [],
            products: [],
          };
        }

        const products = sortByProductType(currData.content, 'name');
        const hasMinCommit = selected.find(
          (product) => product.productType === ProductTypeEnum.MIN_COMMIT,
        );

        if (hasMinCommit) {
          // Only allow one min commit product to be selected
          disabledItems = [
            ...disabledItems,
            ...products.filter(
              (product) => product.productType === ProductTypeEnum.MIN_COMMIT,
            ),
          ];
        }

        const selectedUsageTypeIds = selected
          .reduce(
            (prev, item) =>
              item.usageTypes ? prev.concat(item.usageTypes) : prev,
            [] as IUsageTypeResSchema[],
          )
          .map((usageType) => usageType.id);
        const sameUsageTypeProducts = products.filter(
          ({ usageTypes }) =>
            usageTypes &&
            usageTypes.find((usageType) =>
              selectedUsageTypeIds.includes(usageType.id),
            ),
        );

        if (sameUsageTypeProducts) {
          // Only allow one unique usage type product
          disabledItems = [...disabledItems, ...sameUsageTypeProducts];
        }

        const selectedIds = new Set(selected.map((product) => product.id));

        return {
          products,
          // remove products from list after selection and add right label
          items: products.filter((product) => !selectedIds.has(product.id)),
          disabledItems,
        };
      },
      placeholderData: (previousData, previousQuery) => previousData,
      refetchOnWindowFocus: false,
    },
  );
  const { disabledItems, items } = data || {
    disabledItems: [],
    items: [],
    products: [],
  };

  const handleChange = (item: any) => {
    setQuery('');
    onSelect?.(item);
  };

  return (
    <MCustomSelect
      name="productSelect"
      maxW={330}
      clearable
      placeholder="Search products to add to this offering"
      itemTitle="name"
      itemValue="id"
      useExternalQuery
      externalQuery={query}
      onChangeQuery={setQuery}
      items={items}
      useMainInputAsSearchInput
      MainInputComponent={MainSearchInput}
      returnItem
      loading={loading}
      isDisabled={
        isDisabled ||
        (offeringType === OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL &&
          selected.length === 1)
      }
      isSelectOpen={false}
      onChange={handleChange}
      disabledItems={disabledItems}
      renderItemContent={({
        title,
        item,
        isSubtitle,
        isDisabled: itemIsDisabled,
        isSelected,
        isHighlight,
      }) => {
        const { productType, usageTypes: itemUsageType } = item;
        const isUsageTypeProduct = productType === ProductTypeEnum.USAGE;
        let tooltipLabel = '';
        if (isUsageTypeProduct && itemIsDisabled) {
          const usageTypeName = itemUsageType[0]?.name; // each usage prod contain its UsageTypes
          const usageTypeId = itemUsageType[0]?.id;
          const selectedProd = selected.filter(
            ({ usageTypes }) => usageTypes && usageTypes[0]?.id === usageTypeId,
          );
          tooltipLabel = `Usage type ${usageTypeName} for ${title} is already used by ${selectedProd[0]?.name}. Each usage type may only be in each Offering once.`;
        }

        return (
          <ProductSearchRenderItem
            content={title}
            isSubtitle={isSubtitle}
            isSelected={isSelected}
            isHighlight={isHighlight}
            isDisabled={itemIsDisabled}
            rightLabel={
              PRODUCT_TYPE_DISPLAY_SHORT[productType as ProductTypeEnum]
            }
            tooltipLabel={tooltipLabel}
          />
        );
      }}
    />
  );
};

export default ProductSearch;
