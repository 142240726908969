import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { apiGet } from '~api/axios';
import {
  TimeZoneData,
  TimeZoneOption,
  TimeZoneResponse,
} from '../types/timezoneTypes';

export const timezoneKeys = {
  base: ['timezone'] as const,
};

/**
 * Returns all time zones available in the system.
 * Transforms data into a list of items for display as well as a map for quick lookups.
 */
export const useGetTimezones = (
  options: Partial<
    UseQueryOptions<TimeZoneResponse, unknown, TimeZoneData>
  > = {},
) => {
  return useQuery({
    queryKey: [...timezoneKeys.base],
    queryFn: () =>
      apiGet<TimeZoneResponse>('/api/timezones').then((res) => res.data),
    select: (data) => {
      return data.timezones
        .map((timeZone): TimeZoneOption => {
          const { label, offset, value } = timeZone;
          let prefix = `(${offset}) `;
          if (offset === 'Z') {
            prefix = '';
          }
          return {
            ...timeZone,
            displayLabel: `${prefix}${label} - ${value}`,
          };
        })
        .reduce(
          (acc: TimeZoneData, timeZone) => {
            acc.timezones.push(timeZone);
            acc.timezonesByValue[timeZone.value] = timeZone;
            return acc;
          },
          {
            timezones: [],
            timezonesByValue: {},
          },
        );
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    // 24 hours
    staleTime: 1000 * 60 * 60 * 24,
    // 24 hours
    gcTime: 1000 * 60 * 60 * 24,
    ...options,
  });
};
