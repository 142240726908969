import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useMassCreditInvoices } from '../../../../api/invoiceService';
import { MCenterModal, MFlex, MText } from '../../../../components/Monetize';
import { logger } from '../../../../services/logger';
import {
  InvoiceSummaryResp,
  MassCreditInvoice,
  MassCreditInvoiceRow,
  MassCreditInvoiceSchema,
  NumberStyleEnum,
} from '../../../../types';
import { formatCurrency, formatInteger, pluralize } from '../../../../utils';
import { useMassInvoiceAction } from '../useMassInvoiceAction';
import { MassEmailModalFooter } from './MassCreditModalFooter';
import { MassCreditModalForm } from './MassCreditModalForm';

interface MassCreditModalProps {
  selectedRows: InvoiceSummaryResp[];
  /**
   * Used to determine correct order of selected rows
   */
  visibleRows: InvoiceSummaryResp[];
  onClose: () => void;
}

export const MassCreditModal = ({
  selectedRows: selectedRowsUnordered,
  visibleRows,
  onClose,
}: MassCreditModalProps) => {
  const { mutate: doMassCreditInvoices, isPending: isLoading } =
    useMassCreditInvoices({
      onProgress: (progress) => onProgress(progress),
      onSuccess: (results) => showCompletionToast(results),
      meta: { showErrorToast: true },
    });

  const {
    abortController,
    initiateSubmit,
    hasSubmitted,
    onProgress,
    progress,
    rows,
    showCompletionToast,
  } = useMassInvoiceAction({
    selectedRows: selectedRowsUnordered,
    visibleRows,
    isLoading,
    verbiage: {
      successTitle: 'Success',
      successSingularMessage: 'Credited 1 invoice',
      successSuffix: 'credited',
      failureTitle: 'Crediting Failed',
      failureSingularMessage: 'Failed to credit invoices',
      failureSuffix: 'failed to be credited',
      partialFailureTitle: 'Partial Failure',
      partialFailureSuffix: 'failed to credit',
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<MassCreditInvoice>({
    resolver: zodResolver(MassCreditInvoiceSchema),
    defaultValues: async () => ({
      name: 'Bulk invoice credit',
      reason: '',
      customFields: {},
      rows: rows.map((row) => ({
        invoiceId: row.id,
        accountId: row.account.id,
        billGroupId: row.billGroupId,
        amountDue: row.amountDue,
        currency: row.currency,
        amount: row.amountDue,
      })),
    }),
  });

  function onSubmit(data: MassCreditInvoice) {
    const rowCount = data.rows.length;
    const amountPerCurrency = Object.values(
      data.rows.reduce((acc: Record<string, MassCreditInvoiceRow[]>, row) => {
        if (!acc[row.currency]) {
          acc[row.currency] = [];
        }
        acc[row.currency].push(row);
        return acc;
      }, {}),
    ).map((rows) => ({
      currency: rows[0].currency,
      invoiceCount: rows.length,
      amount: rows.reduce((acc, row) => {
        return acc + row.amount;
      }, 0),
    }));
    initiateSubmit({
      data,
      callback: (newAbortController) => {
        doMassCreditInvoices({
          abortSignal: newAbortController.signal,
          name: data.name,
          reason: data.reason,
          customFields: data.customFields || {},
          rows: data.rows,
        });
      },
      confirm: {
        title: `Credit ${formatInteger(rowCount)} ${pluralize(
          'Invoice',
          rowCount,
        )}?`,
        content: (
          <MFlex flexDirection="column">
            <MText>
              You are about to create credits for the following amounts:
            </MText>
            {amountPerCurrency.map(({ currency, invoiceCount, amount }) => (
              <MText key={currency} pt="1" fontWeight="600">
                {currency}{' '}
                {formatCurrency(amount, {
                  currency,
                  style: NumberStyleEnum.DECIMAL,
                })}{' '}
                {formatInteger(invoiceCount)}{' '}
                {pluralize('invoice', invoiceCount)}
              </MText>
            ))}
          </MFlex>
        ),
        yesButton: `Create ${pluralize('Credit', rowCount)}`,
      },
    });
  }

  function onError(error: any) {
    logger.error('onSubmit', error);
  }

  function handleCancel() {
    if (isLoading) {
      abortController.abort();
    } else {
      onClose();
    }
  }

  return (
    <MCenterModal
      blockScrollOnMount
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      showCloseButton={!isLoading}
      modalTitle={`Credit Invoices`}
      size="6xl"
      renderFooter={() => (
        <MassEmailModalFooter
          total={rows.length}
          success={progress?.success}
          failure={progress?.failure}
          isLoading={isLoading}
          hasSubmitted={hasSubmitted}
          onCancel={handleCancel}
        />
      )}
      isOpen
      onClose={onClose}
    >
      <form
        id="mass-email-form"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
      >
        <MassCreditModalForm
          rows={rows}
          isLoading={isLoading}
          hasSubmitted={hasSubmitted}
          control={control}
          errors={errors}
          setValue={setValue}
          watch={watch}
        />
      </form>
    </MCenterModal>
  );
};
