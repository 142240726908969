import {
  Box,
  Button,
  Flex,
  List,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import clamp from 'lodash/clamp';
import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import {
  CUSTOM_SELECT_SUBTITLE_TYPE,
  OPTION_TYPE_FIELD,
} from '~app/constants/customSelect';
import {
  CustomSelectItem,
  MCustomSelectProps,
} from '~app/types/mCustomSelectTypes';
import { formatInteger } from '../../../utils';
import MText from '../MText';
import { MBox, MCheckbox, MDivider, MFlex } from '../chakra';
import MCustomSelectItemListRender from './MCustomSelectItemListRender';
import CustomItemList from './components/CustomItemList';
import { MainInput } from './components/MainInput';
import SearchInput from './components/SearchInput';
import { useCursor } from './components/useCursor';
import { useQuery } from './components/useQuery';
import { useSelectData } from './components/useSelectData';

export const MCustomSelect = React.forwardRef<any, MCustomSelectProps>(
  (
    {
      name,
      children,
      items: externalItems = [],
      /** item property: to be displayed to user */
      itemTitle = 'title',
      /** item property: value to be returned */
      itemValue = 'value',
      itemSearch = itemTitle,
      omitContainsItemSearch = false,
      returnItem = false,
      endpoint = '',
      additionalSearchParams,
      internalFilterFields,
      getByIdEndpointFn,
      getByIdRecordId,
      loadAll = false,
      isReadOnly = false,
      value,
      /** TODO: Ideally this would be true by default, but causes some tests to fail */
      isLazy = false,
      lazyBehavior = 'unmount',
      onChange,
      onChangeCheckbox,
      popOverProps,
      popOverContentProps = {},
      scrollableRegionProps,
      focusBorderColor,
      placeholder,
      loading: externalLoading = false,
      loadingRenderer,
      useExternalQuery = false,
      externalQuery = '',
      onChangeQuery,
      showQueryInput = false,
      renderPreContent = () => null,
      renderItemContent,
      showContentInInput,
      renderContentInInput,
      disabledItems = [],
      inputProps: inputPropsFromParent,
      renderRightElement,
      MainInputComponent,
      useMainInputAsSearchInput,
      checkboxDisplay,
      showCheckCount = false,
      clearAllCheckValues,
      closeButtonText,
      closeOnBlur = true,
      externalOnClose,
      prependItems,
      prependItemsOutsideScrollArea,
      clearable = false,
      onClear,
      onMultiSelectSubmit,
      onOpenStateChange,
      isSelectOpen = false,
      /** this is a bit of hack because this boolean must be set to true if this component is passed a value for renderItemContent that is rendering a UserAvatar along with the user name */
      displayAvatar = false,
      renderInputLeftElement = false,
      inputLeftElementContent,
      isDisabled = false,
      usePortal = false,
      transformDataFromApi,
      useHighlightedItemList = false,
      hideHighlightSearchResultsCount = false,
      useRawValue,
      showItemDivider = false,
      skipFilter = false,
      onFetchedItems,
      isTruncatedInputTitle = false,
      onNextCall,
      hasMoreDataToCall = false,
      showAllSelect = false,
      onAllSelect,
      rightLabel,
      ...rest
    }: MCustomSelectProps,
    ref,
  ) => {
    const listId = `customSelect-${name}-list`;
    // Pick input related props from input
    const inputProps = {
      ...inputPropsFromParent,
      isDisabled,
      isReadOnly,
    };
    const { query, setQuery } = useQuery({
      useExternalQuery,
      externalQuery,
      onChangeQuery,
    });
    const internalDisabledItems = disabledItems || [];

    const isDisabledItem = (item: any) => {
      const isObject = typeof item === 'object';
      if (isSubtitleItem(item)) {
        return true;
      }
      if (returnItem) {
        const foundDisabledItem = internalDisabledItems.find((dI) => {
          return (
            dI[itemValue as string] ===
            (isObject ? item[itemValue as string] : item)
          );
        });
        return !!foundDisabledItem;
      }
      const foundDisabledItem = internalDisabledItems.find((dI) => {
        return dI === (isObject ? item[itemValue as string] : item);
      });
      return !!foundDisabledItem;
    };

    const isSelectedItem = (item: any) => {
      const val = getValue(item);
      /**
       * if there is no value then we should return false
       * Since undefined === undefined is true in js
       */
      if (!val) {
        return false;
      }

      if (returnItem) {
        if (Array.isArray(value)) {
          const foundItem = value.find((currValue) => {
            return currValue[itemValue as string] === val;
          });
          return !!foundItem;
        }
        return value && value[itemValue as string] === val;
      } else {
        if (Array.isArray(value)) {
          const foundItem = value.find((currValue) => {
            return currValue === val;
          });
          return !!foundItem;
        }
        return value === val;
      }
    };

    const isSubtitleItem = (item: any) =>
      typeof item === 'object'
        ? item[OPTION_TYPE_FIELD] === CUSTOM_SELECT_SUBTITLE_TYPE
        : false;

    const {
      getValue,
      items,
      getTitle,
      getTitleFromValue,
      hasMore,
      totalRecordCount,
      loading,
    } = useSelectData({
      endpoint: endpoint!,
      additionalSearchParams,
      getByIdEndpointFn,
      getByIdRecordId: getByIdRecordId || value,
      isDisabled: isReadOnly || isDisabled,
      internalFilterFields,
      loadAll,
      itemValue,
      externalItems,
      itemTitle,
      query,
      itemSearch,
      omitContainsItemSearch,
      isSubtitleItem,
      returnItem,
      transformDataFromApi,
      useRawValue,
      skipFilter,
      onFetchedItems,
    });

    const [isOpen, setIsOpen] = React.useState<boolean>(isSelectOpen || false);
    const [isAllSelected, setIsAllSelected] = React.useState(false);

    const itemsWithoutSubtitle = useMemo(() => {
      return items.filter((item) => !isSubtitleItem(item));
    }, [items]);

    const onOpen = () => setIsOpen(!isOpen);
    const onClose = () => {
      if (!isOpen) {
        return;
      }
      setCursor(-1);
      setQuery('');
      setIsOpen(false);
      externalOnClose && externalOnClose();
      mainInputRef?.current?.focus();
    };
    const searchResultRef = React.useRef<any>();
    const mainInputRef = React.useRef<any>();
    const resultListRef = React.useRef<any>();

    const isItemSelectable = (item: any) => {
      if (typeof item === 'object') {
        if (item[OPTION_TYPE_FIELD] === CUSTOM_SELECT_SUBTITLE_TYPE) {
          return false;
        } else if (item.item && item.isHeading) {
          // is prepended items and is considered heading
          return false;
        }
      }
      return true;
    };

    const itemRefs = useRef<{ [index: number]: HTMLDivElement }>({} as any);

    const {
      cursor,
      setCursor,
      setCursorCurrent,
      setNextCursor,
      setPrevCursor,
      setCursorAndScroll,
    } = useCursor({
      isItemSelectable,
      items,
      prependItems,
      resultListRef,
      isDisabledItem,
      itemRefs,
    });

    const handleChange = (item: any, event?: any) => {
      if (isSubtitleItem(item)) {
        return;
      }
      const returnValue = returnItem ? item : getValue(item);
      if (onChange) {
        onChange(returnValue);
      }
      onChangeCheckbox && onChangeCheckbox(returnValue, event);
      if (!closeButtonText && !checkboxDisplay) {
        onClose();
      }
    };

    const onQueryInputChange = (queryString: string) => {
      setQuery(queryString);
      setCursor(-1);
    };

    const findItemStartsWith = (key: string) =>
      items.findIndex((item: any) => {
        const isObject = typeof item === 'object';

        if (isObject) {
          return item[itemSearch!]
            ?.toLowerCase()
            ?.startsWith(key.toLowerCase());
        }
        return item?.toLowerCase()?.startsWith(key.toLowerCase());
      });

    const onKeyDown = (e: any) => {
      if (e.key === 'Enter' && !isOpen) {
        return null;
      }
      if ((e.shiftKey && e.key === 'Tab') || e.key === 'Tab') {
        return null;
      }
      e.stopPropagation();
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          if (!isOpen) {
            onOpen();
            setCursor(0);
          } else {
            isOpen && setNextCursor();
          }
          break;
        case 'ArrowUp':
          e.preventDefault();
          isOpen && setPrevCursor();
          break;
        case 'Escape':
          onClose();
          setCursor(-1);
          e.preventDefault();
          onClose();
          mainInputRef?.current?.focus();
          break;
        case 'Enter': {
          e.preventDefault();
          e.stopPropagation();
          // Combine pre-pended items and regular items and wrap in structure to detect type
          const currItems = [
            ...(prependItems || []).map(
              (item): { type: 'prependedItem'; item: CustomSelectItem } => ({
                type: 'prependedItem',
                item,
              }),
            ),
            ...(items || []).map((item): { type: 'normalItem'; item: any } => ({
              type: 'normalItem',
              item,
            })),
          ];
          // blur the main input when item is selected via keyboard
          // mainInputRef?.current?.blur();
          !checkboxDisplay && mainInputRef?.current?.focus();
          // Default cursor, and ensure it is never out of range
          let selectCursor = clamp(
            cursor >= 0 ? cursor : 0,
            0,
            currItems.length,
          );
          if (!!query && cursor < 0) {
            // when query is present(when search is active) and cursor is -1, select first item if one is visible
            selectCursor = clamp(
              prependItems?.length || 0,
              0,
              currItems.length,
            );
          }
          if (currItems.length > 0 && currItems[selectCursor]) {
            const { type, item } = currItems[selectCursor];
            if (type === 'normalItem') {
              if (checkboxDisplay) {
                itemRefs.current[selectCursor].click();
              } else {
                handleChange(item);
              }
            } else {
              item.onClick?.({ onClose });
            }
          }
          break;
        }
        default:
          if (showQueryInput) {
            searchResultRef?.current?.focus();
            setCursor(-1);
          } else {
            const index = findItemStartsWith(e.key);
            if (index !== -1) {
              setCursorAndScroll(index);
            }
          }
          break;
      }
    };

    const onToggleOpen = () => {
      if (!isDisabled) {
        setIsOpen(!isOpen);
      }
    };

    const handleRenderInputLeftElement = () => {
      if (renderInputLeftElement) {
        return inputLeftElementContent;
      }
      return;
    };

    const renderPopoverTriggerInternal = () => {
      const mainInputProps = {
        getTitleFromValue,
        disabled: inputProps.isDisabled || isDisabled,
        isReadOnly,
        onOpen,
        onClose,
        loading,
        value,
        onToggleOpen,
        externalLoading,
        placeholder,
        isOpen,
        items,
        cursor,
        setCursor,
        handleChange,
        showContentInInput,
        renderContentInInput,
        inputProps,
        renderRightElement,
        clearable,
        onClear,
        onChange,

        queryResultCount: 4,
        query: query,
        onQueryKeyDown: onKeyDown,
        onQueryChange: onQueryInputChange,

        loadingRenderer,

        displayAvatar,
        renderInputLeftElement,
        inputLeftElementContent: handleRenderInputLeftElement(),
        isSubtitleItem,
        isTruncatedInputTitle,
      };
      if (MainInputComponent) {
        return <MainInputComponent {...mainInputProps} ref={mainInputRef} />;
      }
      return <MainInput {...mainInputProps} ref={mainInputRef} />;
    };

    useEffect(() => {
      onOpenStateChange && onOpenStateChange(isOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // Focus search input when opened
    // added small timeout to ensure element is rendered to allow focus
    useEffect(() => {
      const foundFirstSelectedItemIndex = items.findIndex(isSelectedItem);
      if (foundFirstSelectedItemIndex !== -1) {
        setCursorAndScroll(
          (prependItems?.length || 0) + foundFirstSelectedItemIndex,
        );
      } else if (isOpen && showQueryInput) {
        setTimeout(() => {
          searchResultRef?.current?.focus();
        }, 50);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, showQueryInput, items]);

    useEffect(() => {
      if (showAllSelect) {
        setIsAllSelected(itemsWithoutSubtitle?.length === value?.length);
      }
    }, [value, itemsWithoutSubtitle, showAllSelect]);

    const PopoverContentWrapper = usePortal ? Portal : React.Fragment;

    const handleScroll = () => {
      if (resultListRef.current) {
        let { scrollTop, clientHeight, scrollHeight } = resultListRef.current;
        if (
          scrollTop + clientHeight >= scrollHeight - 48 &&
          hasMoreDataToCall
        ) {
          onNextCall?.();
        }
      }
    };

    const selectedItemsCount = useMemo(
      () => (showAllSelect ? (value?.length ?? 0) : 0),
      [showAllSelect, value],
    );

    const onSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setIsAllSelected(isChecked);

      const filteredItems = items.filter((item) => !isSubtitleItem(item));
      onAllSelect?.(filteredItems, event);
    };

    return (
      <Box
        position="relative"
        w="100%"
        ref={ref}
        name={name}
        {...rest}
        onKeyDown={onKeyDown}
        data-testid={`customSelect-${name}`}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
        aria-controls={listId}
        role="combobox"
      >
        {renderPopoverTriggerInternal()}
        <Popover
          placement="bottom-start"
          trigger="click"
          offset={[0, 0]}
          isOpen={isReadOnly ? undefined : isOpen}
          onOpen={onOpen}
          onClose={onClose}
          matchWidth
          strategy="fixed"
          isLazy={isLazy}
          lazyBehavior={lazyBehavior}
          closeOnBlur={useMainInputAsSearchInput ? false : closeOnBlur}
          autoFocus={useMainInputAsSearchInput ? false : true}
          // Since the trigger not the true trigger, we need to manage this manually
          returnFocusOnClose={false}
          {...popOverProps}
        >
          <PopoverTrigger>
            <Box />
          </PopoverTrigger>
          <PopoverContentWrapper>
            <PopoverContent
              id={listId}
              border="1px solid"
              borderColor="tGray.back"
              borderRadius="3"
              /**
               * Ensure that width matches the input as the minimum
               * can grow based on dropdown content
               * but will never extend beyond width of page
               */
              w="fit-content"
              minW="100%"
              maxW="95vw"
              mt="0"
              whiteSpace="nowrap"
              filter="drop-shadow(0px 4px 8px rgba(24, 36, 60, 0.15))"
              {...popOverContentProps}
              role="listbox"
            >
              {showAllSelect && (
                <MBox px={3} pt={4}>
                  <MCheckbox
                    isChecked={isAllSelected}
                    isIndeterminate={!isAllSelected && selectedItemsCount > 0}
                    mb={4}
                    w="100%"
                    onChange={onSelectAll}
                  >
                    Select All ({selectedItemsCount})
                  </MCheckbox>
                  <MDivider />
                </MBox>
              )}

              {checkboxDisplay && showCheckCount && !!value.length && (
                <Box px={2} pt={2}>
                  <Flex
                    p={2}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize={'sm'} color={'tPurple.base'}>
                      ({value.length} Selected)
                    </Text>
                    <Text
                      fontSize={'x-small'}
                      color={'tIndigo.base'}
                      _hover={{ cursor: 'pointer' }}
                      onClick={() =>
                        clearAllCheckValues && clearAllCheckValues()
                      }
                    >
                      Clear All
                    </Text>
                  </Flex>
                </Box>
              )}
              {renderPreContent && renderPreContent()}
              {showQueryInput && (
                <SearchInput
                  ref={searchResultRef}
                  value={query}
                  onKeyDown={onKeyDown}
                  onChange={onQueryInputChange}
                />
              )}

              {prependItemsOutsideScrollArea && !!prependItems?.length && (
                <List>
                  <CustomItemList
                    customItems={prependItems}
                    cursor={cursor}
                    isDisabledItem={isDisabledItem}
                    isSubtitleItem={isSubtitleItem}
                    onClose={onClose}
                    itemRefs={itemRefs}
                    getTitle={getTitle}
                  />
                  {!checkboxDisplay && <Box h="2" />}
                </List>
              )}

              <List
                onScroll={handleScroll}
                ref={resultListRef}
                maxH="min(calc(100vh / 4), 400px)"
                overflow="auto"
                className="custom-scroll-bar-v1"
                p={
                  (!prependItemsOutsideScrollArea &&
                    prependItems &&
                    prependItems?.length > 0) ||
                  items.length > 0
                    ? '2'
                    : 0
                }
                {...scrollableRegionProps}
              >
                {!prependItemsOutsideScrollArea &&
                  prependItems &&
                  prependItems.length && (
                    <>
                      <CustomItemList
                        customItems={prependItems}
                        cursor={cursor}
                        isDisabledItem={isDisabledItem}
                        isSubtitleItem={isSubtitleItem}
                        onClose={onClose}
                        itemRefs={itemRefs}
                        getTitle={getTitle}
                      />
                      {!checkboxDisplay && <Box h="2" />}
                    </>
                  )}
                <MCustomSelectItemListRender
                  checkboxDisplay={checkboxDisplay}
                  cursor={cursor}
                  displayAvatar={displayAvatar}
                  getTitle={getTitle}
                  handleChange={handleChange}
                  indexOffset={prependItems?.length || 0}
                  isDisabledItem={isDisabledItem}
                  isSelectedItem={isSelectedItem}
                  isSubtitleItem={isSubtitleItem}
                  itemRefs={itemRefs}
                  items={items}
                  onClose={onClose}
                  query={query}
                  renderItemContent={renderItemContent}
                  setCursorCurrent={setCursorCurrent}
                  useHighlightedItemList={useHighlightedItemList}
                  showItemDivider={showItemDivider}
                  rightLabel={rightLabel}
                />
              </List>

              {items.length > 0 && closeButtonText && (
                <Box p={2}>
                  <Button
                    variant="primary"
                    width="100%"
                    onClick={() => {
                      onMultiSelectSubmit && onMultiSelectSubmit();
                      onClose();
                    }}
                    data-testid="customSelect-apply-btn"
                    mt="0.5"
                  >
                    {closeButtonText}
                  </Button>
                </Box>
              )}
              {useHighlightedItemList &&
                items.length > 0 &&
                !hideHighlightSearchResultsCount && (
                  <>
                    <MDivider />
                    <MFlex
                      color="tGray.darkPurple"
                      p={2}
                      justify="center"
                      align="center"
                    >
                      {hasMore && (
                        <MText>
                          Showing {formatInteger(items.length)} of{' '}
                          {formatInteger(totalRecordCount)} results
                        </MText>
                      )}
                      {!hasMore && (
                        <MText>
                          Showing {formatInteger(items.length)} results
                        </MText>
                      )}
                    </MFlex>
                  </>
                )}
            </PopoverContent>
          </PopoverContentWrapper>
        </Popover>
      </Box>
    );
  },
);

export default MCustomSelect;
