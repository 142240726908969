import { FunctionComponent as FC, useEffect, useRef, useState } from 'react';
import { useSetupPaymentGatewayForPayment } from '~api/paymentsService';
import { handleApiErrorToast } from '~app/api/axios';
import AppLoading from '~app/components/App/AppLoading';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';
import { useAuth } from '~app/services/auth0';
import { IPaymentGatewayAccountSchema, IPaymentMethodResp } from '~app/types';
import { StripePaymentMethodFormFields } from './StripePaymentMethodFormFields';
import { StripeWrapper } from './StripeWrapper';

interface StripePaymentMethodFormProps {
  isOpen: boolean;
  onClose: (paymentMethod?: IPaymentMethodResp) => void;
  accountId: string;
}

export const NewStripePaymentMethodModal: FC<StripePaymentMethodFormProps> = (
  props: StripePaymentMethodFormProps,
) => {
  const { isOpen, onClose, accountId } = props;
  const { tenantId } = useAuth();
  /** sed to avoid duplicate requests */
  const hasMadeRequest = useRef(false);
  const { activeGateways: paymentGateway, currentPaymentGateway } =
    useCurrentPaymentGateways({
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    });

  const [gatewayAccount, setGatewayAccount] =
    useState<IPaymentGatewayAccountSchema | null>(null);
  const [setupIntent, setSetupIntent] = useState<string | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  const { isPending: isLoading, mutate: initPaymentData } =
    useSetupPaymentGatewayForPayment({
      onSuccess: (response) => {
        setGatewayAccount(response.gatewayAccount);
        setSetupIntent(response.setupIntent.setupIntentId);
        setClientSecret(response.clientSecret.clientSecret);
      },
      onError: (error) => {
        onClose();
        handleApiErrorToast(error);
      },
      onSettled: () => {
        hasMadeRequest.current = false;
      },
    });

  useEffect(() => {
    if (
      !hasMadeRequest.current &&
      isOpen &&
      accountId &&
      tenantId &&
      currentPaymentGateway
    ) {
      hasMadeRequest.current = true;
      initPaymentData({
        accountId,
        paymentGateway: currentPaymentGateway,
      });
    }
  }, [accountId, isOpen, initPaymentData, tenantId, currentPaymentGateway]);

  if (isLoading) {
    return <AppLoading />;
  }

  if (!paymentGateway || !gatewayAccount || !setupIntent || !clientSecret) {
    return null;
  }

  return (
    <StripeWrapper
      apiKey={currentPaymentGateway?.publicKey!}
      clientSecret={clientSecret || ''}
    >
      <StripePaymentMethodFormFields
        {...props}
        accountId={accountId}
        paymentGateway={currentPaymentGateway!}
        setupIntent={setupIntent || ''}
        onClose={onClose}
      />
    </StripeWrapper>
  );
};
