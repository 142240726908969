import { useEffect, useState } from 'react';
import { useGetById } from '~app/api/queryUtils';
import {
  arrayBufferToString,
  isBase64Encoded,
  readArrayBufferAsDataURL,
} from '~app/utils/download';

export const useLegalEntityLogo = ({ id }: { id?: string }) => {
  const [base64, setBase64] = useState('');
  const { data, isLoading, isPending, refetch } = useGetById<any>(
    'storageByLegalEntity',
    id!,
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 1000 * 60 * 5, // 5 minutes
      axiosRequestConfig: { responseType: 'arraybuffer' },
    },
  );

  useEffect(() => {
    (async () => {
      if (!data || data.length === 0) {
        return;
      }
      const str = await arrayBufferToString(data);

      if (isBase64Encoded(str)) {
        str && setBase64(`data:image/png;base64,${str}`);
      } else {
        setBase64(await readArrayBufferAsDataURL(data));
      }
    })();
  }, [data]);

  return {
    base64,
    data,
    isLoading,
    isPending,
    refetch,
  };
};
