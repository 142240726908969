import { useAuth } from '~app/services/auth0';
import { FilterTypeEqual, RenderTableFilterOptionProps } from '~app/types';
import { MainSearchInput } from '../../MCustomSelect/components/MainSearchInput';
import { UserAvatarAndName } from '../../MCustomSelect/components/UserAvatarAndName';
import { MCustomSelect } from '../../MCustomSelect/MCustomSelect';

export const OwnerTableFilterOptionContent = ({
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps) => {
  const internalFilter = filter as FilterTypeEqual;
  const { tenantId } = useAuth();

  return (
    <MCustomSelect
      clearable
      useMainInputAsSearchInput
      itemTitle="name"
      itemValue="id"
      displayAvatar
      endpoint={`/api/tenants/${tenantId}/users`}
      loadAll
      renderItemContent={({ title }) => (
        <UserAvatarAndName userName={title} textProps={{ fontSize: 'sm' }} />
      )}
      MainInputComponent={MainSearchInput}
      value={internalFilter?.value}
      onChange={(val) => {
        handleFilterChange(val, filterOption);
      }}
    />
  );
};
