import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { apiGetAllList } from '~api/axios';
import { GetListApiConfig, GetListApiFilter, IBillGroupResp } from '~types';

export const billGroupQueryKeys = {
  base: ['billGroups'] as const,
  list: () => [...billGroupQueryKeys.base, 'list'] as const,
};

export function useGetBillGroups<SelectData = IBillGroupResp[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<UseQueryOptions<IBillGroupResp[], unknown, SelectData>> = {},
) {
  return useQuery({
    queryKey: [...billGroupQueryKeys.list(), config, filters],
    queryFn: () =>
      apiGetAllList<IBillGroupResp>('/api/billGroups/billRun', {
        rows: config?.rows,
        config,
        filters,
        onProgress,
      }),
    ...options,
  });
}

export function useGetBillGroupsForBillRun<SelectData = IBillGroupResp[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<UseQueryOptions<IBillGroupResp[], unknown, SelectData>> = {},
) {
  return useQuery({
    queryKey: [...billGroupQueryKeys.list(), config, filters],
    queryFn: () =>
      apiGetAllList<IBillGroupResp>('/api/billGroups/billRun', {
        rows: config?.rows,
        config,
        filters,
        onProgress,
      }),
    ...options,
  });
}
