import { AccordionItemProps, AccordionProps } from '@chakra-ui/react';
import { subDays } from 'date-fns/subDays';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import React, { FC, useContext, useMemo } from 'react';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAccordionItem,
  MAccordionPanel,
  MText,
} from '~app/components/Monetize';
import MRowWithLineSeparator from '~app/components/Monetize/MRowWithLineSeparator';
import { RATE_BILLING_FREQUENCY_MAP } from '~app/constants/offerings';
import { useFlags } from '~app/services/launchDarkly';
import {
  IQuoteBillingScheduleRespSchema,
  RateBillingFrequencyEnum,
} from '~app/types';
import { formatCurrency } from '~app/utils';
import { replaceUserTimezoneWithUtc, toDateShort } from '~app/utils/dates';
import { roundNumberToDecimal } from '~app/utils/numberUtils';
import { getVariableAmountIndicator } from '~app/utils/quotes';
import { QuoteContext } from '../quoteContext';

interface QuoteFormBillingScheduleProps extends AccordionProps {
  itemProps?: AccordionItemProps;
  loading?: boolean;
  anyVariableAmountProducts: boolean;
}

function getBillingFreq(
  billingFrequency: RateBillingFrequencyEnum,
  billingFrequencyInMonths?: number | null,
) {
  return RATE_BILLING_FREQUENCY_MAP[billingFrequency](billingFrequencyInMonths)
    .label;
}

const QuoteFormBillingSchedule: FC<QuoteFormBillingScheduleProps> =
  React.forwardRef<any, QuoteFormBillingScheduleProps>(
    (
      {
        itemProps,
        anyVariableAmountProducts,
        loading,
        ...rest
      }: QuoteFormBillingScheduleProps,
      ref,
    ) => {
      const { allowBillingScheduleV2 } = useFlags();

      const { quoteData } = useContext(QuoteContext);

      const addIncrementalPeriodInBillingSchedule = (
        billingSchedule: IQuoteBillingScheduleRespSchema,
      ) => {
        if (isUndefined(billingSchedule)) {
          return [];
        }

        if (
          isNil(billingSchedule?.incrementalPeriod) ||
          !allowBillingScheduleV2
        ) {
          return billingSchedule.periods;
        }

        const totalAmount =
          (billingSchedule?.incrementalPeriod?.billableAmount ?? 0) +
          (billingSchedule?.incrementalPeriod?.creditableAmount ?? 0);

        const newPeriod = {
          incrementalLabel: 'Upon Contract Execution',
          startDate: billingSchedule.incrementalPeriod?.billDate!,
          amount: roundNumberToDecimal(totalAmount),
        };

        const updatedPeriods = [newPeriod, ...billingSchedule.periods];

        return updatedPeriods;
      };

      const billingScheduleListingV2 = useMemo(
        () =>
          quoteData?.quoteBillingSchedule
            ? addIncrementalPeriodInBillingSchedule(
                quoteData.quoteBillingSchedule,
              )
            : [],
        [quoteData],
      );

      if (!quoteData?.quoteBillingSchedule) {
        return null;
      }

      const { quoteBillingSchedule, quote } = quoteData;
      const variableAmountIndicator = getVariableAmountIndicator(
        anyVariableAmountProducts,
      );

      return (
        <MAccordion allowMultiple {...rest}>
          <MAccordionItem borderColor="tGray.lightPurple" {...itemProps}>
            {({ isExpanded }) => (
              <>
                <MAccordionCustomButton
                  isExpanded={isExpanded}
                  label="Billing Schedule"
                >
                  <MAccordionCustomButtonItem
                    hideWhenExpanded
                    isExpanded={isExpanded}
                    label="Billing Frequency"
                    minW={'130px'}
                    value={getBillingFreq(
                      quoteBillingSchedule.billingFrequency,
                      quoteBillingSchedule.billingFrequencyInMonths,
                    )}
                  />
                  <MAccordionCustomButtonItem
                    hideWhenExpanded
                    isExpanded={isExpanded}
                    label="Billing Periods"
                    minW={'111px'}
                    value={billingScheduleListingV2.length}
                  />
                </MAccordionCustomButton>

                <MAccordionPanel>
                  {billingScheduleListingV2.map((period, i) => (
                    <MRowWithLineSeparator
                      key={i}
                      leftLabel={
                        <MText>
                          {period.incrementalLabel ??
                            toDateShort(period.startDate)}
                        </MText>
                      }
                      rightLabel={
                        <MText>{`${formatCurrency(period.amount || 0, {
                          currency: quote?.currency,
                        })}${variableAmountIndicator}`}</MText>
                      }
                    />
                  ))}
                  {!allowBillingScheduleV2 && (
                    <MRowWithLineSeparator
                      leftLabel={
                        <MText fontWeight="bold" fontSize="lg">
                          Total
                        </MText>
                      }
                      rightLabel={
                        <MText fontWeight="bold" fontSize="lg">
                          {`${formatCurrency(
                            quoteBillingSchedule.contractAmount || 0,
                            { currency: quote?.currency },
                          )}${variableAmountIndicator}`}
                        </MText>
                      }
                    />
                  )}
                  {allowBillingScheduleV2 &&
                    !isNil(quoteBillingSchedule.incrementalPeriod) && (
                      <>
                        <MText fontSize="xs">
                          Assumes Invoices have been generated up to{' '}
                          {toDateShort(
                            billingScheduleListingV2.length > 1
                              ? subDays(
                                  replaceUserTimezoneWithUtc(
                                    billingScheduleListingV2[1]?.startDate,
                                  ),
                                  1,
                                )
                              : quoteBillingSchedule?.incrementalPeriod
                                  ?.billDate,
                          )}
                        </MText>
                        <MText fontSize="xs">
                          Amount = Incremental Invoice(s) - Credit Note(s)
                          created upon execution of this Amendment quote.
                        </MText>
                      </>
                    )}
                </MAccordionPanel>
              </>
            )}
          </MAccordionItem>
        </MAccordion>
      );
    },
  );

export default QuoteFormBillingSchedule;
