import { CardBody } from '@chakra-ui/react';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import {
  MBox,
  MButton,
  MCard,
  MFormField,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '~app/components/Monetize';
import { useACL } from '~app/services/acl/acl';
import {
  ContactTypes,
  ContactWithTypes,
  IContactRespSchema,
  IQuoteContactAddressDataSchema,
  Maybe,
  QuoteSettingsDefaultAddressSourceEnum,
  SigningOrderEnum,
} from '~app/types';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { sortAlphabetically } from '~app/utils';
import { QuoteContactForm } from './QuoteContactForm';
import QuoteContactItem from './QuoteContactItem';
import { QuoteContactsSelectInput } from './QuoteContactsSelectInput';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { useGetAccountById } from '~app/api/accountsService';
import { useFlags } from '~app/services/launchDarkly';
import { appGlobalDataState } from '~app/store/global.store';

interface QuoteContactsContactListProps {
  isDisabled?: boolean;
  isDisabledPrimaryBilling?: boolean;
  isEsignEnabled: boolean;
  accountId: string;
  accountName?: string;
  accountContacts: IContactRespSchema[];
  contacts: ContactWithTypes[];
  isInternal: boolean;
  legalEntityName?: Maybe<string>;
  loadingContacts: boolean;
  signingOrder?: SigningOrderEnum;
  onToggle: (
    contact: ContactWithTypes,
    type: ContactTypes,
    value: boolean,
    isInternal: boolean,
  ) => void;
  onRemove: (contact: ContactWithTypes, isInternal: boolean) => void;
  onAddContact: (contactId: string, isInternal: boolean) => void;
  onUpdateContact: (contact: IContactRespSchema, isInternal: boolean) => void;
  onNewContact: (contact: IContactRespSchema, isInternal: boolean) => void;
  onChangeSigningOrder?: (signingOrder: SigningOrderEnum) => void;
  addressFormat?: LegalEntityAddressFormatEnum | null;
  canUpdateContact: boolean;
  canCreateContact: boolean;
  addressDataForm: UseFormReturn<IQuoteContactAddressDataSchema>;
  onSaveAddress: () => void;
}

const QuoteContactsContactList: FC<QuoteContactsContactListProps> = ({
  isDisabled = false,
  isDisabledPrimaryBilling = false,
  isEsignEnabled,
  accountId,
  accountName,
  accountContacts,
  contacts,
  isInternal,
  legalEntityName,
  loadingContacts,
  signingOrder,
  onToggle,
  onRemove,
  onAddContact,
  onUpdateContact,
  onNewContact,
  onChangeSigningOrder,
  addressFormat,
  canUpdateContact,
  canCreateContact,
  addressDataForm,
}: QuoteContactsContactListProps) => {
  const { canDo } = useACL();
  const { enableAccountBasedAddresses } = useFlags();
  const [availableContacts, setAvailableContacts] = useState(accountContacts);
  const [addContactActive, setAddContactActive] = useState(
    !loadingContacts && !contacts?.length,
  );
  const [newContactOpen, setNewContactOpen] = useState(false);
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);
  const addressSource = addressDataForm.watch('addressSource');
  const { data: accountDetails, isLoading: isAccountLoading } =
    useGetAccountById(accountId, {
      enabled: !!accountId && !isInternal,
    });

  useEffect(() => {
    if (contacts && accountContacts) {
      const contactIds = new Set(contacts.map(({ contact }) => contact.id));
      setAvailableContacts(
        accountContacts
          .filter(({ id }) => !contactIds.has(id))
          .sort((a, b) => sortAlphabetically('fullName')(a, b)),
      );
    }
  }, [contacts, accountContacts]);

  useEffect(() => {
    if (!loadingContacts && !contacts.length) {
      setAddContactActive(true);
    }
  }, [loadingContacts, contacts]);

  function handleShowContactSelectionList() {
    setAddContactActive(false);
    setNewContactOpen(true);
  }

  function handleContactSelected(contactId: string) {
    onAddContact(contactId, isInternal);
    setAddContactActive(false);
    setNewContactOpen(false);
  }

  function handleNewContact(contact?: IContactRespSchema) {
    if (contact) {
      onNewContact(contact, isInternal);
    }
    setNewContactOpen(false);
  }

  let allowAdditionalContact = false;
  if (isInternal) {
    allowAdditionalContact = contacts.length < 1;
  } else if (isEsignEnabled) {
    allowAdditionalContact = contacts.length < 3;
  } else {
    allowAdditionalContact = contacts.length < 2;
  }

  return (
    <>
      {enableAccountBasedAddresses && hasCrmConfigured && !isInternal && (
        <MCard variant="borderless">
          <CardBody py="2">
            <MText fontSize="sm" fontWeight="600">
              Address Sources
            </MText>
            <MText my="1" fontSize="sm">
              Select the source of Primary and Billing Addresses.
            </MText>
            <Controller
              name="addressSource"
              control={addressDataForm.control}
              render={({ field }) => (
                <MRadioGroup {...field} isDisabled={isDisabled}>
                  <MStack
                    spacing={1}
                    direction="column"
                    color="tGray.darkPurple"
                  >
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
                      onChange={() => {
                        addressDataForm.setValue(
                          'billingAddressId',
                          accountDetails?.billingAddress?.id,
                          { shouldDirty: true },
                        );
                        addressDataForm.setValue(
                          'shippingAddressId',
                          accountDetails?.shippingAddress?.id,
                          { shouldDirty: true },
                        );
                      }}
                      fontSize="sm"
                    >
                      Source from Account Addresses
                    </MRadio>
                    <MRadio
                      value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}
                      fontSize="sm"
                      onChange={() => {
                        addressDataForm.setValue('billingAddressId', '', {
                          shouldDirty: true,
                        });
                        addressDataForm.setValue('shippingAddressId', '', {
                          shouldDirty: true,
                        });
                      }}
                    >
                      Source from Contacts
                    </MRadio>
                  </MStack>
                </MRadioGroup>
              )}
            />
          </CardBody>
        </MCard>
      )}
      {contacts.map((contact, i) => (
        <QuoteContactItem
          key={contact.contact.id || contact.contact.snapshotId}
          isDisabled={isDisabled}
          isDisabledPrimaryBilling={isDisabledPrimaryBilling}
          isEsignEnabled={isEsignEnabled}
          isInternal={isInternal}
          contact={contact}
          onToggleType={onToggle}
          onUpdated={({ contact }) => onUpdateContact(contact, isInternal)}
          onRemove={onRemove}
          addressFormat={addressFormat}
          canUpdateContact={canUpdateContact}
          showAddress={
            isInternal ||
            (!isInternal &&
              addressSource === QuoteSettingsDefaultAddressSourceEnum.CONTACT)
          }
        />
      ))}

      {!isDisabled && (
        <>
          {addContactActive && (
            <MCard variant="borderless" data-testid="eSign-contact-select">
              <CardBody pt="2">
                <MFormField label="Select Contact">
                  <QuoteContactsSelectInput
                    loading={loadingContacts}
                    contacts={availableContacts}
                    onChange={handleContactSelected}
                    onNewContact={handleShowContactSelectionList}
                    canCreateContact={canCreateContact}
                  />
                </MFormField>
              </CardBody>
            </MCard>
          )}

          {isInternal && !newContactOpen && (
            <>
              <MCard variant="borderless">
                <CardBody>
                  <MRadioGroup
                    onChange={(e) => {
                      onChangeSigningOrder &&
                        onChangeSigningOrder(e as SigningOrderEnum);
                    }}
                    value={signingOrder}
                  >
                    <MStack columnGap={5}>
                      <MRadio value={SigningOrderEnum.EXTERNAL_FIRST}>
                        Send to{' '}
                        {accountName ? accountName : 'external account contact'}{' '}
                        first
                      </MRadio>
                      <MRadio value={SigningOrderEnum.INTERNAL_FIRST}>
                        Send to {legalEntityName || 'internal contact'} first
                      </MRadio>
                      <MRadio value={SigningOrderEnum.INSTANTANEOUS}>
                        Send to both contacts at the same time
                      </MRadio>
                      );
                    </MStack>
                  </MRadioGroup>
                </CardBody>
              </MCard>
            </>
          )}

          {newContactOpen && (
            <MCard variant="borderless">
              <CardBody pt={2}>
                <QuoteContactForm
                  isInternal={isInternal}
                  accountId={accountId}
                  onClose={handleNewContact}
                />
              </CardBody>
            </MCard>
          )}

          {allowAdditionalContact && !addContactActive && !newContactOpen && (
            <MBox>
              <MButton
                data-testid={`add-contact-btn-${
                  isInternal ? 'internal' : 'external'
                }`}
                variant="tertiary"
                size="sm"
                onClick={() => setAddContactActive(true)}
              >
                + Add Contact
              </MButton>
            </MBox>
          )}
        </>
      )}
    </>
  );
};

export default QuoteContactsContactList;
