import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { LogoMark } from '~app/assets/images';
import { MBox, MImage } from '~components/Monetize';

interface AnonymousShareLayoutProps {
  children: ReactNode;
}

export const AnonymousShareLayout = ({
  children,
}: AnonymousShareLayoutProps) => {
  return (
    <MBox>
      <MBox
        padding="35px 35px 0px 35px"
        textAlign="right"
        display={['none', 'none', 'block']}
      >
        <MImage h={'40px'} src={LogoMark} />
      </MBox>

      <MBox>{children || <Outlet />}</MBox>
    </MBox>
  );
};
