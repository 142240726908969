import { useGetQuoteBillingSchedule } from '../api/cpqService';
import { useFlags } from '../services/launchDarkly';
import { IQuoteRespSchema, Maybe } from '../types';

export const useBillingSchedule = (quote?: Maybe<IQuoteRespSchema>) => {
  const { allowBillingScheduleV2 } = useFlags();

  /**
   * This will re-fetch the billing schedule whenever the quote is modified
   */
  const { data: quoteBillingSchedule, isFetching: loading } =
    useGetQuoteBillingSchedule(
      quote?.id || '',
      allowBillingScheduleV2 ? 'V2' : 'V1',
      // additional query key to auto-fetch on quote modification
      [quote?.modifyDate],
      {
        enabled: !!quote?.id,
        // Never consider the data stale if we have it already
        staleTime: Infinity,
        // 5 minutes
        gcTime: 300000,
        placeholderData: (previousData, previousQuery) => previousData,
        meta: { showErrorToast: true },
      },
    );

  return {
    loading,
    quoteBillingSchedule: quoteBillingSchedule,
  };
};
