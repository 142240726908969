import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { AiOutlineCamera } from 'react-icons/ai';
import { MdError } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import { useUploadEntity } from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MCenter,
  MFlex,
  MIcon,
  MImage,
  MLink,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { MFileDragDropUpload } from '~app/components/Monetize/MFileDragDropUpload';
import { useToast } from '~app/services/toast';
import { LogoUploadingIndicator } from './LogoUploadingIndicator';

export const LegalEntityLogoUploadBoxContent = ({
  open,
  isMoreThanMaxSize = false,
  companyLogo,
  legalEntityId,
}: {
  open: () => void;
  isMoreThanMaxSize?: boolean;
  companyLogo?: string;
  legalEntityId?: string;
}) => {
  // const { mutateAsync: deleteLegalEntityLogo } = useDeleteEntity(
  //   'storageByLegalEntity',
  //   {
  //     onSuccess: () => {
  //       refetch();
  //     },
  //   },
  // );
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <MBox cursor="pointer" position="relative" maxH="150px" p="5">
      {!!companyLogo && (
        <>
          <MCenter alignSelf="center">
            <MImage src={companyLogo} alt="" maxHeight={100} />
          </MCenter>
          <Box
            w="100%"
            h="100%"
            position="absolute"
            left={0}
            top={0}
            display="flex"
            justifyContent="center"
            borderRadius={4}
            opacity={0}
            onMouseLeave={onClose}
            onClick={onOpen}
            _hover={{
              backgroundColor: 'rgba(24, 36, 60, 0.97)',
              opacity: companyLogo ? 1 : 0,
            }}
          >
            <Popover
              placement="right-start"
              trigger="click"
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            >
              <PopoverTrigger>
                <Box alignSelf="center">
                  <AiOutlineCamera
                    color="white"
                    fontSize={30}
                  ></AiOutlineCamera>
                </Box>
              </PopoverTrigger>
              <PopoverContent
                border="1px solid"
                borderColor="tGray.back"
                borderRadius={2}
                p={2}
                w={150}
                boxShadow="0px 4px 8px rgba(24, 36, 60, 0.15)"
                fontSize={14}
                fontWeight={400}
                opacity={1}
              >
                <MButton
                  p={2}
                  color="tPurple.dark"
                  cursor="pointer"
                  bg="transparent"
                  onClick={open}
                  justifyContent="flex-start"
                  fontWeight={400}
                  _hover={{
                    fontWeight: 600,
                    backgroundColor: 'tBlue.hover',
                  }}
                >
                  Change Logo
                </MButton>
                {/* <MButton
                  p={2}
                  color="tRed.base"
                  cursor="pointer"
                  bg="transparent"
                  justifyContent="flex-start"
                  fontWeight="400"
                  _hover={{
                    backgroundColor: 'tRed.cosmos',
                    fontWeight: 600,
                  }}
                  onClick={() => {
                    if (legalEntityId) {
                      deleteLegalEntityLogo({ id: legalEntityId });
                    }
                  }}
                >
                  Delete
                </MButton> */}
              </PopoverContent>
            </Popover>
          </Box>
        </>
      )}
      {!companyLogo && (
        <>
          <MText textAlign="center" fontSize="xs">
            Drag and Drop your file here or{' '}
            <MLink textDecor="underline" onClick={open}>
              Browse
            </MLink>
          </MText>
          <MText textAlign="center" fontSize="xs" color="tGray.acGray" mt="1">
            This logo will display in 200px width
          </MText>
          <MText textAlign="center" fontSize="xs" color="tGray.acGray" mt="1">
            Max file size: 10MB. Supported type: PNG
          </MText>
          {isMoreThanMaxSize && (
            <MFlex justify="center" align="center" gap="1">
              <MIcon as={MdError} color="tRed.base" />
              <MText color="tRed.base" fontSize="xs">
                The file exceeds the maximum allowed size of 10MB
              </MText>
            </MFlex>
          )}
        </>
      )}
    </MBox>
  );
};

export const LegalEntityLogoUploader = ({
  companyLogoData,
  legalEntityId,
  resetErrorState,
}: {
  companyLogoData: {
    isLoading: boolean;
    refetch: () => void;
    base64: string;
  };
  legalEntityId?: string;
  resetErrorState?: boolean;
}) => {
  const { addToast } = useToast();
  const { isLoading, refetch, base64 } = companyLogoData;
  const [uploadProgressPercentage, setUploadProgressPercentage] =
    useState<number>(0);
  const { mutateAsync: uploadLegalEntityLogo, isPending: uploadLoading } =
    useUploadEntity('storageByLegalEntity', {
      onSuccess: () => {
        addToast({
          summary: 'Logo',
          detail: 'The logo has been successfully saved.',
          severity: 'success',
        });
        refetch();
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const onDropAccepted = useCallback(
    async (file: File) => {
      uploadLegalEntityLogo({
        id: legalEntityId!,
        file,
        progressCallback: (uploadProgress: number) => {
          setUploadProgressPercentage(uploadProgress);
        },
      });
    },
    [uploadLegalEntityLogo],
  );

  if (isLoading) {
    return <MSkeleton height={150} width={500} />;
  }

  return (
    <MFileDragDropUpload
      mt={4}
      accept={['png']}
      label="Drag and Drop your file here"
      onFileUpload={onDropAccepted}
      subtitle="This logo will display in 200px width"
      maxFileSize={10}
      renderBoxContent={(props) =>
        uploadLoading ? (
          <LogoUploadingIndicator
            uploadProgressPercentage={uploadProgressPercentage}
          />
        ) : (
          <LegalEntityLogoUploadBoxContent
            legalEntityId={legalEntityId}
            companyLogo={base64}
            {...props}
          />
        )
      }
      resetErrorState={resetErrorState}
      innerContainerProps={{ p: '5' }}
    />
  );
};
