import { useEffect } from 'react';

import { Accordion } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  useGetInvoiceSettings,
  useUpdateInvoiceSettings,
} from '~app/api/settingsService';
import {
  MAccordionCustomButton,
  MButton,
  MFlex,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MStack,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { useDocumentHead } from '~app/services/documentHead';
import { IInvoiceTemplateConfigSchema } from '~app/types/InvoiceSettingsTypes';
import { InvoiceTemplateForm } from './components/InvoiceTemplateForm';

const InvoiceTemplates = () => {
  const { setDocTitle } = useDocumentHead();
  const { data: invoiceSettings, isLoading: isDataLoading } =
    useGetInvoiceSettings();
  const { mutate: doUpdateInvoiceSettings, isPending: isUpdateLoading } =
    useUpdateInvoiceSettings();

  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
    reset,
  } = useForm<IInvoiceTemplateConfigSchema>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (invoiceSettings) {
      reset({ ...invoiceSettings.invoiceTemplateConfig });
    }
  }, [invoiceSettings]);

  useEffect(() => {
    setDocTitle('Settings', 'Invoice Templates');
  }, []);

  const onSubmit = (payload: IInvoiceTemplateConfigSchema) => {
    invoiceSettings &&
      doUpdateInvoiceSettings({
        ...invoiceSettings,
        invoiceTemplateConfig: payload,
      });
  };

  return (
    <MPageContainer data-testid="settings-invoice-templates">
      <MSettingsPageHeader title="Invoice Templates" />
      <MStack w="100%" overflow="auto" data-testid="InvoiceTemplates">
        {isDataLoading ? (
          <MPageLoader height="450px" />
        ) : (
          <Accordion
            allowMultiple
            maxW="600px"
            data-testid="qTemplate-accordion"
          >
            <MSettingAccordionItem
              renderAccordionButton={({
                isExpanded,
              }: {
                isExpanded: boolean;
              }) => (
                <MAccordionCustomButton
                  isExpanded={isExpanded}
                  label="Invoice Templates"
                  data-testid="invoice-template-mAccordionBtn"
                />
              )}
              description="Configure the default settings for Invoices"
            >
              <InvoiceTemplateForm
                errors={errors}
                control={control}
                handleSubmit={handleSubmit(onSubmit)}
              />

              <MFlex justifyContent="flex-end">
                <MButton
                  type="submit"
                  data-testid="submit-invoiceTemplate-form"
                  variant="primary"
                  mt={4}
                  alignSelf="self-end"
                  isDisabled={isUpdateLoading || !isDirty}
                  isLoading={isUpdateLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  Save
                </MButton>
              </MFlex>
            </MSettingAccordionItem>
          </Accordion>
        )}
      </MStack>
    </MPageContainer>
  );
};

export { InvoiceTemplates };
