import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import {
  cpqServiceQueryKeys,
  doCreateQuoteOffering,
  doDeleteQuoteOffering,
  doRemoveQuoteOffering,
  doRevertQuoteOffering,
  doUpdateQuoteOffering,
} from '~app/api/cpqService';
import {
  IQuoteOfferingRemoveReqSchema,
  IQuoteOfferingReqSchema,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
} from '~app/types';
import { logger } from '../services/logger';
import { quoteOfferingLoadingPromises } from '../store/quote.store';

const useQuoteOffering = (quoteId: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [createOfferingLoading, setCreateOfferingLoading] =
    useState<boolean>(false);
  const [quoteOffering, setQuoteOffering] =
    useState<IQuoteOfferingRespSchema | null>(null);
  const queryClient = useQueryClient();

  const setLoadingPromises = useSetRecoilState(quoteOfferingLoadingPromises);

  /**
   * Keep track of all quote offering actions so that we can ensure
   * that quote submission can wait until all are completed
   */
  const performAsyncAction = async <T>(asyncFn: () => Promise<T>) => {
    const promise = asyncFn();
    try {
      setLoadingPromises((prev) => [...prev, promise]);
      await promise;
    } catch (ex) {
      logger.warn('Error performing async quote offering action', ex);
    } finally {
      setLoadingPromises((prev) => prev.filter((p) => p !== promise));
    }
    return promise;
  };

  const createQuoteOffering = async (
    data: IQuoteOfferingReqSchema,
  ): Promise<IQuoteOfferingRespSchema | null> => {
    return performAsyncAction<IQuoteOfferingRespSchema | null>(async () => {
      try {
        setCreateOfferingLoading(true);
        const res = await doCreateQuoteOffering(quoteId, data);
        setQuoteOffering(res);

        queryClient.invalidateQueries({
          queryKey: [...cpqServiceQueryKeys.quotePrices(quoteId)],
        });
        return res;
      } catch (error) {
        handleApiErrorToast(error);
        return null;
      } finally {
        setCreateOfferingLoading(false);
      }
    });
  };

  const updateQuoteOffering = async (
    id: string,
    data: IQuoteOfferingReqSchema,
  ): Promise<IQuoteOfferingRespSchema | null> => {
    return performAsyncAction<IQuoteOfferingRespSchema | null>(async () => {
      try {
        setLoading(true);
        const res = await doUpdateQuoteOffering(quoteId, id, data);
        setQuoteOffering(res);

        queryClient.invalidateQueries({
          queryKey: [...cpqServiceQueryKeys.quotePrices(quoteId)],
        });
        return res;
      } catch (error) {
        handleApiErrorToast(error);
        return null;
      } finally {
        setLoading(false);
      }
    });
  };

  const deleteQuoteOffering = async (id: string) => {
    performAsyncAction(async () => {
      try {
        setLoading(true);
        await doDeleteQuoteOffering(quoteId, id);

        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quotesById(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quotePrices(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quoteBillingSchedule(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quoteTemplateHtml(quoteId),
        });
      } catch (error) {
        handleApiErrorToast(error);
      } finally {
        setLoading(false);
      }
    });
  };

  const revertQuoteOffering = async (id: string) => {
    return performAsyncAction<IQuoteRespSchema | null>(async () => {
      try {
        setLoading(true);
        const res = await doRevertQuoteOffering(quoteId, id);

        queryClient.invalidateQueries({
          queryKey: [...cpqServiceQueryKeys.quotePrices(quoteId)],
        });
        return res;
      } catch (error) {
        handleApiErrorToast(error);
        return null;
      } finally {
        setLoading(false);
      }
    });
  };

  const removeQuoteOffering = async (
    payload: IQuoteOfferingRemoveReqSchema,
  ) => {
    return performAsyncAction<IQuoteRespSchema | null>(async () => {
      try {
        setLoading(true);
        const res = await doRemoveQuoteOffering(quoteId, payload);

        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quotesById(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quotePrices(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quoteBillingSchedule(quoteId),
        });
        queryClient.invalidateQueries({
          queryKey: cpqServiceQueryKeys.quoteTemplateHtml(quoteId),
        });
        return res;
      } catch (error) {
        handleApiErrorToast(error);
        return null;
      } finally {
        setLoading(false);
      }
    });
  };

  return {
    loading,
    createOfferingLoading,
    quoteOffering,
    createQuoteOffering,
    updateQuoteOffering,
    deleteQuoteOffering,
    revertQuoteOffering,
    removeQuoteOffering,
  };
};

export default useQuoteOffering;
