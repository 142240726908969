import { Collapse, InputRightElement } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { logger } from '@sentry/utils';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdInfo } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useCreatePriceUpliftConfig,
  useDeletePriceUpliftConfig,
  useGetPriceUpliftConfig,
  useUpdatePriceUpliftConfig,
} from '~app/api/priceUpliftService';
import { ONE_TIME_PRODUCT_TYPES } from '~app/constants/products';
import {
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  OnQuoteOfferingChange,
} from '~app/types';
import {
  PriceUpliftConfigFormSchema,
  PriceUpliftConfigFormType,
  PriceUpliftConfigTypeEnum,
  PriceUpliftPercentageTypeEnum,
  PriceUpliftProductConfigurationType,
} from '~app/types/priceUpliftConfig';
import {
  MBox,
  MButton,
  MCheckbox,
  MCustomNumberInput,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInputGroup,
  MRadio,
  MRadioGroup,
  MSkeleton,
  MStack,
  MSwitch,
  MText,
  MTooltip,
} from '../Monetize';

type PriceUpliftConfigurationProps = {
  isReadOnly: boolean;
  quoteOffering?: IQuoteOfferingRespSchema | null;
  onOfferingChange: OnQuoteOfferingChange;
};

const fixOrder = (
  data: PriceUpliftProductConfigurationType[],
  order: IQuoteItemRespSchema[],
): PriceUpliftProductConfigurationType[] => {
  const orderMap = new Map<string, number>();
  for (let i = 0; i < order.length; i++) {
    orderMap.set(order[i].productId, i);
  }
  return data.sort((a, b) => {
    const aIndex = orderMap.get(a.productId) ?? 0;
    const bIndex = orderMap.get(b.productId) ?? 0;
    return aIndex - bIndex;
  });
};

export const PriceUpliftConfiguration = ({
  isReadOnly,
  quoteOffering,
  onOfferingChange,
}: PriceUpliftConfigurationProps) => {
  const items = (quoteOffering?.items ?? []).filter(
    ({ productType }) => !ONE_TIME_PRODUCT_TYPES.has(productType),
  );
  const canUpdateAndDelete = !!quoteOffering?.priceUpliftConfigurationId;
  const [canPriceUplift, setCanPriceUplift] = useState<boolean>(
    !!quoteOffering?.priceUpliftConfigurationId,
  );

  const { isLoading: isPriceUpliftConfigLoading, data: priceUpliftConfig } =
    useGetPriceUpliftConfig(quoteOffering?.priceUpliftConfigurationId!, {
      enabled: !!quoteOffering?.priceUpliftConfigurationId,
      refetchOnWindowFocus: false,
    });

  const [isApplyToAll, setIsApplyToAll] = useState<boolean>(() =>
    priceUpliftConfig
      ? priceUpliftConfig.type ===
        PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS
      : true,
  );
  const isLocked = priceUpliftConfig?.locked ?? false;

  /** calculate values from priceUpliftConfig service */
  let values;

  if (priceUpliftConfig) {
    values = {
      amount: priceUpliftConfig.amount,
      type: priceUpliftConfig.type,
      percentageType: priceUpliftConfig.percentageType,
      productConfigurations: priceUpliftConfig.productConfigurations
        ? fixOrder(priceUpliftConfig.productConfigurations, items)
        : items.map(({ productId }) => ({
            productId,
            percentageType: priceUpliftConfig.percentageType,
            amount: null,
          })),
    };
  }

  const {
    watch,
    control,
    formState: { isValid, isDirty },
    handleSubmit,
    setValue,
  } = useForm<PriceUpliftConfigFormType>({
    mode: 'onChange',
    resolver: zodResolver(PriceUpliftConfigFormSchema),
    values,
    defaultValues: {
      amount: null,
      percentageType: PriceUpliftPercentageTypeEnum.FIXED,
      type: PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS,
      productConfigurations: items.map(({ productId }) => ({
        amount: null,
        productId,
        percentageType: PriceUpliftPercentageTypeEnum.FIXED,
      })),
    },
  });

  const { mutateAsync: doCreatePriceUpliftConfig, isPending: isCreateLoading } =
    useCreatePriceUpliftConfig({
      onSuccess: (data) => {
        onOfferingChange('UPDATE', {
          ...quoteOffering,
          priceUpliftConfigurationId: data.id,
        } as IQuoteOfferingRespSchema);
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });
  const { mutateAsync: doUpdatePriceUpliftConfig, isPending: isUpdateLoading } =
    useUpdatePriceUpliftConfig({
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const { mutate: doDeletePriceUpliftConfig } = useDeletePriceUpliftConfig({
    meta: { showErrorToast: true },
    onSuccess: (data) => {
      onOfferingChange('UPDATE', {
        ...quoteOffering,
        priceUpliftConfigurationId: null,
      } as IQuoteOfferingRespSchema);
    },
  });

  const onSubmit = async (formData: PriceUpliftConfigFormType) => {
    const payload = {
      ...formData,
      productConfigurations:
        formData.type === PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS
          ? null
          : (formData.productConfigurations ?? [])
              .filter(({ amount }) => Boolean(amount))
              .map((config) => ({
                ...config,
                percentageType: formData.percentageType,
              })),
    } satisfies PriceUpliftConfigFormType;
    const parsed = PriceUpliftConfigFormSchema.safeParse(payload);
    if (parsed.success) {
      const commonParams = {
        quoteOfferingId: quoteOffering?.id ?? '',
        priceUpliftConfig: parsed.data,
      };

      if (!canUpdateAndDelete) {
        await doCreatePriceUpliftConfig({
          quoteId: quoteOffering?.quoteId ?? '',
          ...commonParams,
        });
      } else {
        if (quoteOffering?.priceUpliftConfigurationId) {
          await doUpdatePriceUpliftConfig({
            priceUpliftConfigurationId:
              quoteOffering.priceUpliftConfigurationId,
            ...commonParams,
          });
        } else {
          logger.error(
            'priceUpliftConfigurationId is not available for updating.',
          );
        }
      }
    } else {
      logger.error('Unable to pares data', parsed.error);
    }
  };

  const onError = (err: any) => logger.error(err);

  const percentageType = watch('percentageType');
  const isLockedOrIsReadOnly = isLocked || isReadOnly;

  if (isPriceUpliftConfigLoading) {
    return (
      <MFlex direction="column">
        <MSkeleton h={40} w="full" />
      </MFlex>
    );
  }

  return (
    <MFlex flexDir="column" gap={4}>
      <MFlex justify="space-between" w="full">
        <MText fontWeight="semibold">Price Uplift on Renewal</MText>
        <MSwitch
          isDisabled={isLockedOrIsReadOnly}
          isReadOnly={isLockedOrIsReadOnly}
          defaultChecked={canPriceUplift}
          onChange={(ev) => {
            setCanPriceUplift(ev.target.checked);
            if (canUpdateAndDelete) {
              doDeletePriceUpliftConfig({
                quoteId: quoteOffering?.quoteId!,
                quoteOfferingId: quoteOffering?.id!,
                priceUpliftConfigurationId:
                  quoteOffering?.priceUpliftConfigurationId!,
              });
            }
          }}
        />
      </MFlex>

      {canPriceUplift && (
        <MFlex
          flexDirection="column"
          gap={4}
          as="form"
          id="price-uplift-config-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <MText>
            Price increase can be automatically added to the renewal Quote. Set
            a percentage or a max limit for uplift pricing
          </MText>
          <MFormField>
            <Controller
              control={control}
              name="percentageType"
              render={({ field: { value, onChange, ...rest } }) => (
                <MRadioGroup
                  isDisabled={isLockedOrIsReadOnly}
                  onChange={onChange}
                  value={value}
                  {...rest}
                >
                  <MStack spacing={4} direction="row" color="tGray.darkPurple">
                    <MRadio value={PriceUpliftPercentageTypeEnum.FIXED}>
                      Fixed Percentage
                    </MRadio>
                    <MRadio value={PriceUpliftPercentageTypeEnum.MAX}>
                      <MText display="inline-flex" alignItems="center">
                        Max Percentage
                        <MTooltip
                          label="Maximum percentage allowed for the price to increase"
                          placement="bottom-start"
                          shouldWrapChildren
                        >
                          <MIcon
                            display="flex"
                            alignItems="center"
                            as={MdInfo}
                            color="tPurple.base"
                            mx={1}
                          />
                        </MTooltip>
                      </MText>
                    </MRadio>
                  </MStack>
                </MRadioGroup>
              )}
            />
          </MFormField>

          <MFlex w="full" gap={4} align="center">
            <MFormField w="20%">
              <Controller
                control={control}
                name="amount"
                render={({ field: { value, onChange, ...rest } }) => (
                  <MInputGroup>
                    <MCustomNumberInput
                      isReadOnly={isLockedOrIsReadOnly}
                      inputMode="decimal"
                      value={value ?? ''}
                      onChange={(
                        valueAsString: string,
                        valueAsNumber: number,
                      ) => {
                        onChange(valueAsString);
                      }}
                      placeholder="0"
                      isDisabled={!isApplyToAll}
                      {...rest}
                    />
                    <InputRightElement fontWeight="bold" children="%" />
                  </MInputGroup>
                )}
              />
            </MFormField>
            <MFormField w="80%">
              <Controller
                name="type"
                control={control}
                render={({ field: { value, onChange, ...rest } }) => (
                  <MCheckbox
                    isReadOnly={isLockedOrIsReadOnly}
                    value={value}
                    isChecked={isApplyToAll}
                    onChange={(ev) => {
                      setValue(
                        'type',
                        ev.target.checked
                          ? PriceUpliftConfigTypeEnum.APPLY_TO_ALL_PRODUCTS
                          : PriceUpliftConfigTypeEnum.PRODUCT_SPECIFIC,
                      );
                      setIsApplyToAll(ev.target.checked);
                    }}
                    {...rest}
                  >
                    Apply to all products
                  </MCheckbox>
                )}
              />
            </MFormField>
          </MFlex>
          <MBox>
            <Collapse in={!isApplyToAll} animateOpacity>
              <MDivider />
              <MFlex gap={4} flexDirection="column" my={4}>
                {items &&
                  items.map(({ id, productName, productId }, idx) => (
                    <MGrid key={id} templateColumns="3fr 1fr" columnGap={4}>
                      <MGridItem>
                        <MTooltip label={productName}>
                          <MText noOfLines={1}>{productName}</MText>
                        </MTooltip>
                      </MGridItem>
                      <MGridItem>
                        <MFormField>
                          <Controller
                            name={`productConfigurations.${idx}.amount`}
                            control={control}
                            render={({
                              field: { value, onChange, ...rest },
                            }) => (
                              <MInputGroup>
                                <MCustomNumberInput
                                  isReadOnly={isLocked}
                                  inputMode="decimal"
                                  placeholder="0"
                                  value={value || ''}
                                  onChange={(
                                    valueAsString: string,
                                    valueAsNumber: number,
                                  ) => {
                                    onChange(valueAsString);
                                    setValue(
                                      `productConfigurations.${idx}.productId`,
                                      productId,
                                    );
                                    setValue(
                                      `productConfigurations.${idx}.percentageType`,
                                      percentageType,
                                    );
                                  }}
                                  {...rest}
                                />
                                <InputRightElement
                                  fontWeight="bold"
                                  children="%"
                                />
                              </MInputGroup>
                            )}
                          />
                        </MFormField>
                      </MGridItem>
                    </MGrid>
                  ))}
              </MFlex>
            </Collapse>
            <MDivider />
          </MBox>
          <MFlex justify="flex-end" align="center">
            <MButton
              form="price-uplift-config-form"
              type="submit"
              variant="primary"
              isDisabled={
                !isValid ||
                !isDirty ||
                isLockedOrIsReadOnly ||
                isCreateLoading ||
                isUpdateLoading
              }
              isLoading={isCreateLoading || isUpdateLoading}
            >
              Save
            </MButton>
          </MFlex>
        </MFlex>
      )}
    </MFlex>
  );
};
