import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useCreateEntity,
  useDeleteEntity,
  usePerformEntityAction,
} from '~app/api/queryUtils';
import {
  ITaxConnectionSchema,
  TaxProviderTypeEnum,
  TaxStatusEnum,
} from '~app/types/taxTypes';
import { settingsQueryKeys } from '../../../../../../api/settingsService';
import { MDivider, MLink, MText } from '../../../../../../components/Monetize';
import {
  TAX_ENVIRONMENTS_DISPLAY,
  TAX_IDENTIFIER_DISPLAY,
} from '../../../../../../constants/taxes';
import { SettingsToggle } from '../../../../Quoting/QuoteSettings/components/SettingsToggle';
import { TaxCardEdit } from '../TaxCardEdit';
import { TaxCardAction, TaxCardView } from '../TaxCardView';
import { AvalaraForm } from './AvalaraForm';

interface AvalaraTaxConnectionProps {
  connection: ITaxConnectionSchema;
  verifyConnection: (provider: TaxProviderTypeEnum) => Promise<void>;
  isAvalaraConnectionLoading: boolean;
}

export const AvalaraTaxConnection: FC<AvalaraTaxConnectionProps> = ({
  connection,
  verifyConnection,
  isAvalaraConnectionLoading,
}) => {
  const queryClient = useQueryClient();
  const { mutateAsync: createTaxConfiguration } = useCreateEntity<
    ITaxConnectionSchema,
    unknown
  >('tax', {
    onMutate: async (newTaxSettingsData) => {
      await queryClient.cancelQueries({
        queryKey: [
          ...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA),
        ],
      });
      const previousTaxSettingsData = queryClient.getQueryData([
        ...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA),
      ]);
      queryClient.setQueryData(
        settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA),
        newTaxSettingsData,
      );

      return { previousTaxSettingsData };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(
        [...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA)],
        data,
      );
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [
          ...settingsQueryKeys.taxByProvider(TaxProviderTypeEnum.AVALARA),
        ],
      });
    },
  });

  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');

  const { mutateAsync: doUpdateStatus } =
    usePerformEntityAction<ITaxConnectionSchema>('tax', {
      meta: { showErrorToast: true },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: settingsQueryKeys.tax() });
      },
    });
  const { mutateAsync: doDeleteConnection } = useDeleteEntity('tax', {
    meta: { showErrorToast: true },
  });
  const isActive = connection.status === TaxStatusEnum.ACTIVE;

  const handleAction = async (action: TaxCardAction) => {
    switch (action) {
      case 'STATUS_UPDATE':
        return doUpdateStatus({
          id: TaxProviderTypeEnum.AVALARA,
          action: isActive ? TaxStatusEnum.INACTIVE : TaxStatusEnum.ACTIVE,
        });
      case 'VERIFY':
        await verifyConnection(TaxProviderTypeEnum.AVALARA);
        break;
      case 'DELETE':
        await doDeleteConnection({ id: TaxProviderTypeEnum.AVALARA });
        break;
      case 'OPEN':
        window.open('https://www.avalara.com/', '_blank');
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mode === 'VIEW' && (
        <>
          <TaxCardView
            mb="4"
            status={connection.status}
            actions={[
              {
                action: 'STATUS_UPDATE',
                label: isActive ? 'Disable Connection' : 'Enable Connection',
                isDanger: false,
              },
              { action: 'VERIFY', label: 'Verify Connection' },
              { action: 'OPEN', label: 'Avalara', icon: MdOpenInNew },
              { action: 'DELETE', label: 'Delete', isDanger: true },
            ]}
            tags={[
              {
                label: 'Account Number',
                value: connection.credentials?.accountNumber!,
              },
              {
                label: 'Environment',
                value:
                  TAX_ENVIRONMENTS_DISPLAY[
                    connection.credentials?.environment!
                  ],
              },
              {
                label: 'Tax Calculation Field',
                value:
                  TAX_IDENTIFIER_DISPLAY[connection.configurations.identifier!],
              },
              {
                label: 'Company Code',
                value: connection.credentials?.companyCode!,
              },
              {
                label: 'License Key',
                value: connection.credentials?.licenseKey!,
                colSpan: 2,
              },
            ]}
            type={TaxProviderTypeEnum.AVALARA}
            onEdit={() => setMode('EDIT')}
            onAction={handleAction}
            tagsContainer={{
              columnGap: 4,
              templateColumns: '9.375rem 1fr',
            }}
          />
          {connection && (
            <>
              <MText fontSize="md" fontWeight="600">
                Configuration
              </MText>
              <MText>
                For more information about tax configuration,{' '}
                <MLink
                  href="https://developer.avalara.com/documentation/"
                  target="_blank"
                  variant="link"
                >
                  view documentation
                </MLink>
              </MText>
              <MDivider my="4" />

              <SettingsToggle
                name="postAndCommitTransactions"
                heading="Post and Commit Transactions"
                subheading=""
                fetchLoading={isAvalaraConnectionLoading}
                value={!!connection.configurations.postAndCommitTransactions}
                onChange={async (postAndCommitTransactions) => {
                  await createTaxConfiguration({
                    ...connection,
                    configurations: {
                      ...connection.configurations,
                      postAndCommitTransactions,
                    },
                  });
                }}
              />
            </>
          )}
        </>
      )}
      {mode === 'EDIT' && (
        <TaxCardEdit type={TaxProviderTypeEnum.AVALARA}>
          <AvalaraForm
            connection={connection}
            handleCancel={() => setMode('VIEW')}
          />
        </TaxCardEdit>
      )}
    </>
  );
};
