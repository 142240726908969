import { useDisclosure } from '@chakra-ui/react';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doArchiveQuoteService,
  doCancelQuoteService,
  doCloneQuote,
  doPrintQuoteToPdf,
  doRetractQuoteService,
} from '~app/api/cpqService';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { NotesDrawerV2 } from '~app/components/Notes/NotesDrawerV2';
import {
  QUOTE_ACCEPTED_STATUSES,
  QUOTE_ARCHIVE_ACTION_STATUSES,
  QUOTE_COPYABLE_STATUSES,
  QUOTE_READONLY_STATUSES,
} from '~app/constants/quotes';
import { useACL } from '~app/services/acl/acl';
import { useAuth } from '~app/services/auth0';
import { useConfirmModal } from '~app/services/confirmModal';
import { useFlags } from '~app/services/launchDarkly';
import { logger } from '~app/services/logger';
import { useToast } from '~app/services/toast';
import {
  INewQuoteTypeReqSchema,
  IQuoteBasicRespSchema,
  IQuoteItem,
  IQuoteOfferingRespSchema,
  IQuoteRespSchema,
  NewQuoteTypeEnum,
  QuoteCancelationReasonEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
  UserRoleEnum,
} from '~app/types';
import { downloadBlobAsFile } from '~app/utils/download';
import { ROLES, ROUTES } from '~constants';
import { ManualQuoteAcceptanceModal } from '~routes/Quotes/Quote/ManualQuoteAcceptanceModal';
import { CustomizeQuoteDrawerV2 } from '../../routes/Quotes/Quote/components/customizeQuoteDrawer/CustomizeQuoteDrawerV2';
import ManualRenewalModal from '../../routes/Quotes/Quote/ManualRenewalModal';
import { PreviewQuotePdfDrawerV2 } from './PreviewQuotePdfDrawerV2';

type QuoteActionProps = {
  quote: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>;
  isQuotesList?: boolean;
  manualRenewalFormMethods?: UseFormReturn<INewQuoteTypeReqSchema, object>;
  loadData: () => void;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  isReadOnly?: boolean;
  handleEditQuoteClick?: () => void;
  canEditQuote?: boolean;
  editQuoteLoading?: boolean;
};

export const QuoteActionsV2: FC<QuoteActionProps> = ({
  quote,
  isQuotesList,
  manualRenewalFormMethods,
  loadData,
  setQuote,
  isReadOnly,
  handleEditQuoteClick,
  canEditQuote,
  editQuoteLoading,
}: QuoteActionProps) => {
  const { hideMarkAsRenewal, useQuotePdfV2, quoteAcceptanceAllowedRoles } =
    useFlags();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();
  const {
    onOpen: onOpenManualAcceptanceModal,
    onClose: onCloseManualAcceptanceModal,
    isOpen: isOpenManualAcceptanceModal,
  } = useDisclosure();
  const {
    onOpen: onOpenManualRenewalModal,
    onClose: onCloseManualRenewalModal,
    isOpen: isOpenManualRenewalModal,
  } = useDisclosure();
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);
  const canReadSales = canDo([['sales', 'read']]);
  const { currentTenantUser, currentTenantUserHasRole } = useAuth();

  const canAcceptQuote =
    !quoteAcceptanceAllowedRoles?.length ||
    quoteAcceptanceAllowedRoles[0] === 'ALL' ||
    currentTenantUserHasRole(quoteAcceptanceAllowedRoles as UserRoleEnum[]);

  if (!quote) {
    return null;
  }

  const isAmendment = quote.type === QuoteTypeEnum.AMENDMENT;
  const { requiresEsign } = quote;

  // Check if the quote Item amendStatus is NO_CHANGE
  const ifAmendStatusIsNoChange = quote?.quoteOfferings
    ?.reduce(
      (acc: IQuoteItem[], curr: IQuoteOfferingRespSchema) =>
        curr.items ? [...acc, ...curr.items] : acc,
      [] as IQuoteItem[],
    )
    .every(
      (x: IQuoteItem) =>
        x.amendmentStatus === QuoteItemAmendmentStatusEnum.NO_CHANGE,
    );

  const handleCancelQuote = (
    data: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
  ) => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        data?.id &&
          (await doCancelQuoteService(
            data,
            QuoteCancelationReasonEnum.BY_USER,
          ));
      } catch (err) {
        logger.error(err);
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: `Are you sure you want to cancel "${data.description}"?`,
      description:
        'A Quote cancelation cannot be undone, you can always create a new quote if needed.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
      noBtnProps: {
        variant: 'cancel' as any,
      },
      yesButton: 'Confirm',
      noButton: 'Close',
    });
  };

  const handleArchiveQuote = (
    data: IQuoteBasicRespSchema & Partial<IQuoteRespSchema>,
  ) => {
    const onYes = async () => {
      try {
        setModalLoading(true);
        data?.id && (await doArchiveQuoteService(data.id));
      } catch (error) {
        handleApiErrorToast(error);
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: `Are you sure you want to archive Quote for "${data.description}"?`,
      description:
        'This cannot be undone and this Quote will not be returned in a list of results going forward.',
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Archive',
      noButton: 'Cancel',
    });
  };

  const handleAcceptQuote = () => {
    onOpenManualAcceptanceModal();
  };

  const handleManualRenewal = () => {
    onOpenManualRenewalModal();
  };

  const handleRetractQuote = async () => {
    const onYes = async () => {
      setModalLoading(true);
      try {
        quote.id && (await doRetractQuoteService(quote.id));
      } catch (err) {
        // pass
      }
      setModalLoading(false);
      loadData();
      closeModal();
    };

    showConfirmModal({
      title: 'Are you sure you want to retract this quote?',
      description:
        'Retracting a Quote will invalidate the eSign Document, you can always resend the quote for signature',
      onYes,
      yesBtnProps: {
        variant: 'delete',
      },
      noBtnProps: {
        variant: 'cancel',
      },
      yesButton: 'Retract',
      noButton: 'Close',
    });
  };

  const handlePrintToPdf = async () => {
    try {
      const resp = await doPrintQuoteToPdf(quote.id, useQuotePdfV2);

      downloadBlobAsFile(resp, `${quote.description}.pdf`);
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const handleCloneQuote = async (quoteId: string) => {
    if (quoteId) {
      try {
        const quoteResponse = await doCloneQuote(quoteId);

        if (quoteResponse?.id) {
          navigate(ROUTES.getQuoteEditRoute(quoteResponse.id));

          addToast({
            summary: 'New Quote Created',
            detail: `${quoteResponse.description} has successfully been created.`,
            severity: 'success',
          });
        }
      } catch (err) {
        handleApiErrorToast(err);
      }
    }
  };

  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);

  if (!hasValidRole) {
    return null;
  }

  const canCancel =
    !isQuotesList &&
    !QUOTE_READONLY_STATUSES.has(quote.status) &&
    canUpdateSales;

  const actions: ActionType[] = [
    {
      actionElement: (
        <PreviewQuotePdfDrawerV2
          quote={quote as IQuoteRespSchema}
          key={'Preview PDF'}
        />
      ),
      enabled: !isQuotesList && !!quote.id,
    },
    {
      actionElement: (
        <CustomizeQuoteDrawerV2
          quote={quote as IQuoteRespSchema}
          key={'Customize Quote'}
        />
      ),
      enabled: !isQuotesList && !!quote.id,
    },
    {
      title: 'Copy Quote',
      enabled: QUOTE_COPYABLE_STATUSES.has(quote.status) && canUpdateSales,
      action: () => handleCloneQuote(quote.id),
    },
    {
      title: 'Print to PDF',
      // Only displays on table view
      enabled:
        !location.pathname.startsWith(ROUTES.QUOTE_BASE_PATH) && canReadSales,
      action: () => handlePrintToPdf(),
    },
    {
      actionElement: (
        <NotesDrawerV2
          id={quote.id}
          entity="quotes"
          milestones={
            QUOTE_ACCEPTED_STATUSES.has(quote.status) && quote.acceptedAt
              ? [{ date: quote.acceptedAt, label: 'Quote Accepted' }]
              : undefined
          }
          isReadOnly={isReadOnly}
          disabled={!quote}
          key={'Notes'}
        />
      ),
      enabled: !isQuotesList && !!quote.id,
    },
    {
      title: 'Retract E-sign',
      enabled:
        !isQuotesList &&
        requiresEsign &&
        quote.status === QuoteStatusEnum.SENT &&
        canUpdateSales,
      action: () => handleRetractQuote(),
    },
    {
      title: 'Manually Accept',
      enabled:
        !isQuotesList &&
        quote.status === QuoteStatusEnum.SENT &&
        !(isAmendment && ifAmendStatusIsNoChange) &&
        !requiresEsign &&
        canAcceptQuote &&
        canUpdateSales,
      action: () => handleAcceptQuote(),
    },
    // TODO: Need resend email api from BE
    // {
    //   title: 'Resend Email',
    //   enabled:
    //     quote.status === QuoteStatusEnum.SENT &&
    //     requiresEsign &&
    //     canUpdateSales,
    //   action: () => handleResendQuote(),
    // },
    // {
    //   title: 'Resend E-sign',
    //   loading: itemLoading === 'esign',
    //   enabled:
    //     !isQuotesList &&
    //     requiresEsign &&
    //     quote.status === QuoteStatusEnum.SENT,
    //   action: () => handleResendQuote(),
    // },
    {
      title: 'Mark as Renewal',
      enabled:
        !isQuotesList &&
        !hideMarkAsRenewal &&
        quote.type === QuoteTypeEnum.NEW &&
        quote.status === QuoteStatusEnum.DRAFT &&
        quote.newQuoteType !== NewQuoteTypeEnum.MANUAL_RENEWAL &&
        canUpdateSales,
      action: () => handleManualRenewal(),
    },
    // Danger items should always remain at the bottom
    {
      title: 'Cancel',
      color: 'danger',
      enabled: canCancel,
      hasTopDivider: true,
      action: () => handleCancelQuote(quote),
    },
    {
      title: 'Archive',
      enabled:
        QUOTE_ARCHIVE_ACTION_STATUSES.has(quote.status) &&
        canUpdateSales &&
        quote.ownerId === currentTenantUser.id,
      hasTopDivider: !canCancel,
      action: () => handleArchiveQuote(quote),
    },
  ];

  return (
    <>
      <DataTableActions
        actions={actions}
        iconContainerProps={{ minH: '8', minW: '8', btnSize: 6 }}
      />
      <ManualQuoteAcceptanceModal
        isOpen={isOpenManualAcceptanceModal}
        quoteId={quote.id}
        onClose={() => {
          onCloseManualAcceptanceModal();
          loadData();
        }}
      />
      {manualRenewalFormMethods && (
        <ManualRenewalModal
          isOpen={isOpenManualRenewalModal}
          quoteId={quote.id}
          manualRenewalForm={manualRenewalFormMethods}
          setQuote={setQuote}
          onClose={onCloseManualRenewalModal}
        />
      )}
    </>
  );
};
