import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { unparse } from 'papaparse';
import { transformRevRecToTabularData } from '~app/routes/RevenueRecognition/rev-rec.utils';
import {
  IRevenueRecognitionResponse,
  IRevRecSettingsResponse,
  IRevRecWaterfallRequest,
  RevenueRecognitionResponse,
  RevRecTriggerEnum,
} from '~app/types/revenueTypes';
import { apiGet, apiPost } from './axios';

export const revenueKeys = {
  base: ['revenue'] as const,
  revRecSettings: () => [...revenueKeys.base, 'settings'] as const,
  revRecSchedule: () => [...revenueKeys.base, 'schedule'] as const,
};

export function useRevRecSettings(
  options: Partial<UseQueryOptions<IRevRecSettingsResponse>> = {},
) {
  return useQuery({
    queryKey: [...revenueKeys.revRecSettings()],
    queryFn: () =>
      apiGet<IRevRecSettingsResponse>(
        '/api/configurations/revenueRecognition',
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    select: (data) => ({
      ...data,
      trigger: data.enabled ? data.trigger : RevRecTriggerEnum.INVOICE_CREATED,
    }),
    ...options,
  });
}

export function useSaveRevRecSettings(
  options: Partial<
    UseMutationOptions<void, unknown, IRevRecSettingsResponse>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<void, unknown, IRevRecSettingsResponse>({
    mutationFn: (params) =>
      apiPost('/api/configurations/revenueRecognition', {
        ...params,
        trigger: params.enabled ? params.trigger : null,
      }).then((res) => res.data),

    onSuccess: (response, variables, context) => {
      queryClient.setQueryData([...revenueKeys.revRecSettings()], variables);
      onSuccess && onSuccess(response, variables, context);
    },
    ...restOptions,
  });
}

export function useRevRecSchedule(
  params: IRevRecWaterfallRequest,
  options: Partial<UseQueryOptions<IRevenueRecognitionResponse>> = {},
) {
  return useQuery({
    queryKey: [...revenueKeys.revRecSchedule(), params],
    queryFn: () =>
      apiGet<IRevenueRecognitionResponse>('/api/revenueRecognition/dashboard', {
        params,
      }).then((res) => RevenueRecognitionResponse.parse(res.data)),

    ...options,
  });
}

export function useExportRevRec(
  options: Partial<
    UseMutationOptions<string, unknown, IRevRecWaterfallRequest>
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<string, unknown, IRevRecWaterfallRequest>({
    mutationFn: (params) =>
      apiGet<IRevenueRecognitionResponse>('/api/revenueRecognition/dashboard', {
        params,
      })
        .then((res) => RevenueRecognitionResponse.parse(res.data))
        .then((revRecData) => {
          const { recognitionSchedule } = revRecData;
          const periods = recognitionSchedule[0]?.periods || [];
          const { rows } = transformRevRecToTabularData(revRecData);
          const csv = unparse(
            {
              fields: [
                'period',
                'currency',
                'total',
                ...periods.map((period) => period.period),
                'deferred',
              ],
              data: rows,
            },
            { header: true },
          );
          return csv;
        }),
    onSuccess: (...response) => {
      onSuccess && onSuccess(...response);
    },
    ...restOptions,
  });
}
