import { useGetQuotePrices } from '~app/api/cpqService';
import { IQuotePrice } from '~app/types';

const defaultValue = [] as IQuotePrice[];

export interface QuotePricesDataTypes {
  loading: boolean;
  quotePrices: IQuotePrice[];
}
const useQuotePrices = (quoteId: string): QuotePricesDataTypes => {
  const { data: quotePricesData, isLoading: loading } = useGetQuotePrices(
    quoteId,
    { enabled: !!quoteId },
  );

  return {
    loading,
    quotePrices: quotePricesData || defaultValue,
  };
};

export default useQuotePrices;
