import { useDisclosure, usePrevious } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { IoMdAlert } from 'react-icons/io';
import { approvalsQueryKeys, doQuoteApprove } from '~app/api/approvalService';
import { APPROVAL } from '~app/constants';
import { QUOTE_SUBMITTED_STATUSES } from '~app/constants/quotes';
import { useAuth } from '~app/services/auth0';
import {
  IApprovalRespSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '~app/types';
import { toDateShort } from '~app/utils/dates';
import { sortQuoteApprovals } from '~app/utils/quotes';
import { MBox, MFlex, MIcon, MText, MVStack } from '../../Monetize';
import { QuoteProgressStep } from './QuoteProgressStep';
import { QuoteStepApprovalItem } from './QuoteStepApprovalItem';

interface QuoteStepApprovalProps {
  quote: IQuoteRespSchema;
  approvals: IApprovalRespSchema[];
  reloadData?: () => void;
}

const getCurrentIndex = (
  isQuoteSubmitted: boolean,
  internalApprovals: IApprovalRespSchema[],
) => {
  if (!isQuoteSubmitted) {
    return -1;
  }

  let i = 0;
  for (i = 0; i < internalApprovals.length; i += 1) {
    if (
      !internalApprovals[i].skipped &&
      internalApprovals[i].status !== APPROVAL.APPROVAL_STATUS.APPROVED
    ) {
      break;
    }
  }
  return i;
};

const getLastRejectIndex = (
  isQuoteSubmitted: boolean,
  internalApprovals: IApprovalRespSchema[],
) => {
  if (!isQuoteSubmitted) {
    return internalApprovals.length;
  }

  let i = 0;
  for (i = 0; i < internalApprovals.length; i += 1) {
    if (internalApprovals[i].status === APPROVAL.APPROVAL_STATUS.DECLINED) {
      break;
    }
  }

  return i;
};

export const QuoteStepApproval: FC<QuoteStepApprovalProps> = React.forwardRef<
  any,
  QuoteStepApprovalProps
>(({ quote, approvals, reloadData }, ref) => {
  let stepTitle = 'Get X approvals';
  let stepDate = '';
  const isQuoteSubmitted = QUOTE_SUBMITTED_STATUSES.has(quote.status);

  const [internalApprovals, setInternalApprovals] = useState<
    IApprovalRespSchema[]
  >(() => sortQuoteApprovals(approvals || []));
  const previousApprovals = usePrevious(approvals);
  const popoverState = useDisclosure();

  const { userId } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(() =>
    getCurrentIndex(isQuoteSubmitted, internalApprovals),
  );
  const [lastRejectedIndex, setLastRejectIndex] = useState(() =>
    getLastRejectIndex(isQuoteSubmitted, internalApprovals),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showHighlight, setShowHighlight] = useState(false);

  const queryClient = useQueryClient();

  const isCompleted =
    quote.status !== QuoteStatusEnum.DRAFT && currentIndex === approvals.length;
  const isDisabled =
    quote.status === QuoteStatusEnum.DRAFT && approvals.length === 0;
  const isCurrent =
    quote.status === QuoteStatusEnum.REVIEW ||
    quote.status === QuoteStatusEnum.DENIED;
  const showInprogressStepNumber = isCurrent && !isCompleted;
  const showStepPopover = approvals.length > 0 && !isDisabled;
  let isError = false;
  let showLightBackground = false;

  const approvedCount = internalApprovals.filter(
    (approval) =>
      approval.status === APPROVAL.APPROVAL_STATUS.APPROVED || approval.skipped,
  ).length;

  if (quote.status === QuoteStatusEnum.DENIED) {
    stepTitle = 'Approval Denied';
    isError = true;
    showLightBackground = true;
  } else if (approvals.length === 0) {
    stepTitle = 'No Approvals Required';
  } else if (approvals.length > 0 && quote.status === QuoteStatusEnum.DRAFT) {
    stepTitle = `Get ${approvals.length} ${
      approvals.length === 1 ? 'Approval' : 'Approvals'
    }`;
  } else if (approvals.length > 0 && quote.status !== QuoteStatusEnum.DRAFT) {
    stepTitle = `${approvedCount}/${approvals.length} ${
      approvals.length === 1 ? 'Approval' : 'Approvals'
    } Received`;
  }

  if (isCompleted) {
    stepDate = toDateShort(quote.approvalGrantedAt);
  }

  useEffect(() => {
    setInternalApprovals(sortQuoteApprovals(approvals || []));
  }, [approvals]);

  useEffect(() => {
    setCurrentIndex(getCurrentIndex(isQuoteSubmitted, internalApprovals));
    setLastRejectIndex(getLastRejectIndex(isQuoteSubmitted, internalApprovals));
  }, [internalApprovals, isQuoteSubmitted]);

  // When the approval amount changes, this field will briefly show a dropshadow background and blue stroke for a few seconds before going back to normal:
  useEffect(() => {
    if (!previousApprovals) {
      return;
    }
    if (previousApprovals.length !== approvals.length) {
      setShowHighlight(true);
      setTimeout(() => {
        setShowHighlight(false);
      }, 1500);
    }
  }, [previousApprovals, approvals]);

  const onApprove = async (approval: IApprovalRespSchema) => {
    // this function and use of reloadData to be removed once all consumers of this component are on React Query
    setIsLoading(true);
    try {
      await doQuoteApprove(approval.id);
      queryClient.invalidateQueries({
        queryKey: [...approvalsQueryKeys.dashboard()]
      });
    } catch (err) {
      // pass
    }
    setIsLoading(false);

    reloadData && reloadData();
  };

  // Decide if the current user is the approver or part of the team that needs to take action
  const currentUserNeedsAction = internalApprovals.some(
    (approval, index) =>
      index >= currentIndex &&
      (approval.currentUserApprover || userId === approval.userId),
  );

  const requiresApproval = isCurrent && currentUserNeedsAction && !isError;

  const [showedPopver, setShowedPopover] = useState(false);
  useEffect(() => {
    if (!showedPopver && internalApprovals.length > 0 && requiresApproval) {
      popoverState.onOpen();
      setShowedPopover(true);
    }
  }, [internalApprovals, showedPopver, requiresApproval, popoverState]);

  return (
    <QuoteProgressStep
      key="quote-progress-approvals"
      stepTitle={
        <MBox>
          <MText fontSize="sm" fontWeight="500" color="inherit">
            {stepTitle}
          </MText>
        </MBox>
      }
      stepDate={
        requiresApproval ? (
          <MFlex alignItems="center">
            <MIcon
              color="tOrange.base"
              as={IoMdAlert}
              mr="1"
              w="3"
              h="3"
              mt="-0.5"
            />
            <MText fontSize="xs" color="tOrange.base">
              Requires your approval
            </MText>
          </MFlex>
        ) : (
          stepDate
        )
      }
      stepNumber={2}
      isCompleted={isCompleted}
      showInprogressStepNumber={showInprogressStepNumber}
      isDisabled={isDisabled}
      isCurrent={isCurrent}
      showStepPopover={showStepPopover}
      isError={isError}
      showLightBackground={showLightBackground}
      showHighlight={showHighlight}
      popoverContent={({ onClose }) => (
        <MBox p="2">
          <MBox
            maxH="350px"
            className="custom-scroll-bar-v1"
            overflowY="auto"
            pr="2"
          >
            <MVStack spacing={2}>
              {internalApprovals.map((approval, index) => {
                const isCurrentUserOrTeam =
                  userId === approval.userId || approval.currentUserApprover;
                return (
                  <QuoteStepApprovalItem
                    key={approval.id}
                    approval={approval}
                    quote={quote}
                    canDoActions={isCurrentUserOrTeam && isCurrent}
                    onClose={onClose}
                    onApprove={onApprove}
                    isLoading={isLoading}
                    reloadData={reloadData}
                    itemIndex={index + 1}
                    showConnectionLine={index < internalApprovals.length - 1}
                  />
                );
              })}
            </MVStack>
          </MBox>
        </MBox>
      )}
      containerProps={{ ml: -8 }}
      textProps={
        // If draft and approvals exist, show in purple
        quote.status === QuoteStatusEnum.DRAFT && approvals.length > 0
          ? { color: 'tPurple.base' }
          : {}
      }
      popoverState={popoverState}
    />
  );
});
