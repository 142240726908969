import { Link } from '@chakra-ui/react';
import { MdEdit } from 'react-icons/md';
import { Link as ReactDomLink } from 'react-router-dom';
import {
  MBox,
  MCheckbox,
  MCustomIconButton,
  MDivider,
  MFlex,
  MLink,
  MSimpleGrid,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { useCustomFields } from '~app/hooks/useCustomFields';
import {
  CustomFieldEntityEnum,
  CustomFieldTypeEnum,
  IBillGroupResp,
  IContractWithQuotes,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
  IInvoiceRespSchema,
  Maybe,
} from '~app/types';
import { useGetById } from '../../api/queryUtils';
import {
  getBillGroupPageRoute,
  getInvoiceDetailContractsEdit,
  getQuoteEditRoute,
} from '../../constants/routes';
import { toDateShort } from '../../utils/dates';

type EditInvoiceSectionProps = {
  isLoading: boolean;
  isDraftInvoice?: boolean;
  invoice: IInvoiceRespSchema;
  customFiledValues?: ICustomFieldRecordSchema;
  onOpenEditInvoiceModal: () => void;
};

export const EditInvoiceSection = ({
  isLoading,
  isDraftInvoice,
  invoice,
  customFiledValues,
  onOpenEditInvoiceModal,
}: EditInvoiceSectionProps) => {
  const { data: billGroup } = useGetById<IBillGroupResp>(
    'billGroups',
    invoice.billGroupId,
  );

  const { data: contractAndQuoteData } = useGetById<
    IContractWithQuotes,
    { quoteDescription: Maybe<string>; contractName: string }
  >('contracts', invoice.contractId!, {
    enabled: !!invoice.contractId,
    select: (data) => ({
      quoteDescription: data.quotes.find(
        (quote) => quote.id === invoice.quoteId,
      )?.description,
      contractName: `${toDateShort(data.startDate)} - ${toDateShort(data.endDate)}`,
    }),
  });

  const { customFieldList, isLoading: isCustomFieldsLoading } = useCustomFields(
    CustomFieldEntityEnum.INVOICE,
  );

  const stripHtmlTags = (html: string | null) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html ?? 'Blank', 'text/html');

    return doc.body.textContent;
  };

  if (isLoading || isCustomFieldsLoading) {
    return (
      <MBox w="100%" display="flex" alignItems="center" justifyContent="center">
        <MSpinner size="sm" />
      </MBox>
    );
  }

  return (
    <MBox
      width="100%"
      border="1px"
      borderColor="tGray.lightPurple"
      borderRadius={8}
      p={6}
    >
      <MFlex justifyContent="space-between">
        <MText fontSize={16} fontWeight={600} mb={4}>
          Invoice Details
        </MText>
        <MCustomIconButton
          variant="icon"
          btnSize={5}
          iconColor="tPurple.base"
          icon={MdEdit}
          data-testid="edit-draft-invoice-btn"
          onClick={onOpenEditInvoiceModal}
        />
      </MFlex>
      <MDivider mb={6} />
      <MSimpleGrid
        columns={{
          base: 1,
          sm: 2,
          md: 3,
          lg: 4,
          xl: 5,
        }}
        gap={4}
        mb={6}
        alignItems="flex-start"
      >
        {invoice.billGroupId && (
          <MBox>
            <MText color="tGray.darkPurple" fontWeight={400}>
              Bill Group
            </MText>
            <Link
              as={ReactDomLink}
              to={getBillGroupPageRoute(invoice.billGroupId)}
              fontSize="sm"
              _hover={{ textDecoration: 'underline' }}
            >
              {billGroup?.name ?? invoice.billGroupId}
            </Link>
          </MBox>
        )}
        {invoice.contractId && (
          <MBox>
            <MText color="tGray.darkPurple" fontWeight={400}>
              Contract
            </MText>
            <Link
              as={ReactDomLink}
              to={getInvoiceDetailContractsEdit(invoice.contractId)}
              fontSize="sm"
              _hover={{ textDecoration: 'underline' }}
            >
              {contractAndQuoteData?.contractName ?? invoice.contractId}
            </Link>
          </MBox>
        )}
        {invoice.quoteId && (
          <MBox>
            <MText color="tGray.darkPurple" fontWeight={400}>
              Quote
            </MText>
            <Link
              as={ReactDomLink}
              to={getQuoteEditRoute(invoice.quoteId)}
              fontSize="sm"
              _hover={{ textDecoration: 'underline' }}
              maxWidth="200px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              display="block"
            >
              {contractAndQuoteData?.quoteDescription ?? invoice.contractId}
            </Link>
          </MBox>
        )}

        {isDraftInvoice && (
          <>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Billing Contact *
              </MText>
              <MText color="tPurple.200">
                {invoice.billingContact.fullName ?? 'Blank'}
              </MText>
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Billing Address *
              </MText>
              {invoice.billingAddress?.id ? (
                <>
                  {invoice.billingAddress.line1 && (
                    <MText>{invoice.billingAddress.line1}</MText>
                  )}
                  {invoice.billingAddress.line2 && (
                    <MText>{invoice.billingAddress.line2}</MText>
                  )}
                  <MText>
                    {invoice.billingAddress.city},{' '}
                    {invoice.billingAddress.country}
                  </MText>
                </>
              ) : (
                <MText>Blank</MText>
              )}
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Shipping Contact *
              </MText>
              <MText color="tPurple.200">
                {invoice.shippingContact.fullName ?? 'Blank'}
              </MText>
            </MBox>
            <MBox>
              <MText color="tGray.darkPurple" fontWeight={400}>
                Shipping Address *
              </MText>
              {invoice.shippingAddress?.id ? (
                <>
                  {invoice.shippingAddress.line1 && (
                    <MText>{invoice.shippingAddress.line1}</MText>
                  )}
                  {invoice.shippingAddress.line2 && (
                    <MText>{invoice.shippingAddress.line2}</MText>
                  )}
                  <MText>
                    {invoice.shippingAddress.city},{' '}
                    {invoice.shippingAddress.country}
                  </MText>
                </>
              ) : (
                <MText>Blank</MText>
              )}
            </MBox>
          </>
        )}

        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            PO Number
          </MText>
          <MText color="tPurple.200">
            {invoice.purchaseOrderNumber ?? 'Blank'}
          </MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Registration Number
          </MText>
          <MText color="tPurple.200">
            {invoice.registrationNumber ?? 'Blank'}
          </MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            VAT Number
          </MText>
          <MText color="tPurple.200">{invoice.vatNumber ?? 'Blank'}</MText>
        </MBox>
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Invoice Memo
          </MText>
          <MText color="tPurple.200" isTruncated>
            {stripHtmlTags(invoice.invoiceMemo)}
          </MText>
        </MBox>
        {customFieldList?.length < 5 &&
          customFieldList?.map((customField: ICustomFieldResSchema) => {
            const { key, displayLabel, type } = customField;
            const fieldValue = customFiledValues![key];

            switch (type) {
              case CustomFieldTypeEnum.CHECKBOX: {
                return (
                  <MBox key={key}>
                    <MCheckbox isChecked={!!fieldValue}>
                      {displayLabel}
                    </MCheckbox>
                  </MBox>
                );
              }
              default:
                return (
                  <MBox key={key}>
                    <MText color="tGray.darkPurple" fontWeight={400}>
                      {displayLabel}
                    </MText>
                    <MText color="tPurple.200">{fieldValue ?? 'Blank'}</MText>
                  </MBox>
                );
            }
          })}
      </MSimpleGrid>

      {customFieldList?.length >= 5 && (
        <MBox>
          <MText color="tGray.darkPurple" fontWeight={400}>
            Additional Fields
          </MText>
          <MLink
            onClick={onOpenEditInvoiceModal}
            fontSize="sm"
            cursor="pointer"
          >
            View All in Edit screen
          </MLink>
        </MBox>
      )}
    </MBox>
  );
};
