import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { Dispatch, SetStateAction } from 'react';
import { MCustomSelect } from '~app/components/Monetize';
import { useGetListData } from '../../../../../api/queryUtils';
import { listApiConfig } from '../../../../../constants/guidedQuoting';
import {
  GuidedQuotingContext,
  GuidedQuotingContextOffering,
  IRateResBaseSchema,
} from '../../../../../types';
import { isBetweenInclusive } from '../../../../../utils/dates';
import { RateItemContent } from '../quoteOffering/RateItemContent';

interface RateSelectProps {
  filter: any;
  value: any;
  setValue: Dispatch<any>;
  offeringId: string;
  offeringQuestionId: string;
  currency?: string;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
}

export const RateQuestionDropdown = ({
  filter,
  value,
  currency,
  offeringId,
  offeringQuestionId,
  setValue,
  setContext,
}: RateSelectProps) => {
  // FIXME: ideally parent fetches all rates that we might care about and then filter on offering here instead of fetching per offering
  // FIXME: once this is moved to parent, we will want to auto-select the rate if there is only one per offering
  // tried it as-is and only selects the first one since the changes are coming in at the same time - we would want to do at once
  const { data: rates } = useGetListData<
    IRateResBaseSchema,
    IRateResBaseSchema[]
  >(
    'productCatalogRates',
    {
      config: listApiConfig,
      filters: {
        ...filter,
        ...(currency && { currency }),
        ...(offeringId && { offeringId }),
        status: 'ACTIVE',
      },
    },
    {
      isGetAll: true,
      refetchOnWindowFocus: false,
      placeholderData: (previousData, previousQuery) => previousData,
      select: (data) => {
        // Make sure we only show valid rates - unable to do this with API filters
        return data.content.filter((rate) => {
          if (!rate.startDate && !rate.endDate) {
            return true;
          }
          const today = startOfDay(new Date());
          if (rate.startDate && rate.endDate) {
            return isBetweenInclusive(
              today,
              parseISO(rate.startDate),
              parseISO(rate.endDate),
            );
          }
          if (rate.startDate) {
            return today >= parseISO(rate.startDate);
          }
          if (rate.endDate) {
            return today <= parseISO(rate.endDate);
          }
        });
      },
    },
  );

  const handleSelection = (newValue: any) => {
    setValue(newValue);
    setContext((prev) => {
      const newState = structuredClone(prev);

      const contextValue = (
        newState[offeringQuestionId] as GuidedQuotingContextOffering
      ).value[offeringId];

      contextValue.rate = newValue.id;

      return newState;
    });
  };

  return (
    <MCustomSelect
      mt="0 !important"
      returnItem={true}
      itemTitle="name"
      itemValue="id"
      renderItemContent={RateItemContent}
      value={value ?? []}
      items={rates ?? []}
      onChange={handleSelection}
      isLazy
    />
  );
};
