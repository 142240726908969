import { AccordionButton, AccordionItemProps, Icon } from '@chakra-ui/react';
import { FC } from 'react';
import type { IconType } from 'react-icons';
import { MdChevronRight, MdExpandMore } from 'react-icons/md';
import {
  MAccordionItem,
  MAccordionPanel,
  MFlex,
  MText,
} from '~app/components/Monetize';

interface QuoteShareAccordionProps extends AccordionItemProps {
  title: string;
  icon: IconType;
  children?: React.ReactNode;
}

export const QuoteShareAccordion: FC<QuoteShareAccordionProps> = ({
  title,
  icon,
  children,
  ...rest
}: QuoteShareAccordionProps) => {
  return (
    <MAccordionItem border="none" {...rest}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton
              px={2}
              onClick={() => {}}
              minH="unset"
              borderBottom="unset"
            >
              <MFlex alignItems="center" alignContent="spread" w="full" py={2}>
                <Icon
                  boxSize="1.5rem"
                  as={isExpanded ? MdExpandMore : MdChevronRight}
                />
                <Icon boxSize="1.5rem" as={icon} ml={1} />
                <MFlex textAlign="left" alignItems="center" py={1} flexGrow={1}>
                  <MText fontWeight="500" fontSize="lg" ml={2}>
                    {title}
                  </MText>
                </MFlex>
              </MFlex>
            </AccordionButton>
          </h2>
          <MAccordionPanel p="4" px="10">
            {children}
          </MAccordionPanel>
        </>
      )}
    </MAccordionItem>
  );
};
