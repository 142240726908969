import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  MCloseButton,
  MCustomSelect,
  MFormField,
  MGridItem,
  MStack,
  MText,
} from '~app/components/Monetize';
import {
  IBulkQuoteOfferingReqSchema,
  IOfferingRes,
  IRateResBaseSchema,
} from '~app/types';
import { formatInteger } from '~app/utils';
import { getOfferingNameById } from '../../quoteUtils';
import { RateItemContent } from '../quoteOffering/RateItemContent';

export default function BulkRatesRow({
  fieldsCount,
  field,
  offerings,
  errorMessages,
  disabled,
  control,
  index,
  itemListByOfferingId,
  billingFrequency,
  moreThanOneRate,
  handleErrorMessageValidation,
  getBillingFrequencyLabel,
  removeOffering,
}: {
  fieldsCount: number;
  field: { offeringId: string };
  offerings: IOfferingRes[];
  errorMessages: Record<string, string> | undefined;
  control: Control<IBulkQuoteOfferingReqSchema>;
  index: number;
  disabled?: boolean;
  itemListByOfferingId: Record<
    string,
    {
      items: IRateResBaseSchema[];
      isBillingFrequencyMatch: boolean;
    }
  >;
  billingFrequency: string;
  moreThanOneRate: boolean;
  handleErrorMessageValidation: (offeringId: string) => void;
  getBillingFrequencyLabel: (billingFrequency: string) => string;
  removeOffering: (offeringId: string) => void;
}) {
  const selectElementVariant = useMemo(() => {
    if (errorMessages?.[field?.offeringId]) {
      return 'error';
    }

    // Use optional chaining to ensure safe access:
    const item = itemListByOfferingId?.[field?.offeringId];

    if (item?.isBillingFrequencyMatch && moreThanOneRate) {
      return 'unsaved';
    }

    return undefined;
  }, [errorMessages, field?.offeringId, itemListByOfferingId, moreThanOneRate]);

  return (
    <>
      <MGridItem colSpan={6}>
        <MText h={8} alignContent="center">
          {getOfferingNameById(field.offeringId, offerings)}
        </MText>
      </MGridItem>

      <MGridItem colSpan={4}>
        <MStack direction="row" alignItems="center">
          <MFormField
            isRequired
            error={errorMessages?.[field?.offeringId]}
            isDisabled={disabled}
          >
            <Controller
              name={`bulkRate.${index}.rateId`}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <MCustomSelect
                  inputProps={{
                    variant: selectElementVariant,
                  }}
                  isDisabled={disabled}
                  placeholder="Select Rate"
                  items={itemListByOfferingId[field.offeringId].items ?? []}
                  itemTitle="name"
                  itemValue="id"
                  onChange={(val) => {
                    onChange(val);
                    handleErrorMessageValidation(field.offeringId);
                  }}
                  renderItemContent={RateItemContent}
                  {...rest}
                />
              )}
            />
          </MFormField>
          {fieldsCount > 1 && (
            <MCloseButton
              isDisabled={disabled}
              onClick={() => removeOffering(field.offeringId)}
            />
          )}
        </MStack>
        {errorMessages?.[field?.offeringId] && (
          <MText color="tRed.base" fontSize="13px" mt={2}>
            {errorMessages?.[field?.offeringId]}
          </MText>
        )}

        {itemListByOfferingId[field.offeringId]?.isBillingFrequencyMatch &&
          moreThanOneRate && (
            <MText fontSize="13px" mt={2}>
              There are{' '}
              {formatInteger(
                itemListByOfferingId[field.offeringId].items.length,
              )}{' '}
              rates available to be billed{' '}
              {getBillingFrequencyLabel(billingFrequency)}. Please review.
            </MText>
          )}
      </MGridItem>
    </>
  );
}
