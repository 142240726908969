import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import {
  array,
  boolean,
  nativeEnum,
  number,
  object,
  string,
  union,
  z,
} from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { CustomFieldTypeEnumZ } from './customFieldsTypes';

export const MAX_PRIORITY = Math.pow(2, 31) - 1;

// from POST /rules
export enum RuleTargetEnum {
  QUOTE = 'QUOTE',
  QUOTE_ITEM = 'QUOTE_ITEM',
}
export const RuleTargetEnumZ = nativeEnum(RuleTargetEnum);

export enum RuleTypeEnum {
  APPROVAL = 'APPROVAL',
  VALIDATION = 'VALIDATION',
  CONDITIONAL_TERM = 'CONDITIONAL_TERM',
}

export const RuleTypeEnumZ = nativeEnum(RuleTypeEnum);

export enum RuleStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  ARCHIVED = 'ARCHIVED',
}
export const RuleStatusEnumZ = nativeEnum(RuleStatusEnum);

export enum RuleActionTypeEnum {
  APPROVAL = 'APPROVAL',
  VALIDATION = 'VALIDATION',
  CONDITIONAL_TERM = 'CONDITIONAL_TERM',
}
export const RuleActionTypeEnumZ = nativeEnum(RuleActionTypeEnum);

export enum RuleValidationErrorLevelEnum {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARN = 'WARN',
}
export const RuleValidationErrorLevelEnumZ = nativeEnum(
  RuleValidationErrorLevelEnum,
);

export enum QuoteRulePropertiesEnum {
  AMOUNT = 'amount',
  AMOUNT_BEFORE_DISCOUNT = 'amountWithoutDiscount',
  AUTO_RENEW_CONTRACT = 'autoRenewContract',
  BILLING_CONTACT = 'billingContact',
  CONDITIONAL_TERMS = 'conditionalTerms',
  CONTRACT_LENGTH = 'contractLength',
  CONTRACT_START_DATE = 'contractStartDate',
  CONTRACT_TERMS = 'contractTerms',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  DISCOUNT_AMOUNT = 'discountAmount',
  DISCOUNT_PERCENT = 'discountPercent',
  MIGRATED = 'migrated',
  MSA = 'documentLocation',
  NET_TERMS = 'netTerms',
  OPPORTUNITY = 'opportunity',
  PRIMARY_CONTACT = 'primaryContact',
  QUOTE_EXPIRATION_DATE = 'expirationDate',
  REQUIRES_ESIGN = 'requiresEsign',
  SOW = 'sowDocumentStorageId',
  TYPE = 'type',
}

export enum QuoteItemRulePropertiesEnum {
  AMOUNT = 'amount',
  CUSTOM_DISCOUNT_AMOUNT_OR_PERCENT = 'customDiscountAmountOrPercent',
  DISCOUNT_AMOUNT = 'discountAmount',
  DISCOUNT_PERCENT = 'discountPercent',
  PRODUCT_ID = 'productId',
  PRICE_UPLIFT_AMOUNT = 'priceUpliftAmount',
  QUANTITY = 'quantity',
}

export enum QuoteOwnerRulePropertiesEnum {
  TEAMS = 'teams',
}

export enum QuoteOfferingRulePropertiesEnum {
  ACCOUNT_SPECIFIC_RATE = 'accountSpecificRate',
  OFFERING_ID = 'offeringId',
  PARENT_QUOTE_OFFERING_ID = 'parentQuoteOfferingId',
}

export enum QuoteOfferingRateRulePropertiesEnum {
  RATE_ID = 'rateId',
  BILLING_FREQUENCY = 'billingFrequency',
  BILLING_FREQUENCY_IN_MONTHS = 'billingFrequencyInMonths',
  SUBSCRIPTION_TIMING = 'subscriptionTiming',
}

export type QuotePropertiesEnum =
  | QuoteRulePropertiesEnum
  | QuoteItemRulePropertiesEnum
  | QuoteOwnerRulePropertiesEnum
  | QuoteOfferingRulePropertiesEnum
  | QuoteOfferingRateRulePropertiesEnum;

export type IRuleAction = IRuleApprovalAction;

export type IRuleApprovalAction = {
  approval: IApprovalDetails;
};

export interface IApprovalDetails {
  userId: string | null;
  username: string | null;
  teamId: string | null;
  teamName: string | null;
  mandatory: boolean;
}

const RuleReqActionApproval = object({
  approverId: string().nonempty(getRequiredMessage('Approver')),
  approverName: string(),
  userId: string().optional(),
  username: string().optional(),
  teamId: string().optional(),
  teamName: string().optional(),
  mandatory: boolean(),
  priority: number().optional(),
});

const RuleReqActionValidation = object({
  errorLevel: RuleValidationErrorLevelEnumZ,
  message: string().nonempty(getRequiredMessage('Message')),
});

const RuleReqActionConditionalTerm = object({
  displayOrder: z.number().min(1),
  editableOnQuote: z.boolean(),
  terms: z.string().nonempty(getRequiredMessage('Terms')),
});

export const RuleReqAction = object({
  actionType: RuleActionTypeEnumZ,
  approval: RuleReqActionApproval.optional(),
  validation: RuleReqActionValidation.optional(),
  conditionalTerms: RuleReqActionConditionalTerm.optional(),
});
export type IRuleReqAction = z.infer<typeof RuleReqAction>;

export enum RuleOperatorEnum {
  AND = 'and',
  OR = 'or',
}
export const RuleOperatorEnumZ = nativeEnum(RuleOperatorEnum);

export enum RuleCollectionOperators {
  contains = 'contains',
  not_contains = 'not_contains',
}

export enum RuleConditionalOperators {
  gt = '>',
  lt = '<',
  gte = '>=',
  lte = '<=',
  eq = '=',
  ne = '!=',
}

export enum RuleCondModelEnum {
  QUOTE = 'quote',
  QUOTE_CUSTOM_FIELD = 'quoteCustomField',
  QUOTE_ITEM = 'quoteItem',
  QUOTE_OWNER = 'quoteOwner',
  QUOTE_OFFERING = 'quoteOffering',
  QUOTE_OFFERING_RATE = 'quoteOfferingRate',
}
export const RuleCondModelEnumZ = nativeEnum(RuleCondModelEnum);

export const RuleCustomFieldAttribute = object({
  fieldName: z.string().nonempty(getRequiredMessage('Field Name')),
  fieldType: CustomFieldTypeEnumZ,
});
export type IRuleCustomFieldAttribute = z.infer<
  typeof RuleCustomFieldAttribute
>;
export type IRulePropertyAttribute =
  | string
  | z.infer<typeof RuleCustomFieldAttribute>
  | null
  | undefined;

export const RuleOperandSchema = object({
  operator: string().nonempty(getRequiredMessage('Operator')),
  property: object({
    model: RuleCondModelEnumZ,
    attribute: union([string().nullish(), RuleCustomFieldAttribute.nullish()]),
  }),
  value: union([string(), number(), boolean(), array(string())]).optional(),
  scope: z
    .object({
      product: z.object({ id: z.string().nullish() }).nullish(),
      rate: z.object({ id: z.string().nullish() }).nullish(),
      offering: z.object({ id: z.string().nullish() }).nullish(),
    })
    .nullish(),
})
  .refine(
    (val) => {
      if (
        [
          QuoteOfferingRulePropertiesEnum.PARENT_QUOTE_OFFERING_ID,
          QuoteRulePropertiesEnum.CONTRACT_TERMS,
        ].includes(val.property.attribute as any)
      ) {
        return true;
      }

      return !isNil(val.value) && val.value !== '';
    },
    {
      message: getRequiredMessage('Value'),
      path: ['value'],
    },
  )
  .refine(
    (val) => {
      if (val.property.attribute === QuoteItemRulePropertiesEnum.PRODUCT_ID) {
        if (
          ['haveSameQuantity', 'doesNotHaveSameQuantity'].includes(val.operator)
        ) {
          if (
            !isArray(val.value) ||
            val.value.length !== 2 ||
            val.value.some((v) => !isString(v))
          ) {
            return false;
          }
        }
      }

      return true;
    },
    {
      message: '2 products should be selected.',
      path: ['value'],
    },
  )
  .refine(
    ({ property }) => {
      if (
        property.model !== RuleCondModelEnum.QUOTE_CUSTOM_FIELD &&
        (!property.attribute || !isString(property.attribute))
      ) {
        return false;
      }

      return true;
    },
    {
      message: getRequiredMessage('Attribute'),
      path: ['property.attribute'],
    },
  )
  .refine(
    ({ property }) => {
      if (
        property.model === RuleCondModelEnum.QUOTE_CUSTOM_FIELD &&
        !(property.attribute as IRuleCustomFieldAttribute)?.fieldName
      ) {
        return false;
      }

      return true;
    },
    {
      message: getRequiredMessage('Attribute'),
      path: ['property.attribute'],
    },
  );

export const RuleOperandGroupSchema = object({
  operator: RuleOperatorEnumZ,
  operands: array(RuleOperandSchema),
});

export const RuleConditionExpressionSchema = object({
  operator: RuleOperatorEnumZ,
  operands: z.array(RuleOperandGroupSchema),
});

export type IRuleOperand = z.infer<typeof RuleOperandSchema>;
export type IRuleOperandGroup = z.infer<typeof RuleOperandGroupSchema>;
export type IRuleConditionExpression = z.infer<
  typeof RuleConditionExpressionSchema
>;

// from GET /rules/rules/{id}
export const RuleResSchema = object({
  id: string(),
  name: string(),
  description: string(),
  priority: number().nullish(),
  condition: string(),
  type: RuleTypeEnumZ,
  target: RuleTargetEnumZ,
  status: RuleStatusEnumZ,
  action: RuleReqAction,
  conditionExpression: RuleConditionExpressionSchema,
});

export type IRuleResSchema = z.infer<typeof RuleResSchema>;

export const RuleReqSchema = object({
  name: string().nonempty(getRequiredMessage('Name')),
  description: string().nonempty(getRequiredMessage('Description')),
  type: RuleTypeEnumZ,
  priority: union([string(), number()])
    .nullish()
    .transform((val) => {
      return val ? Math.floor(+val) : val;
    }),
  conditionExpression: RuleConditionExpressionSchema,
  status: RuleStatusEnumZ,
  action: RuleReqAction,
})
  .refine(
    (val) => {
      if (val.type === RuleTypeEnum.APPROVAL) {
        return !!val.action.approval;
      }
      return true;
    },
    {
      message: 'Approval Rule is required.',
      path: ['action', 'approval'],
    },
  )
  .refine(
    (val) => {
      if (val.type === RuleTypeEnum.VALIDATION) {
        return !!val.action.validation;
      }
      return true;
    },
    {
      message: 'Validation Rule is required.',
      path: ['action', 'validation'],
    },
  )
  .refine(
    (val) => {
      if (val.type === RuleTypeEnum.CONDITIONAL_TERM) {
        return !!val.action.conditionalTerms;
      }
      return true;
    },
    {
      message: 'Conditional Term is required.',
      path: ['action', 'conditionalTerms'],
    },
  )
  .refine(
    (val) => {
      if (val.type === RuleTypeEnum.APPROVAL) {
        if (typeof val.priority === 'number' && !Number.isNaN(val.priority)) {
          return val.priority >= 1 && val.priority <= MAX_PRIORITY;
        }
        return true;
      }
      return true;
    },
    {
      message: `Priority must be between 1-∞`,
      path: ['priority'],
    },
  );
export type IRuleReqSchema = z.infer<typeof RuleReqSchema>;

// for FE use only
export enum ApproverTypeEnum {
  PERSON = 'PERSON',
  TEAM = 'TEAM',
}
export const ApproverTypeEnumZ = nativeEnum(ApproverTypeEnum);

export const RULE_VALIDATION_ERROR_LEVEL_DISPLAY: {
  [key in RuleValidationErrorLevelEnum]: string;
} = {
  INFO: 'Info',
  ERROR: 'Error',
  WARN: 'Warning',
};
