import isEmpty from 'lodash/isEmpty';
import {
  useGetCrmFields,
  useGetMonetizeNowCustomFields,
  useGetUserDefinedCrmFieldMappings,
} from '~app/api/fieldMappingService';
import { MFlex, MSkeleton, MText } from '~app/components/Monetize';
import {
  mapExistingFieldMapping,
  mapMatchingHubSpotFields,
} from '~app/utils/crmFieldMapping';
import { CrmFieldMappingAccordion } from '../fieldmapping/CrmFieldMappingAccordion';

export const HubSpotFieldMapping = () => {
  const { data: hubSpotFields, isLoading: isHubspotFieldLoading } =
    useGetCrmFields('hubspot');
  const { monetizeNowFields, isLoading: isMnFieldLoading } =
    useGetMonetizeNowCustomFields('hubspot');

  const {
    data: userDefinedFieldMappings,
    isLoading: isUserDefinedFieldMappingLoading,
  } = useGetUserDefinedCrmFieldMappings('hubspot', {
    enabled: !isEmpty(hubSpotFields) && !isEmpty(monetizeNowFields),
  });

  const { companyToAccount, contactToContact, dealToQuote } =
    mapMatchingHubSpotFields(hubSpotFields, monetizeNowFields);

  const {
    userDefinedCompaniesToAccount,
    userDefinedContactsToContact,
    userDefinedDealsToQuote,
  } = mapExistingFieldMapping(
    userDefinedFieldMappings,
    hubSpotFields,
    monetizeNowFields,
  );

  const isLoading =
    isHubspotFieldLoading ||
    isMnFieldLoading ||
    isUserDefinedFieldMappingLoading;

  const renderAccordionItems = (
    <>
      {!isEmpty(companyToAccount) && (
        <CrmFieldMappingAccordion
          system="hubspot"
          crmObjectType="companies"
          crmObjectTypeLabel={'Company'}
          mnObjectType="Account"
          matchedFields={companyToAccount}
          userDefinedFields={userDefinedCompaniesToAccount}
        />
      )}
      {!isEmpty(contactToContact) && (
        <CrmFieldMappingAccordion
          system="hubspot"
          crmObjectType="contacts"
          crmObjectTypeLabel={'Contact'}
          mnObjectType="Contact"
          matchedFields={contactToContact}
          userDefinedFields={userDefinedContactsToContact}
        />
      )}
      {!isEmpty(dealToQuote) && (
        <CrmFieldMappingAccordion
          system="hubspot"
          crmObjectType="deals"
          crmObjectTypeLabel={'Deal'}
          mnObjectType="Quote"
          matchedFields={dealToQuote}
          userDefinedFields={userDefinedDealsToQuote}
        />
      )}
    </>
  );
  return (
    <MFlex gap="4" direction="column">
      <MFlex color="tPurple.dark" direction="inherit" gap="2">
        <MText fontWeight="semibold">HubSpot Data Mapping</MText>
        <MText>
          There are some HubSpot data points that are equivalent to MonetizeNow
          data points. You can map the data from HubSpot to MonetizeNow for
          accurate importing.
        </MText>
      </MFlex>
      <MFlex direction="inherit" gap="4">
        {isLoading ? (
          <>
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
          </>
        ) : (
          renderAccordionItems
        )}
      </MFlex>
    </MFlex>
  );
};
