/* eslint-disable @typescript-eslint/no-shadow */
import { zodResolver } from '@hookform/resolvers/zod';
import orderBy from 'lodash/orderBy';
import partition from 'lodash/partition';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useGetAccountContacts,
  useGetEsignContacts,
} from '~app/api/contactsService';
import { updateContactByQuote, updateQuoteAddress } from '~app/api/cpqService';
import { QUOTE_READONLY_STATUSES } from '~app/constants/quotes';
import { useIsTenantEsignConfigured } from '~app/hooks/useIsTenantEsignConfigured';
import { useNonInitialEffect } from '~app/hooks/useNonInitialEffect';
import {
  ContactTypes,
  ContactWithTypes,
  DEFAULT_PAGER,
  IContactRespSchema,
  IQuoteContactAddressDataSchema,
  IQuoteContacts,
  IQuoteContactsRequest,
  IQuoteRespSchema,
  QuoteRequestSchema,
  TDataTablePager,
} from '~app/types';
import { filterInactiveContacts, getQuoteStatus } from '~app/utils';
import { arrayToObject } from '~app/utils/misc';
import { updateContactsMap } from './quoteContactUtils';

export interface UseQuoteContactsProps {
  initialQuoteContacts?: IQuoteContacts;
  quoteId?: string;
  quote?: IQuoteRespSchema | null;
  isReadOnly?: boolean;
  accountId?: string;
  evaluateQuoteRules: (quoteId: string) => void;
}

// Transform quote contacts into a format that is easier to work with in UI
function getContactsFromInitialContacts(
  initialQuoteContacts?: IQuoteContacts | null,
) {
  if (!initialQuoteContacts) {
    return {
      externalContacts: [],
      internalContacts: [],
      selectedAccountContacts: [],
    };
  }
  const { billing, primary, esign } = initialQuoteContacts;
  const contactsMap: Record<string, ContactWithTypes> = {};
  billing && updateContactsMap(contactsMap, billing, 'billing');
  primary && updateContactsMap(contactsMap, primary, 'primary');
  (esign || []).forEach((contact) =>
    updateContactsMap(contactsMap, contact, 'esign'),
  );
  const [internal, external] = partition(
    Object.values(contactsMap),
    'contact.internal',
  );

  // Initially sorted based on primary, then billing, then esign
  // After that, the order remains based on user interaction while drawer is open
  return {
    externalContacts: orderBy(external, [
      ({ primary }) => (primary ? 0 : 1),
      ({ billing }) => (billing ? 0 : 1),
      ({ esign }) => (esign ? 0 : 1),
    ]),
    internalContacts: orderBy(internal, [
      ({ primary }) => (primary ? 0 : 1),
      ({ billing }) => (billing ? 0 : 1),
      ({ esign }) => (esign ? 0 : 1),
    ]),
    selectedAccountContacts: Object.values(contactsMap).map(
      (item) => item.contact.id!,
    ),
  };
}

// Jest was constantly re-rendering when arrays were defined inline
const defaultResponse: any[] = [];
const accountContactsPager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'fullName',
  sortOrder: 1,
  rows: 100,
};

/**
 * Logic to support Quote Contacts
 */
export function useQuoteContacts({
  initialQuoteContacts,
  quoteId,
  quote,
  isReadOnly,
  accountId,
  evaluateQuoteRules,
}: UseQuoteContactsProps) {
  const { data: accountContacts, isLoading: loadingContacts } =
    useGetAccountContacts(
      {
        accountId: accountId!,
        config: accountContactsPager,
        isGetAll: true,
      },
      {
        enabled: !!accountId,
        select: (response) => response?.content || defaultResponse,
        refetchOnWindowFocus: false,
      },
    );

  const {
    allContacts: internalAccountEsignContacts,
    isLoading: loadingInternalEsignContacts,
  } = useGetEsignContacts({ accountId, refetchOnWindowFocus: false });

  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const isEsignEnabled = isTenantEsignConfigured && !!quote?.requiresEsign;
  const [isDisabled, setIsDisabled] = useState(isReadOnly);
  const [isDisabledPrimaryBilling, setIsDisabledPrimayBilling] =
    useState(isReadOnly);
  const { isAccepted } = getQuoteStatus(quote);

  useEffect(() => {
    const disabled =
      isReadOnly ||
      (quote?.status && QUOTE_READONLY_STATUSES.has(quote?.status));
    setIsDisabled(disabled);

    // Allow editing on accepted status
    setIsDisabledPrimayBilling(isAccepted ? false : disabled);
  }, [isReadOnly, quote?.status, isAccepted]);

  const quoteContactsWithAddress: IQuoteContacts | null = !initialQuoteContacts
    ? null
    : {
        ...initialQuoteContacts,
        primary: initialQuoteContacts.primary
          ? {
              ...initialQuoteContacts.primary,
              address: quote?.shippingAddress!,
            }
          : null,
        billing: initialQuoteContacts.billing
          ? {
              ...initialQuoteContacts.billing,
              address: quote?.billingAddress!,
            }
          : null,
      };

  const [isLoading, setIsLoading] = useState(false);
  const [didSave, setDidSave] = useState(false);
  // quoteContacts/externalContacts/internalContacts are required to be set on initial render for UI to behave correctly
  const [quoteContacts, setQuoteContacts] = useState<IQuoteContacts | null>(
    quoteContactsWithAddress,
  );
  const [externalContacts, setExternalContacts] = useState<ContactWithTypes[]>(
    () =>
      getContactsFromInitialContacts(quoteContactsWithAddress).externalContacts,
  );
  const [internalContacts, setInternalContacts] = useState<ContactWithTypes[]>(
    () =>
      getContactsFromInitialContacts(quoteContactsWithAddress).internalContacts,
  );

  const addressDataForm = useForm<IQuoteContactAddressDataSchema>({
    mode: 'onChange',
    resolver: zodResolver(QuoteRequestSchema),
    defaultValues: {
      addressSource: quote?.addressSource,
      shippingAddressId: quote?.shippingAddress?.id,
      billingAddressId: quote?.billingAddress?.id,
    },
  });
  const addressSource = addressDataForm.watch('addressSource');

  /** Used to calculate which contacts cannot show up in selection list */
  const [selectedAccountContacts, setSelectedAccountContacts] = useState<
    string[]
  >(
    () =>
      getContactsFromInitialContacts(initialQuoteContacts)
        .selectedAccountContacts,
  );

  /** Used for easy lookup */
  const [accountContactsById, setAccountContactsById] = useState<
    Record<string, IContactRespSchema>
  >({});

  /** Used to render quote external contacts */
  const [externalAccountContacts, setExternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);
  /** Used to render quote internal contacts */
  const [internalAccountContacts, setInternalAccountContacts] = useState<
    IContactRespSchema[]
  >([]);

  const [
    additionalRecipientSelectedContacts,
    setAdditionalRecipientSelectedContacts,
  ] = useState<IContactRespSchema[]>(
    initialQuoteContacts?.additionalESignRecipients || [],
  );
  /**
   * Configure initial data structures that are better suited for UI
   * Only applies if `initialQuoteContacts` changes
   * all other updates don't reset all data because sometimes there are unsaved contacts
   */
  useEffect(() => {
    const contactData = getContactsFromInitialContacts(initialQuoteContacts);
    setExternalContacts(contactData.externalContacts);
    setInternalContacts(contactData.internalContacts);
    setSelectedAccountContacts(contactData.selectedAccountContacts);
  }, [initialQuoteContacts]);

  useEffect(() => {
    const activeExternal = filterInactiveContacts(accountContacts || []);
    setAccountContactsById(
      arrayToObject(
        [...(activeExternal || []), ...(internalAccountEsignContacts || [])],
        'id',
      ),
    );
    setExternalAccountContacts(activeExternal);
    setInternalAccountContacts(internalAccountEsignContacts || []);
  }, [accountContacts, internalAccountEsignContacts]);

  useEffect(() => {
    if (addressSource !== quote?.addressSource || !quote) {
      addressDataForm.reset({
        addressSource: quote?.addressSource,
        shippingAddressId: quote?.shippingAddress?.id,
        billingAddressId: quote?.billingAddress?.id,
      });
    }
  }, [quote]);
  /**
   * Potentially save quote contacts whenever internal or external contacts are updated
   * If no changes were made, update is skipped
   *
   * useNonInitialEffect because we don't want to save on initial render
   */
  useNonInitialEffect(() => {
    handleSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalContacts, externalContacts, additionalRecipientSelectedContacts]);

  /**
   * Toggle the type of a contact (billing, primary, esign)
   */
  const handleToggle = (
    contact: ContactWithTypes,
    type: ContactTypes,
    value: boolean,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];
    const updatedContacts = contacts.map((currContact) => ({ ...currContact }));

    // Only one contact can be selected for a given type
    // esign may allow multiple types in the future
    updatedContacts.forEach((currContact) => (currContact[type] = false));

    setContacts(
      updatedContacts.map((currContact) =>
        currContact.contact.id === contact.contact.id
          ? { ...currContact, [type]: value }
          : currContact,
      ),
    );
  };

  /**
   * Remove a contact from the quote
   */
  const handleRemove = (contact: ContactWithTypes, isInternal: boolean) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];
    // future improvement could be to update remaining contacts to try to make them as valid as possible

    const newContacts = contacts.filter(
      (currContact) => currContact !== contact,
    );

    // Auto-select primary or billing if a contact was removed
    if (!isInternal) {
      const primaryContact = newContacts.find(({ primary }) => primary);
      const billingContact = newContacts.find(({ billing }) => billing);

      if (primaryContact && !billingContact) {
        primaryContact.billing = true;
      } else if (billingContact && !primaryContact) {
        billingContact.primary = true;
      }
    }

    setContacts(newContacts);
    setSelectedAccountContacts(
      selectedAccountContacts.filter((item) => item !== contact.contact.id),
    );
  };

  /**
   * Remove a additionalContact from the quote
   */
  const handleAdditionalContactRemove = (contactId: string) => {
    const [contacts, setContacts] = [
      additionalRecipientSelectedContacts,
      setAdditionalRecipientSelectedContacts,
    ];
    const newContacts = contacts.filter(
      (currContact) => currContact.id !== contactId,
    );
    setContacts(newContacts);
  };

  /**
   * Save contacts - called automatically when things change
   */
  const handleSave = async () => {
    try {
      setIsLoading(true);
      const request: IQuoteContactsRequest = {
        primaryId: externalContacts.find((contact) => contact.primary)?.contact
          .id,
        billingId: externalContacts.find((contact) => contact.billing)?.contact
          .id,
        esignCcIds: additionalRecipientSelectedContacts.map(
          (recipientContacts) => recipientContacts.id, // additional contact ids for save
        ),
        esignIds: (externalContacts
          .concat(internalContacts)
          .filter((contact) => contact.esign)
          .map(({ contact }) => contact.id)
          .filter(Boolean) || []) as string[],
      };

      const isDirty =
        quoteContacts?.billing?.id !== request.billingId ||
        quoteContacts?.primary?.id !== request.primaryId ||
        quoteContacts?.esign.length !== request.esignIds.length ||
        quoteContacts?.additionalESignRecipients.length !==
          request.esignCcIds.length ||
        !quoteContacts?.additionalESignRecipients.every(({ id }) =>
          request.esignCcIds.includes(id),
        ) ||
        !quoteContacts?.esign.every(({ id }) => request.esignIds.includes(id));

      if (isDirty) {
        setQuoteContacts(await updateContactByQuote(quoteId!, request));
        quote?.id && evaluateQuoteRules(quote.id);

        if (!didSave) {
          setDidSave(true);
        }
      }
      // onClose(true);
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Called when a contact is edited (e.x. name, email, address etc..)
   * This contact has already been saved
   */
  const handleUpdatedContact = async (
    contact: IContactRespSchema,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts.map((currContact) =>
        currContact.contact.id === contact.id
          ? {
              ...currContact,
              contact,
            }
          : currContact,
      ),
    ]);

    if (!didSave) {
      setDidSave(true);
    }
  };

  /**
   * Called when a new contact is added to the quote
   */
  const handleNewContact = async (
    contact: IContactRespSchema,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts,
      {
        contact: contact,
        primary: !isInternal && !contacts.some(({ primary }) => primary),
        billing: !isInternal && !contacts.some(({ billing }) => billing),
        esign: !!isEsignEnabled && !contacts.some(({ esign }) => esign),
      },
    ]);

    setSelectedAccountContacts([...selectedAccountContacts, contact.id]);

    if (!didSave) {
      setDidSave(true);
    }
  };

  const handleChangeContact = (
    contact: IContactRespSchema,
    type: ContactTypes,
    isInternal: boolean,
  ) => {
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];
    const updatedContacts = contacts.map((currContact) => ({ ...currContact }));
    if (
      !updatedContacts.find(
        (curContact) => curContact.contact.id === contact.id,
      )
    ) {
      updatedContacts.push({
        contact,
        primary: false,
        billing: false,
        esign: false,
      });
    }
    // Only one contact can be selected for a given type
    // esign may allow multiple types in the future
    updatedContacts.forEach((currContact) => (currContact[type] = false));
    const newContacts = updatedContacts.map((currContact) =>
      currContact.contact.id === contact.id
        ? {
            ...currContact,
            primary:
              currContact.primary ||
              (!isInternal && !contacts.some(({ primary }) => primary)),
            billing:
              currContact.billing ||
              (!isInternal && !contacts.some(({ billing }) => billing)),
            esign:
              currContact.esign ||
              (!!isEsignEnabled && !contacts.some(({ esign }) => esign)),
            [type]: true,
          }
        : currContact,
    );
    setContacts(newContacts);
  };

  /**
   * Called when a new contact is added to the quote
   */
  const handleContactSelectionChange = (
    contactId: string,
    isInternal: boolean,
  ) => {
    const contact = accountContactsById[contactId];
    const [contacts, setContacts] = isInternal
      ? [internalContacts, setInternalContacts]
      : [externalContacts, setExternalContacts];

    setContacts([
      ...contacts,
      {
        contact: contact,
        primary: !isInternal && !contacts.some(({ primary }) => primary),
        billing: !isInternal && !contacts.some(({ billing }) => billing),
        esign: !!isEsignEnabled && !contacts.some(({ esign }) => esign),
      },
    ]);

    setSelectedAccountContacts([
      ...selectedAccountContacts.filter((id) => id !== contactId),
      contactId,
    ]);
  };

  /**
   * Called when a additional contact is selected for adding to the quote
   */
  const handleSelectedAdditionalRecipients = (contactId: string) => {
    const contact = accountContactsById[contactId];
    setAdditionalRecipientSelectedContacts([
      ...additionalRecipientSelectedContacts,
      contact,
    ]);
  };

  /**
   * Called when a brand new Additional Contact is Created and show as selected
   */
  const handleCreatedNewAdditionalContact = (contact: IContactRespSchema) => {
    setAdditionalRecipientSelectedContacts([
      ...additionalRecipientSelectedContacts,
      contact,
    ]);
  };

  const addressData = addressDataForm.getValues();
  const onSaveAddress = async () => {
    setIsLoading(true);
    try {
      await updateQuoteAddress(quoteId!, addressData);
      await setDidSave(true);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    (async () => {
      if (addressDataForm.formState.isDirty) {
        await onSaveAddress();
        addressDataForm.reset({}, { keepValues: true });
      }
    })();
  }, [addressDataForm.formState.isDirty]);

  return {
    isLoading,
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    additionalRecipientSelectedContacts,
    didSave,
    handleToggle,
    handleRemove,
    handleSave,
    handleUpdatedContact,
    handleNewContact,
    handleContactSelectionChange,
    handleSelectedAdditionalRecipients,
    handleCreatedNewAdditionalContact,
    handleAdditionalContactRemove,
    addressSource,
    addressDataForm,
    onSaveAddress,
    handleChangeContact,

    isDisabled,
    isDisabledPrimaryBilling,
    isEsignEnabled,
    loadingContacts,
    loadingInternalEsignContacts,
  };
}
