import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { InternalAxiosRequestConfig } from 'axios';
import isFunction from 'lodash/isFunction';
import { useEffect } from 'react';
import {
  apiDelete,
  apiGet,
  apiGetAllList,
  apiPatch,
  apiPost,
  apiPut,
  apiUploadPut,
  MAxiosCustomConfig,
} from '~api/axios';
import { isGenericApiResponse } from '~app/utils/misc';
import { ApiListResponse, GetListApiConfig, GetListApiFilter } from '~types';
import { useStable } from '../hooks/useStable';
import { CPQ_SERVICE_API } from './cpqService';
import { GUIDED_QUOTING_SERVICE_API } from './guidedQuotingService';
import { INVOICE_SERVICE_API } from './invoiceService';
import { LEGAL_ENTITY_SERVICE_API } from './legalEntityService';
import { PRODUCT_SERVICE_API } from './productCatalogService';
import {
  ACCOUNT_SERVICE_API,
  QUERY_UTILS_ENTRIES,
  TABLE_SEARCH,
} from './queryKeysService';
import {
  getInvalidateKeys,
  getListQueryKey,
  isEntityWithId,
  setByIdCacheFromReturnedList,
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';
import { RULE_SERVICE_V3_API } from './ruleServiceV3';
import { SETTING_SERVICE_API } from './settingsService';
import { TENANT_USERS_SERVICE_API } from './usersService';
import { composeGetQuery } from './utils';

/**
 * INSTRUCTIONS
 * ------------
 * 1. In the service, create a new const object of type ApiQueryItem (this is required to allow for self-reference on byIdQueryKey)
 * 2. In the service, either add to or create an exported object with well-named keys (e.g. cpqServiceQuotes)
 *    that uses the object from step one as the value
 * 3. If you created a new object in step 2, then import and add that into API_DATA_MAP
 *
 * !IMPORTANT -> if you have a use-case that does not align with the utility query functions, use a stand-alone hook instead
 *
 * @example
 * ```
 * // *optionally add Required<ApiQueryItem> as the type if you are going to implement every property
 * const quotesKeys: Required<ApiQueryItem> = { ... };
 *
 * export const CPQ_SERVICE_API = asQueryUtil({ cpqServiceQuotes: quotesKeys });
 * ```
 */

export interface ApiQueryItem<EndpointArgs = any> {
  list?: {
    endpoint: string | ((args: EndpointArgs) => string);
    queryKey: QueryKey | ((args: EndpointArgs) => QueryKey);
    /**
     * If set and setEachRecordInCache is true, then each record returned will be set in cache individually
     *
     * ❌ Don't set this for entities where the list data is not identical to `getById` data
     */
    byIdQueryKey?: (id: string, args?: EndpointArgs) => QueryKey;
  };
  byId?: {
    endpoint: (id: string, args?: EndpointArgs) => string;
    queryKey: (id: string, args?: EndpointArgs) => QueryKey;
  };
  create?: {
    endpoint: (args?: EndpointArgs) => string;
    invalidateKeys:
      | QueryKey[]
      | ((id: string, args?: EndpointArgs) => QueryKey[]);
    setDataKey: (id: string, args?: EndpointArgs) => QueryKey;
  };
  update?: {
    endpoint: (id: string, args?: EndpointArgs) => string;
    invalidateKeys:
      | QueryKey[]
      | ((id: string, args?: EndpointArgs) => QueryKey[]);
    setDataKey: (id: string, args?: EndpointArgs) => QueryKey;
    skipListUpdate?: boolean; // If list has a different shape than byId, set to true to skip list cache update
  };
  upload?: {
    endpoint: (id: string, args?: EndpointArgs) => string;
    invalidateKeys:
      | QueryKey[]
      | ((id: string, args?: EndpointArgs) => QueryKey[]);
    setDataKey: (id: string, args?: EndpointArgs) => QueryKey;
    skipListUpdate?: boolean; // If list has a different shape than byId, set to true to skip list cache update
  };
  delete?: {
    endpoint: (id: string, args?: EndpointArgs) => string;
    invalidateKeys:
      | QueryKey[]
      | ((id: string, args?: EndpointArgs) => QueryKey[]);
    setDataKey: (id: string, args?: EndpointArgs) => QueryKey;
  };
}

/**
 * Map of api endpoints and data to perform queries
 * Imported from each service that can manage their own set of data
 */
const API_DATA_MAP = {
  ...QUERY_UTILS_ENTRIES,
  ...TABLE_SEARCH,
  ...PRODUCT_SERVICE_API,
  ...RULE_SERVICE_V3_API,
  ...CPQ_SERVICE_API,
  ...TENANT_USERS_SERVICE_API,
  ...INVOICE_SERVICE_API,
  ...SETTING_SERVICE_API,
  ...ACCOUNT_SERVICE_API,
  ...LEGAL_ENTITY_SERVICE_API,
  ...GUIDED_QUOTING_SERVICE_API,
};

export type ApiDataMapKey = keyof typeof API_DATA_MAP;

export interface ListDataFilterOptions {
  config: GetListApiConfig;
  filters?: GetListApiFilter;
}

type AxiosConfig = { axiosConfig?: MAxiosCustomConfig };
type EndpointArgs = { endpointArgs?: Record<string, any> };

/**
 * Hook to Get data from a list API endpoint
 * Automatically sets each individual returned record in cache
 */
export function useGetListData<T, SelectData = ApiListResponse<T>>(
  type: keyof typeof API_DATA_MAP,
  { config, filters }: ListDataFilterOptions = {
    config: {},
  },
  options: Partial<UseQueryOptions<ApiListResponse<T>, unknown, SelectData>> &
    AxiosConfig &
    EndpointArgs & {
      // If true, it fetches all paginated content of the resource regardless of params.page and pageSize
      isGetAll?: boolean;
    } = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.list) {
    throw new Error(
      `[QUERY][ERROR] No list endpoint defined for ${type as string}`,
    );
  }
  const { queryKey, byIdQueryKey, endpoint } = API_DATA_MAP[type].list!;
  const params = composeGetQuery(config, filters);
  const {
    axiosConfig,
    endpointArgs: _endpointArgs,
    isGetAll,
    ...rest
  } = options;
  const endpointArgs = useStable(_endpointArgs);
  const resource = isFunction(endpoint) ? endpoint(endpointArgs) : endpoint;

  const apiGetList = async () => {
    if (isGetAll) {
      const items = await apiGetAllList(resource, {
        rows: params.pageSize || 100,
        filters,
        config,
      });
      return {
        totalElements: items.length,
        totalPages: Math.ceil(items.length / (params.pageSize || 100)),
        content: items,
      } as ApiListResponse<T>;
    } else {
      return apiGet<ApiListResponse<T>>(resource, {
        params,
        axiosConfig,
      }).then((res) => res.data);
    }
  };

  const response = useQuery({
    queryKey: [...getListQueryKey(queryKey, endpointArgs), params],
    queryFn: apiGetList,
    ...rest,
  });

  useEffect(() => {
    if (
      response.data &&
      byIdQueryKey &&
      Array.isArray((response.data as any)?.content)
    ) {
      setByIdCacheFromReturnedList(
        queryClient,
        byIdQueryKey,
        response.data as any,
        endpointArgs,
      );
    }
  }, [byIdQueryKey, endpointArgs, queryClient, response.data]);

  return response;
}

/**
 * Hook to get individual record by id from an API endpoint
 */
export function useGetById<T, SelectData = T>(
  type: keyof typeof API_DATA_MAP,
  id: string,
  options: Partial<UseQueryOptions<T, unknown, SelectData>> &
    AxiosConfig & {
      axiosRequestConfig?: Partial<InternalAxiosRequestConfig>;
    } & EndpointArgs = {},
) {
  if (!API_DATA_MAP[type]?.byId) {
    throw new Error(`[QUERY][ERROR] No byId endpoint defined for ${type}`);
  }
  const { axiosConfig, axiosRequestConfig, endpointArgs, ...restOptions } =
    options;
  const { queryKey, endpoint } = API_DATA_MAP[type].byId!;
  return useQuery({
    queryKey: queryKey(id, endpointArgs),
    queryFn: () =>
      apiGet<T>(endpoint(id, endpointArgs), {
        axiosConfig,
        ...axiosRequestConfig,
      }).then((res) => res.data),
    ...restOptions,
  });
}

/**
 * Hook to create an record.
 * Invalidates cache keys provided in the configuration
 */
export function useCreateEntity<Response, Payload>(
  type: keyof typeof API_DATA_MAP,
  options: Partial<UseMutationOptions<Response, unknown, Payload>> &
    AxiosConfig & {
      axiosRequestConfig?: Partial<InternalAxiosRequestConfig>;
    } & EndpointArgs = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.create) {
    throw new Error(
      `[QUERY][ERROR] No create endpoint defined for ${type as string}`,
    );
  }
  const {
    axiosConfig,
    axiosRequestConfig,
    endpointArgs,
    onSuccess,
    ...restOptions
  } = options;
  const { endpoint, invalidateKeys, setDataKey } = API_DATA_MAP[type].create!;

  return useMutation({
    mutationFn: (payload) =>
      apiPost<Response>(endpoint(endpointArgs), payload, {
        axiosConfig,
        ...axiosRequestConfig,
      }).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      getInvalidateKeys(invalidateKeys, data, endpointArgs).forEach((key) =>
        queryClient.invalidateQueries({ queryKey: key }),
      );
      if (isEntityWithId(data)) {
        queryClient.setQueryData(setDataKey(data.id!, endpointArgs), data);
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Hook to update a record
 *
 * This updates data in the list cache if records with the same id are found.
 *
 * @param type Key from API_DATA_MAP to indicate which configuration to use.
 * @param options
 * @returns
 */
export function useUpdateEntity<Response, Payload>(
  type: keyof typeof API_DATA_MAP,
  options: Partial<
    UseMutationOptions<Response, unknown, { id: string; payload: Payload }>
  > &
    AxiosConfig &
    EndpointArgs = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.update) {
    throw new Error(
      `[QUERY][ERROR] No update endpoint defined for ${type as string}`,
    );
  }
  const { axiosConfig, endpointArgs, onSuccess, ...restOptions } = options;
  const { endpoint, invalidateKeys, setDataKey, skipListUpdate } =
    API_DATA_MAP[type].update!;
  return useMutation({
    mutationFn: ({ id, payload }) =>
      apiPut<Response>(endpoint(id, endpointArgs), payload).then(
        (res) => res.data,
      ),
    onSuccess: (data, variables, context) => {
      const { id } = variables;
      getInvalidateKeys(invalidateKeys, data, endpointArgs).forEach((key) =>
        queryClient.invalidateQueries({ queryKey: key }),
      );
      if (data && !isGenericApiResponse(data)) {
        queryClient.setQueryData(setDataKey(id, endpointArgs), data);
        if (API_DATA_MAP[type].list?.queryKey && !skipListUpdate) {
          updateListCacheWithUpdatedItem(
            queryClient,
            getListQueryKey(API_DATA_MAP[type].list?.queryKey!, endpointArgs),
            data,
          );
        }
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Hook to upload file and update a record
 *
 * This uploads a file and updates data in the list cache if records with the same id are found.
 *
 * @param type Key from API_DATA_MAP to indicate which configuration to use.
 * @param options
 * @returns
 */

export function useUploadEntity<Response>(
  type: keyof typeof API_DATA_MAP,
  options: Partial<
    UseMutationOptions<
      Response,
      unknown,
      {
        id: string;
        file: File;
        progressCallback?: (uploadProgress: number) => void;
      }
    >
  > &
    AxiosConfig &
    EndpointArgs = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.upload) {
    throw new Error(
      `[QUERY][ERROR] No upload endpoint defined for ${type as string}`,
    );
  }

  const { axiosConfig, endpointArgs, onSuccess, ...restOptions } = options;
  const { endpoint, invalidateKeys, setDataKey, skipListUpdate } =
    API_DATA_MAP[type].upload!;

  return useMutation({
    mutationFn: ({ id, file, progressCallback }) => {
      const formData = new FormData();
      formData.append('file', file);

      return apiUploadPut<Response>(
        endpoint(id, endpointArgs),
        formData,
        {},
        progressCallback,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      const { id } = variables;
      getInvalidateKeys(invalidateKeys, data, endpointArgs).forEach((key) =>
        queryClient.invalidateQueries({ queryKey: key }),
      );
      if (data && !isGenericApiResponse(data)) {
        queryClient.setQueryData(setDataKey(id, endpointArgs), data);
        if (API_DATA_MAP[type].list?.queryKey && !skipListUpdate) {
          updateListCacheWithUpdatedItem(
            queryClient,
            getListQueryKey(API_DATA_MAP[type].list?.queryKey!, endpointArgs),
            data,
          );
        }
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Performs an action against a record. This is only compatible when the endpoint
 * is a PUT and is structured like `/api/offerings/${id}/${action}`
 *
 * The base url must 100% match the update endpoint with the action appended to the end.
 *
 * This updates data in the list cache if records with the same id are found.
 *
 * @param type Key from API_DATA_MAP to indicate which configuration to use.
 * @param options
 * @returns
 */

type ApiMethodOverride = {
  apiMethodOverride?: typeof apiPost | typeof apiPatch | typeof apiPut;
};

export function usePerformEntityAction<Response>(
  type: keyof typeof API_DATA_MAP,
  options: Partial<
    UseMutationOptions<
      Response,
      unknown,
      {
        id: string;
        action: string;
        data?: Record<string, any>;
        params?: Record<string, any>;
      }
    >
  > &
    AxiosConfig &
    ApiMethodOverride &
    EndpointArgs = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.update) {
    throw new Error(
      `[QUERY][ERROR] No performAction endpoint defined for ${type as string}`,
    );
  }
  const {
    axiosConfig,
    apiMethodOverride,
    endpointArgs,
    onSuccess,
    ...restOptions
  } = options;
  const { endpoint, invalidateKeys, setDataKey, skipListUpdate } =
    API_DATA_MAP[type].update!;
  return useMutation({
    mutationFn: ({ id, action, data, params }) => {
      return (apiMethodOverride || apiPut)<Response>(
        `${endpoint(id, endpointArgs)}/${action}`,
        data,
        {
          params,
          axiosConfig,
        },
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      const { id } = variables;
      getInvalidateKeys(invalidateKeys, data, endpointArgs).forEach((key) =>
        queryClient.invalidateQueries({ queryKey: key }),
      );
      if (data && !isGenericApiResponse(data)) {
        queryClient.setQueryData(setDataKey(id, endpointArgs), data);
        if (API_DATA_MAP[type].list?.queryKey && !skipListUpdate) {
          updateListCacheWithUpdatedItem(
            queryClient,
            getListQueryKey(API_DATA_MAP[type].list?.queryKey!, endpointArgs),
            data,
          );
        }
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

/**
 * Hook to delete a record
 *
 * This updates data in the list cache if records with the same id are found.
 *
 * @param type Key from API_DATA_MAP to indicate which configuration to use.
 * @param options
 * @returns
 */
export function useDeleteEntity<Response>(
  type: keyof typeof API_DATA_MAP,
  options: Partial<UseMutationOptions<Response, unknown, { id: string }>> &
    AxiosConfig &
    EndpointArgs = {},
) {
  const queryClient = useQueryClient();
  if (!API_DATA_MAP[type]?.delete) {
    throw new Error(`[QUERY][ERROR] No delete endpoint defined for ${type}`);
  }
  const { axiosConfig, endpointArgs, onSuccess, ...restOptions } = options;
  const { endpoint, invalidateKeys, setDataKey } = API_DATA_MAP[type].delete!;
  return useMutation({
    mutationFn: ({ id }) =>
      apiDelete<Response>(endpoint(id, endpointArgs), {
        axiosConfig,
      }).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      getInvalidateKeys(invalidateKeys, variables).forEach((key) =>
        queryClient.invalidateQueries({ queryKey: key }),
      );
      queryClient.removeQueries({
        queryKey: setDataKey(variables.id, endpointArgs),
      });
      if (API_DATA_MAP[type].list?.queryKey) {
        updateListCacheWithRemovedItem(
          queryClient,
          getListQueryKey(API_DATA_MAP[type].list?.queryKey!, endpointArgs),
          variables.id,
        );
      }
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}
