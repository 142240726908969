import isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doGetCrmAuthorizeRedirectUrl,
  doVerifyConnection,
  useGetCrmConfiguration,
} from '~app/api/settingsService';
import {
  MAlert,
  MBox,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { SettingCardEdit } from '~app/components/Settings/SettingCardEdit';
import { SettingCardEmpty } from '~app/components/Settings/SettingCardEmpty';
import { useToast } from '~app/services/toast';
import { appGlobalDataState } from '~app/store/global.store';
import { ISalesforceSchema } from '~app/types';
import { useDocumentHead } from '~services/documentHead';
import { HubspotCrmConnection } from './components/hubspot/HubspotCrmConnection';
import { SalesforceCrmConnection } from './components/salesforce/SalesforceCrmConnection';
import { SalesforceForm } from './components/salesforce/SalesforceForm';

const CrmConnections = () => {
  const [newConnectionType, setNewConnectionType] = useState<
    'HUBSPOT' | 'SALESFORCE' | null
  >(null);

  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);

  const { data: crmConnections, isLoading } = useGetCrmConfiguration({
    enabled: hasCrmConfigured, // already fetched and checked in global store if we have crm configured
    meta: { showErrorToast: true },
  });
  const { addToast } = useToast();

  const hubspotConnection = crmConnections?.hubspot;
  const salesforceConnection = crmConnections?.salesforce;

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Settings', 'CRM');
  }, []);

  const initiateSalesforceOAuth = async (data: ISalesforceSchema) => {
    try {
      const { url } = await doGetCrmAuthorizeRedirectUrl(
        'salesforce',
        data.customUrl,
      );
      window.location.href = url;
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  const initiateHubSpotOAuth = async () => {
    try {
      const { url } = await doGetCrmAuthorizeRedirectUrl('hubspot');
      window.location.href = url;
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  const verifyConnection = async (
    connectionTypeText: 'Salesforce' | 'Hubspot',
  ) => {
    try {
      await doVerifyConnection();
      addToast({
        summary: 'Connection Verified',
        detail: `Your ${connectionTypeText} connection is active, verify that it is connected in the ${connectionTypeText} App.`,
        severity: 'success',
      });
    } catch (ex) {
      addToast({
        summary: 'Connection Verification Failed',
        detail: `There was an issue connecting to ${connectionTypeText}, check your connection setup or contact support for the help resolving this.`,
        severity: 'error',
      });
    }
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Customer Relationship Management (CRM)" />

      <MBox maxW="600px">
        <MText color="tGray.darkPurple" mb={4}>
          Connect your CRM to quote, manage reporting, and sync data between
          MonetizeNow and your CRM.
        </MText>

        {isUndefined(crmConnections) && !hasCrmConfigured && !isLoading && (
          <MAlert type="error" mb={8}>
            <MText>
              Unable to retrieve CRM configuration. Please ensure you have
              configured your CRM settings correctly or contact support for
              assistance. Creating a new connection will overwrite the existing
              configuration.
            </MText>
          </MAlert>
        )}

        {isLoading ? (
          <MSkeleton height={36} w="full" />
        ) : (
          <MFlex>
            {/* NO CONNECTION */}
            {!salesforceConnection &&
              !hubspotConnection &&
              !newConnectionType && (
                <MFlex gap={6}>
                  <SettingCardEmpty
                    type="Salesforce"
                    onClick={() => setNewConnectionType('SALESFORCE')}
                  />
                  <SettingCardEmpty
                    type="HubSpot"
                    onClick={initiateHubSpotOAuth}
                  />
                </MFlex>
              )}

            {/* NEW SFDC CONNECTION */}
            {newConnectionType === 'SALESFORCE' && (
              <SettingCardEdit type="Salesforce">
                <SalesforceForm
                  onAuthorize={initiateSalesforceOAuth}
                  handleCancel={() => setNewConnectionType(null)}
                />
              </SettingCardEdit>
            )}

            {/* HAS CONNECTION CONFIGURED */}
            {salesforceConnection && (
              <MFlex flexDirection="column" gap={6}>
                <SalesforceCrmConnection
                  connection={salesforceConnection}
                  onAuthorize={initiateSalesforceOAuth}
                  verifyConnection={() => verifyConnection('Salesforce')}
                />
              </MFlex>
            )}
            {hubspotConnection && (
              <MFlex flexDirection="column" gap={6}>
                <HubspotCrmConnection
                  connection={hubspotConnection}
                  reAuthorize={initiateHubSpotOAuth}
                  verifyConnection={() => verifyConnection('Hubspot')}
                />
              </MFlex>
            )}
          </MFlex>
        )}
      </MBox>
    </MPageContainer>
  );
};

export default CrmConnections;
