import { DPDay, DPMonth, DPYear, useDatePicker } from '@rehookify/datepicker';
import { addMonths } from 'date-fns/addMonths';
import { differenceInCalendarMonths } from 'date-fns/differenceInCalendarMonths';
import { endOfMonth } from 'date-fns/endOfMonth';
import { isLastDayOfMonth } from 'date-fns/isLastDayOfMonth';
import { ReactNode, useEffect, useState } from 'react';
import { MBox } from '../chakra';
import { CalendarView } from './DatePickerCalendarView';
import { DateHeader } from './DatePickerHeader';
import { MonthView } from './DatePickerMonthView';
import { YearView } from './DatePickerYearView';

interface DatePickerBodyProps {
  isDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  selectedDates: Date[];
  /**
   * Date means normal date picker (default)
   */
  mode?: 'DATE' | 'MONTH' | 'YEAR';
  /**
   * Only applies when mode is MONTH
   * Determines what months a user can choose
   * Default is 1, which means all months are available
   * if 3, user can only select every 3 months (quarter)
   * if 6, user can only select every 6 months (semi-annual)
   */
  interval?: 1 | 3 | 6;
  /**
   * Only applies when mode is MONTH
   * Determines what month the interval starts at
   * Only matters is interval is not 1
   */
  anchorMonth?: number;
  onDatesChange: (value: Date[], shouldClose?: boolean) => void;
  baseDate?: Date;
  offsetDate: Date;
  onOffsetChange: (value: Date) => void;
  children?: ReactNode;
}

export const DatePickerBody = ({
  isDisabled = false,
  selectedDates,
  onDatesChange,
  offsetDate,
  onOffsetChange,
  minDate,
  maxDate,
  mode = 'DATE',
  interval,
  anchorMonth,
  baseDate,
  children,
}: DatePickerBodyProps) => {
  const [isYearViewActive, setIsYearViewActive] = useState(mode === 'YEAR');
  const isMonthModeActive = mode === 'MONTH';
  const isDateModeActive = !isYearViewActive && !isMonthModeActive;

  const {
    data: { calendars, weekDays, years, months },
    propGetters: {
      dayButton,
      addOffset,
      setOffset,
      subtractOffset,
      nextYearsButton,
      previousYearsButton,
      monthButton,
      yearButton,
    },
  } = useDatePicker({
    selectedDates,
    onDatesChange,
    offsetDate,
    onOffsetChange,
    calendar: {
      mode: 'static',
      offsets: [0],
    },
    // focusDate: value,
    // locale: 'en-US',
    years: {
      mode: 'fluid',
      numberOfYears: 18,
      step: 18,
    },
    dates: {
      toggle: true,
      mode: 'single',
      limit: 1,
      minDate,
      maxDate,
    },
  });

  const [{ month, year }] = calendars;

  useEffect(() => {
    if (mode === 'YEAR') {
      return;
    }
    setIsYearViewActive(false);
  }, [mode, year]);

  const toggleYear = () => {
    if (isDisabled || mode === 'YEAR') {
      return;
    }
    setIsYearViewActive(!isYearViewActive);
  };

  const handleYearChange = ({ $date }: DPYear) => {
    onOffsetChange($date);
    if (mode === 'DATE') {
      setIsYearViewActive(false);
    } else if (mode === 'YEAR') {
      if (baseDate) {
        const diffMonth = differenceInCalendarMonths($date, baseDate);
        const newValue = addMonths(baseDate, diffMonth);
        onDatesChange([newValue]);
      } else {
        onDatesChange([$date]);
      }
    }
  };

  const handleMonthChange = (dpMonth: DPMonth) => {
    if (baseDate) {
      const diffMonth = differenceInCalendarMonths(dpMonth.$date, baseDate);
      let newValue = addMonths(baseDate, diffMonth);

      // If baseDate was the last day of its month, set newValue to the last day of the selected month
      if (isLastDayOfMonth(baseDate)) {
        newValue = endOfMonth(newValue);
      }

      onDatesChange([newValue]);
    } else {
      setOffset(dpMonth.$date);
      onDatesChange([dpMonth.$date]);
    }
  };

  const handleDayChange = ({ $date }: DPDay) => {
    onDatesChange([$date]);
  };

  return (
    <MBox as="section" aria-label={`${month} ${year}`} role="group">
      <DateHeader
        month={month}
        year={year}
        isDisabled={isDisabled}
        isYearViewActive={isYearViewActive}
        mode={mode}
        toggleYear={toggleYear}
        nextMonthBtnProps={addOffset({ months: 1 })}
        previousMonthBtnProps={subtractOffset({ months: 1 })}
        nextYearBtnProps={nextYearsButton}
        previousYearBtnProps={previousYearsButton}
        setYear={($date) => onOffsetChange($date)}
        maxDate={maxDate}
        minDate={minDate}
      />
      {isMonthModeActive && !isYearViewActive && (
        <MonthView
          months={months}
          interval={interval}
          anchorMonth={anchorMonth}
          monthBtnPropsFn={monthButton}
          onChange={handleMonthChange}
        />
      )}
      {isYearViewActive && (
        <YearView
          years={years}
          yearBtnPropsFn={yearButton}
          onChange={handleYearChange}
        />
      )}
      {isDateModeActive && (
        <CalendarView
          calendars={calendars[0]}
          weekDays={weekDays}
          dayBtnPropsFn={dayButton}
          onChange={handleDayChange}
        />
      )}
      {children}
    </MBox>
  );
};
