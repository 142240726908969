import { MCenterModal } from '~app/components/Monetize';
import {
  IOfferingRes,
  IQuoteItemReqSchema,
  SelectedProductsWithinOfferingForm,
} from '~app/types';
import { ManageProductsForm } from './ManageProductsForm';

interface ManageProductsModalProps {
  offering: IOfferingRes;
  quoteItems: IQuoteItemReqSchema[];
  onSave: (selectedProducts: SelectedProductsWithinOfferingForm) => void;
  onClose: () => void;
  quoteOffering: any;
}

export const ManageProductsModal = ({
  offering,
  quoteItems,
  quoteOffering,
  onSave,
  onClose,
}: ManageProductsModalProps) => {
  if (quoteItems && !quoteItems?.length) {
    return null;
  }

  return (
    <MCenterModal
      isOpen
      modalTitle="Select Products for Offering"
      size="lg"
      modalHeaderProps={{ display: 'flex', alignItems: 'center' }}
      onClose={onClose}
      modalBodyProps={{
        overflow: 'none',
        padding: 0,
      }}
    >
      <ManageProductsForm
        onClose={onClose}
        offering={offering}
        quoteOffering={quoteOffering}
        updateOfferingItems={onSave}
        setProductsLoading={() => {}}
        isProductsLoading={false}
      />
    </MCenterModal>
  );
};
