import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { FunctionComponent as FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSetRecoilState } from 'recoil';
import { doCreatePaymentMethodByAccount } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetAccountContacts } from '~app/api/contactsService';
import { accountServiceQueryKeys } from '~app/api/queryKeysService';
import { ContactFormModal } from '~app/components/Contacts/ContactFormModal';
import { ContactSelect } from '~app/components/Contacts/ContactSelect';
import { COMPANY_NAME } from '~app/constants/paymentMethods';
import { getAccountPaymentMethodStripeCallbackRoute } from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import Sentry from '~app/services/sentry';
import { useToast } from '~app/services/toast';
import { tempPaymentMethodState } from '~app/store/global.store';
import { filterInactiveContacts } from '~app/utils';
import {
  MBox,
  MButton,
  MCenterModal,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MStack,
} from '~components/Monetize';
import {
  IContactRespSchema,
  IPaymentGateway,
  IPaymentMethodReq,
  IPaymentMethodReqUI,
  IPaymentMethodResp,
  PaymentMethodReqSchemaUI,
} from '~types';
import { logger } from '../../../services/logger';

interface StripePaymentMethodFormFieldsProps {
  children?: React.ReactNode;
  isOpen: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onClose: (paymentMethod?: IPaymentMethodResp) => void;
  accountId: string;
  reloadData?: null | (() => void);
  paymentGateway: IPaymentGateway;
  setupIntent: string;
}

export const StripePaymentMethodFormFields: FC<
  StripePaymentMethodFormFieldsProps
> = ({
  isOpen,
  onClose,
  accountId,
  isLoading,
  isDisabled,
  paymentGateway,
}: StripePaymentMethodFormFieldsProps) => {
  const [newContact, setNewContact] = useState<IContactRespSchema>();
  const [contacts, setContacts] = useState<IContactRespSchema[]>([]);
  const setTempPaymentMethod = useSetRecoilState(tempPaymentMethodState);
  const elements = useElements();
  const stripe = useStripe();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { canDo } = useACL();

  const {
    isOpen: isContactModalOpen,
    onOpen: onOpenContactModal,
    onClose: onCloseContactModal,
  } = useDisclosure();
  const { isLoading: isLoadingContacts, data: contactList } =
    useGetAccountContacts(
      {
        accountId: accountId!,
        config: { rows: 100, page: 0 },
      },
      {
        enabled: !!accountId,
        meta: { showErrorToast: true },
        select: (data) => data?.content || [],
      },
    );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const { mutate: confirmStripeSetup, isPending: saveLoading } = useMutation({
    mutationFn: ({
      stripe,
      elements,
      requestData,
    }: {
      stripe: Stripe;
      elements: StripeElements;
      requestData: IPaymentMethodReqUI;
    }) => {
      return Promise.all([
        stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: getStripeCallbackUrl(),
          },
          redirect: 'if_required',
        }),
        requestData,
      ]);
    },
    onSuccess: ([stripeSetupResult, requestData]: [
      stripeSetupResult: SetupIntentResult,
      requestData: IPaymentMethodReqUI,
    ]) => {
      // This block not called if Stripe decides to redirect,
      // which it will do for some payment types, in which case
      // we handle it in PaymentMethodsStripeCallback component
      // Removing {redirect: 'if_required'} passed to stripe.confirmSetup
      // will result in the redirect always being called
      setTempPaymentMethod(null); // clear the temp storage that is only used by redirect code
      if (stripeSetupResult.error) {
        // not sure whether Stripe would ever return a successful call that contains a result error, just covering the bases with this

        addToast({
          summary: `Stripe Connection Failed`,
          detail:
            `${stripeSetupResult.error.message}` ||
            'There was an error connecting to Stripe.',
          severity: 'error',
        });
        Sentry.captureException(
          new Error(
            `Error connecting to Stripe: ${stripeSetupResult.error.message}`,
          ),
          {
            tags: {
              type: 'STRIPE',
            },
            extra: stripeSetupResult,
          },
        );
        return;
      }
      const newPaymentMethod: IPaymentMethodReq = {
        paymentToken: stripeSetupResult.setupIntent.id,
        paymentGatewayId: paymentGateway.id,
        oneTime: false,
        testPaymentMethod: true, // not used presently
        billingDetails: {
          fullName: requestData.contact?.fullName || '',
          email: requestData.contact?.email,
          phone: requestData.contact?.phone,
          title: requestData.contact?.title,
          line1: requestData.contact?.address?.line1,
          line2: requestData.contact?.address?.line2,
          city: requestData.contact?.address?.city,
          state: requestData.contact?.address?.state,
          postalCode: requestData.contact?.address?.postalCode,
          country: requestData.contact?.address?.country,
        },
        paymentMethodName: requestData.paymentMethodName,
      };

      doCreatePaymentMethodByAccount(accountId, newPaymentMethod).then(
        (res) => {
          queryClient.invalidateQueries({
            queryKey: [
              ...accountServiceQueryKeys.paymentMethods.paymentMethodList(
                accountId!,
              ),
            ],
          });
          onClose(res);
        },
      );
    },
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<IPaymentMethodReqUI>({
    resolver: zodResolver(PaymentMethodReqSchemaUI),
    mode: 'onChange',
    defaultValues: {},
  });

  const selectedContactId = getValues('contactId');

  useEffect(() => {
    let activeContacts: IContactRespSchema[] = [];
    if (contactList?.length) {
      activeContacts = filterInactiveContacts(
        [...contactList],
        selectedContactId!,
      );
    }
    setContacts([...activeContacts]);
    if (newContact) {
      setValue('contactId', newContact.id, { shouldValidate: true });
    }
  }, [contactList, newContact, setValue, selectedContactId]);

  const onSubmit = async (requestData: IPaymentMethodReqUI) => {
    if (!stripe || !elements) {
      handleApiErrorToast({
        response: {
          data: {
            message: 'There was a problem creating the payment method.',
          },
        },
      });
      return;
    }
    // Add selected contact to requestData
    requestData.contact = contacts.find(
      (contact) => contact.id === requestData.contactId,
    );
    // Store in localstorage so redirect code in PaymentMethodStripeCallback can get data back if Stripe decides to make the callback
    setTempPaymentMethod(requestData);
    confirmStripeSetup({ stripe, elements, requestData });
  };

  const onError = (errs: any, event: any) => {
    logger.error('submit error: ', errs);
    logger.error('submit error event: ', event);
  };
  const onSubmitForm = handleSubmit(onSubmit, onError);

  const getStripeCallbackUrl = () => {
    const url = getAccountPaymentMethodStripeCallbackRoute(
      accountId || '',
      paymentGateway?.id || '',
    );
    return `${window.location.origin}${url}`;
  };

  const onCloseContactModalHandle = (contact?: IContactRespSchema) => {
    contact && setNewContact(contact);
    onCloseContactModal();
  };
  const onClosePaymentMethodHandle = () => {
    onClose();
  };

  return (
    <>
      <form onSubmit={onSubmitForm}>
        <MCenterModal
          isOpen={isOpen}
          onClose={onClose}
          modalTitle="New Payment Method"
          size="sm"
          modalContentProps={{ 'data-testid': 'payment-method-form' } as any}
          renderFooter={() => (
            <MStack
              spacing={4}
              direction="row"
              align="center"
              justify="right"
              flex={1}
            >
              <MButton
                onClick={onClosePaymentMethodHandle}
                variant="cancel"
                minW="auto"
              >
                Cancel
              </MButton>
              <MButton
                variant="primary"
                isLoading={saveLoading || isLoading}
                isDisabled={saveLoading || isLoading}
                onClick={onSubmitForm}
                minW="auto"
              >
                Save
              </MButton>
            </MStack>
          )}
        >
          <MBox>
            <PaymentElement
              options={{
                business: {
                  name: COMPANY_NAME,
                },
              }}
            />
            <MGrid templateColumns="repeat(12, 1fr)" gap={4} mb={3}>
              <MGridItem colSpan={12} key="Contact">
                <MFormField
                  isDisabled={isDisabled}
                  error={errors.contactId}
                  label="Contact"
                  isRequired
                >
                  <Controller
                    name="contactId"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <ContactSelect
                        {...field}
                        showAddContacts={canDo([
                          ['account_contacts', 'create'],
                        ])}
                        accountId={accountId}
                      />
                    )}
                  />
                </MFormField>
              </MGridItem>
              <MGridItem colSpan={12} key="paymentMethodName">
                <MFormField
                  isDisabled={isDisabled}
                  error={errors.paymentMethodName}
                  label="Payment method name"
                  isRequired
                >
                  <Controller
                    name="paymentMethodName"
                    control={control}
                    defaultValue=""
                    render={({ field }) => <MInput {...field} />}
                  />
                </MFormField>
              </MGridItem>
            </MGrid>
          </MBox>
        </MCenterModal>
      </form>

      <ContactFormModal
        isOpen={isContactModalOpen}
        onClose={onCloseContactModalHandle}
        accountId={accountId!}
      />
    </>
  );
};
