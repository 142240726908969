import { BoxProps, ButtonProps, Grid } from '@chakra-ui/react';
import { DPCalendar, DPDay, useDatePicker } from '@rehookify/datepicker';
import { format as formatDate } from 'date-fns/format';
import { useState } from 'react';
import MButton from '../MButton';
import MText from '../MText';
import { MBox } from '../chakra';

const REMOVE_LEADING_ZERO_REGEX = /^0+/;

interface CalendarViewProps {
  calendars: DPCalendar;
  weekDays: string[];
  onChange: (day: DPDay) => void;
  dayBtnPropsFn: ReturnType<typeof useDatePicker>['propGetters']['dayButton'];
}

export const CalendarView = ({
  calendars,
  weekDays,
  onChange,
  dayBtnPropsFn,
}: CalendarViewProps) => {
  const { days, month, year } = calendars;
  return (
    <>
      <Grid gridTemplateColumns="repeat(7, minmax(0, 1fr))" aria-hidden="true">
        {weekDays.map((day) => (
          <MText
            w="40px"
            key={`${month}-${day}`}
            textAlign="center"
            color="tGray.darkPurple"
            fontSize="12px"
          >
            {day.substring(0, 2)}
          </MText>
        ))}
      </Grid>
      <Grid
        gridTemplateColumns="repeat(7, minmax(0, 1fr))"
        aria-label={`${month} ${year}`}
      >
        {days.map((calendarDay) => (
          <DayButton
            key={calendarDay.$date.toString()}
            calendarDay={calendarDay}
            month={month}
            year={year}
            {...dayBtnPropsFn(calendarDay)}
            onClick={() => onChange(calendarDay)}
          />
        ))}
      </Grid>
    </>
  );
};

const DayButton = ({
  calendarDay,
  month,
  year,
  ...rest
}: {
  calendarDay: DPDay;
  month: string;
  year: string;
} & ButtonProps) => {
  const {
    day: dayWithLeadingZero,
    disabled,
    inCurrentMonth,
    now,
    selected,
    $date,
  } = calendarDay;
  const day = dayWithLeadingZero.replace(REMOVE_LEADING_ZERO_REGEX, '');
  let buttonProps: ButtonProps = {};
  let buttonTextProps: BoxProps = {};
  // Store in state and lazy evaluate to avoid re-rendering and formatting the date on every render
  const [baseAriaLabel] = useState(() =>
    inCurrentMonth
      ? `${month} ${day}, ${year}`
      : formatDate($date, 'MMMM d, yyyy'),
  );
  let ariaLabel = baseAriaLabel;

  if (!inCurrentMonth) {
    buttonTextProps = { opacity: 0.5 };
  }

  if (selected) {
    ariaLabel = `${ariaLabel} selected`;
    buttonProps = {
      ...buttonProps,
      bg: 'tIndigo.base',
      color: 'white',
      fontWeight: 700,
      _hover: {
        bg: 'tIndigo.base',
      },
      _focus: {
        bg: 'tIndigo.base',
      },
    };
  }

  if (now) {
    ariaLabel = `Today, ${ariaLabel}`;
    buttonProps = { ...buttonProps, border: '1px solid tGray.lightPurple' };
  }

  return (
    <MButton
      aria-label={ariaLabel}
      variant="tertiary"
      minW="unset"
      w="40px"
      h="36px"
      isDisabled={disabled}
      color="tPurple.dark"
      fontWeight={400}
      borderRadius="3"
      _hover={
        disabled
          ? undefined
          : {
              fontWeight: 700,
              bg: 'tBlue.hover',
            }
      }
      _focus={{
        bg: 'inherit',
      }}
      _focusVisible={{
        outlineStyle: 'auto !important',
        outlineWidth: '1px !important',
        outlineColor: '#4659DA !important',
        outlineOffset: '-1px',
      }}
      {...buttonProps}
      {...rest}
    >
      <MBox as={'span'} {...buttonTextProps}>
        {day}
      </MBox>
    </MButton>
  );
};
