import { zodResolver } from '@hookform/resolvers/zod';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { handleApiErrorToast } from '~app/api/axios';
import { useCreateRefund } from '~app/api/refundService';
import {
  MButton,
  MCenterModal,
  MGrid,
  MGridItem,
  MRadio,
  MStack,
  MText,
} from '~app/components/Monetize';
import { MTooltip } from '~app/components/Monetize/MTooltip';
import { ElectronicRefundDetail } from '~app/components/Refunds/ElectronicRefundDetail';
import { RefundCreateForm } from '~app/components/Refunds/RefundCreateForm';
import { ROUTES } from '~app/constants';
import {
  PaymentMethodTypeEnumDisplay,
  REQUIRES_MANUAL_REFUND,
} from '~app/constants/paymentMethods';
import {
  APPROVED_REFUND_STATUS,
  FAILED_REFUND_STATUS,
  IPayment,
  IRefundReqSchema,
  IRefundSchema,
  PaymentMethodTypeEnum,
  RefundReasonEnum,
  RefundReqSchema,
} from '~app/types';
import { formatCurrency } from '~app/utils';
import { nullifyEmptyStrings } from '~app/utils/misc';
import { logger } from '../../../services/logger';

interface PaymentRefundModalProps {
  isOpen: boolean;
  payment: IPayment;
  onClose: (reload: boolean) => void;
}

export const RefundModal: FC<PaymentRefundModalProps> = ({
  isOpen,
  payment,
  onClose,
}: PaymentRefundModalProps) => {
  const navigate = useNavigate();

  const params = useParams();
  const accountId = params?.accountId || '';
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formData, setFormData] = useState<IRefundReqSchema>();
  const [paymentMode, setPaymentMode] = useState<'MANUAL' | 'ELECTRONIC'>(() =>
    REQUIRES_MANUAL_REFUND.has(payment.paymentType) ? 'MANUAL' : 'ELECTRONIC',
  );

  const { mutateAsync: doCreateRefund, isPending: isLoading } =
    useCreateRefund();

  const RefundPaymentFormSchemaValidator = useMemo(() => {
    return RefundReqSchema.superRefine(({ refundAmount, description }, ctx) => {
      if (
        isNumber(refundAmount) &&
        refundAmount > (payment.unAppliedAmount ?? 0)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Cannot exceed remaining Payment amount of ${formatCurrency(
            payment.unAppliedAmount,
            { currency: payment.currency },
          )}`,
          path: ['refundAmount'],
        });
      } else if (isNumber(refundAmount) && refundAmount <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Amount must be greater than ${formatCurrency(0, {
            currency: payment.currency,
          })}`,
          path: ['refundAmount'],
        });
      }

      if (description === '' || description === null) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Reason is required',
          path: ['description'],
        });
      }
    });
  }, [payment.unAppliedAmount, payment.currency]);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<IRefundReqSchema>({
    resolver: zodResolver(RefundPaymentFormSchemaValidator),
    mode: 'onChange',
    defaultValues: {
      refundAmount: payment.unAppliedAmount ?? 0,
      description: '',
    },
  });
  const allWatch = watch();

  const shouldResetError = ({
    formData,
    allWatch,
    errorMessage,
  }: {
    formData: IRefundReqSchema | undefined;
    allWatch: IRefundReqSchema;
    errorMessage: string | null;
  }) => {
    if (!formData) {
      return false;
    }

    const currentFormData = {
      refundAmount: Number(allWatch.refundAmount),
      description: allWatch.description,
    };

    return !isEqual(formData, currentFormData) && errorMessage !== null;
  };

  useEffect(() => {
    if (shouldResetError({ formData, allWatch, errorMessage })) {
      setErrorMessage(null);
      setFormData(undefined);
    }
  }, [allWatch, formData, errorMessage]);

  const handleResultSuccess = (result: IRefundSchema) => {
    if (
      result &&
      APPROVED_REFUND_STATUS.has(result.status as RefundReasonEnum)
    ) {
      handleClose(true);
      navigate(
        ROUTES.getRefundView({
          accountId: accountId!,
          refundId: result.id,
        }),
      );
      return true;
    }
    return false;
  };

  const handleResultFailure = (result: IRefundSchema) => {
    if (result && FAILED_REFUND_STATUS.has(result.status as RefundReasonEnum)) {
      setErrorMessage(result.errorMessage);
      return true;
    }
    return false;
  };

  const onSubmit = async (formData: IRefundReqSchema) => {
    try {
      setFormData(formData);
      let result: IRefundSchema | null = null;
      if (paymentMode === PaymentMethodTypeEnum.MANUAL && payment) {
        result = await doCreateRefund({
          paymentId: payment.id,
          payload: nullifyEmptyStrings(formData),
          paymentType: 'manual',
        });
      } else if (paymentMode !== PaymentMethodTypeEnum.MANUAL && payment) {
        result = await doCreateRefund({
          paymentId: payment.id,
          payload: nullifyEmptyStrings(formData),
        });
      }

      if (result) {
        if (!handleResultSuccess(result)) {
          handleResultFailure(result);
        }
      }
    } catch (err) {
      setErrorMessage((err as any).message);
      setFormData(formData);
      handleApiErrorToast(err);
    }
  };

  const onError = (errs: any) => logger.error(errs);

  function handleClose(reload = false) {
    if (isLoading) {
      return;
    }

    reset();
    onClose && onClose(reload);
  }

  return (
    <MCenterModal
      closeOnEsc={!isLoading}
      closeOnOverlayClick={!isLoading}
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={!isLoading}
      modalTitle="Refund Payment"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            isDisabled={isLoading}
            variant="cancel"
            onClick={() => onClose(false)}
            minW="auto"
          >
            Cancel
          </MButton>
          <MButton
            isDisabled={errorMessage !== null || isLoading}
            variant="primary"
            isLoading={isLoading}
            type="submit"
            onClick={handleSubmit(onSubmit)}
            minW="auto"
          >
            Confirm
          </MButton>
        </MStack>
      )}
    >
      <MGrid alignItems="center" gap={4}>
        <MGridItem colSpan={12}>
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <MGrid templateColumns="repeat(12, 1fr)" gap={4} mb={3}>
              <MGridItem colSpan={12}>
                {errorMessage && (
                  <MText color="tRed.base" mb={4}>
                    {PaymentMethodTypeEnumDisplay[payment.paymentType]} refund
                    failed.
                  </MText>
                )}
                <MRadio
                  isChecked={paymentMode === 'ELECTRONIC'}
                  isDisabled={REQUIRES_MANUAL_REFUND.has(payment.paymentType)}
                  onChange={() => setPaymentMode('ELECTRONIC')}
                >
                  <MTooltip
                    isDisabled={
                      !REQUIRES_MANUAL_REFUND.has(payment.paymentType)
                    }
                    label="Payment not made from refundable source"
                  >
                    <MText
                      pt="2px"
                      fontWeight={600}
                      color={
                        REQUIRES_MANUAL_REFUND.has(payment.paymentType)
                          ? 'tGray.disabledText'
                          : 'currentcolor'
                      }
                    >
                      Refund Electronically to Original Payment Method
                    </MText>
                  </MTooltip>
                </MRadio>
                {paymentMode === 'ELECTRONIC' && (
                  <MGrid gap={4} px={5}>
                    {payment && <ElectronicRefundDetail payment={payment} />}
                    <RefundCreateForm
                      isLoading={isLoading}
                      control={control}
                      errors={errors}
                    />
                  </MGrid>
                )}
              </MGridItem>
              <MGridItem colSpan={12}>
                <MRadio
                  isChecked={paymentMode === 'MANUAL'}
                  onChange={() => setPaymentMode('MANUAL')}
                >
                  <MText fontWeight={600} pt="2px">
                    Refund Manually
                  </MText>
                </MRadio>

                {paymentMode === 'MANUAL' && (
                  <MGrid gap={4} px={5}>
                    <MGridItem colSpan={12}>
                      <MText fontSize={13}>
                        MonetizeNow will record the refund. Send funds
                        separately to your customer.
                      </MText>
                    </MGridItem>
                    <RefundCreateForm
                      isLoading={isLoading}
                      control={control}
                      errors={errors}
                    />
                  </MGrid>
                )}
              </MGridItem>
            </MGrid>
          </form>
        </MGridItem>
      </MGrid>
    </MCenterModal>
  );
};
