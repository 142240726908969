import { CardBody, StyleProps } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useGetQuoteDisplayConfig,
  useUpdateQuoteDisplayConfig,
} from '~app/api/cpqService';
import QuoteTemplateIcon from '~app/assets/icons/QuoteTemplateIcon';
import {
  MBox,
  MButton,
  MCard,
  MCustomIconButton,
  MDivider,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerFooter,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MPageLoader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import MAlert from '~app/components/Monetize/MAlert';
import { QuoteTemplateForm } from '~app/components/Quotes/QuoteTemplates/QuoteTemplateForm';
import {
  QUOTE_READONLY_STATUSES,
  QuoteStatusEnumDisplay,
} from '~app/constants/quotes';
import { useConfirmModal } from '~app/services/confirmModal';
import {
  IQuoteRespSchema,
  IQuoteTemplateConfigSchema,
  QuoteAmendmentVersionEnum,
} from '~app/types';

interface CustomizeQuoteDrawerProps {
  quote: IQuoteRespSchema;
  isReadOnly?: boolean;
  iconButtonStyles?: StyleProps;
}

const CustomizeQuoteDrawer: FC<CustomizeQuoteDrawerProps> = ({
  quote,
  isReadOnly,
  iconButtonStyles = {},
}: CustomizeQuoteDrawerProps) => {
  const cancelBtnRef = useRef(null);
  const { showConfirmModal, closeModal: closeConfirmationModal } =
    useConfirmModal();
  const [isDisabled, setIsDisabled] = useState(isReadOnly || false);
  const [isOpen, setIsOpen] = useState(false);

  const { isLoading: isLoadingData, data: displayConfig } =
    useGetQuoteDisplayConfig(quote.id, {
      enabled: isOpen,
    });
  const { isPending: isSavingData, mutate: doUpdateQuoteDisplayConfig } =
    useUpdateQuoteDisplayConfig({
      onSuccess: () => {
        setIsOpen(false);
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const loading = isLoadingData || isSavingData;

  const {
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, errors },
    reset,
  } = useForm<IQuoteTemplateConfigSchema>({
    mode: 'onChange',
    defaultValues: displayConfig,
  });

  useEffect(() => {
    if (displayConfig && isOpen) {
      reset({
        defaultTemplate: 'QUOTE_DEFAULT_1', // FALLBACK TO DEFAULT TEMPLATE UNTIL BE HAS THIS DATA
        ...displayConfig,
      });
    }
  }, [isOpen, displayConfig, reset]);

  useEffect(() => {
    setIsDisabled(isReadOnly || QUOTE_READONLY_STATUSES.has(quote.status));
  }, [isReadOnly, quote.status]);

  const onSubmit = async (requestData: IQuoteTemplateConfigSchema) => {
    doUpdateQuoteDisplayConfig({ quoteId: quote.id, payload: requestData });
  };

  function handleClose() {
    if (isDisabled) {
      setIsOpen(false);
      return;
    }

    if (isDirty && Object.keys(dirtyFields).length) {
      showConfirmModal({
        title: `Are you sure you want to close?`,
        description:
          'You have unsaved changes, are you sure you want to close?',
        onYes: () => {
          closeConfirmationModal();
          setIsOpen(false);
          displayConfig && reset(displayConfig);
        },
        yesBtnProps: {
          variant: 'primary',
        },
        noBtnProps: {
          variant: 'cancel',
        },
      });
    } else {
      setIsOpen(false);
      displayConfig && reset(displayConfig);
    }
  }

  return (
    <>
      <MTooltip isDisabled={isOpen} label="Customize" placement="bottom-end">
        <MCustomIconButton
          variant="icon"
          btnSize={6}
          aria-label="Customize Quote"
          p={4}
          mx={0.5}
          icon={QuoteTemplateIcon}
          onClick={() => setIsOpen(true)}
          iconColor="tPurple.base"
          {...iconButtonStyles}
        />
      </MTooltip>
      {isOpen && (
        <MDrawer
          isOpen
          placement="right"
          onClose={handleClose}
          size="md"
          initialFocusRef={cancelBtnRef}
          onEsc={handleClose}
          onOverlayClick={handleClose}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          returnFocusOnClose={false}
          blockScrollOnMount={false}
        >
          <MDrawerOverlay />
          <MDrawerContent>
            <MDrawerCloseButton />
            <MDrawerHeader>Customize Quote</MDrawerHeader>
            <MDivider />
            <MDrawerBody
              data-testid="customize-drawer-body"
              px="2"
              bg="tGray.sidebarDark"
            >
              <MCard variant="borderless">
                <CardBody>
                  {loading && (
                    <MFlex justify="center" grow={1}>
                      <MPageLoader height="auto" />
                    </MFlex>
                  )}
                  {!loading && (
                    <>
                      {isDisabled && (
                        <MAlert mb={6} w={'100%'}>
                          This quote cannot be customized because it is{' '}
                          {QuoteStatusEnumDisplay[quote.status].toLowerCase()}.
                        </MAlert>
                      )}
                      <MText color="tGray.darkPurple">
                        These settings only apply to the review page and the PDF
                        file. To preview the settings, download the PDF.
                      </MText>

                      <QuoteTemplateForm
                        source="quote"
                        control={control}
                        errors={errors}
                        isDisabled={isDisabled}
                        requireSigningPage={quote.requiresEsign}
                        handleSubmit={handleSubmit(onSubmit)}
                        isAmendmentV2Quote={
                          quote?.amendmentVersion ===
                          QuoteAmendmentVersionEnum.v2
                        }
                      />
                    </>
                  )}
                </CardBody>
              </MCard>
            </MDrawerBody>

            <MDivider />

            <MDrawerFooter>
              <MBox w="full">
                <MFlex justifyContent="center">
                  <MButton
                    ref={cancelBtnRef}
                    data-testid="customize-drawer-cancel-btn"
                    onClick={() => handleClose()}
                    variant="cancel"
                    mr={2}
                    isDisabled={loading}
                  >
                    Cancel
                  </MButton>
                  <MButton
                    form="customize-quote-form"
                    type="submit"
                    data-testid="customize-drawer-save-btn"
                    variant="primary"
                    ml={2}
                    isLoading={loading}
                    isDisabled={loading || isDisabled || !isDirty}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </MButton>
                </MFlex>
              </MBox>
            </MDrawerFooter>
          </MDrawerContent>
        </MDrawer>
      )}
    </>
  );
};

export default CustomizeQuoteDrawer;
