import { useQuery } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiGet, apiGetAllList } from '~app/api/axios';
import { ComposeQueryType, composeGetQuery } from '~app/api/utils';
import { useDebounce } from '~app/hooks/useDebounce';
import { ApiListResponse, DEFAULT_PAGER, Maybe } from '~app/types';
import { UseSelectDataProps } from '~app/types/mCustomSelectTypes';
import { pluralize } from '../../../../utils';
import { filteredByQuery } from './filteredByQuery';

const EMPTY_ARR = [];

export const getUseSelectDataQueryKey = (
  endpoint?: string | string[],
  queryParams?: ComposeQueryType,
) => {
  return queryParams
    ? (['useSelectData', endpoint, queryParams] as const)
    : (['useSelectData', endpoint] as const);
};

export const getUseSelectDataByIdQueryKey = (recordIds?: string | string[]) => {
  return ['useSelectData', 'selectedRecordId', recordIds] as const;
};

/**
 * Depending on API that is called, this could be one record or a paginated list of records.
 */
function getRecordForGetById(record: unknown) {
  if (
    isObject(record) &&
    'content' in record &&
    Array.isArray(record.content) &&
    record.content.length > 0
  ) {
    return record.content[0];
  }
  if (Array.isArray(record) && record.length > 0) {
    return record[0];
  }
  return record;
}

/**
 * Combine all list data and extra fetched data into object
 */
function combineListAndByIdData({
  previousData,
  itemValue,
  endpointData,
  getByIdData,
}: {
  previousData: Record<string, any>;
  itemValue?: Maybe<string>;
  endpointData?: any[];
  getByIdData?: any[];
}): Record<string, any> {
  return [...(endpointData || []), ...(getByIdData || [])].reduce(
    (acc: Record<string, any>, item) => {
      const record = getRecordForGetById(item);
      if (record && record[itemValue as string]) {
        acc[record[itemValue as string]] = record;
      }
      return acc;
    },
    { ...previousData },
  );
}

export const useSelectData = ({
  endpoint,
  additionalSearchParams,
  internalFilterFields,
  getByIdEndpointFn,
  getByIdRecordId,
  isDisabled,
  loadAll,
  itemValue,
  externalItems = EMPTY_ARR,
  itemTitle,
  query = '',
  itemSearch = '',
  omitContainsItemSearch = false,
  isSubtitleItem,
  returnItem,
  transformDataFromApi,
  useRawValue,
  skipFilter,
  onFetchedItems,
}: UseSelectDataProps) => {
  const enabled = !isDisabled && !!endpoint;

  // Query record function - either the limited list or paginate to fetch all items
  const apiFn = useCallback(
    (endpoint: string, queryParams: ComposeQueryType) => {
      if (loadAll) {
        return apiGetAllList(endpoint, {
          filters: queryParams,
        }).then(
          (result): ApiListResponse<any> => ({
            content: result,
            totalElements: result.length,
            totalPages: 1,
          }),
        );
      }
      return apiGet<ApiListResponse<any>>(endpoint, {
        params: queryParams,
      }).then((response) => response.data);
    },
    [loadAll],
  );

  const [queryParams, setQueryParams] = useState<ComposeQueryType>({});
  const debouncedSearchTerm = useDebounce(query, 500);
  const getByIdEndpoints = useMemo(() => {
    if (
      getByIdEndpointFn &&
      (isString(getByIdRecordId) || Array.isArray(getByIdRecordId))
    ) {
      if (Array.isArray(getByIdRecordId)) {
        return getByIdRecordId.filter(Boolean).map(getByIdEndpointFn);
      } else if (getByIdRecordId) {
        return [getByIdEndpointFn(getByIdRecordId)];
      }
    }
    return EMPTY_ARR;
  }, [getByIdRecordId]);

  const {
    isLoading,
    isPending,
    data: endpointResponseData,
  } = useQuery({
    queryKey: getUseSelectDataQueryKey(endpoint, queryParams),
    queryFn: () =>
      apiFn(endpoint!, queryParams).then((items) => ({
        ...items,
        content:
          (transformDataFromApi
            ? transformDataFromApi(items.content)
            : items.content) ?? EMPTY_ARR,
      })),
    enabled,
    refetchOnWindowFocus: false,
    placeholderData: (previousData, previousQuery) => previousData,
    refetchOnMount: false,
  });

  const endpointData = endpointResponseData?.content || EMPTY_ARR;
  const totalRecordCount =
    endpointResponseData?.totalElements ?? endpointData?.length ?? 0;
  const hasMore = totalRecordCount > endpointData.length;

  /**
   * Get the record by id to make sure we can render the item in the input
   * even if we have never seen the item before (e.x. before user opens dropdown of existing form)
   */
  const {
    isPending: getByIdIsPending,
    isLoading: getByIdIsLoading,
    data: getByIdData,
  } = useQuery({
    queryKey: getUseSelectDataByIdQueryKey(getByIdRecordId),
    queryFn: () =>
      Promise.all(
        getByIdEndpoints.map((getByIdEndpoint) =>
          apiGet(getByIdEndpoint).then((res) => res.data),
        ),
      ),
    enabled: enabled && getByIdEndpoints.length > 0,
    gcTime: 1000 * 60 * 10, // 10 minutes
    staleTime: 1000 * 60 * 3, // 3 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  // store every item we receive so that we can render the selected item in the input even if not in the list
  const [returnedItemCache, setReturnedItemCache] = useState<
    Record<string, any>
  >(() =>
    combineListAndByIdData({
      previousData: {},
      itemValue,
      endpointData,
      getByIdData,
    }),
  );

  /**
   * Combine all data to ensure we have every selected data point to render UI
   * Note: this was in the onSuccess callback, but since we cache aggressively this was not called
   */
  useEffect(() => {
    if (!endpointData && !getByIdData) {
      return;
    }
    setReturnedItemCache((previousData) =>
      combineListAndByIdData({
        previousData,
        itemValue,
        endpointData,
        getByIdData,
      }),
    );
  }, [endpointData, getByIdData, itemValue]);

  // If endpoint is not provided, then we will use externalItems. Otherwise, we will use endpointData
  let items = endpoint
    ? endpointData || EMPTY_ARR
    : !skipFilter
      ? filteredByQuery(
          externalItems!,
          query,
          itemSearch,
          isSubtitleItem,
          internalFilterFields,
        ) || EMPTY_ARR
      : externalItems! || EMPTY_ARR;

  useEffect(() => {
    if (onFetchedItems && endpointData) {
      onFetchedItems(endpointData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endpointData]);

  const allItems = endpoint
    ? endpointData || EMPTY_ARR
    : externalItems || EMPTY_ARR;

  const getByIdLoading =
    !!getByIdEndpoints.length &&
    !!getByIdRecordId &&
    (getByIdIsPending || getByIdIsLoading);

  const loading = enabled && (isPending || isLoading || getByIdLoading);

  useEffect(() => {
    const searchTerm = omitContainsItemSearch
      ? debouncedSearchTerm
      : { contains: debouncedSearchTerm };

    setQueryParams(
      composeGetQuery(DEFAULT_PAGER, {
        ...additionalSearchParams,
        [itemSearch]: searchTerm,
      }),
    );
  }, [
    itemSearch,
    debouncedSearchTerm,
    additionalSearchParams,
    omitContainsItemSearch,
  ]);

  const getValue = (item: any) => {
    const isObject = typeof item === 'object';
    return isObject ? item[itemValue as string] : item;
  };

  const getTitle = (item: any) => {
    const isObject = typeof item === 'object';
    return isObject ? item[itemTitle as string] : item;
  };

  const getTitleFromValue = (val: any) => {
    // handle multi-select title
    if (Array.isArray(val)) {
      val = val.filter(Boolean);
      if (val.length === 0) {
        return '';
      } else if (val.length === 1) {
        val = val[0];
      } else {
        return `${val.length} ${pluralize('item', val.length)} selected`;
      }
    }

    if (val && isString(val) && returnedItemCache[val]) {
      return returnedItemCache[val][itemTitle as string];
    }

    if (val === '' || isNil(val) || allItems.length === 0) {
      return useRawValue ? val : '';
    }

    if (returnItem) {
      // returnItem and value is already object with valid [itemTitle], then we know value is valid value
      if (val[itemTitle as string]) {
        return val[itemTitle as string];
      }

      // if not, and value only contains only itemValue, then we can find it from items.
      const foundItem = allItems.find(
        (item: any) =>
          val && item[itemValue as string] === val[itemValue as string],
      );
      if (foundItem) {
        return foundItem[itemTitle as string];
      }
      return useRawValue ? val : '';
    }

    const isObject = allItems.length > 0 && typeof allItems[0] === 'object';

    if (isObject) {
      const foundItem = allItems.find(
        (item: any) => item[itemValue as string] === val,
      );
      if (foundItem) {
        return foundItem[itemTitle as string];
      }
      return useRawValue ? val : '';
    }
    return val;
  };

  return {
    getValue,
    items,
    getTitle,
    getTitleFromValue,
    loading,
    totalRecordCount,
    hasMore,
  };
};

export const UNIT_TEST_EXPORT = {
  combineListAndByIdData,
  getUseSelectDataQueryKey,
  getUseSelectDataByIdQueryKey,
  getRecordForGetById,
};
