import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetById, useUpdateEntity } from '~app/api/queryUtils';
import { doGetTenantUser } from '~app/api/usersService';
import { ROUTES } from '~app/constants';
import { useAuth } from '~app/services/auth0';
import { tempRedirectInfoState } from '~app/store/global.store';
import { IUser } from '~app/types';
import {
  MAvatar,
  MBox,
  MButton,
  MFlex,
  MFormField,
  MHStack,
  MInput,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
} from '~components/Monetize';
import { IProfileSchema, ProfileSchema } from '~types';
import { UserTeams } from './components/UserTeams';

const Profile = () => {
  const setRedirectInfo = useSetRecoilState(tempRedirectInfoState);
  const { userId, currentTenant, tenantId, getDecodedToken } = useAuth();
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<null | IUser>(null);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm<IProfileSchema>({
    resolver: zodResolver(ProfileSchema),
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    const getUser = async () => {
      setIsUserLoading(true);
      const user = await doGetTenantUser(currentTenant?.id!, userId!);
      setCurrentUser(user);
      setIsUserLoading(false);
    };

    if (currentTenant?.id! && userId!) {
      getUser();
    }
  }, [currentTenant?.id, userId]);

  const { isPending: profileFormLoading, mutateAsync: updateProfileForm } =
    useUpdateEntity<IUser, IProfileSchema>('tenantUser', {
      onError: (error) => {
        handleApiErrorToast(error);
      },
      onSuccess: (user) => {
        reset(user);
      },
    });

  const { isLoading, data: userProfile } = useGetById<IUser>(
    'tenantUser',
    userId!,
    {
      refetchOnWindowFocus: false,
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (userProfile) {
      reset(userProfile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  const loading = isLoading || profileFormLoading;

  const onSubmit = async (data: IProfileSchema) => {
    updateProfileForm({
      id: userId,
      payload: data,
    });
  };

  const handleLogout = () => {
    setRedirectInfo({
      tenantId,
      redirectUrl: `${location.pathname}${location.search}`,
    });
    navigate(ROUTES.AUTH_LOGOUT);
  };

  const decodedAuthToken = getDecodedToken();
  const isAllowedToUpdateProfile =
    decodedAuthToken?.sub?.split('|')[0] !== 'auth0';

  return (
    <MPageContainer>
      <MSettingsPageHeader title="Profile" backButtonTitle="Go Back">
        <MFlex>
          <MButton variant="cancel" onClick={handleLogout} isLoading={loading}>
            Logout
          </MButton>
        </MFlex>
      </MSettingsPageHeader>
      <MBox w="100%" my="8">
        <MBox maxWidth="27rem">
          <MText mb="2" fontWeight="600">
            Profile Picture
          </MText>
          <MAvatar name={userProfile?.name || ''} size="2xl" mb="6" />
          <form onSubmit={handleSubmit(onSubmit)} data-testid="tenant-form">
            <MHStack alignItems="flex-end" spacing="4">
              <MFormField
                maxW="21.875rem"
                error={errors.name}
                label="Name"
                isRequired
              >
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput
                      {...field}
                      placeholder="Name"
                      isDisabled={isAllowedToUpdateProfile}
                    />
                  )}
                />
              </MFormField>
              {isDirty && (
                <MFlex h="16" alignItems={!isValid ? 'center' : 'flex-end'}>
                  <MButton
                    mb={!isValid ? '4' : undefined}
                    size="sm"
                    variant="primary"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    isDisabled={profileFormLoading || loading || !isDirty}
                    isLoading={profileFormLoading || loading}
                  >
                    Save
                  </MButton>
                </MFlex>
              )}
            </MHStack>
          </form>
          <MText mt="6" mb="2" fontWeight="600">
            Email
          </MText>
          <MText>{userProfile?.email}</MText>
        </MBox>
      </MBox>
      {isUserLoading && (
        <MFlex flex={1} justify="center" alignItems="center">
          <MPageLoader height="auto" />
        </MFlex>
      )}
      {!isUserLoading && currentUser && <UserTeams teams={currentUser.teams} />}
    </MPageContainer>
  );
};

export default Profile;
