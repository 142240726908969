import {
  useGetAccountOverview,
  useGetAccountOverviewStats,
} from '~app/api/accountsService';

export const useOverviewData = (accountId: string, billGroupId?: string) => {
  const { isLoading: isLoadingAccountOverview, data: accountOverview } =
    useGetAccountOverview(accountId, billGroupId, {
      meta: { showErrorToast: true },
    });

  const {
    isLoading: isLoadingAccountOverviewStat,
    data: accountOverviewStats,
  } = useGetAccountOverviewStats(accountId, {
    meta: { showErrorToast: true },
  });

  return {
    totalRecords: accountOverview?.length || 0,
    loadingAll: isLoadingAccountOverview || isLoadingAccountOverviewStat,
    loadingOverview: isLoadingAccountOverview,
    listData: accountOverview || [],
    accountOverviewStats,
  };
};
