import { Stripe, StripeElements } from '@stripe/stripe-js';
import { MBox } from '../../components/Monetize';
import { StripeWrapper } from '../../components/PaymentMethods/Form/StripeWrapper';
import {
  IShareInvoiceGenerateSetupIntentResp,
  Maybe,
  PaymentMethodSummaryResponse,
  QuoteShareResponse,
} from '../../types';
import { QuoteShareContacts } from './QuoteShareContacts';
import { QuoteSharePaymentMethod } from './QuoteSharePaymentMethod';

interface QuoteShareHeaderContainerProps {
  quoteData: QuoteShareResponse;
  stripeKeys?: IShareInvoiceGenerateSetupIntentResp;
  existingPaymentMethods?: Maybe<PaymentMethodSummaryResponse[]>;
  onStripeInit: (data: {
    stripe?: Maybe<Stripe>;
    elements?: Maybe<StripeElements>;
  }) => void;
}

export const QuoteShareHeaderContainer = ({
  quoteData,
  stripeKeys,
  existingPaymentMethods,
  onStripeInit,
}: QuoteShareHeaderContainerProps) => {
  return (
    <MBox w="100%">
      <QuoteShareContacts />
      {stripeKeys && (
        <StripeWrapper
          apiKey={stripeKeys.publicKey}
          clientSecret={stripeKeys.clientSecret}
        >
          <QuoteSharePaymentMethod
            existingPaymentMethods={existingPaymentMethods}
            onInit={onStripeInit}
          />
        </StripeWrapper>
      )}
    </MBox>
  );
};
