import { useEffect, useMemo, useState } from 'react';
import { useCreateAccount, useGetAccountList } from '~app/api/accountsService';
import {
  MButton,
  MCenterModal,
  MCustomSelect,
  MHStack,
  MSpacer,
  MStack,
  MText,
} from '~app/components/Monetize';
import { MainSearchInput } from '~app/components/Monetize/MCustomSelect/components/MainSearchInput';
import { useACL } from '~app/services/acl/acl';
import {
  AccountStatusEnum,
  DEFAULT_PAGER,
  IAccountDetails,
  IAccountRespSchema,
} from '~app/types';
import { nullifyEmptyStrings } from '../../../../../utils/misc';
import NewAccountForm from '../NewQuoteForm/NewAccountForm';

interface NewGuidedQuoteFormPageProps {
  onClose: () => void;
  onFinished: (account: IAccountRespSchema) => void;
}

export const NewGuidedQuoteAccountSelectionModal = ({
  onClose,
  onFinished,
}: NewGuidedQuoteFormPageProps) => {
  const { canDo } = useACL();

  const { mutateAsync: doCreateAccount } = useCreateAccount();

  const [isNewAccountLoading, setIsNewAccountLoading] = useState(false);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(true);
  const [newAccountFormOpen, setNewAccountFormOpen] = useState(false);
  const [account, setAccount] = useState<IAccountRespSchema>();
  const [previousAccountList, setPreviousAccountList] = useState<
    IAccountDetails[]
  >([]);
  const [accountFilterParams, setAccountFilterParams] = useState({
    currentPage: 0,
  });
  const [canCreateAccount] = useState(canDo([['accounts', 'create']]));

  // TODO: move this infinite scroll logic to a custom hook
  // For account select dropdown
  const { isFetching: isLoadingAccounts, data: accountList } =
    useGetAccountList({
      config: {
        ...DEFAULT_PAGER,
        sortField: 'accountName',
        sortOrder: 1,
        rows: 50,
      },
      filters: { ...accountFilterParams, status: AccountStatusEnum.ACTIVE },
      options: {
        staleTime: 60 * 1000 * 1,
        placeholderData: (previousData, previousQuery) => previousData,
      },
    });

  const hasMoreDataToCall =
    accountList &&
    accountList?.pageable?.pageNumber! < accountList?.totalPages - 1;

  const accountItems = useMemo(() => {
    return [...previousAccountList, ...(accountList?.content || [])];
  }, [accountList, previousAccountList]);

  function onNextCall() {
    if (hasMoreDataToCall) {
      setPreviousAccountList((prevState) => [
        ...prevState,
        ...accountList.content,
      ]);
      setAccountFilterParams({
        ...accountFilterParams,
        currentPage: accountList?.pageable?.pageNumber! + 1,
      });
    }
  }

  // When the user starts the question flow
  const onSubmit = async () => {
    setNewAccountFormOpen(false);

    if (newAccountFormOpen && account) {
      setIsNewAccountLoading(true);
      const createdAccount = await doCreateAccount(
        nullifyEmptyStrings(account),
      );
      setIsNewAccountLoading(false);
      onFinished(createdAccount);
    } else if (account) {
      onFinished(account as IAccountRespSchema);
    }
  };

  useEffect(() => {
    if (account && account.accountName && account.accountName.length > 0) {
      setIsStartButtonDisabled(false);
    } else {
      setIsStartButtonDisabled(true);
    }
  }, [account]);

  return (
    <MCenterModal
      isOpen
      size="xl"
      onClose={onClose}
      modalTitle="New Guided Quote"
      renderFooter={() => (
        <MStack spacing={4} direction="row" align="center" flex={1}>
          <MSpacer />
          <MButton
            variant="cancel"
            isDisabled={isNewAccountLoading}
            onClick={onClose}
            minW="auto"
          >
            {newAccountFormOpen ? 'Back' : 'Cancel'}
          </MButton>

          <MButton
            variant="primary"
            isLoading={isNewAccountLoading}
            onClick={onSubmit}
            isDisabled={isStartButtonDisabled}
            minW="auto"
          >
            Start
          </MButton>
        </MStack>
      )}
    >
      {newAccountFormOpen && (
        <NewAccountForm
          onChange={(event) => {
            setAccount(event as unknown as IAccountRespSchema);
          }}
        />
      )}
      {!newAccountFormOpen && (
        <>
          <MHStack spacing={1}>
            <MText fontWeight="semibold" mb={2}>
              Existing Account
            </MText>
            <MText
              fontWeight="semibold"
              color="red.500"
              mb={2}
              alignSelf="flex-start"
            >
              *
            </MText>
          </MHStack>
          <MCustomSelect
            clearable
            onClear={() => setAccount(undefined)}
            value={account}
            itemTitle="accountName"
            itemValue="id"
            items={accountItems}
            onNextCall={onNextCall}
            hasMoreDataToCall={hasMoreDataToCall}
            useMainInputAsSearchInput
            MainInputComponent={MainSearchInput}
            returnItem
            loading={isLoadingAccounts}
            onChange={(account: any) =>
              setAccount(account as IAccountRespSchema)
            }
            isSelectOpen={false}
          />
          {canCreateAccount && (
            <MHStack mt={3}>
              <MText>{`Can't find the right account?`}</MText>
              <MButton
                variant="tertiary"
                onClick={() => setNewAccountFormOpen(true)}
                data-testid="new-quote-with-new-account-btn"
              >
                Create New Account
              </MButton>
            </MHStack>
          )}
        </>
      )}
    </MCenterModal>
  );
};
