import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import pick from 'lodash/pick';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useRecoilValue } from 'recoil';
import {
  useGetAccountById,
  useSetContactOnInvoice,
  useUpdateInvoiceCustomFields,
} from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetContactById } from '~app/api/contactsService';
import {
  useCreateCreditAndRebill,
  useSetAddressOnInvoice,
  useUpdateInvoiceDetails,
} from '~app/api/invoiceService';
import { useGetById } from '~app/api/queryUtils';
import { AccountAddressSelect } from '~app/components/Account/AccountAddressSelect';
import { ContactFormModal } from '~app/components/Contacts/ContactFormModal';
import { ContactSelect } from '~app/components/Contacts/ContactSelect';
import {
  MAlert,
  MButton,
  MCenterModal,
  MDivider,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MLockedTextOrContent,
  MRadio,
  MRadioGroup,
  MStack,
  MText,
} from '~app/components/Monetize';
import { useCustomSelectValue } from '~app/components/Monetize/MCustomSelect/useCustomSelectValue';
import MEditor from '~app/components/Monetize/MEditor';
import { MTooltip } from '~app/components/Monetize/chakra';
import { ROUTES } from '~app/constants';
import { CHANGE_CONTACT_STATUSES } from '~app/constants/invoice';
import { useACL } from '~app/services/acl/acl';
import { useFlags } from '~app/services/launchDarkly';
import { logger } from '~app/services/logger';
import { appGlobalDataState } from '~app/store/global.store';
import { getAddressFromContact } from '~app/utils/address';
import { CustomFieldDataForm } from '../../components/CustomFields/CustomFieldDataForm';
import {
  CreditAndRebillReqSchema,
  CustomFieldEntityEnum,
  CustomFieldRecordSchema,
  IBillGroupResp,
  IContactRespSchema,
  ICreditAndRebillReqSchema,
  IInvoiceRespSchema,
  InvoiceAddressDataSchema,
  InvoiceContactUpdateSchema,
  InvoiceStatusEnum,
  InvoiceUpdateSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '../../types';
import { nullifyEmptyStrings } from '../../utils/misc';
import { SettingsToggle } from '../Settings/Quoting/QuoteSettings/components/SettingsToggle';
import { CreditAndRebillApplyCheckbox } from './CreditAndRebillApplyCheckbox';

interface EditInvoiceModalProps {
  isDraftInvoice?: boolean;
  invoice: IInvoiceRespSchema;
  isOpen: boolean;
  enableCreditAndRebill: boolean;
  isCreditAnRebillPossible: boolean;
  handleClose: () => void;
}
export const EditInvoiceModal = ({
  isDraftInvoice,
  invoice,
  isOpen,
  enableCreditAndRebill,
  isCreditAnRebillPossible,
  handleClose,
}: EditInvoiceModalProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { canDo } = useACL();
  const canUpdateContact = canDo([['account_contacts', 'update']]);
  const canCreateContact = canDo([['account_contacts', 'create']]);
  const {
    enableAccountBasedAddresses,
    showCreditAndRebill,
    lockFinalizedInvoiceData,
  } = useFlags();
  const contactModalState = useDisclosure();
  const { hasCrmConfigured } = useRecoilValue(appGlobalDataState);
  const [creditAndRebill, setCreditAndRebill] = useState<boolean>(
    enableCreditAndRebill,
  );
  const [applyCreditNote, setApplyCreditNote] = useState(enableCreditAndRebill);
  const [contactId, setContactId] = useState<string>();
  const [loading, setLoading] = useState(false);
  const { data: billGroupData, isLoading: isBillGroupLoading } =
    useGetById<IBillGroupResp>('billGroups', invoice.billGroupId, {
      enabled: !!invoice.billGroupId,
      refetchOnWindowFocus: false,
    });
  const accountId = invoice.account.id;
  const { data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    refetchOnWindowFocus: false,
  });
  const creditAndRebillResData = pick(
    invoice,
    Object.keys(CreditAndRebillReqSchema.shape),
  );
  const isFinalizedInvoiceMode = !isDraftInvoice && !creditAndRebill;

  const accountAddresses: string[] = [];

  if (accountDetails?.billingAddress?.id) {
    accountAddresses.push(accountDetails?.billingAddress?.id);
  }

  if (accountDetails?.shippingAddress?.id) {
    accountAddresses.push(accountDetails?.shippingAddress?.id);
  }

  const getDefaultAddressSource = (invoice: IInvoiceRespSchema) => {
    if (!enableAccountBasedAddresses) {
      return QuoteSettingsDefaultAddressSourceEnum.CONTACT;
    }
    if (
      accountAddresses.includes(invoice.billingAddress?.id || '') ||
      accountAddresses.includes(invoice.shippingAddress?.id || '')
    ) {
      return QuoteSettingsDefaultAddressSourceEnum.ACCOUNT;
    } else {
      return QuoteSettingsDefaultAddressSourceEnum.CONTACT;
    }
  };

  const defaultValues: ICreditAndRebillReqSchema = {
    ...creditAndRebillResData,
    addressInformation: {
      addressSource: getDefaultAddressSource(invoice),
      billingAddressId: accountAddresses.includes(
        invoice.billingAddress?.id || '',
      )
        ? invoice.billingAddress?.id
        : undefined,
      shippingAddressId: accountAddresses.includes(
        invoice.shippingAddress?.id || '',
      )
        ? invoice.shippingAddress?.id
        : undefined,
    },
    billingContactId: invoice.billingContact.id,
    shippingContactId: invoice.shippingContact.id,
    applyCreditNote: false,
    customFields: creditAndRebillResData.customFields || {},
    invoiceMemo: invoice?.invoiceMemo ?? '',
  };

  const {
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors },
  } = useForm<ICreditAndRebillReqSchema>({
    resolver: zodResolver(CreditAndRebillReqSchema),
    mode: 'onChange',
    defaultValues,
    values: defaultValues,
  });
  const { addressSource } = watch('addressInformation');
  const billingContactId = watch('billingContactId');
  const shippingContactId = watch('shippingContactId');
  const isAddressSourceContact =
    addressSource === QuoteSettingsDefaultAddressSourceEnum.CONTACT;
  const { mutateAsync: updateInvoiceDetailsOrCreditAndRebill } =
    useCreateCreditAndRebill();
  const { mutateAsync: updateInvoiceDetails } = useUpdateInvoiceDetails();
  const { mutateAsync: doUpdateInvoiceCustomFields } =
    useUpdateInvoiceCustomFields();
  const { mutateAsync: doUpdateInvoiceAddress } = useSetAddressOnInvoice();
  const { mutateAsync: doSetContactOnInvoice } = useSetContactOnInvoice();

  const canUpdateBilling = canDo([['billing', 'update']]);
  const isReadOnly =
    !creditAndRebill &&
    (!canUpdateBilling || !CHANGE_CONTACT_STATUSES.has(invoice.status));
  const isReadOnlyProps = {
    isDisabled: isReadOnly,
    isReadOnly: isReadOnly,
    variant: 'readonly',
  };
  const isInvoiceMemoAvailable: boolean =
    creditAndRebill ||
    (invoice.status !== InvoiceStatusEnum.PAID &&
      invoice.status !== InvoiceStatusEnum.REVERSED);

  const onSubmit = async (data: ICreditAndRebillReqSchema) => {
    try {
      setLoading(true);
      data = nullifyEmptyStrings(data);
      if (creditAndRebill) {
        const parsedData = CreditAndRebillReqSchema.parse(data);

        const { applyCreditToInvoiceError, creditAndRebillResponse } =
          await updateInvoiceDetailsOrCreditAndRebill({
            applyCreditToOldInvoice: applyCreditNote,
            invoice,
            payload: {
              ...parsedData,
              invoiceMemo:
                parsedData.invoiceMemo === '' ? null : parsedData.invoiceMemo,
            },
          });

        if (applyCreditToInvoiceError) {
          handleApiErrorToast(applyCreditToInvoiceError, {
            severity: 'warning',
            summary: 'Error Applying Credit Note',
          });
        }

        if (creditAndRebillResponse.invoice.id) {
          navigate(
            {
              pathname: ROUTES.getInvoiceDetailRoute(
                creditAndRebillResponse.invoice.id,
              ),
            },
            { replace: true },
          );
        }
      } else {
        const {
          customFields,
          purchaseOrderNumber,
          registrationNumber,
          vatNumber,
          addressInformation: { billingAddressId, shippingAddressId },
          invoiceMemo,
        } = data;
        if (isReadOnly) {
          const parsedCustomFields =
            CustomFieldRecordSchema.parse(customFields);
          await doUpdateInvoiceCustomFields({
            invoiceId: invoice.id,
            customFields: parsedCustomFields,
          });
        } else {
          const parsedInvoiceData = InvoiceUpdateSchema.parse({
            customFields,
            purchaseOrderNumber,
            registrationNumber,
            vatNumber,
            invoiceMemo: invoiceMemo === '' ? null : invoiceMemo,
          });
          const parsedInvoiceContact = InvoiceContactUpdateSchema.parse({
            contactId: billingContactId,
            shippingContactId,
          });

          const isContactAddressSource =
            addressSource === QuoteSettingsDefaultAddressSourceEnum.CONTACT;
          const parsedInvoiceAddress = InvoiceAddressDataSchema.parse({
            addressSource,
            billingAddressId: isContactAddressSource ? null : billingAddressId,
            shippingAddressId: isContactAddressSource
              ? null
              : shippingAddressId,
          });

          await updateInvoiceDetails({
            invoiceId: invoice.id,
            data: parsedInvoiceData,
          });

          if (!isFinalizedInvoiceMode) {
            await doSetContactOnInvoice({
              invoiceId: invoice?.id,
              data: parsedInvoiceContact,
            });
            await doUpdateInvoiceAddress({
              invoiceId: invoice.id,
              data: parsedInvoiceAddress,
            });
          }
        }
      }
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const onError = (err: any) =>
    logger.error('Error while updating invoice details ====>', err);

  const billingContactCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: billingContactId,
    setValue: (val) => {
      setValue('billingContactId', val, { shouldDirty: true });
    },
    getOneById: doGetContactById,
  });

  const billingAddressTextForAddressSourceContact =
    billingContactCustomValue.internalValue
      ? getAddressFromContact(billingContactCustomValue.internalValue, {
          addressFormat: invoice.fromCompany.addressFormat,
        }).fullAddress
      : 'Address populates from Billing Contact';

  const shippingContactCustomValue = useCustomSelectValue<IContactRespSchema>({
    value: shippingContactId,
    setValue: (val) => {
      setValue('shippingContactId', val, { shouldDirty: true });
    },
    getOneById: doGetContactById,
  });

  const shippingAddressTextForAddressSourceContact =
    shippingContactCustomValue.internalValue
      ? getAddressFromContact(shippingContactCustomValue.internalValue, {
          addressFormat: invoice.fromCompany.addressFormat,
        }).fullAddress
      : 'Address populates from Billing Contact';

  const handleEdit = async (contactId?: string) => {
    if (contactId) {
      setContactId(contactId);
      contactModalState.onOpen();
    }
  };

  return (
    <>
      <MCenterModal
        size="lg"
        isLoading={isBillGroupLoading}
        isOpen={isOpen}
        onClose={handleClose}
        modalTitle={enableCreditAndRebill ? 'Credit & Rebill' : 'Edit Invoice'}
        modalOverlayProps={{ zIndex: '100' }}
        modalContentProps={{
          containerProps: {
            zIndex: '100',
          },
        }}
        modalFooterProps={{ border: 0 }}
        renderFooter={() => (
          <MGrid templateColumns="repeat(1, 1fr)" width={'100%'} gap={2}>
            <MGridItem>
              {!creditAndRebill && !isFinalizedInvoiceMode && (
                <MAlert type="warning">
                  <MText>
                    Tax is not automatically recalculated upon save.
                  </MText>
                </MAlert>
              )}
              {creditAndRebill && (
                <CreditAndRebillApplyCheckbox
                  invoice={invoice}
                  applyCreditNote={applyCreditNote}
                  onApplyCreditNote={setApplyCreditNote}
                />
              )}
            </MGridItem>
            <MGridItem>
              <MStack
                spacing={4}
                direction="row"
                align="center"
                justify="right"
                flex={1}
              >
                <MButton variant="cancel" onClick={handleClose} minW="auto">
                  Cancel
                </MButton>
                <MButton
                  form="edit-invoice-form"
                  variant="primary"
                  isLoading={
                    loading ||
                    isBillGroupLoading ||
                    billingContactCustomValue.isLoading ||
                    shippingContactCustomValue.isLoading
                  }
                  disabled={
                    loading ||
                    isBillGroupLoading ||
                    billingContactCustomValue.isLoading ||
                    shippingContactCustomValue.isLoading
                  }
                  type="submit"
                  minW="auto"
                >
                  {/* {creditAndRebill ? 'Preview' : 'Save'} */}
                  Save
                </MButton>
              </MStack>
            </MGridItem>
          </MGrid>
        )}
      >
        <form id="edit-invoice-form" onSubmit={handleSubmit(onSubmit, onError)}>
          {isCreditAnRebillPossible &&
            invoice.status !== InvoiceStatusEnum.DRAFT && (
              <>
                {!lockFinalizedInvoiceData && (
                  <SettingsToggle
                    comingSoon={!showCreditAndRebill}
                    isDisabled={!showCreditAndRebill}
                    name="creditAndRebill"
                    heading="Credit & Rebill"
                    subheading="When toggled on, a Credit Note will be created for the full value of this Invoice. Then, changes made here will be reflected in a new (rebilled) Invoice."
                    value={creditAndRebill}
                    onChange={setCreditAndRebill}
                  />
                )}
                {lockFinalizedInvoiceData && !isFinalizedInvoiceMode && (
                  <MText>
                    Credit Note will be created for the full value of this
                    Invoice. Then, changes made here will be reflected in a new
                    (rebilled) Invoice.
                  </MText>
                )}

                {!isFinalizedInvoiceMode && <MDivider my={4} />}
              </>
            )}

          {enableAccountBasedAddresses && !isFinalizedInvoiceMode && (
            <MFormField
              label="Addresses Sourced From"
              isHorizontal
              mb={4}
              labelProps={{ mb: 0 }}
              tooltip="Set source of Shipping and Billing Addresses on this Invoice"
            >
              <Controller
                control={control}
                name="addressInformation.addressSource"
                render={({ field: { value, onChange, ...rest } }) => (
                  <MRadioGroup
                    onChange={onChange}
                    value={value}
                    {...rest}
                    {...isReadOnlyProps}
                  >
                    <MStack
                      spacing={4}
                      direction="row"
                      color="tGray.darkPurple"
                    >
                      <MRadio
                        value={QuoteSettingsDefaultAddressSourceEnum.ACCOUNT}
                        isDisabled={!hasCrmConfigured}
                        onChange={() => {
                          setValue(
                            'addressInformation.billingAddressId',
                            undefined,
                          );
                          setValue(
                            'addressInformation.shippingAddressId',
                            undefined,
                          );
                        }}
                      >
                        <MTooltip
                          label="CRM must be connected to use Account Addresses"
                          placement="bottom-start"
                          shouldWrapChildren
                          isDisabled={hasCrmConfigured}
                        >
                          <MText
                            display="inline-flex"
                            alignItems="center"
                            color="currentColor"
                          >
                            Account Addresses
                          </MText>
                        </MTooltip>
                      </MRadio>
                      <MRadio
                        value={QuoteSettingsDefaultAddressSourceEnum.CONTACT}
                        onChange={() => {
                          setValue(
                            'addressInformation.billingAddressId',
                            undefined,
                          );
                          setValue(
                            'addressInformation.shippingAddressId',
                            undefined,
                          );
                        }}
                      >
                        Contacts
                      </MRadio>
                    </MStack>
                  </MRadioGroup>
                )}
              />
            </MFormField>
          )}

          {!isFinalizedInvoiceMode && (
            <>
              <MFormField
                mb={4}
                isRequired
                label="Billing Contact"
                labelRightComponent={
                  canUpdateContact &&
                  billingContactCustomValue.internalValue && (
                    <MButton
                      size="xs"
                      mb="2"
                      onClick={() => {
                        handleEdit(billingContactCustomValue.internalValue?.id);
                      }}
                      {...isReadOnlyProps}
                      variant="tertiary"
                    >
                      Edit
                    </MButton>
                  )
                }
              >
                <ContactSelect
                  showAddContacts={canCreateContact}
                  accountId={accountId}
                  loading={billingContactCustomValue.isLoading}
                  value={billingContactCustomValue.internalValue}
                  returnItem
                  onChange={(val) => {
                    billingContactCustomValue.onInternalValueChange(val as any);
                  }}
                  clearable={false}
                  {...isReadOnlyProps}
                />
              </MFormField>

              <MFormField mb={4} label="Billing Address">
                <MLockedTextOrContent
                  isLocked={isAddressSourceContact}
                  text={billingAddressTextForAddressSourceContact}
                >
                  <Controller
                    name="addressInformation.billingAddressId"
                    control={control}
                    render={({ field }) => (
                      <AccountAddressSelect
                        {...field}
                        addressFormat={invoice.fromCompany.addressFormat}
                        accountId={accountId}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MLockedTextOrContent>
              </MFormField>

              <MFormField
                mb={4}
                isRequired
                label="Shipping Contact"
                labelRightComponent={
                  canUpdateContact &&
                  shippingContactCustomValue.internalValue && (
                    <MButton
                      size="xs"
                      mb="2"
                      onClick={() => {
                        handleEdit(
                          shippingContactCustomValue.internalValue?.id,
                        );
                      }}
                      {...isReadOnlyProps}
                      variant="tertiary"
                    >
                      Edit
                    </MButton>
                  )
                }
              >
                <ContactSelect
                  showAddContacts={canCreateContact}
                  accountId={accountId}
                  loading={shippingContactCustomValue.isLoading}
                  value={shippingContactCustomValue.internalValue}
                  returnItem
                  onChange={(val) => {
                    shippingContactCustomValue.onInternalValueChange(
                      val as any,
                    );
                  }}
                  clearable={false}
                  {...isReadOnlyProps}
                />
              </MFormField>

              <MFormField label="Shipping Address">
                <MLockedTextOrContent
                  isLocked={isAddressSourceContact}
                  text={shippingAddressTextForAddressSourceContact}
                >
                  <Controller
                    name="addressInformation.shippingAddressId"
                    control={control}
                    render={({ field }) => (
                      <AccountAddressSelect
                        {...field}
                        addressFormat={invoice.fromCompany.addressFormat}
                        accountId={accountId}
                        {...isReadOnlyProps}
                      />
                    )}
                  />
                </MLockedTextOrContent>
              </MFormField>
              <MDivider my={4} />
            </>
          )}

          <MGrid alignItems="center" templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={6}>
              <MFormField error={errors.purchaseOrderNumber} label="PO Number">
                <Controller
                  name="purchaseOrderNumber"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="PO Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.registrationNumber}
                label="Registration Number"
              >
                <Controller
                  name="registrationNumber"
                  defaultValue=""
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="Registration Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.vatNumber} label="VAT Number">
                <Controller
                  name="vatNumber"
                  defaultValue=""
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <MInput
                      placeholder="VAT Number"
                      value={value || ''}
                      {...rest}
                      {...isReadOnlyProps}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>

          {isInvoiceMemoAvailable && (
            <>
              <MDivider my={4} />
              <MFormField label="Invoice Memo" mb={4}>
                <MText mb={2}>Visible to end customers</MText>
                <Controller
                  control={control}
                  name="invoiceMemo"
                  render={({ field: { value, onChange, ...fields } }) => (
                    <MEditor
                      value={value ?? ''}
                      autoScroll
                      boxProps={{
                        pb: 0,
                        minH: 200,
                      }}
                      handleEditorChange={onChange}
                      {...fields}
                    />
                  )}
                />
              </MFormField>
            </>
          )}

          <Controller
            name="customFields"
            control={control}
            render={({ field: { value, onChange } }) => (
              <CustomFieldDataForm
                entity={CustomFieldEntityEnum.INVOICE}
                value={value}
                setValue={(val) => {
                  onChange(val);
                }}
                fieldColSpan={6}
                mode="modal"
                {...isReadOnlyProps}
                isDisabled={!canUpdateBilling}
                isReadOnly={!canUpdateBilling}
                variant={undefined}
              />
            )}
          />
        </form>
      </MCenterModal>
      {contactId && (
        <ContactFormModal
          isOpen
          accountId={accountId}
          contactId={contactId}
          onClose={(contact) => {
            contactModalState.onClose();
            setContactId(undefined);
            if (contact) {
              queryClient.invalidateQueries({
                queryKey: ['useCustomSelectValue', contact.id],
              });
            }
          }}
          onUpdatedContact={(contact) => {
            queryClient.invalidateQueries({
              queryKey: ['useCustomSelectValue', contact.id],
            });
            contactModalState.onClose();
          }}
        />
      )}
    </>
  );
};
