import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useGetById } from '~app/api/queryUtils';
import { MPageLoader } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { getQuoteEditRoute, getQuoteReviewRoute } from '~app/constants/routes';
import { IQuoteRespSchema } from '~app/types';

/**
 * Redirects to edit or review based on quote status
 */
export const QuoteBaseRedirector = () => {
  const { quoteId = '' } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { data: quote, isError } = useGetById<IQuoteRespSchema>(
    'cpqServiceQuotes',
    quoteId,
    {
      enabled: !!quoteId,
      retry: false,
      meta: { showErrorToast: true },
    },
  );

  // NOTE: I have to wrap the whole things inside the useEffect for the below error
  // ERROR: You should call navigate() in a React.useEffect(), not when your component is first rendered
  useEffect(() => {
    if (quote) {
      const route =
        quote.status === 'DRAFT'
          ? getQuoteEditRoute(quote.id)
          : getQuoteReviewRoute(quote.id);
      if (state && (state as any).view) {
        const { view } = state as any;
        const tempRoute =
          view === 'external'
            ? getQuoteReviewRoute(quote.id)
            : getQuoteEditRoute(quote.id);
        navigate(tempRoute, { replace: true, state });
      } else {
        navigate(route, { replace: true });
      }
    } else if (isError) {
      navigate(ROUTES.SALES_QUOTES_ROUTE, { replace: true });
    }
  }, [navigate, quote, isError, state]);

  return <MPageLoader />;
};
