import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import { apiGet, apiPost, apiPut } from '~api/axios';
import {
  IPaymentMethodContactSchema,
  IPaymentMethodResp,
  ISharedInvoicePaymentReq,
  IShareInvoiceGenerateSetupIntentResp,
  IShareInvoiceRespSchema,
  Maybe,
  PaymentMethodSummaryResponse,
  QuoteShareResponse,
  SharedInvoicePaymentExistingMethod,
} from '~types';
import { getQuoteShareStripePaymentCallbackRoute } from '../constants/routes';
import { logger } from '../services/logger';
import {
  QuoteShareContactRequest,
  QuoteShareForm,
  QuoteSharePaymentMethodRequest,
  RecordShareBaseParams,
  RecordShareEngagement,
  RecordShareEngagementAction,
  RecordShareEngagementSchema,
  RecordShareEntity,
} from '../types/recordShareTypes';
import { areContactsSame } from '../utils/contact.utils';
import { invoiceServiceQueryKeys } from './invoiceService';

export const shareQueryKeys = {
  base: () => ['share'] as const,
  baseWithSecret: (secretId: string) =>
    [...shareQueryKeys.base(), secretId] as const,
  engagement: (recordId: string) =>
    [...shareQueryKeys.base(), 'engagement', recordId] as const,
  invoice: (secretId: string) =>
    [...shareQueryKeys.baseWithSecret(secretId), 'invoice'] as const,
  quote: (secretId: string) =>
    [...shareQueryKeys.baseWithSecret(secretId), 'quote'] as const,
  quotePdf: (secretId: string) =>
    [...shareQueryKeys.quote(secretId), 'pdf'] as const,
  paymentMethods: (secretId: string) =>
    [...shareQueryKeys.baseWithSecret(secretId), 'paymentMethods'] as const,
};

export function useGetRecordShareEngagements<
  SelectData = RecordShareEngagement[],
>(
  recordId: string,
  options: Partial<
    UseQueryOptions<RecordShareEngagement[], unknown, SelectData>
  > = {},
) {
  return useQuery<RecordShareEngagement[], unknown, SelectData>({
    queryKey: shareQueryKeys.engagement(recordId),
    queryFn: () =>
      apiGet<RecordShareEngagement[]>(`/api/share/engagement/${recordId}`).then(
        (res) => RecordShareEngagementSchema.array().parse(res.data),
      ),
    ...options,
  });
}

export function useGetInvoiceByShareId(
  secretId: string,
  tenantId: string,
  options: Partial<UseQueryOptions<IShareInvoiceRespSchema>> = {},
) {
  return useQuery({
    queryKey: [...shareQueryKeys.invoice(secretId)],
    queryFn: () =>
      apiGet<IShareInvoiceRespSchema>(`/api/share/invoices/${secretId}`, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),
    ...options,
    refetchOnWindowFocus: false,
  });
}

export function useGetPaymentMethodsByInvoiceShareId<
  SelectData = Array<PaymentMethodSummaryResponse>,
>(
  { invoiceSecretId, tenantId }: { invoiceSecretId: string; tenantId: string },
  options: Partial<
    UseQueryOptions<Array<PaymentMethodSummaryResponse>, unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...shareQueryKeys.paymentMethods(invoiceSecretId)],
    queryFn: () =>
      apiGet<Array<PaymentMethodSummaryResponse>>(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods`,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data),
    ...options,
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function useSetupIntentForInvoiceShare(
  options: Partial<
    UseMutationOptions<
      IShareInvoiceGenerateSetupIntentResp,
      unknown,
      { invoiceSecretId: string; tenantId: string }
    >
  > = {},
) {
  return useMutation<
    IShareInvoiceGenerateSetupIntentResp,
    unknown,
    { invoiceSecretId: string; tenantId: string }
  >({
    mutationFn: ({ invoiceSecretId, tenantId }) => {
      return apiPost<IShareInvoiceGenerateSetupIntentResp>(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods/token`,
        null,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export const doPrintInvoicePdfForInvoiceShare = async (
  tenantId: string,
  invoiceSecretId: string,
  invoiceId: string,
  invoiceNumber: Maybe<string>,
) => {
  const res = await apiGet<any>(
    `/api/share/invoices/${invoiceSecretId}/print`,
    {
      responseType: 'arraybuffer',
      axiosConfig: {
        excludeUserIdFromHeader: true,
        excludeTenantIdFromHeader: true,
        customXTenantId: tenantId,
      },
      headers: new AxiosHeaders({
        accept: 'application/pdf',
      }),
    },
  );
  return {
    data: res.data,
    fileName: `invoice-${invoiceNumber || invoiceId}.pdf`,
  };
};

export const usePrintInvoiceToHtmlForInvoiceShare = (
  {
    invoiceSecretId,
    lastModifiedTimestamp,
    tenantId,
  }: {
    invoiceSecretId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
    tenantId: string;
  },
  options: Partial<UseQueryOptions<string>> = {},
) => {
  return useQuery({
    queryKey: [
      ...invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
      lastModifiedTimestamp,
      tenantId,
    ],
    queryFn: () =>
      apiGet<string>(`/api/share/invoices/${invoiceSecretId}/print`, {
        responseType: 'text',
        headers: new AxiosHeaders({
          accept: 'text/html',
        }),
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),

    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: (previousData, previousQuery) => previousData,
    ...options,
  });
};

export function useInvoicePaymentForInvoiceShare(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      {
        invoiceSecretId: string;
        tenantId: string;
        body: ISharedInvoicePaymentReq;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: ISharedInvoicePaymentReq;
    }
  >({
    mutationFn: ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(
        `/api/share/invoices/${invoiceSecretId}/startPayment`,
        body,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => {
        queryClient.invalidateQueries({
          queryKey: shareQueryKeys.invoice(invoiceSecretId),
        });
        queryClient.invalidateQueries({
          queryKey: invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        });
        return res.data;
      });
    },
    ...options,
  });
}

/**
 * Create payment method for invoice share
 * this is used when the payment method has additional action required
 * such as verifying micro-deposits
 */
export function useCreateInvoicePaymentMethodForInvoiceShare(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      {
        invoiceSecretId: string;
        tenantId: string;
        body: ISharedInvoicePaymentReq;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: ISharedInvoicePaymentReq;
    }
  >({
    mutationFn: ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods`,
        body,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => {
        queryClient.invalidateQueries({
          queryKey: shareQueryKeys.invoice(invoiceSecretId),
        });
        queryClient.invalidateQueries({
          queryKey: invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        });
        return res.data;
      });
    },
    ...options,
  });
}

/**
 * Pay with existing payment method
 */
export function usePayInvoiceWithPaymentMethodForInvoiceShare(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      {
        invoiceSecretId: string;
        tenantId: string;
        body: SharedInvoicePaymentExistingMethod;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: SharedInvoicePaymentExistingMethod;
    }
  >({
    mutationFn: ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(`/api/share/invoices/${invoiceSecretId}/payment`, body, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => {
        queryClient.invalidateQueries({
          queryKey: shareQueryKeys.invoice(invoiceSecretId),
        });
        queryClient.invalidateQueries({
          queryKey: invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
        });
        return res.data;
      });
    },
    ...options,
  });
}

export function useValidateRecordShare(
  options: Partial<
    UseMutationOptions<
      IShareInvoiceGenerateSetupIntentResp,
      unknown,
      RecordShareBaseParams & { entity?: string }
    >
  > = {},
) {
  return useMutation<
    IShareInvoiceGenerateSetupIntentResp,
    unknown,
    RecordShareBaseParams & { entity?: string }
  >({
    mutationFn: ({ recordId, tenantId, token, entity = 'QUOTE' }) => {
      return apiGet<IShareInvoiceGenerateSetupIntentResp>(
        `/api/share/validate/${entity}/${recordId}`,
        {
          params: { token },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export function useTrackRecordShareAction(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      RecordShareBaseParams & {
        entity: RecordShareEntity;
        action: RecordShareEngagementAction;
        email?: Maybe<string>;
      }
    >
  > = {},
) {
  return useMutation<
    void,
    unknown,
    RecordShareBaseParams & {
      entity: RecordShareEntity;
      action: RecordShareEngagementAction;
      email?: Maybe<string>;
    }
  >({
    mutationFn: ({ recordId, tenantId, token, entity, action, email }) => {
      return apiPost<void>(
        `/api/share/engagement/${entity}/${recordId}`,
        { action, email },
        {
          params: { token },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export function useGetQuoteShare<SelectData = QuoteShareResponse>(
  { tenantId, token, recordId }: RecordShareBaseParams,
  options: Partial<
    UseQueryOptions<QuoteShareResponse, unknown, SelectData>
  > = {},
) {
  return useQuery<QuoteShareResponse, unknown, SelectData>({
    queryKey: [...shareQueryKeys.quote(recordId), tenantId, token],
    queryFn: () =>
      apiGet<QuoteShareResponse>(`/api/share/quotes/${recordId}`, {
        params: { token },
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),
    ...options,
    retry: false,
    refetchOnWindowFocus: false,
  });
}

export function usePrintQuoteShare(
  { tenantId, token, recordId }: RecordShareBaseParams,
  options: Partial<UseQueryOptions<ArrayBuffer>> = {},
) {
  return useQuery<ArrayBuffer>({
    queryKey: [...shareQueryKeys.quotePdf(recordId), tenantId, token],
    queryFn: () =>
      apiGet<ArrayBuffer>(`/api/share/quotes/${recordId}/print`, {
        params: { token },
        headers: new AxiosHeaders({
          Accept: 'application/pdf',
        }),
        responseType: 'arraybuffer',
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),
    ...options,
    retry: false,
    refetchOnWindowFocus: false,
    placeholderData: (previousData, previousQuery) => previousData,
  });
}

export function useSetupIntentForQuoteShare(
  options: Partial<
    UseMutationOptions<
      IShareInvoiceGenerateSetupIntentResp,
      unknown,
      RecordShareBaseParams & { paymentGatewayId: string }
    >
  > = {},
) {
  return useMutation<
    IShareInvoiceGenerateSetupIntentResp,
    unknown,
    RecordShareBaseParams & { paymentGatewayId: string }
  >({
    mutationFn: ({ recordId, tenantId, token, paymentGatewayId }) => {
      return apiPost<IShareInvoiceGenerateSetupIntentResp>(
        `/api/share/quotes/${recordId}/paymentMethods/token`,
        { paymentGatewayId },
        {
          params: { token },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export function useCreateOrUpdateContactsForQuoteShare(
  options: Partial<
    UseMutationOptions<
      | { didUpdate: true; response: QuoteShareResponse }
      | { didUpdate: false; response?: never },
      unknown,
      {
        authParams: RecordShareBaseParams;
        quoteShareData: QuoteShareResponse;
        data: QuoteShareForm;
      }
    >
  > = {},
) {
  return useMutation<
    | { didUpdate: true; response: QuoteShareResponse }
    | { didUpdate: false; response?: never },
    unknown,
    {
      authParams: RecordShareBaseParams;
      quoteShareData: QuoteShareResponse;
      data: QuoteShareForm;
    }
  >({
    mutationFn: async ({
      authParams: { recordId, tenantId, token },
      data,
      quoteShareData,
    }) => {
      // Determine if user modified contacts
      const billingContactSame = areContactsSame(
        quoteShareData.quote.billingContact,
        data.contacts.billingContact,
      );

      const shippingContactSame =
        (data.contacts.useBillingForShippingContact &&
          quoteShareData.quote.shippingContact.id ===
            quoteShareData.quote.billingContact.id) ||
        areContactsSame(
          quoteShareData.quote.shippingContact,
          data.contacts.shippingContact,
        );

      // Update Quote Contacts if they were modified
      if (!billingContactSame || !shippingContactSame) {
        const contactRequest: QuoteShareContactRequest = {
          billingContact: data.contacts.billingContact,
          shippingContact: data.contacts.shippingContact,
        };
        if (data.contacts.useBillingForShippingContact) {
          contactRequest.shippingContact = contactRequest.billingContact;
        } else if (
          contactRequest.billingContact.id === contactRequest.shippingContact.id
        ) {
          // User modified the contacts, so we need to ensure that the shipping contact is created
          contactRequest.shippingContact.id = null;
        }
        await apiPut<QuoteShareResponse>(
          `/api/share/quotes/${recordId}/contacts`,
          contactRequest,
          {
            params: { token },
            axiosConfig: {
              excludeUserIdFromHeader: true,
              excludeTenantIdFromHeader: true,
              customXTenantId: tenantId,
            },
          },
        ).then((res) => ({ didUpdate: true, response: res.data }));
      }

      return { didUpdate: false };
    },
    ...options,
  });
}

export function useUpdatePoNumberOnQuoteShare(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      RecordShareBaseParams & { purchaseOrderNumber: string | null }
    >
  > = {},
) {
  return useMutation<
    void,
    unknown,
    RecordShareBaseParams & { purchaseOrderNumber: string | null }
  >({
    mutationFn: ({ tenantId, token, recordId, purchaseOrderNumber }) =>
      apiPut(
        `/api/share/quotes/${recordId}/purchaseOrderNumber`,
        { purchaseOrderNumber: purchaseOrderNumber || null },
        {
          params: { token },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data),
    ...options,
  });
}

export function useSavePaymentMethodOnStripe(
  options: Partial<
    UseMutationOptions<
      SetupIntentResult,
      unknown,
      {
        stripe: Stripe;
        elements: StripeElements;
        billingContact: IPaymentMethodContactSchema;
        authParams: RecordShareBaseParams;
      }
    >
  > = {},
) {
  return useMutation({
    mutationFn: ({
      stripe,
      elements,
      billingContact,
      authParams: { recordId, tenantId, token },
    }: {
      stripe: Stripe;
      elements: StripeElements;
      billingContact: IPaymentMethodContactSchema;
      authParams: RecordShareBaseParams;
    }) => {
      const returnUrlPath = getQuoteShareStripePaymentCallbackRoute(
        tenantId,
        recordId,
        token,
      );
      return stripe.confirmSetup({
        elements,
        confirmParams: {
          // FIXME: this route is not implemented
          return_url: `${window.location.origin}${returnUrlPath}`,
          payment_method_data: {
            billing_details: {
              email: billingContact.email,
              name: billingContact.fullName,
              phone: billingContact.phone,
              address: {
                city: billingContact.city,
                country: billingContact.country,
                line1: billingContact.line1,
                line2: billingContact.line2,
                postal_code: billingContact.postalCode,
                state: billingContact.state,
              },
            },
          },
        },
        redirect: 'if_required',
      });
    },
    ...options,
  });
}

export function useCreatePaymentMethodForQuoteShare(
  options: Partial<
    UseMutationOptions<
      IPaymentMethodResp,
      unknown,
      {
        authParams: RecordShareBaseParams;
        requestData: QuoteSharePaymentMethodRequest;
      }
    >
  > = {},
) {
  const { mutateAsync: savePaymentMethodToStripe } =
    useSavePaymentMethodOnStripe();

  return useMutation<
    IPaymentMethodResp,
    unknown,
    {
      stripe: Stripe;
      elements: StripeElements;
      authParams: RecordShareBaseParams;
      requestData: QuoteSharePaymentMethodRequest;
    }
  >({
    mutationFn: async ({ authParams, requestData, stripe, elements }) => {
      const { recordId, tenantId, token } = authParams;

      const { error, setupIntent } = await savePaymentMethodToStripe({
        authParams,
        billingContact: requestData.paymentMethod.billingDetails,
        stripe,
        elements,
      });

      if (error) {
        logger.error('Error saving payment method to stripe', error);
        throw new Error(error.message);
      }

      if (
        setupIntent.status === 'requires_action' ||
        setupIntent.status === 'requires_confirmation'
      ) {
        // TODO: handle these cases
      }

      requestData.paymentMethod.paymentToken = setupIntent.id;

      // Save Payment Method
      return apiPost<IPaymentMethodResp>(
        `/api/share/quotes/${recordId}/paymentMethods`,
        requestData,
        {
          params: { token },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export function useAcceptQuoteForQuoteShare(
  options: Partial<
    UseMutationOptions<
      QuoteShareResponse,
      unknown,
      RecordShareBaseParams & { paymentMethodId?: Maybe<string> }
    >
  > = {},
) {
  return useMutation<
    QuoteShareResponse,
    unknown,
    RecordShareBaseParams & { paymentMethodId?: Maybe<string> }
  >({
    mutationFn: async ({ recordId, tenantId, token, paymentMethodId }) => {
      // accept quote
      return apiPost<QuoteShareResponse>(
        `/api/share/quotes/${recordId}/acceptAndProcess`,
        null,
        {
          params: { token, paymentMethodId },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    ...options,
  });
}

export function useAcceptAndProcessQuoteForQuoteShare(
  options: Partial<
    UseMutationOptions<
      QuoteShareResponse,
      unknown,
      {
        authParams: RecordShareBaseParams;
        quoteShareData: QuoteShareResponse;
        data: QuoteShareForm;
        stripe?: Maybe<Stripe>;
        elements?: Maybe<StripeElements>;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { mutateAsync: createOrUpdateContacts } =
    useCreateOrUpdateContactsForQuoteShare();
  const { mutateAsync: updatePoNumber } = useUpdatePoNumberOnQuoteShare();
  const { mutateAsync: createPaymentMethod } =
    useCreatePaymentMethodForQuoteShare();
  const { mutateAsync: acceptAndProcessQuote } = useAcceptQuoteForQuoteShare();

  return useMutation<
    QuoteShareResponse,
    unknown,
    {
      authParams: RecordShareBaseParams;
      quoteShareData: QuoteShareResponse;
      data: QuoteShareForm;
      stripe?: Maybe<Stripe>;
      elements?: Maybe<StripeElements>;
    }
  >({
    mutationFn: async ({
      authParams,
      data,
      quoteShareData,
      stripe,
      elements,
    }) => {
      // TODO: handle partial failure cases
      // Update is only performed if required
      await createOrUpdateContacts({ authParams, data, quoteShareData });

      if (
        data.purchaseOrderNumber !== quoteShareData.quote.purchaseOrderNumber
      ) {
        await updatePoNumber({
          ...authParams,
          purchaseOrderNumber: data.purchaseOrderNumber ?? null,
        });
      }

      if (
        data.payment.paymentGatewayId &&
        data.payment.paymentMethod &&
        data.payment.paymentMethodType === 'new' &&
        data.payment.paymentMethod.paymentToken &&
        stripe &&
        elements
      ) {
        await createPaymentMethod({
          authParams,
          requestData: {
            paymentGatewayId: data.payment.paymentGatewayId,
            paymentMethod: data.payment.paymentMethod,
          },
          stripe,
          elements,
        });
      }

      const quoteResponse = await acceptAndProcessQuote({
        ...authParams,
        paymentMethodId:
          data.payment.paymentMethodType === 'existing'
            ? data.payment.paymentMethodId
            : null,
      });
      return quoteResponse;
    },
    ...options,
    onSuccess: (data, variables, context) => {
      const {
        authParams: { recordId, tenantId, token },
      } = variables;
      queryClient.invalidateQueries({
        queryKey: [...shareQueryKeys.quotePdf(recordId), tenantId, token],
      });

      queryClient.setQueryData(
        [...shareQueryKeys.quote(recordId), tenantId, token],
        data,
      );

      options?.onSuccess?.(data, variables, context);
    },
  });
}
