import { zodResolver } from '@hookform/resolvers/zod';
import {
  FunctionComponent as FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useDropzone } from 'react-dropzone';
import { MdInsertDriveFile } from 'react-icons/md';
import * as z from 'zod';
import { handleApiErrorToast } from '~app/api/axios';
import { MFileDragDropUpload } from '~app/components/Monetize/MFileDragDropUpload';
import { useQuote, useQuoteSettings } from '~app/hooks';
import { QuoteAcceptanceModeEnum, QuoteStatusEnum } from '~app/types';
import {
  MAlert,
  MBox,
  MButton,
  MCenterModal,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MLink,
  MStack,
  MText,
  MTextarea,
} from '~components/Monetize';

export const ManualAcceptanceSchema = z.object({
  manualAcceptanceReason: z
    .string()
    .nonempty('Please provide a reason to manually accept this quote.'),
});

type IManualAcceptanceSchema = z.infer<typeof ManualAcceptanceSchema>;

interface ManualQuoteAcceptanceModalProps {
  isOpen: boolean;
  quoteId: string;
  onClose: () => void;
}

const formDefault: IManualAcceptanceSchema = {
  manualAcceptanceReason: '',
};

export const ManualQuoteAcceptanceModal: FC<ManualQuoteAcceptanceModalProps> = (
  props: ManualQuoteAcceptanceModalProps,
) => {
  const { isOpen, onClose, quoteId } = props;
  const [signedQuote, setSignedQuote] = useState<File | null>(null);
  const { transitionQuoteStatus } = useQuote();
  const { quoteSettings } = useQuoteSettings();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid, isSubmitting },
    reset,
  } = useForm<IManualAcceptanceSchema>({
    resolver: zodResolver(ManualAcceptanceSchema),
    mode: 'onChange',
    defaultValues: formDefault,
  });

  useEffect(() => {
    reset(formDefault);
    setSignedQuote(null);
  }, [reset, isOpen]);

  const onSubmit = async (requestData: IManualAcceptanceSchema) => {
    const data = new FormData();
    data.append(
      'metadata',
      new Blob(
        [
          JSON.stringify({
            acceptanceReason: requestData.manualAcceptanceReason,
            mode: quoteSettings?.pauseAtAccepted
              ? QuoteAcceptanceModeEnum.PAUSE_AT_ACCEPTED
              : QuoteAcceptanceModeEnum.PROCESS_AUTOMATICALLY,
          }),
        ],
        {
          type: 'application/json',
        },
      ),
    );

    if (signedQuote) {
      data.append('signedQuote', signedQuote);
    }

    try {
      await transitionQuoteStatus(quoteId, {
        newState: QuoteStatusEnum.ACCEPTED,
        extraData: data,
      });
      onClose();
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  const onDropAccepted = useCallback((acceptedFiles: File[]) => {
    setSignedQuote(acceptedFiles[0]);
  }, []);

  const { open } = useDropzone({
    accept: { 'application/pdf': [] },
    multiple: false,
    onDropAccepted,
  });

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={`Reason for Manually Accepting`}
      size="md"
      modalHeaderProps={{
        paddingBottom: 0,
      }}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isLoading={isSubmitting}
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid || isSubmitting}
            data-testid="manually-accept-modal-btn"
            minW="auto"
            type="submit"
          >
            Accept
          </MButton>
        </MStack>
      )}
    >
      <MBox borderRadius={3}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={1}>
            <MGridItem colSpan={12}>
              <MText color="tGray.darkPurple" mb="2">
                To manually accept this quote, please provide a reason. You can
                also upload a signed quote.
              </MText>
              <MFormField
                error={errors?.manualAcceptanceReason}
                label="Reason"
                isRequired
              >
                <Controller
                  name="manualAcceptanceReason"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MTextarea
                      {...field}
                      data-testid="manual-acceptance-reason"
                      isDisabled={isSubmitting}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </form>
      </MBox>
      {signedQuote ? (
        <MBox mt={1}>
          <MFlex align="center" p={1}>
            <MIcon as={MdInsertDriveFile} w={6} h={6} />
            <MText ml={1}>{signedQuote.name}</MText>
            <MLink ml={2} textDecor="underline" fontSize="sm" onClick={open}>
              Change
            </MLink>
          </MFlex>
          <MAlert
            type="info"
            mt={2}
            mb={2}
            borderRadius={4}
            bgColor="tBlue.hover"
          >
            <MText size="md">
              You cannot change this document once it's uploaded.
            </MText>
          </MAlert>
        </MBox>
      ) : (
        <MFileDragDropUpload
          p={5}
          mt={4}
          accept={['pdf']}
          label="Drag and Drop your quote file here"
          onFileUpload={setSignedQuote}
          maxFileSize={10}
        />
      )}
    </MCenterModal>
  );
};
