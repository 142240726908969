/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { ENVIRONMENT } from '~app/config';
import { logger } from '~app/services/logger';

type ReactQueryDevtools = typeof import('@tanstack/react-query-devtools');

/**
 * React Query Devtools was not working with the standard way of importing
 * This component lazily imports the library for localhost development.
 *
 * This ensures the library will not be included in the production bundle.
 */
export const ReactQueryDevtoolsWrapper = () => {
  const [ReactQueryDevtools, setReactQueryDevtools] =
    useState<ReactQueryDevtools | null>(null);

  // Uses lazy import for code splitting and only imports module in local development
  useEffect(() => {
    (async () => {
      try {
        // Enable for localhost except for integration tests (Playwright)
        if (ENVIRONMENT.IS_LOCAL_DEV) {
          // @ts-ignore
          const devtoolsModule: ReactQueryDevtools = await import(
            '@tanstack/react-query-devtools/build/modern/production.js'
          );
          setReactQueryDevtools(devtoolsModule);
        }
      } catch (ex) {
        logger.warn('Failed to load React Query Devtools', ex);
      }
    })();
  }, []);

  if (!ReactQueryDevtools) {
    return null;
  }

  return (
    <ReactQueryDevtools.ReactQueryDevtools
      initialIsOpen={false}
      buttonPosition="bottom-right"
    />
  );
};
