import { BoxProps, ListItem, UnorderedList } from '@chakra-ui/react';
import { FC } from 'react';
import { useGetQuoteDeltaview } from '../../api/cpqService';
import { getExplanationFromDeltaView } from '../../routes/Quotes/Quote/quoteUtils';
import { IQuoteRespSchema, IQuoteTemplateConfigSchema } from '../../types';
import { MBox, MText } from '../Monetize';

export interface DeltaviewFailureExplanationProps extends BoxProps {
  quote: IQuoteRespSchema;
  displayConfig: IQuoteTemplateConfigSchema | null | undefined;
}

export const DeltaviewFailureExplanation: FC<
  DeltaviewFailureExplanationProps
> = ({ quote, displayConfig, ...rest }: DeltaviewFailureExplanationProps) => {
  const { data: explanations, isLoading } = useGetQuoteDeltaview(quote, {
    enabled: !!quote.id && displayConfig?.showDeltaViewOnAmendments,
    select: (data) => {
      return getExplanationFromDeltaView(quote, data);
    },
  });

  if (
    !displayConfig?.showDeltaViewOnAmendments ||
    isLoading ||
    !explanations?.length
  ) {
    return null;
  }

  return (
    <MBox bg="#EAECF980" w="100%" mb="6" mt="2" p="4" {...rest}>
      <MText textAlign="center" fontWeight="bold" fontSize="md" mb="2">
        AMENDMENT CHANGES
      </MText>

      <MText mr="2">
        Differences from the Prior Contract could not be determined due to
        complex adjustments made to the following offering(s):{' '}
      </MText>
      <UnorderedList>
        {explanations.map((explanation, index) => (
          <ListItem key={index}>{explanation}</ListItem>
        ))}
      </UnorderedList>
    </MBox>
  );
};
