import { ColumnProps } from 'primereact/column';
import { useEffect } from 'react';
import { MdLock } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useDeleteWebhook,
  useGetWebHooks,
  useUpdateWebhook,
} from '~app/api/webhooksService';
import {
  MBox,
  MButton,
  MDataTable,
  MIcon,
  MPageContainer,
  MSettingsPageHeader,
  MText,
  MTooltip,
  shouldOpenInNewWindow,
} from '~app/components/Monetize';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { ROUTES } from '~app/constants';
import { useConfirmModal } from '~app/services/confirmModal';
import { useDocumentHead } from '~app/services/documentHead';
import {
  dateTimeBodyTemplate,
  idBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import { IWebhookResSchema } from '~types';

const Webhook = () => {
  const { setDocTitle } = useDocumentHead();
  const navigate = useNavigate();

  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();

  const { data: webhooksList, isLoading: loading } = useGetWebHooks();
  const { mutateAsync: updateWebHookMutate } = useUpdateWebhook();
  const { mutateAsync: deleteWebHookMutate } = useDeleteWebhook();
  const navigateToCreate = () => navigate(ROUTES.SETTINGS_WEBHOOK_NEW);

  const handleDeleteWebhook = (data: IWebhookResSchema) => {
    const onYes = async () => {
      try {
        setModalLoading(true);
        await deleteWebHookMutate(data);
        closeModal();
      } catch (err) {
        handleApiErrorToast(err);
      } finally {
        setModalLoading(false);
      }
    };

    showConfirmModal({
      title: `Are you sure you want to delete "${data.url}"?`,
      description:
        'Once removed, the webhook will no longer be available for use.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
      noBtnProps: {
        variant: 'cancel' as any,
      },
    });
  };

  const lockedBodyTemplate = (rowData: IWebhookResSchema) => {
    if (rowData.locked) {
      return (
        <MTooltip
          label="This webhook is required for an integration and can't be updated"
          placement="bottom-end"
        >
          <MBox display="inline">
            <MIcon as={MdLock} w={3} h={3} color="tPurple.dark" />
          </MBox>
        </MTooltip>
      );
    }
    return null;
  };

  useEffect(() => {
    setDocTitle('Settings', 'Webhooks');
  }, []);

  const statusTemplate = (rowData: IWebhookResSchema) => (
    <MText noOfLines={1}>{rowData.enabled ? 'Active' : 'Inactive'}</MText>
  );

  const actionBodyTemplate = (rowData: IWebhookResSchema) => {
    const { webhookId } = rowData;
    const actions: ActionType[] = [
      {
        title: 'Edit',
        enabled: !rowData.locked,
        action: () => onSelectEdit(webhookId),
      },
      {
        title: 'Enable',
        enabled: !rowData.enabled,
        action: () =>
          updateWebHookMutate({
            webhookId,
            payload: { ...rowData, enabled: true },
          }),
      },
      {
        title: 'Disable',
        color: 'danger',
        enabled: rowData.enabled && !rowData.locked,
        action: () =>
          updateWebHookMutate({
            webhookId,
            payload: { ...rowData, enabled: false },
          }),
      },
      {
        title: 'Delete',
        color: 'danger',
        enabled: !rowData.locked,
        action: () => handleDeleteWebhook(rowData),
      },
    ];

    return <DataTableActions actions={actions} />;
  };

  const onSelectEdit = (id: string, openInNewWindow = false) => {
    openInNewWindow
      ? window.open(ROUTES.getWebhookEditRoute(id), '_blank')
      : navigate(ROUTES.getWebhookEditRoute(id));
  };

  const columns: ColumnProps[] = [
    {
      field: 'locked',
      header: '',
      body: lockedBodyTemplate,
      style: {
        width: '1rem',
        paddingLeft: '6px',
        paddingRight: '0px',
      },
    },
    {
      className: 'overflow-hidden table-cell-md',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IWebhookResSchema>('webhookId'),
    },
    {
      field: 'url',
      header: 'URL',
      body: textBodyTemplate<IWebhookResSchema>('url'),
      style: { maxWidth: '40em' },
    },
    {
      field: 'createdTime',
      header: 'Creation Date',
      body: dateTimeBodyTemplate<IWebhookResSchema>('createdTime'),
      className: 'table-cell-md',
    },
    {
      field: 'enabled',
      header: 'Status',
      body: statusTemplate,
      className: 'table-cell-md',
    },
    {
      body: actionBodyTemplate,
      style: { width: '4em' },
    },
  ];
  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Webhooks">
        {!!webhooksList?.length && (
          <MButton variant="primary" onClick={navigateToCreate}>
            New
          </MButton>
        )}
      </MSettingsPageHeader>

      <MDataTable
        value={webhooksList}
        totalRecords={webhooksList?.length}
        rowHover
        className="p-datatable-responsive"
        loading={loading}
        columns={columns}
        onRowClick={(e) =>
          onSelectEdit(e.data?.webhookId, shouldOpenInNewWindow(e))
        }
        emptyProps={{
          mainMessage: 'Looks like there are no webhooks here.',
          btnLabel: 'New Webhook',
          to: ROUTES.SETTINGS_WEBHOOK_NEW,
        }}
      />
    </MPageContainer>
  );
};

export default Webhook;
