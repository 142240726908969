import { BoxProps, Heading, Tooltip } from '@chakra-ui/react';
import lodashGet from 'lodash/get';
import { Controller, useFormContext } from 'react-hook-form';
import {
  MBox,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
} from '../../components/Monetize';
import {
  countriesWithStates,
  statesByCountry,
} from '../../services/countryState';
import { useFlags } from '../../services/launchDarkly';
import { QuoteShareForm } from '../../types/recordShareTypes';

interface QuoteShareContactProps extends BoxProps {
  label: string;
  formKeyPrefix: 'contacts.billingContact' | 'contacts.shippingContact';
}

export const QuoteShareContact = ({
  label,
  formKeyPrefix,
  ...boxProps
}: QuoteShareContactProps) => {
  const { quoteShareConfig } = useFlags();

  const {
    control,
    setValue,
    watch,
    formState: { errors: rootErrors },
  } = useFormContext<QuoteShareForm>();

  const errors = rootErrors[formKeyPrefix] || {};

  const formCountry = watch(`${formKeyPrefix}.address.country`);
  const states = formCountry ? statesByCountry[formCountry] : [];

  return (
    <MBox {...boxProps}>
      <Heading as="h3" fontSize="sm" mb={2}>
        {label}
      </Heading>
      <MBox display={{ lg: 'flex' }} gap={4}>
        <MGrid
          templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
          gap={4}
        >
          <MGridItem colSpan={1}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.fullName`)}
              label="Name"
              isRequired
            >
              <Controller
                name={`${formKeyPrefix}.fullName`}
                control={control}
                defaultValue=""
                render={({ field }) => <MInput {...field} />}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={1}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.title`)}
              label="Job Title"
            >
              <Controller
                name={`${formKeyPrefix}.title`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput {...rest} value={value || ''} />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={1}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.email`)}
              label="Email"
              isRequired
            >
              <Controller
                name={`${formKeyPrefix}.email`}
                control={control}
                defaultValue=""
                render={({ field }) => <MInput {...field} />}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={1}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.phone`)}
              label="Phone"
            >
              <Controller
                name={`${formKeyPrefix}.phone`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} />
                )}
              />
            </MFormField>
          </MGridItem>
        </MGrid>

        <MGrid templateColumns="repeat(12, 1fr)" gap={4} mt={{ sm: 4, md: 0 }}>
          <MGridItem colSpan={{ base: 12, md: 6 }}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.address.line1`)}
              label="Address Line 1"
              isRequired={quoteShareConfig?.contact?.requireAddress}
            >
              <Controller
                name={`${formKeyPrefix}.address.line1`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} maxLength={40} />
                )}
              />
            </MFormField>
          </MGridItem>
          <MGridItem colSpan={{ base: 12, md: 6 }}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.address.line2`)}
              label="Address Line 2"
            >
              <Controller
                name={`${formKeyPrefix}.address.line2`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} maxLength={40} />
                )}
              />
            </MFormField>
          </MGridItem>
          <MGridItem colSpan={{ base: 6, sm: 4, md: 3 }}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.address.city`)}
              label="City"
              isRequired={quoteShareConfig?.contact?.requireAddress}
            >
              <Controller
                name={`${formKeyPrefix}.address.city`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} />
                )}
              />
            </MFormField>
          </MGridItem>
          <Tooltip
            isDisabled={!!formCountry}
            label="Choose a country to see available states"
          >
            <MGridItem colSpan={{ base: 6, sm: 4, md: 3 }}>
              <MFormField
                error={lodashGet(errors, `${formKeyPrefix}.address.state`)}
                label="State"
                isRequired={quoteShareConfig?.contact?.requireAddress}
              >
                <Controller
                  name={`${formKeyPrefix}.address.state`}
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...rest } }) => (
                    <MCustomSelect
                      showQueryInput
                      items={states}
                      itemTitle="name"
                      itemValue="isoCode"
                      value={value || ''}
                      clearable
                      useRawValue
                      {...rest}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </Tooltip>
          <MGridItem colSpan={{ base: 12, sm: 4, md: 3 }}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.address.postalCode`)}
              label="Zip"
              isRequired={quoteShareConfig?.contact?.requireAddress}
            >
              <Controller
                name={`${formKeyPrefix}.address.postalCode`}
                control={control}
                defaultValue=""
                render={({ field: { value, ...rest } }) => (
                  <MInput value={value || ''} {...rest} />
                )}
              />
            </MFormField>
          </MGridItem>
          <MGridItem colSpan={{ base: 12, md: 3 }}>
            <MFormField
              error={lodashGet(errors, `${formKeyPrefix}.address.country`)}
              label="Country"
              isRequired={quoteShareConfig?.contact?.requireAddress}
            >
              <Controller
                name={`${formKeyPrefix}.address.country`}
                control={control}
                defaultValue=""
                render={({ field: { value, onChange, ...rest } }) => (
                  <MCustomSelect
                    showQueryInput
                    items={countriesWithStates}
                    itemTitle="name"
                    itemValue="isoCode"
                    value={value}
                    onChange={(ev) => {
                      setValue(`${formKeyPrefix}.address.state`, '');
                      onChange(ev);
                    }}
                    useRawValue
                    {...rest}
                  />
                )}
              />
            </MFormField>
          </MGridItem>
        </MGrid>
      </MBox>
    </MBox>
  );
};
