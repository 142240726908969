import isEmpty from 'lodash/isEmpty';
import {
  useGetCrmFields,
  useGetMonetizeNowCustomFields,
  useGetUserDefinedCrmFieldMappings,
} from '~app/api/fieldMappingService';
import { MFlex, MSkeleton, MText } from '~app/components/Monetize';
import {
  mapExistingFieldMapping,
  mapMatchingSalesforceFields,
} from '~app/utils/crmFieldMapping';
import { CrmFieldMappingAccordion } from '../fieldmapping/CrmFieldMappingAccordion';

export const SalesforceFieldMapping = () => {
  const { data: salesforceFields, isLoading: isSalesforceFieldLoading } =
    useGetCrmFields('salesforce');
  const { monetizeNowFields, isLoading: isMnFieldLoading } =
    useGetMonetizeNowCustomFields('salesforce');

  const {
    data: userDefinedFieldMappings,
    isLoading: isUserDefinedFieldMappingLoading,
  } = useGetUserDefinedCrmFieldMappings('salesforce', {
    enabled: !isEmpty(salesforceFields) && !isEmpty(monetizeNowFields),
  });

  const { accountToAccount, contactToContact, opportunityToQuote } =
    mapMatchingSalesforceFields(salesforceFields, monetizeNowFields);

  const {
    userDefinedUOpportunityToQuote,
    userDefinedUContactToContact,
    userDefinedUAccountToAccount,
  } = mapExistingFieldMapping(
    userDefinedFieldMappings,
    salesforceFields,
    monetizeNowFields,
  );

  const isLoading =
    isSalesforceFieldLoading ||
    isMnFieldLoading ||
    isUserDefinedFieldMappingLoading;

  const renderAccordionItems = (
    <>
      {!isEmpty(accountToAccount) && (
        <CrmFieldMappingAccordion
          system="salesforce"
          crmObjectType="Account"
          mnObjectType="Account"
          matchedFields={accountToAccount}
          userDefinedFields={userDefinedUAccountToAccount}
        />
      )}
      {!isEmpty(contactToContact) && (
        <CrmFieldMappingAccordion
          system="salesforce"
          crmObjectType="Contact"
          mnObjectType="Contact"
          matchedFields={contactToContact}
          userDefinedFields={userDefinedUContactToContact}
        />
      )}
      {!isEmpty(opportunityToQuote) && (
        <CrmFieldMappingAccordion
          system="salesforce"
          crmObjectType="Opportunity"
          mnObjectType="Quote"
          matchedFields={opportunityToQuote}
          userDefinedFields={userDefinedUOpportunityToQuote}
        />
      )}
    </>
  );
  return (
    <MFlex gap="4" direction="column">
      <MFlex color="tPurple.dark" direction="inherit" gap="2">
        <MText fontWeight="semibold">Salesforce Data Mapping</MText>
        <MText>
          There are some Salesforce data points that are equivalent to
          MonetizeNow data points. You can map the data from Salesforce to
          MonetizeNow for accurate importing.
        </MText>
      </MFlex>
      <MFlex direction="inherit" gap="4">
        {isLoading ? (
          <>
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
            <MSkeleton height={12} w="full" />
          </>
        ) : (
          renderAccordionItems
        )}
      </MFlex>
    </MFlex>
  );
};
