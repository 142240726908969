import {
  CheckboxProps,
  Link,
  ResponsiveValue,
  TextProps,
} from '@chakra-ui/react';
import { CellContext, ColumnDef, ColumnMeta } from '@tanstack/react-table';
import lodashGet from 'lodash/get';
import isDate from 'lodash/isDate';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import { ReactNode } from 'react';
import {
  MBox,
  MCheckbox,
  MFlex,
  MIDCopyBox,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { logger } from '~app/services/logger';
import { flattenObject } from '../../../api/utils';
import {
  CustomFieldTypeEnum,
  IAddressSchema,
  IContactRequestSchema,
  IContactRespSchema,
  ICustomFieldResSchema,
  Maybe,
} from '../../../types';
import { formatCurrency } from '../../../utils';
import { getAddress } from '../../../utils/address';
import { toDateShort, toDateTimeShort } from '../../../utils/dates';
import { orderObjectsBy } from '../../../utils/misc';
import {
  booleanFilterFn,
  dateFilterFn,
  selectionFilterFn,
  setFilterFn,
} from './dataTableFilters/dataTableFilterFns';

interface TemplateOptions<TData = unknown, ExtraProps = TextProps> {
  noOfLines?: ResponsiveValue<number>;
  /**
   * Optional extra properties to pass to the component
   */
  extraProps?: ExtraProps;
  /**
   * Value to use if the data[property] is null/undefined
   */
  fallback?: ReactNode;
  tooltipProperty?: keyof TData;
}

interface TemplateOptionsWithChildren<ExtraProps = TextProps>
  extends TemplateOptions<ExtraProps> {
  children?: (data: any) => ReactNode;
}

interface TemplateOptionsEnumDisplay<TData = unknown, ExtraProps = TextProps>
  extends TemplateOptions<TData, ExtraProps> {
  displayMap: { [key: string]: string };
  styleMap?: { [key: string]: string };
}

interface TemplateOptionsNameAndId<TData = unknown, ExtraProps = TextProps>
  extends TemplateOptions<TData, ExtraProps> {
  idProp: keyof TData;
  nameProp: keyof TData;
  /** If provided, the id will be a clickable link */
  idLinkFn?: (id: string, record: TData) => string;
}

interface TemplateOptionsCurrency<TData = unknown, ExtraProps = TextProps>
  extends TemplateOptions<TData, ExtraProps> {
  currencyProperty?: keyof TData;
  currencyFallback?: string;
}

interface TemplateOptionsPeriod<TData = unknown, ExtraProps = TextProps>
  extends TemplateOptions<TData, ExtraProps> {
  endPeriodProperty?: keyof TData;
}

interface TemplateOptionsContactAddress<TData = unknown, ExtraProps = TextProps>
  extends TemplateOptions<TData, ExtraProps> {
  contactProp: keyof TData;
}

interface ColumnSizeOptions {
  /**
   * @default 150
   */
  min?: number;
  /**
   * @default 300
   */
  max?: number;
  /**
   * Default multiplier for column size - this is multiplied by number of characters in label
   * @default 9
   */
  multiplier?: number;
  /**
   * Default offset accounts for sorting and filtering icons
   * set to 0 if column does not allow sorting adn filtering
   * @default 25
   */
  offset?: number;
}

export type TableFilterMap = Array<{
  label: string;
  value: string | number | boolean;
}>;

export type TableData<T extends object> = T & {
  _textFilter?: string;
};

export const BLANK_TABLE_FILTER_KEY = '__BLANK__';

/**
 * Returns a header label and size/minSize for a column based on header length or provided options
 * Should be called and de-structured on a column definition
 *
 * @param headerLabel
 * @param fixedSizeOrOptions
 * @returns
 */
export function getColHeaderWithSize(
  headerLabel: string,
  fixedSizeOrOptions?: number | ColumnSizeOptions,
) {
  if (typeof fixedSizeOrOptions === 'number') {
    return {
      header: headerLabel,
      size: fixedSizeOrOptions,
      minSize: fixedSizeOrOptions,
    };
  }
  const size = getTableColumnSize(headerLabel, fixedSizeOrOptions);
  return {
    header: headerLabel,
    size: getTableColumnSize(headerLabel, fixedSizeOrOptions),
    minSize: size,
  };
}

/**
 * Helper function to set up column filters based on a specific filterVariant
 * Should be called and de-structured on a column definition
 */
export function getColHeaderFilters<TData>(
  filterVariant: Exclude<
    ColumnMeta<TData, unknown>['filterVariant'],
    undefined
  >,
  meta?: ColumnMeta<TData, unknown>,
): Pick<ColumnDef<any, any>, 'enableColumnFilter' | 'filterFn' | 'meta'> {
  switch (filterVariant) {
    case 'BOOLEAN':
      return {
        enableColumnFilter: true,
        filterFn: booleanFilterFn,
        meta: { filterVariant: 'BOOLEAN', ...meta },
      };
    case 'DATE':
      return {
        enableColumnFilter: true,
        filterFn: dateFilterFn,
        meta: { filterVariant: 'DATE', ...meta },
      };
    case 'SET':
      return {
        enableColumnFilter: true,
        filterFn: setFilterFn,
        meta: { filterVariant: 'SET', ...meta },
      };
    case 'SELECTION':
      return {
        enableColumnFilter: true,
        filterFn: selectionFilterFn,
        meta: { filterVariant: 'SELECTION', ...meta },
      };
    default:
      return {};
  }
}

/**
 * Returns a size for a column based on the length of the label
 */
export function getTableColumnSize(
  label: string,
  { min, max, multiplier, offset }: ColumnSizeOptions = {},
) {
  min = min ?? 150;
  max = max ?? 300;
  multiplier = multiplier ?? 9;
  offset = offset ?? 25;
  return Math.min(max, Math.max(min, label.length * multiplier + offset));
}

/**
 * Helper function for adding custom fields to table
 */
export function getCustomFieldsColumnGroup({
  id,
  header,
  accessorPathPrefix = 'customFields',
  customFieldMetadata,
}: {
  id: string;
  header: string;
  accessorPathPrefix?: string;
  customFieldMetadata: ICustomFieldResSchema[];
}): ColumnDef<any, any> {
  return {
    id,
    header,
    columns: orderObjectsBy(customFieldMetadata, ['displayLabel']).map(
      (field, i): ColumnDef<any, any> => {
        let headerFilterFn: Parameters<typeof getColHeaderFilters>[0] = 'SET';
        let cell = textBodyTemplate();
        switch (field.type) {
          case CustomFieldTypeEnum.CHECKBOX:
            headerFilterFn = 'BOOLEAN';
            cell = checkboxBodyTemplate();
            break;
          case CustomFieldTypeEnum.DATE:
            headerFilterFn = 'DATE';
            cell = dateBodyTemplate();
            break;
          case CustomFieldTypeEnum.NUMBER:
          case CustomFieldTypeEnum.SINGLE_LINE_TEXT:
          case CustomFieldTypeEnum.DROPDOWN:
          default:
            headerFilterFn = 'SET';
            break;
        }

        return {
          id: `${accessorPathPrefix}.${field.key}`,
          ...getColHeaderWithSize(field.displayLabel, {
            min: i === 0 ? 175 : 200,
          }),
          accessorFn: (originalRow) =>
            lodashGet(originalRow, `${accessorPathPrefix}.${field.key}`),
          cell,
          enableSorting: true,
          sortingFn: 'text',
          ...getColHeaderFilters(headerFilterFn),
        };
      },
    ),
  };
}

/**
 * Processes data to optimize for table display
 * * All nested objects are flattened using "." as separator
 * * All text keys are concatenated into a single lowercase string for text search
 * * Filter values are generated for all columns with unique values to allow an excel-like filter
 *
 * @param data
 * @param filterDataDisplayMap
 * @param textSearchKeys
 * @returns
 */
export function prepareTableData<T extends object>(
  data: T[],
  filterDataDisplayMap: { [key: string]: { [value: string]: string } } = {},
  textSearchKeys: string[] = [],
): {
  filterValues: Map<string, TableFilterMap>;
  data: TableData<T>[];
} {
  const filterValuesTemp = new Map<string, Set<string | number | boolean>>();
  const filterValues = new Map<string, TableFilterMap>();

  const enrichedData = data.map((originalRow) => {
    const row = flattenObject(originalRow);
    const output: TableData<T> = {
      ...originalRow,
      // TODO: will be used for global search
      // _textFilter: Array.from(
      //   new Set(
      //     textSearchKeys.map((key) =>
      //       String(lodashGet(row, key, '') ?? '').toLocaleLowerCase(),
      //     ),
      //   ),
      // ).join(''),
    };

    Object.keys(row).forEach((key) => {
      const value = lodashGet(row, key);

      if (!filterValuesTemp.has(key)) {
        filterValuesTemp.set(key, new Set());
      }

      if (
        typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean'
        // TODO: other data types?
      ) {
        filterValuesTemp.get(key)?.add(value);
      } else if (value === null) {
        filterValuesTemp.get(key)?.add(BLANK_TABLE_FILTER_KEY);
      }
    });
    return output;
  });

  Array.from(filterValuesTemp.keys()).forEach((key) => {
    const values = filterValuesTemp.get(key);
    if (values) {
      filterValues.set(
        key,
        orderObjectsBy(
          Array.from(values).map((value) => {
            if (value === BLANK_TABLE_FILTER_KEY) {
              return {
                label: '(Blanks)',
                value: BLANK_TABLE_FILTER_KEY,
              };
            }
            return {
              label:
                filterDataDisplayMap[key]?.[String(value)] ?? String(value),
              value,
            };
          }),
          ['label'],
        ),
      );
    }
  });

  return {
    filterValues,
    data: enrichedData,
  };
}

/**
 * CUSTOM FILTER FUNCTIONS
 *
 * ONLY APPLIES TO BROWSER SIDE FILTERING
 *
 * Used if the built-ins do not meet the requirements
 * {@link https://tanstack.com/table/latest/docs/guide/column-filtering#filterfns}
 */

/**
 * Filter for SELECTION filter variant
 * Filter for the checkbox selection column
 */
// export const selectionFilterFn = (
//   row: Row<unknown>,
//   columnId: string,
//   filterValue: SelectionOption,
//   addMeta: (meta: any) => void,
// ) => {
//   if (!filterValue || filterValue === 'ALL') {
//     return true;
//   }
//   const isSelected = row.getIsSelected();
//   if (
//     (filterValue === 'SELECTED' && isSelected) ||
//     (filterValue === 'UNSELECTED' && !isSelected)
//   ) {
//     return true;
//   }
//   return false;
// };

/**
 * Filter for SET filter variant
 */
// export const setFilterFn = (
//   row: Row<unknown>,
//   columnId: string,
//   filterValue: Set<any>,
//   addMeta: (meta: any) => void,
// ) => {
//   if (!filterValue || !filterValue.size) {
//     return true;
//   }
//   const value = row.getValue<any>(columnId) ?? BLANK_TABLE_FILTER_KEY;
//   return filterValue.has(value);
// };

// /**
//  * Filter for DATE filter variant
//  * Any datetime value is converted to a date string before comparison
//  */
// export const dateFilterFn = (
//   row: Row<unknown>,
//   columnId: string,
//   filterValue: DateFilterData,
//   addMeta: (meta: any) => void,
// ) => {
//   const parseResults = DateFilterDataSchema.safeParse(filterValue);
//   if (!parseResults.success) {
//     return true;
//   }
//   let value = row.getValue<string | null>(columnId) ?? null;
//   const { comparison, firstDate, secondDate } = parseResults.data;

//   if (!firstDate || (isBetweenComparison(comparison) && !secondDate)) {
//     return true;
//   }

//   if (!value) {
//     return false;
//   }
//   // strip time to allow for proper comparison with datetime
//   value = value.substring(0, 10);

//   switch (comparison) {
//     case 'EQUALS':
//       return value === firstDate;
//     case 'GREATER_THAN':
//       return value > firstDate;
//     case 'GREATER_THAN_EQUAL':
//       return value >= firstDate;
//     case 'LESS_THAN':
//       return value < firstDate;
//     case 'LESS_THAN_EQUAL':
//       return value <= firstDate;
//     case 'BETWEEN_EXCLUSIVE': {
//       if (!secondDate) {
//         return true;
//       }
//       return value > firstDate && value < secondDate;
//     }
//     case 'BETWEEN_INCLUSIVE': {
//       if (!secondDate) {
//         return true;
//       }
//       return value >= firstDate && value <= secondDate;
//     }
//     default:
//       return true;
//   }
// };

/**
 * CELL TEMPLATE RENDERERS
 */

export function idBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  noOfLines = 1,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        (isString(value) && (
          <MIDCopyBox copyValue={value} displayIcon={false} {...extraProps} />
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function idWithExtraBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  noOfLines = 1,
  children,
}: TemplateOptionsWithChildren = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        (isString(value) && (
          <MFlex alignItems="center" columnGap={2} {...extraProps}>
            <MIDCopyBox copyValue={value} displayIcon={false} />
            {children?.(data)}
          </MFlex>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function textBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  noOfLines = 3,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        (isString(value) && (
          <MText noOfLines={noOfLines} {...extraProps}>
            {value}
          </MText>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function checkboxBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
}: { fallback?: boolean; extraProps?: CheckboxProps } = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = !!(data.getValue() ?? fallback);
    try {
      return (
        <MCheckbox isChecked={value} isDisabled isReadOnly {...extraProps} />
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function contactBodyTemplate<TData>({
  extraProps,
  fallback,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, IContactRequestSchema>) => {
    try {
      const value = data.getValue();
      if (!value) {
        return fallback;
      }
      const { fullName, email, phone } = value;

      return (
        <MBox {...extraProps}>
          <MText noOfLines={3} fontWeight={600}>
            {fullName}
          </MText>
          <MText noOfLines={2}>{email}</MText>
          {phone && <MText noOfLines={2}>{phone}</MText>}
        </MBox>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function emailListBodyTemplate<TData>({
  extraProps,
  fallback,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, string[]>) => {
    try {
      const value = data.getValue() || [];
      if (!Array.isArray(value)) {
        return fallback;
      }

      return (
        <MText noOfLines={5} fontWeight={600}>
          {value.join(', ')}
        </MText>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function addressWithContactBodyTemplate<TData>({
  contactProp,
  extraProps,
  fallback,
}: TemplateOptionsContactAddress<TData>) {
  return (data: CellContext<TData, IAddressSchema>) => {
    try {
      const value = data.getValue();
      const contact =
        (data.row.original[contactProp] as IContactRespSchema) || undefined;
      if (!value && !contact) {
        return fallback;
      }

      const address = getAddress(value);

      return (
        <MBox {...extraProps}>
          {contact.fullName && <MText noOfLines={1}>{contact.fullName}</MText>}
          {contact.email && (
            <MText noOfLines={1} mb={1}>
              {contact.email}
            </MText>
          )}
          <MText noOfLines={1}>{address.addressLine1}</MText>
          <MText noOfLines={1}>{address.addressLine1}</MText>
          {address.addressLine2 && (
            <MText noOfLines={1}>{address.addressLine2}</MText>
          )}
          {address.otherAddress && (
            <MText noOfLines={1}>{address.otherAddress}</MText>
          )}
        </MBox>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function addressBodyTemplate<TData>({
  extraProps,
  fallback,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, IAddressSchema>) => {
    try {
      const value = data.getValue();
      if (!value) {
        return fallback;
      }

      const address = getAddress(value);

      return (
        <MBox {...extraProps}>
          <MText noOfLines={1}>{address.addressLine1}</MText>
          {address.addressLine2 && (
            <MText noOfLines={1}>{address.addressLine2}</MText>
          )}
          {address.otherAddress && (
            <MText noOfLines={1}>{address.otherAddress}</MText>
          )}
        </MBox>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

/**
 * Pass in with name property and the id will be obtained
 */
export function nameWithIdBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  idProp,
  nameProp,
  noOfLines = 3,
  idLinkFn,
}: TemplateOptionsNameAndId<TData, TValue>) {
  return (data: CellContext<TData, TValue>) => {
    try {
      const id = lodashGet(data.row.original, idProp);
      const name = lodashGet(data.row.original, nameProp);
      if (!isString(id) && !name) {
        return <MText {...extraProps}>{fallback}</MText>;
      }
      const link =
        idLinkFn && isString(id) ? idLinkFn(id, data.row.original) : undefined;
      return (
        <MBox width="12.5rem" {...extraProps}>
          {isString(name) && (
            <MText fontWeight="500" whiteSpace="normal" noOfLines={noOfLines}>
              {name}
            </MText>
          )}
          {isString(id) &&
            (link ? (
              <Link
                href={link}
                target="_blank"
                fontWeight="400"
                whiteSpace="normal"
                _hover={{ textDecoration: 'underline' }}
                onClick={(ev) => ev.stopPropagation()}
              >
                {id}
              </Link>
            ) : (
              <MText fontWeight="400" whiteSpace="normal">
                {id}
              </MText>
            ))}
        </MBox>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function textWithTooltipBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  tooltipProperty,
  noOfLines = 1,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    const tooltip = (data.row.original as any)[tooltipProperty as any];
    try {
      return (
        (isString(value) && (
          <MTooltip
            label={isString(tooltip) ? tooltip : null}
            placement="bottom-end"
          >
            <MText noOfLines={noOfLines} {...extraProps}>
              {value}
            </MText>
          </MTooltip>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function textWithExtraBodyTemplate<TData, TValue>({
  extraProps,
  fallback,
  noOfLines = 1,
  children,
}: TemplateOptionsWithChildren = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        (isString(value) && (
          <MFlex align="center" columnGap={2} {...extraProps}>
            <MText noOfLines={noOfLines}>{value}</MText>
            {children?.(data)}
          </MFlex>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function currencyBodyTemplate<TData, TValue>({
  extraProps,
  noOfLines = 1,
  currencyProperty,
  currencyFallback = 'USD',
  fallback,
}: TemplateOptionsCurrency<TData, TValue> = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    const currency =
      currencyProperty && isString(data.row.original[currencyProperty])
        ? (data.row.original[currencyProperty] as string)
        : currencyFallback;
    try {
      return (
        ((isString(value) || isNumber(value)) && (
          <MText noOfLines={noOfLines} {...extraProps}>
            {formatCurrency(value, { currency })}
          </MText>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function dateBodyTemplate<TData, TValue>({
  extraProps,
  noOfLines = 1,
  fallback,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        ((isString(value) || isDate(value)) && (
          <MText noOfLines={noOfLines} {...extraProps}>
            {toDateShort(value)}
          </MText>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function dateTimeBodyTemplate<TData, TValue>({
  extraProps,
  noOfLines = 1,
  fallback,
}: TemplateOptions = {}) {
  return (data: CellContext<TData, TValue>) => {
    const value = data.getValue();
    try {
      return (
        ((isString(value) || isDate(value)) && (
          <MText noOfLines={noOfLines} {...extraProps}>
            {toDateTimeShort(value)}
          </MText>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

export function datePeriodBodyTemplate<TData, TValue>({
  extraProps,
  noOfLines = 1,
  fallback,
  endPeriodProperty,
}: TemplateOptionsPeriod<TData, TValue> = {}) {
  return (data: CellContext<TData, TValue>) => {
    const startDate = data.getValue();
    const endDate = (endPeriodProperty &&
      data.row.original[endPeriodProperty]) as Maybe<string | Date>;
    if (!startDate && !endDate) {
      return fallback;
    }
    try {
      const startDateStr =
        isString(startDate) || isDate(startDate)
          ? toDateShort(startDate)
          : null;
      const endDateStr =
        isString(endDate) || isDate(endDate) ? toDateShort(endDate) : null;

      return (
        <MText noOfLines={noOfLines} {...extraProps}>
          {startDateStr}
          {!!startDateStr && !!endDateStr && ' - '}
          {endDateStr}
        </MText>
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}

// TODO: figure out how to strongly type this
export function enumDisplayBodyTemplate<TData, TValue>({
  noOfLines = 1,
  extraProps,
  fallback,
  displayMap,
  styleMap,
}: TemplateOptionsEnumDisplay<TData, TValue>) {
  return (data: CellContext<TData, TValue>) => {
    const rawValue = data.getValue();
    const value = displayMap[rawValue as any];
    const dynamicStyle = styleMap ? styleMap[rawValue as any] : {};
    try {
      return (
        (isString(value) && (
          <MText
            noOfLines={noOfLines}
            {...dynamicStyle}
            {...extraProps}
            maxW="fit-content"
            h="18px"
            lineHeight="18px"
          >
            {value}
          </MText>
        )) ||
        fallback
      );
    } catch (ex) {
      logger.warn('Could not generate template', {
        data,
        columns: data.cell.column.id,
        ex,
      });
    }
  };
}
