import { useDisclosure } from '@chakra-ui/react';
import { ColumnProps } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  doActivateAccount,
  doDeactivateAccount,
  useGetAccountList,
} from '~api/accountsService';
import {
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { AccountStatusDisplayText } from '~app/constants/accounts';
import {
  getAccountDetailRoute,
  getAccountEditModalRoute,
} from '~app/constants/routes';
import { ACLCheck } from '~app/services/acl/ACLCheck';
import { useACL } from '~app/services/acl/acl';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import {
  buildFilterParamsRequestObject,
  getFiltersApplied,
  getIsTrulyEmptyList,
  transformTableFilterValue,
} from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  dateTimeBodyTemplate,
  idBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import {
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MPageHeader,
  MText,
  shouldOpenInNewWindow,
} from '~components/Monetize';
import { useDocumentHead } from '~services/documentHead';
import {
  AccountStatusEnum,
  FilterOptionType,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IAccount,
} from '~types';
import AccountFormModal from './AccountFormModal';

const AccountsTable = () => {
  // START useState
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.ACCOUNT_LIST),
  );
  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [searchKey] = useState<string>('query');
  const [searchTerm, setSearchTerm] = useState<string>(
    searchParams.get('search') ?? '',
  );
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      searchKey,
      'equals',
    ),
  );
  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.ACCOUNT_LIST),
  );
  const { canDo } = useACL();
  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const {
    isLoading,
    isRefetching,
    isFetched,
    data: accountList,
    refetch,
    isError,
    error,
  } = useGetAccountList({
    config: pager,
    filters: filterParams,
    /** If user pressed enter on the table search, we need to use the table search API */
    useSearchApi: !!searchTerm,
  });

  const {
    isOpen: isCreateModalOpen,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();
  // END useState

  useEffect(() => {
    const urlSearchQuery = searchParams.get('search') ?? '';
    setSearchTerm(urlSearchQuery);
    setFilterParams(
      buildFilterParamsRequestObject(
        transformTableFilterValue(filters),
        urlSearchQuery,
        searchKey,
        'equals',
      ),
    );
  }, [pager, searchKey, filters, searchParams]);
  // pager is added here as a dependency because it is used in loadData();

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Accounts');
  }, []);

  const onResetFilter = () => {
    setFilters([]);
  };

  const primaryContactTemplate = (data: IAccount) =>
    data.primaryContact?.fullName && (
      <MText noOfLines={1}>{data.primaryContact.fullName}</MText>
    );

  const handleDeactivateAccount = async (rowData: IAccount) => {
    await doDeactivateAccount(rowData.id);
    refetch(); // TODO: we already have the new data, we can update the cache instead of a full re-fetch
  };
  const handleActivateAccount = async (rowData: IAccount) => {
    await doActivateAccount(rowData.id);
    refetch(); // TODO: we already have the new data, we can update the cache instead of a full re-fetch
  };

  const actionBodyTemplate = (rowData: IAccount) => {
    const { id, accountName, status } = rowData;
    const confirmProps = {
      title: `Are you sure you want to deactivate "${accountName}"?`,
      description: 'Once deactivated, this account will no longer be billed.',
      yesButton: 'Deactivate',
    };
    const actions = [
      {
        title: 'Edit',
        enabled: canDo([['accounts', 'update']]),
        action: () => navigate(getAccountEditModalRoute(id)),
      },
      {
        title: 'Activate',
        enabled:
          canDo([['accounts', 'update']]) &&
          [AccountStatusEnum.INACTIVE, AccountStatusEnum.CANCELED].includes(
            status,
          ),
        action: () => handleActivateAccount(rowData),
      },
      {
        title: 'Deactivate',
        color: 'danger',
        confirmProps: {
          ...confirmProps,
          yesButton: 'Deactivate',
        },
        enabled:
          canDo([['accounts', 'update']]) &&
          status === AccountStatusEnum.ACTIVE,
        action: () => handleDeactivateAccount(rowData),
      },
    ];

    return <DataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'id',
      header: 'ID',
      body: idBodyTemplate<IAccount>('id'),
      style: { width: '10em' },
    },
    {
      field: 'customId',
      header: 'Custom ID',
      sortable: true,
      className: 'table-cell-lg',
      body: textBodyTemplate<IAccount>('customId'),
    },
    {
      className: 'overflow-hidden table-cell-lg',
      field: 'accountName',
      header: 'Name',
      sortable: true,
      body: textBodyTemplate<IAccount>('accountName'),
    },
    {
      field: 'contact',
      header: 'Primary Contact',
      body: primaryContactTemplate,
      className: 'table-cell-lg',
    },
    {
      field: 'createDate',
      header: 'Creation Date',
      body: dateTimeBodyTemplate<IAccount>('createDate'),
      sortable: true,
    },
    {
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IAccount, AccountStatusEnum>(
        'status',
        AccountStatusDisplayText,
      ),
    },
    {
      field: 'defaultCurrency',
      header: 'Currency',
      body: textBodyTemplate<IAccount>('defaultCurrency'),
      sortable: false,
      style: { textAlign: 'right' },
    },
    {
      body: actionBodyTemplate,
      style: { width: '4em' },
    },
  ];

  const statusItems = objectToObjArray(
    AccountStatusDisplayText,
    'value',
    'label',
  );

  const filterOptions: FilterOptionType[] = [
    {
      title: 'Created',
      key: 'createDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Updated',
      key: 'modifyDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading: isLoading,
    totalElements: accountList?.totalElements || 0,
    filters,
    searchTerm,
    page: pager.page,
  });

  return (
    <MPageContainer enableAccountSearch>
      <MPageHeader title="Accounts">
        <MFlex>
          {!isTrulyEmptyList && (
            <>
              <MDataTableFilter
                filters={filters}
                filterOptions={filterOptions}
                setFilters={(f) => setFilters(f)}
                onResetFilter={onResetFilter}
                resetFilter={filterComponentReset}
              />
              <ExportTableButton
                entity="accounts"
                filters={filters}
                searchKey={searchKey}
                searchTerm={searchTerm}
                sortField={pager.sortField}
                sortOrder={pager.sortOrder}
              />
            </>
          )}

          <ACLCheck acls={[['accounts', 'create']]}>
            <MButton
              variant="primary"
              ml="2"
              onClick={onOpenCreateModal}
              data-testid="create-account-btn"
            >
              New
            </MButton>
          </ACLCheck>
        </MFlex>
      </MPageHeader>

      <MDataTable
        value={accountList?.content}
        totalRecords={accountList?.totalElements}
        totalPages={accountList?.totalPages}
        pager={pager}
        setPager={setPager}
        rowHover
        onRowClick={(e) => {
          shouldOpenInNewWindow(e)
            ? window.open(getAccountDetailRoute(e.data.id), '_blank')
            : navigate(getAccountDetailRoute(e.data.id));
        }}
        className="p-datatable-responsive"
        loading={isLoading || (isRefetching && !isFetched)}
        columns={columns}
        filtersApplied={getFiltersApplied(filters) > 0 || !!searchTerm}
        resetFilter={() => {
          filterComponentReset.current && filterComponentReset.current();
          searchComponentReset.current && searchComponentReset.current();
        }}
        emptyProps={{
          mainMessage: 'Looks like there are no accounts here.',
          smallMessage:
            "To get started, you'll need to create an account first.",
          btnLabel: canDo([['accounts', 'create']]) ? 'New Account' : '',
          onClick: onOpenCreateModal,
        }}
        errorProps={{
          isFetchingError: isError,
          error: error,
          onRefetchData: refetch,
        }}
      />

      <AccountFormModal
        isOpen={isCreateModalOpen}
        onClose={onCloseCreateModal}
      />
    </MPageContainer>
  );
};

export default AccountsTable;
