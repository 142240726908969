import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateTeam } from '~app/api/settingsService';
import {
  MButton,
  MCenterModal,
  MFormField,
  MInput,
  MStack,
} from '~app/components/Monetize';
import {
  ITeam,
  ITeamResponseSchema,
  ITeamSchema,
  TeamSchema,
} from '~app/types';
import { handleApiErrorToast } from '../../../../../api/axios';

interface EditTeamNameModalProps {
  isOpen: boolean;
  onClose: () => void;
  team: ITeamResponseSchema;
}

export const EditTeamNameModal = ({
  isOpen,
  onClose,
  team,
}: EditTeamNameModalProps) => {
  const {
    control,
    formState: { isDirty, errors },
    reset,
    handleSubmit,
  } = useForm<ITeamSchema>({
    resolver: zodResolver(TeamSchema),
    mode: 'onChange',
  });

  const { mutate: doUpdateTeam, isPending: updateLoading } = useUpdateTeam({
    teamId: team.id,
  });

  useEffect(() => {
    reset(team);
  }, [team, reset]);

  const onUpdateTeamName = async (submitData: ITeam) => {
    try {
      const { users: members, ...finalData } = submitData;
      const memberIds = members?.map(({ id: memberId }) => memberId) || [];

      await doUpdateTeam({ ...finalData, userIds: memberIds });
      onClose();
    } catch (ex) {
      handleApiErrorToast(ex);
    }
  };

  return (
    <MCenterModal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Edit Team Name"
      modalBodyProps={{ paddingTop: '0px' }}
      modalContentProps={{ 'data-testid': 'editTeam-name-modal' } as any}
      returnFocusOnClose={false}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isDisabled={!isDirty || updateLoading}
            onClick={handleSubmit(onUpdateTeamName)}
            isLoading={updateLoading}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <form onSubmit={handleSubmit(onUpdateTeamName)}>
        <MFormField error={errors.name} isRequired>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <MInput placeholder="Enter Team Name" {...field} />
            )}
          />
        </MFormField>
      </form>
    </MCenterModal>
  );
};
