import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost, apiPut } from '~api/axios';
import { sortAlphabetically } from '~app/utils';
import { IEventCategory, IWebhookReqSchema, IWebhookResSchema } from '~types';
import {
  updateListCacheWithRemovedItem,
  updateListCacheWithUpdatedItem,
} from './queryUtilsHelpers';

export const webhookQueryKeys = {
  base: ['webhook'] as const,
  webhookList: () => [...webhookQueryKeys.base, 'list'] as const,
  webhookDetail: (id: string) => [...webhookQueryKeys.base, id] as const,
  webhookEvents: () => [...webhookQueryKeys.base, 'events'] as const,
};

export function useGetWebHooks(
  options: Partial<UseQueryOptions<IWebhookResSchema[]>> = {},
) {
  return useQuery({
    queryKey: [...webhookQueryKeys.webhookList()],
    queryFn: () =>
      apiGet<IWebhookResSchema[]>('/webhooks/webhooks').then((res) => res.data),
    meta: {
      setByIdCacheFromReturnedList: {
        byIdQueryKey: (id) => webhookQueryKeys.webhookDetail(id),
      },
    },
    ...options,
  });
}

export function useGetWebHookById({ webhookId }: { webhookId: string }) {
  return useQuery({
    queryKey: [...webhookQueryKeys.webhookDetail(webhookId)],
    queryFn: () =>
      apiGet<IWebhookResSchema>(`/webhooks/webhooks/${webhookId}`).then(
        (res) => res.data,
      ),
    enabled: !!webhookId,
  });
}

export function useCreateWebhook(
  options: Partial<
    UseMutationOptions<IWebhookResSchema, unknown, IWebhookReqSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<IWebhookResSchema, unknown, IWebhookReqSchema>({
    mutationFn: (payload) => {
      return apiPost<IWebhookResSchema>(`/webhooks/webhooks`, payload).then(
        (res) => res.data,
      );
    },
    onSuccess: (...data) => {
      queryClient.invalidateQueries({
        queryKey: [...webhookQueryKeys.webhookList()],
      });
      onSuccess && onSuccess(...data);
    },
    ...restOptions,
  });
}

export function useUpdateWebhook(
  options: Partial<
    UseMutationOptions<
      IWebhookResSchema,
      unknown,
      { webhookId: string; payload: IWebhookReqSchema }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<
    IWebhookResSchema,
    unknown,
    { webhookId: string; payload: IWebhookReqSchema }
  >({
    mutationFn: ({ webhookId, payload }) => {
      return apiPut<IWebhookResSchema>(
        `/webhooks/webhooks/${webhookId}`,
        payload,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...webhookQueryKeys.webhookList()],
      });
      queryClient.invalidateQueries({
        queryKey: [...webhookQueryKeys.webhookDetail(variables.webhookId)],
      });
      updateListCacheWithUpdatedItem(
        queryClient,
        [...webhookQueryKeys.webhookDetail(variables.webhookId)],
        data,
      );
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useDeleteWebhook(
  options: Partial<
    UseMutationOptions<IWebhookResSchema, unknown, IWebhookResSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IWebhookResSchema, unknown, IWebhookResSchema>({
    mutationFn: (payload) => {
      return apiDelete<IWebhookResSchema>(
        `/webhooks/webhooks/${payload.webhookId}`,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...webhookQueryKeys.webhookList()],
      });
      updateListCacheWithRemovedItem(
        queryClient,
        [...webhookQueryKeys.webhookDetail(data.webhookId)],
        data.webhookId,
      );
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useGetWebHookEvents() {
  const transformEvents = (events: IEventCategory[]) =>
    events
      .map(({ types, ...rest }) => ({
        types: types.sort(sortAlphabetically('key')),
        ...rest,
      }))
      .sort(sortAlphabetically('category'));

  return useQuery({
    queryKey: [...webhookQueryKeys.webhookEvents()],
    queryFn: () =>
      apiGet<{ events: IEventCategory[] }>(
        `/webhooks/webhooks/webhookEvents`,
      ).then(({ data: { events } }) => transformEvents(events)),
    refetchOnWindowFocus: false,
    // 24 hours
    staleTime: 1000 * 60 * 60 * 24,
  });
}
