import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import isNumber from 'lodash/isNumber';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useCreateInvoicePayment } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAlert,
  MBox,
  MButton,
  MCenterModal,
  MGrid,
  MGridItem,
  MRadio,
  MSpinner,
  MStack,
  MText,
} from '~app/components/Monetize';
import { AddPaymentMethodForm } from '~app/components/PaymentMethods/Form/AddPaymentMethodForm';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';
import { toDateOnly } from '~app/utils/dates';
import { nullifyEmptyStrings } from '~app/utils/misc';
import {
  IInvoiceRespSchema,
  IPaymentMethodResp,
  InvoiceSummaryResp,
  MakePaymentForm,
  MakePaymentFormSchema,
  PaymentMethodStatusEnum,
  PaymentMethodTypeEnum,
} from '~types';
import { accountServiceQueryKeys } from '../../../api/queryKeysService';
import { ACH_OR_BANK_TRANSFER } from '../../../constants/paymentMethods';
import { useFlags } from '../../../services/launchDarkly';
import { formatCurrency } from '../../../utils';
import { usePaymentMethodsData } from '../../AccountDetails/PaymentMethods/PaymentMethodList';
import { CreatePaymentWithManualPayment } from './CreatePaymentWithManualPayment';
import { CreatePaymentWithPaymentMethod } from './CreatePaymentWithPaymentMethod';

type PayInvoiceModalProps = {
  isOpen: boolean;
  invoice: IInvoiceRespSchema | InvoiceSummaryResp;
  onClose: (reload: boolean) => void;
};

export const PayInvoiceModal = ({
  isOpen,
  invoice,
  onClose,
}: PayInvoiceModalProps) => {
  const queryClient = useQueryClient();
  const { allowPartialPayment } = useFlags();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { mutateAsync: doCreateInvoicePayment } = useCreateInvoicePayment();
  const paymentMethodsData = usePaymentMethodsData(invoice?.account?.id ?? '');

  const { activeGateways, isLoading: isGatewayListLoading } =
    useCurrentPaymentGateways();

  const isGatewayAvailable = !!activeGateways.length;

  const {
    isOpen: isPaymentMethodModalOpen,
    onOpen: onOpenPaymentMethodModal,
    onClose: onClosePaymentMethodModal,
  } = useDisclosure();

  // Adjust zod schema to have knowledge of invoice amount due
  const MakePaymentFormSchemaValidator = useMemo(() => {
    return MakePaymentFormSchema.superRefine(({ amount }, ctx) => {
      if (isNumber(amount) && amount > invoice.amountDue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Amount must not exceed invoice amount due of ${formatCurrency(
            invoice.amountDue,
            { currency: invoice.currency },
          )}`,
          path: ['amount'],
        });
      } else if (isNumber(amount) && amount <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Amount must be greater than ${formatCurrency(0, {
            currency: invoice.currency,
          })}`,
          path: ['amount'],
        });
      }
    });
  }, [invoice.amountDue, invoice.currency]);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isValid },
    watch,
  } = useForm<MakePaymentForm>({
    resolver: zodResolver(MakePaymentFormSchemaValidator),
    mode: 'onChange',
    defaultValues: {
      type: 'gateway',
      referenceNo: '',
      amount: invoice.amountDue,
      paymentDate: toDateOnly(new Date(), 'userTimezone'),
      description: '',
    },
  });

  // Ensure form does not use stale data once opened
  useEffect(() => {
    reset({
      type: 'gateway',
      referenceNo: '',
      amount: invoice.amountDue,
      paymentDate: toDateOnly(new Date(), 'userTimezone'),
      description: '',
    });
  }, [isOpen]);

  useEffect(() => {
    if (!isGatewayListLoading && !activeGateways.length) {
      setValue('type', 'manual');
    }
  }, [isGatewayListLoading, activeGateways]);

  const allPaymentMethods = (
    paymentMethodsData?.listData?.content || []
  ).filter(
    (paymentMethod) => paymentMethod.status === PaymentMethodStatusEnum.ACTIVE,
  );

  const paymentMethodType = watch('type');
  const watchPaymentMethodId = watch('paymentMethodId');

  const selectedPaymentMethod = allPaymentMethods.find(
    (paymentMethod) => paymentMethod.id === watchPaymentMethodId,
  );

  const onCloseAddPaymentMethodModal = (
    newPaymentMethod?: IPaymentMethodResp,
  ) => {
    onClosePaymentMethodModal();
    if (newPaymentMethod) {
      setValue('paymentMethodId', newPaymentMethod.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  const onSubmit = async (formData: MakePaymentForm) => {
    setIsSaving(true);
    try {
      if (formData.type === 'gateway') {
        if (!formData.paymentMethodId || !selectedPaymentMethod) {
          return;
        }

        switch (selectedPaymentMethod.paymentMethodType) {
          case PaymentMethodTypeEnum.CREDIT_CARD:
          case PaymentMethodTypeEnum.DIRECT_DEBIT: {
            await doCreateInvoicePayment({
              type: 'electronic',
              accountId: invoice.account.id,
              invoiceId: invoice.id,
              payload: {
                paymentMethodId: formData.paymentMethodId,
                amount: formData.amount,
              },
            });
            break;
          }
          case PaymentMethodTypeEnum.ACH_CREDIT:
          case PaymentMethodTypeEnum.US_BANK_TRANSFER: {
            await doCreateInvoicePayment({
              type: 'achCredit',
              accountId: invoice.account.id,
              invoiceId: invoice.id,
              payload: {
                paymentMethodId: formData.paymentMethodId,
                amount: formData.amount,
              },
            });
            break;
          }
          default:
            throw new Error(
              `Invalid payment method type ${selectedPaymentMethod.paymentMethodType}`,
            );
        }
      } else {
        await doCreateInvoicePayment({
          type: 'manual',
          accountId: invoice.account.id,
          invoiceId: invoice.id,
          payload: nullifyEmptyStrings({
            amount: formData.amount,
            paymentDate: formData.paymentDate,
            referenceNo: formData.referenceNo,
            description: formData.description,
          }),
        });
      }
      queryClient.invalidateQueries({
        queryKey: accountServiceQueryKeys.billGroups.billGroupById(
          invoice.billGroupId,
        ),
      });
      handleClose(true);
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setIsSaving(false);
    }
  };

  function handleClose(reload = false) {
    onClose(reload);
    reset();
  }

  return (
    <MCenterModal
      size="md"
      isOpen={isOpen}
      onClose={handleClose}
      modalTitle="Pay Invoice"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton
            onClick={() => handleClose()}
            variant="cancel"
            minW="auto"
            isDisabled={isSaving}
          >
            Cancel
          </MButton>
          <MButton
            form="payment-form"
            type="submit"
            variant="primary"
            isDisabled={!isValid}
            isLoading={isSaving}
            minW="auto"
          >
            Pay
          </MButton>
        </MStack>
      )}
    >
      <>
        {isGatewayListLoading && (
          <MBox textAlign="center">
            <MSpinner />
          </MBox>
        )}
        {!isGatewayListLoading && (
          <form id="payment-form" onSubmit={handleSubmit(onSubmit)}>
            <MGrid
              alignItems="center"
              templateColumns="repeat(12, 1fr)"
              gap={4}
            >
              <MGridItem colSpan={12}>
                <MRadio
                  onChange={() => {
                    isGatewayAvailable && setValue('type', 'gateway');
                    setValue(
                      'paymentDate',
                      toDateOnly(new Date(), 'userTimezone'),
                    );
                  }}
                  isChecked={paymentMethodType === 'gateway'}
                  isDisabled={!isGatewayAvailable}
                >
                  <MText fontSize="sm" ml={2} fontWeight="bold">
                    Payment Method
                  </MText>
                </MRadio>
                <MBox ml="1.5rem">
                  {paymentMethodType === 'gateway' && isGatewayAvailable && (
                    <CreatePaymentWithPaymentMethod
                      allowPartialPayment={allowPartialPayment}
                      allPaymentMethods={allPaymentMethods}
                      control={control}
                      errors={errors}
                      isLoading={paymentMethodsData.loading}
                      isSaving={isSaving}
                      onOpenPaymentMethodModal={onOpenPaymentMethodModal}
                    />
                  )}
                </MBox>
              </MGridItem>

              {!!selectedPaymentMethod &&
                ACH_OR_BANK_TRANSFER.has(
                  selectedPaymentMethod.paymentMethodType,
                ) && (
                  <MGridItem colSpan={12} mt="-.7rem">
                    <MText color="tGray.darkPurple" ml="6">
                      Paying with an Bank Transfer will attempt to charge the
                      customers balance on Stripe
                    </MText>
                  </MGridItem>
                )}
              {!isGatewayAvailable && (
                <MGridItem colSpan={12} mt="-.7rem">
                  <MAlert type="warning">
                    You need to set up a payment gateway in your tenant settings
                    before you can add a new payment method.
                  </MAlert>
                </MGridItem>
              )}
              <MGridItem colSpan={12}>
                <MRadio
                  onChange={() => setValue('type', 'manual')}
                  isChecked={paymentMethodType === 'manual'}
                >
                  <MText fontSize="sm" ml={2} fontWeight="bold">
                    Manual Payment
                  </MText>
                </MRadio>
                {paymentMethodType === 'manual' && (
                  <MBox ml="1.5rem">
                    <CreatePaymentWithManualPayment
                      allowPartialPayment={allowPartialPayment}
                      control={control}
                      errors={errors}
                      isSaving={isSaving}
                    />
                  </MBox>
                )}
              </MGridItem>
            </MGrid>
          </form>
        )}
        <AddPaymentMethodForm
          isOpen={isPaymentMethodModalOpen}
          accountId={invoice?.account?.id!}
          onClose={onCloseAddPaymentMethodModal}
        />
      </>
    </MCenterModal>
  );
};
