import useResizeObserver from '@react-hook/resize-observer';
import type { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { Document, Page, pdfjs } from 'react-pdf';
import {
  MBox,
  MButton,
  MFlex,
  MIcon,
  MPageLoader,
  MSkeleton,
} from '~app/components/Monetize';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { handleApiErrorToast } from '../../api/axios';
import { getSafeFilename } from '../../utils';
import { downloadBlobAsFile } from '../../utils/download';

const WIDTH_BUFFER = 25;

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

// TODO: improve the display of this
const LOADING_SKELETON_OFFERING_BUNDLE = () => {
  return (
    <>
      <MSkeleton height="20px" width="100%" mt="5" />
      <MBox pl="5">
        <MSkeleton height="12px" width="100%" mt="3" />
        <MSkeleton height="12px" width="100%" mt="1" />
        <MSkeleton height="12px" width="100%" mt="3" />
        <MSkeleton height="12px" width="100%" mt="1" />
      </MBox>
    </>
  );
};

const LOADING_SKELETON = (
  <MBox padding="10" w="100%">
    <MSkeleton height="25px" width="20%" />
    <MSkeleton height="12px" width="10%" mt="1" />
    <MSkeleton height="12px" width="15%" mt="1" />
    <MFlex alignItems="center" justifyContent="space-between" mt="10" w="100%">
      <MBox w="50%">
        <MSkeleton height="15px" width="30%" />
        <MSkeleton height="15px" width="35%" mt="1" />
        <MSkeleton height="15px" width="20%" mt="1" />
        <MSkeleton height="15px" width="25%" mt="1" />
      </MBox>
      <MBox w="50%" textAlign="right" display="flex" justifyContent="flex-end">
        <MSkeleton height="80px" width="50%" />
      </MBox>
    </MFlex>
    {/* offering header */}
    <MSkeleton height="20px" width="100%" mt="10" />
    <MSkeleton height="2px" width="100%" mt="1" />
    {/* Bundle header */}
    <LOADING_SKELETON_OFFERING_BUNDLE />
    <LOADING_SKELETON_OFFERING_BUNDLE />
    <LOADING_SKELETON_OFFERING_BUNDLE />
    <LOADING_SKELETON_OFFERING_BUNDLE />
    <LOADING_SKELETON_OFFERING_BUNDLE />
  </MBox>
);

interface QuoteSharePdfProps {
  quoteId: string;
  isLoading: boolean;
  quotePdfData?: ArrayBuffer;
  children: ReactNode;
}

export const QuoteSharePdf = ({
  quoteId,
  isLoading,
  quotePdfData,
  children,
}: QuoteSharePdfProps) => {
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<DOMRect>();
  const [numPages, setNumPages] = useState<number | null>(null);
  // react-pdf transfers the ArrayBuffer to the worker, so we pass it a clone so we can access the original
  const [clonedPdf, setClonedPdf] = useState<ArrayBuffer>();

  useEffect(() => {
    if (quotePdfData) {
      setClonedPdf(quotePdfData.slice(0));
    }
  }, [quotePdfData]);

  // If browser is resized, the PDF will be adjusted to fit the new size
  useResizeObserver(pdfContainerRef, (entry) =>
    setSize({
      ...(entry.contentRect as DOMRect),
      width: entry.contentRect.width - WIDTH_BUFFER,
    }),
  );

  useLayoutEffect(() => {
    if (pdfContainerRef.current) {
      const roundingRect = pdfContainerRef.current.getBoundingClientRect();
      roundingRect &&
        setSize({
          ...roundingRect,
          width: roundingRect.width - WIDTH_BUFFER,
        });
    }
  }, [pdfContainerRef]);

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf?.numPages || 0);
  };

  const downloadPdf = async () => {
    try {
      if (quotePdfData) {
        downloadBlobAsFile(
          quotePdfData,
          `${getSafeFilename(`quote-${quoteId}`)}.pdf`,
        );
      }
    } catch (err) {
      handleApiErrorToast(err);
    }
  };

  return (
    <MBox width="100%" maxW={PAPER_PAGE_MAX_WIDTH} mt={2}>
      <MFlex justifyContent="end" mb={2}>
        <MButton variant="tertiary" onClick={downloadPdf} minW="auto" mr={2}>
          <MIcon as={FiDownload} mr={2} />
          Download
        </MButton>
        {children}
      </MFlex>
      <MBox
        ref={pdfContainerRef}
        bg="white"
        border="1px solid"
        borderColor="tGray.lightPurple"
        borderRadius="md"
        justifySelf="center"
        w="100%"
        pb={[4, 0, 7]}
        pt={[4, 0]}
      >
        {isLoading && LOADING_SKELETON}
        {!isLoading && !!clonedPdf && (
          <MFlex w="full" flexDir="column">
            <Document
              loading={
                <MFlex justify="center" grow={1}>
                  <MPageLoader />
                </MFlex>
              }
              file={clonedPdf}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <MFlex key={`page_${index + 1}`} p={1} justify="center">
                  <Page
                    pageNumber={index + 1}
                    className="pdf-page"
                    width={size?.width || PAPER_PAGE_MAX_WIDTH - WIDTH_BUFFER}
                  />
                </MFlex>
              ))}
            </Document>
          </MFlex>
        )}
      </MBox>
    </MBox>
  );
};
