import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  useStyleConfig,
  Wrap,
} from '@chakra-ui/react';

import React, { FC } from 'react';
import { MdArrowDropDown, MdClose } from 'react-icons/md';
import { CustomSelectStateProps } from '~app/types/mCustomSelectTypes';
import { truncateMiddle } from '~app/utils';
import MCustomIconButton from '../../MCustomIconButton';

export interface MainInputProps
  extends Pick<
    CustomSelectStateProps,
    | 'getTitleFromValue'
    | 'disabled'
    | 'isReadOnly'
    | 'onOpen'
    | 'onClose'
    | 'loading'
    | 'loadingRenderer'
    | 'value'
    | 'onToggleOpen'
    | 'externalLoading'
    | 'placeholder'
    | 'isOpen'
    | 'items'
    | 'cursor'
    | 'setCursor'
    | 'handleChange'
    | 'showContentInInput'
    | 'renderContentInInput'
    | 'inputProps'
    | 'renderRightElement'
    | 'clearable'
    | 'onClear'
    | 'onChange'
    | 'queryResultCount'
    | 'query'
    | 'onQueryKeyDown'
    | 'onQueryChange'
    | 'displayAvatar'
    | 'renderInputLeftElement'
    | 'inputLeftElementContent'
    | 'isSubtitleItem'
    | 'isTruncatedInputTitle'
  > {
  ref: any;
}
export const MainInput: FC<MainInputProps> = React.forwardRef<
  any,
  MainInputProps
>(
  (
    {
      getTitleFromValue,
      disabled,
      isReadOnly,
      onOpen,
      onClose,
      loading,
      loadingRenderer,
      value,
      onToggleOpen,
      externalLoading,
      placeholder,
      isOpen,
      showContentInInput,
      renderContentInInput,
      inputProps,
      renderRightElement,
      clearable,
      onClear,
      onChange,
      inputLeftElementContent,
      isTruncatedInputTitle,
    },
    ref,
  ) => {
    const styles = useStyleConfig('Input', {
      variant: inputProps?.variant ?? 'primary',
    });
    const onBlurInput = (ev: any) => {
      if (
        ev.relatedTarget &&
        ev.relatedTarget.classList?.contains('chakra-popover__content')
      ) {
        // It means user selected an option
        // then pass

        return true;
      }
      // otherwise close the popup

      onClose();
      return true;
    };

    const getBgColor = () => {
      if (disabled) {
        return 'tGray.back';
      }
      if (isOpen) {
        return 'tBlue.hover';
      }
      return 'tWhite.base';
    };
    const getBorderColor = () =>
      isOpen ? 'tBlue.lightShade' : 'tGray.lightPurple';

    const rightElement = renderRightElement && renderRightElement({ value });

    const handleOnClear = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (onClear) {
        onClear();
      } else {
        onChange && onChange('' as any);
      }
    };

    let rightMargin = rightElement ? rightElement.width : 8;
    if (clearable && value && !disabled) {
      rightMargin += 4;
    }

    const title = getTitleFromValue(value) || '';
    const inputTitle = isTruncatedInputTitle ? truncateMiddle(title) : title;

    if (!showContentInInput) {
      return (
        <InputGroup>
          {inputLeftElementContent && (
            <InputLeftElement>{inputLeftElementContent}</InputLeftElement>
          )}
          <Input
            type="text"
            ref={ref}
            placeholder={placeholder}
            readOnly
            value={inputTitle}
            onClick={!disabled ? onOpen : undefined}
            onBlur={!disabled ? onBlurInput : undefined}
            isDisabled={disabled}
            isReadOnly={isReadOnly}
            variant={isReadOnly ? 'readonly' : 'primary'}
            bg={getBgColor()}
            color={isOpen ? 'tIndigo.base' : 'tPurple.dark'}
            tabIndex={isOpen ? -1 : 0}
            pr={rightMargin}
            _placeholder={{
              color: 'tGray.lightPurple',
            }}
            {...inputProps}
          />

          {!isReadOnly && (
            <InputRightElement width={rightMargin}>
              {rightElement?.content}
              {clearable && value && !disabled && (
                <MCustomIconButton
                  icon={MdClose}
                  bg="none"
                  color="tPurple.dark"
                  _hover={{
                    bg: 'none',
                  }}
                  ml="2"
                  btnSize={4}
                  padding={0}
                  containerSize={4}
                  onClick={handleOnClear}
                />
              )}
              {loading || externalLoading ? (
                <>{loadingRenderer ? loadingRenderer : <Spinner size="xs" />}</>
              ) : (
                <Icon
                  as={MdArrowDropDown}
                  fontSize="20"
                  onClick={!disabled ? onToggleOpen : () => {}}
                  zIndex="1"
                  color={disabled ? 'tGray.acGray' : 'tPurple.dark'}
                  opacity={disabled ? 0.25 : 'unset'}
                />
              )}
            </InputRightElement>
          )}
        </InputGroup>
      );
    }

    return (
      <InputGroup
        __css={(styles as any).field}
        bg={getBgColor()}
        borderColor={getBorderColor()}
        display="flex"
        flexWrap="wrap"
        position="relative"
        alignItems="center"
      >
        <Wrap spacing={0.5}>
          {showContentInInput &&
            renderContentInInput &&
            renderContentInInput({ onToggleOpen })}
          <Flex alignItems="center" flex="1" display="grid">
            <Input
              type="text"
              ref={ref}
              placeholder={placeholder}
              onChange={() => {}}
              value={!showContentInInput ? inputTitle : ''}
              onFocus={!disabled ? onOpen : () => {}}
              onBlur={!disabled ? onBlurInput : () => {}}
              isDisabled={disabled}
              isReadOnly={isReadOnly}
              tabIndex={isOpen ? -1 : 0}
              border="none"
              _focus={{ border: 'none' }}
              width="auto"
              bg="none"
              _placeholder={{
                color: 'tGray.darkGrayPurple',
              }}
              {...inputProps}
            />
          </Flex>
        </Wrap>
        <Box
          onClick={!disabled ? onToggleOpen : () => {}}
          position="absolute"
          right="0"
          bottom="6px"
          display="flex"
          alignItems="center"
        >
          {loading || externalLoading ? (
            <>{loadingRenderer ? loadingRenderer : <Spinner size="xs" />}</>
          ) : (
            <Icon as={MdArrowDropDown} fontSize="20" zIndex="1" />
          )}
        </Box>
      </InputGroup>
    );
  },
);
