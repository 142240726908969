import { useEffect, useState } from 'react';
import { useUpdateTeam } from '~app/api/settingsService';
import { useGetUsersListByTenantId } from '~app/api/usersService';
import {
  MButton,
  MCenterModal,
  MCustomMultiSelect,
  MFormField,
  MStack,
} from '~app/components/Monetize';
import { useAuth } from '~app/services/auth0';
import {
  DEFAULT_PAGER,
  ITeamResponseSchema,
  IUser,
  UserStatusEnum,
} from '~app/types';
import { handleApiErrorToast } from '../../../../../api/axios';
import { UserAvatarAndName } from '../../../../../components/Monetize/MCustomSelect/components/UserAvatarAndName';
import { MemberLeftElementContent } from './MemberLeftElementContent';

interface AddMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  team: ITeamResponseSchema;
}

export const AddMemberModal = ({
  isOpen,
  onClose,
  team,
}: AddMemberModalProps) => {
  const [addMemberOption, setAddMemberOption] = useState<IUser[]>();
  const [newMembers, setNewMembers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const { tenantId } = useAuth();

  const { users: teamUsers, id } = team;

  const { data: userList } = useGetUsersListByTenantId<IUser>({
    tenantId,
    config: { ...DEFAULT_PAGER, rows: 100000 },
    filters: {
      status: {
        in: [UserStatusEnum.ACTIVE, UserStatusEnum.INVITED],
      },
    },
  });

  const { mutateAsync: doUpdateTeam } = useUpdateTeam({
    teamId: id,
  });

  useEffect(() => {
    setAddMemberOption(
      userList?.content.filter(
        (user) => !teamUsers.some((teamUser) => teamUser.id === user.id),
      ),
    );
  }, [team, userList]);

  const onUpdateTeamMembers = async () => {
    if (!newMembers && team) {
      return;
    }
    const newUserIds = newMembers?.map(({ id: userId }) => userId || '') || [];
    const userIds = [...team.users.map((user) => user.id), ...newUserIds];
    const finalData = {
      name: team.name,
      type: team.type,
      owner: team.owner,
      userIds,
    };
    try {
      setLoading(true);
      await doUpdateTeam(finalData);
      onClose();
      setNewMembers([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleApiErrorToast(error);
    }
  };

  return (
    <MCenterModal
      size="md"
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Add Members"
      modalBodyProps={{ paddingTop: '0px' }}
      modalContentProps={{ 'data-testid': 'add-member-modal' } as any}
      returnFocusOnClose={false}
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          <MButton onClick={onClose} variant="cancel" minW="auto">
            Cancel
          </MButton>
          <MButton
            variant="primary"
            isDisabled={!newMembers?.length || loading}
            onClick={onUpdateTeamMembers}
            isLoading={loading}
            type="submit"
            minW="auto"
          >
            Save
          </MButton>
        </MStack>
      )}
    >
      <MFormField label="Add Member" isRequired>
        <MCustomMultiSelect
          placeholder={newMembers.length ? '' : 'Select'}
          returnItem
          itemValue="id"
          itemTitle="name"
          showQueryInput
          isSelectOpen={false}
          items={addMemberOption}
          value={newMembers}
          renderItemContent={({ title }) => (
            <UserAvatarAndName
              userName={title}
              textProps={{ fontSize: 'sm' }}
            />
          )}
          renderInputLeftElement={
            !!newMembers.length && newMembers.length === 1 //Show Avatar for single member choose
          }
          inputLeftElementContent={
            <MemberLeftElementContent value={newMembers} />
          }
          onChange={(members) => setNewMembers(members)}
          showCheckCount
          closeButtonText="Apply"
        />
      </MFormField>
    </MCenterModal>
  );
};
