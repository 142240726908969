import {
  Editable,
  EditableInputProps,
  EditablePreviewProps,
  EditableProps,
  Tooltip,
  useEditable,
} from '@chakra-ui/react';
import React, { FC } from 'react';
import { pluralize } from '~app/utils';
import { MFlex, MInput } from './chakra';
import MText from './MText';

interface MEditableInputProps extends EditableProps {
  inputProps?: EditableInputProps & { 'data-testid'?: string };

  previewProps?: EditablePreviewProps;
  tooltip?: string;
  showCancel?: boolean;
  showSave?: boolean;
  showEdit?: boolean;
  unitName?: string;
}

const MEditableInput: FC<MEditableInputProps> = React.forwardRef<
  any,
  MEditableInputProps
>(
  (
    {
      inputProps,
      previewProps,
      tooltip,
      showCancel,
      showSave,
      showEdit,
      unitName,
      isDisabled,
      ...rest
    }: MEditableInputProps,
    ref,
  ) => {
    const { value, getPreviewProps, getInputProps, onEdit } = useEditable({
      isPreviewFocusable: true,
      selectAllOnFocus: true,
      submitOnBlur: true,
      isDisabled,
      ...rest,
    });
    const { hidden } = getPreviewProps();

    const unitToDisplay = unitName && pluralize(unitName, Number(value));

    return (
      <Editable display="flex" alignItems="center">
        <Tooltip label={tooltip}>
          <MText
            display="flex"
            alignItems="center"
            minH="8"
            as="span"
            color="tPurple.base"
            px={2}
            w="100%"
            border="1px solid"
            borderRadius="3px"
            borderColor="transparent"
            cursor="text"
            onClick={onEdit}
            hidden={hidden}
            _hover={
              isDisabled
                ? {}
                : {
                    background: 'tGray.support',
                    border: '1px solid',
                    borderColor: 'tGray.lightPurple',
                    borderRadius: '3px',
                  }
            }
            {...previewProps}
          >
            {value} {unitToDisplay}
          </MText>
        </Tooltip>
        <MFlex alignItems="center">
          <MInput
            flexGrow={1}
            flexShrink={0}
            py={2}
            autoFocus={false}
            {...getInputProps()}
            {...(inputProps as any)}
          />
        </MFlex>
      </Editable>
    );
  },
);

export default MEditableInput;
