import { z } from 'zod';

export enum AddressSourceEnum {
  ACCOUNT = 'ACCOUNT',
  CONTACT = 'CONTACT',
}

export const AddressSourceEnumZ = z.nativeEnum(AddressSourceEnum);

export const ADDRESS_SOURCE_DISPLAY: {
  [key in AddressSourceEnum]: string;
} = {
  ACCOUNT: 'Account',
  CONTACT: 'Contact',
};

export const AddressSchema = z.object({
  id: z.string().optional(),
  line1: z.string().max(255).nullish(),
  line2: z.string().max(255).nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  postalCode: z.string().max(50).nullish(),
  country: z.string().nullish(),
  description: z.string().nullish(),
});
export type IAddressSchema = z.infer<typeof AddressSchema>;

export const AddressRequiredSchema = AddressSchema.pick({
  id: true,
  description: true,
}).extend({
  line1: z.string().max(255),
  line2: z.string().max(255),
  city: z.string(),
  state: z.string(),
  postalCode: z.string().max(50),
  country: z.string(),
});
export type AddressRequired = z.infer<typeof AddressRequiredSchema>;

export const ContactAddressDataSchema = z.object({
  addressSource: AddressSourceEnumZ,
  shippingAddressId: z.string().nullish(),
  billingAddressId: z.string().nullish(),
});

export type IContactAddressDataSchema = z.infer<
  typeof ContactAddressDataSchema
>;
