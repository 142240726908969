import { useEffect, useState } from 'react';
import { useInvoiceListByAccount } from '~app/api/accountsService';
import {
  buildFilterParamsRequestObject,
  transformTableFilterValue,
} from '~app/utils';
import {
  DEFAULT_PAGER,
  FilterType,
  GetListApiFilter,
  TDataTablePager,
} from '~types';

export const useInvoices = (accountId: string, billGroupId?: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      'id',
    ),
  );

  const {
    isLoading,
    isFetched,
    isRefetching,
    data: invoices,
  } = useInvoiceListByAccount(
    {
      accountId,
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
    },
    { meta: { showErrorToast: true } },
  );

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(
        transformTableFilterValue(filters),
        searchTerm,
        'id',
      ),
    );
  }, [filters, searchTerm, pager]);

  const onResetFilter = () => {
    setFilters([]);
    setSearchTerm('');
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    onResetFilter,
    isLoading: isLoading || (isRefetching && !isFetched),
    invoices,
    searchTerm,
    setSearchTerm,
  };
};
