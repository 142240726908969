import { extendTheme } from '@chakra-ui/react';
import Button from './chakra/components/Button';
import Drawer from './chakra/components/Drawer';
import Heading from './chakra/components/Heading';
import Link from './chakra/components/Link';

import { CardTheme } from './chakra/components/Card';
import CloseButton from './chakra/components/CloseButton';
import Modal from './chakra/components/Modal';
import Table from './chakra/components/Table';
import Tabs from './chakra/components/Tabs';

// Form
import Accordion from './chakra/components/Accordion';
import { avatarTheme } from './chakra/components/Avatar';
import Checkbox from './chakra/components/Checkbox';
import Divider from './chakra/components/Divider';
import FormLabel from './chakra/components/FormLabel';
import Input from './chakra/components/Input';
import { popoverTheme } from './chakra/components/Popover';
import Radio from './chakra/components/Radio';
import Select from './chakra/components/Select';
import Spinner from './chakra/components/Spinner';
import Switch from './chakra/components/Switch';
import Tag from './chakra/components/Tag';
import Textarea from './chakra/components/Textarea';
import Tooltip from './chakra/components/Tooltip';

const robotoFont = `"Roboto", sans-serif`;
const breakpoints = {
  sm: '431px',
  md: '701px',
  lg: '961px',
  xl: '1201px',
  '2xl': '1537px',
};

export const colors = {
  tBlue: {
    base: '#18243C',
    hover: '#EAECF9',
    lightShade: '#B4BCEF',
    light: '#6478FF',
    magenta: '#8C32FF',
    vLShadeMagenta: '#F7ECFF',
    lShadeMagenta: '#BAA1E3',
    bSweet: '#635BFF',
    totalBlue: '#6478FF',
    dark: '#05115E',
    100: '#E6E9FF',
  },
  tPurple: {
    dark: '#3D3C65',
    base: '#3D3C65',
    200: '#3D3C65',
    100: '#3D3C65',
    500: '#3D3C65',
    900: '#3D3C65',
    safety: '#8C32FF',
    linkWater: '#EAECF9',
    inputBox: '#F7ECFF',
    lightGrayPurple: '#7B7A85',
  },
  tIndigo: {
    base: '#4659DA',
    200: '#4659DA',
    100: '#4659DA',
    500: '#4659DA',
    900: '#4659DA',
  },
  tRed: {
    base: '#BF4848',
    magenta: '#F909A7',
    hover: '#802A2A',
    cosmos: '#FFD4D4',
    paleRed: '#FFD5D5',
    cinnabar: '#E53E3E',
  },
  tRoyalBlue: {
    base: '#4659DA',
  },
  tGray: {
    acGray: '#6E6E81',
    darkPurple: '#606070',
    darkGrayPurple: '#606070',
    lightPurple: '#C8C5D4',
    back: '#E3E8EC',
    support: '#F6F6F6',
    sidebar: '#FCFCFC',
    drawerSidebar: '#FBFAFA',
    sidebarDark: '#FBFAFA',
    lightGray: '#d3d3d3',
    gray89: '#E3E3E3',
    grey96: '#0000000a',
    light: '#C8C8C8',
    back1: '#8C8998',
    disabled: 'rgba(246, 246, 246, 0.75)',
    acBlack: '#333333',
    disabledText: '#A1A2A3',
    disabledBack: '#F0F0F0',
    overlay: '#F6F6F659',
    lightBack: '#F1EFEE',
    shareBack: '#F2F1F3',
  },
  darkMCyan: '#319795',
  tCyan: {
    dark: '#319795',
  },
  tWhite: {
    base: '#ffffff',
    titanWhite: '#FAFAFF',
    text: '#FBFAFA',
    focus: 'rgba(255, 244, 226, 0.75)',
    300: '#F8F7F7',
    400: '#E4E4E4',
  },
  tBlack: {
    200: '#8D8D8D',
    300: '#7A7A7A',
  },
  tMud: {
    base: '#460C0C',
  },
  tCreamsicle: {
    base: '#FFF4E2',
  },
  tOrange: {
    base: '#E47915',
    input: '#FFF4E2',
    tangerine: '#FC6C22',
    bg: '#FFF4E2BF',
  },
  tGreen: {
    mDarkShade: '#18A800',
    approval: '#24823F',
    light: '#EBFFE8',
    bayside: '#50E3C2',
    lime: '#79C878',
  },
  tTurquoise: {
    ocean: '#09C4F9',
    bayside: '#50E3C2',
  },
  tYellow: {
    base: '#F6E24E',
  },
};

export default extendTheme({
  styles: {
    global: {
      'html, body': {
        fontFamily: `${robotoFont} !important`,
      },
      '*:focus': {
        outline: 'none !important',
      },
      '*:focus-visible': {
        // Chakra adds a box shadow to focusable elements (inputs and buttons) and this does not match our design system
        // this was under *:focus, but that caused issues with popover and drawer shadows being removed
        boxShadow: 'none !important',
      },
    },
  },
  colors,
  fonts: {
    heading: robotoFont,
  },
  fontSizes: {
    xxs: '0.625rem', // 10px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '2.5xl': '1.75rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
    hero: '6rem',
  },
  letterSpacings: {
    tight: '-0.04em',
    normal: '0',
    wide: '0.08em',
    wider: '0.16em',
    widest: '0.24em',
  },
  lineHeights: {
    normal: 'normal',
    none: '1',
    shorter: '1.1',
    short: '1.3',
    base: '1.5',
    tall: '1.6',
    taller: '2',
  },
  space: {
    header: '5rem',
  },
  shadows: {
    t1: '0px 4px 15px rgba(211, 211, 211, 0.25)',
    t2: '0px 4px 8px rgba(24, 36, 60, 0.15)',
    popover: '0px 4px 25px rgba(180, 188, 239, 0.38)',
    sideNavbar: '-2px 0px 16px rgba(200, 197, 212, 0.15)',
    card: '0px 1px 16px rgba(200, 197, 212, 0.15)',
  },
  components: {
    // MAccordion: Accordion,
    Avatar: avatarTheme,
    Accordion,
    Button,
    Card: CardTheme,
    Checkbox,
    CloseButton,
    Drawer,
    FormLabel,
    Heading,
    Input,
    Link,
    Modal,
    NumberInput: Input,
    Popover: popoverTheme,
    Radio,
    Select,
    Spinner,
    Switch,
    Tabs,
    Table,
    Tag,
    Textarea,
    Tooltip,
    Divider,
  },
  breakpoints,
});
