import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useGetListData } from '~app/api/queryUtils';
import {
  MFlex,
  MPageContentContainer,
  MPageHeader,
  MPageSearchInput,
  MSwitch,
  MText,
} from '~app/components/Monetize';
import {
  AccountIdTableFilterOptionContent,
  AmountTableFilterOptionContent,
  DateGlteTableFilterOptionContent,
  MDataTableFilter,
} from '~app/components/Monetize/DataTable';
import { CurrencyTableFilterOptionContent } from '~app/components/Monetize/DataTable/FilterOptions/CurrencyTableFilterOptionContent';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { InvoiceStatusEnumDisplay } from '~app/constants/invoice';
import { InvoiceList } from '~app/routes/Invoices/InvoiceList';
import {
  tableFilterSelector,
  tablePagerSelector,
} from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import {
  buildFilterParamsRequestObject,
  getIsTrulyEmptyList,
  transformTableFilterValue,
} from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  FilterOptionType,
  FilterType,
  FilterTypeEqual,
  FilterTypeOperator,
  GetListApiFilter,
  InvoiceSummaryResp,
  RenderTableFilterOptionProps,
} from '~types';

const renderSentInvoiceToggleOptionContent = ({
  filter,
  filterOption,
  handleFilterChange,
}: RenderTableFilterOptionProps) => {
  const internalFilter = filter as FilterTypeEqual;
  const { options } = filterOption;

  const falseValue = options?.falseValue ?? '';
  const trueValue = options?.trueValue ?? '';
  const isChecked = internalFilter?.value === trueValue;

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked ? trueValue : falseValue;
    handleFilterChange(newValue, filterOption);
  };

  return (
    <MFlex alignItems="center" my="2" justifyContent="space-between">
      <MText mr="4">Hide Sent Invoices</MText>
      <MSwitch size="md" isChecked={isChecked} onChange={handleToggleChange} />
    </MFlex>
  );
};
const renderAmountOptionContent = (props: RenderTableFilterOptionProps) => {
  return <AmountTableFilterOptionContent {...props} />;
};
const renderDateGlteOptionContent = (props: RenderTableFilterOptionProps) => {
  return <DateGlteTableFilterOptionContent {...props} />;
};

export const BillingDashboard = () => {
  const [initialFilters, persistTableFilter] = useRecoilState(
    tableFilterSelector(FilterStateKeys.INVOICE_LIST),
  );

  const [filters, setFilters] = useState<FilterType[]>(initialFilters);
  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.INVOICE_LIST),
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(
      transformTableFilterValue(filters),
      searchTerm,
      'id',
    ),
  );

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(
        transformTableFilterValue(filters),
        searchTerm,
        'id',
      ),
    );
  }, [filters, searchTerm, pager]);

  useEffect(() => {
    persistTableFilter(filters);
  }, [filters, persistTableFilter]);

  const { isLoading, data: invoices } = useGetListData<InvoiceSummaryResp>(
    'invoices',
    { config: pager, filters: filterParams },
    {
      meta: { showErrorToast: true },
    },
  );

  const onResetFilter = () => {
    setFilters([]);
    setSearchTerm('');
  };

  const statusItems = objectToObjArray(
    InvoiceStatusEnumDisplay,
    'value',
    'label',
  );

  const filterOptions: FilterOptionType[] = [
    {
      title: '',
      key: 'sent',
      operator: FilterTypeOperator.TOGGLE,
      renderOptionContent: renderSentInvoiceToggleOptionContent,
      options: {
        falseValue: '',
        trueValue: 'false',
      },
    },
    {
      title: 'Account',
      key: 'accountId',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props: RenderTableFilterOptionProps) => (
        <AccountIdTableFilterOptionContent {...props} />
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: renderAmountOptionContent,
    },
    // Not removing this since it could be used as a reference point whenever we implement "Entity Select" Search
    // {
    //   title: 'Account',
    //   key: 'accountId',
    //   operator: FilterTypeOperator.EQUAL,
    //   renderOptionContent: renderAccountOptionContent,
    // },
    {
      title: 'Currency',
      key: 'currency',
      operator: FilterTypeOperator.EQUAL,
      renderOptionContent: (props) => (
        <CurrencyTableFilterOptionContent {...props} />
      ),
    },
    {
      title: 'Invoice Date',
      key: 'invoicingDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: renderDateGlteOptionContent,
    },
    {
      title: 'Updated',
      key: 'modifyDate',
      operator: FilterTypeOperator.GLTE,
      renderOptionContent: (props) => (
        <DateGlteTableFilterOptionContent showTimePicker {...props} />
      ),
    },
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const filterComponentReset = React.useRef<any>(null);
  const searchComponentReset = React.useRef<any>(null);
  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading: isLoading,
    totalElements: invoices?.totalElements || 0,
    filters,
    searchTerm: searchTerm,
    page: pager.page,
  });

  return (
    <MPageContentContainer>
      <MPageHeader title="Invoices" hideContent={isTrulyEmptyList}>
        <MFlex>
          {!isTrulyEmptyList && (
            <>
              <MPageSearchInput
                placeholderKey="Invoice ID"
                value={searchTerm || ''}
                onChange={(e: any) => setSearchTerm && setSearchTerm(e)}
                count={invoices?.totalElements}
                resetSearch={searchComponentReset}
              />
              <MDataTableFilter
                filters={filters}
                filterOptions={filterOptions}
                setFilters={setFilters}
                onResetFilter={onResetFilter}
                resetFilter={filterComponentReset}
              />
              <ExportTableButton
                entity="invoices"
                filters={filters}
                searchKey="id"
                searchTerm={searchTerm}
                sortField={pager.sortField}
                sortOrder={pager.sortOrder}
              />
            </>
          )}
        </MFlex>
      </MPageHeader>
      <InvoiceList
        omitBillGroup
        filters={filters}
        setFilters={setFilters}
        onResetFilter={onResetFilter}
        setPager={setPager}
        pager={pager}
        isLoading={isLoading}
        invoices={invoices}
        filterComponentReset={filterComponentReset}
        showAccount={true}
        searchTerm={searchTerm}
        searchComponentReset={searchComponentReset}
        source="dashboard"
      />
    </MPageContentContainer>
  );
};
