import { zodResolver } from '@hookform/resolvers/zod';
import hasValue from 'lodash/has';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import startCase from 'lodash/startCase';
import { useEffect, useMemo } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsArrowRight } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { handleApiErrorToast } from '~app/api/axios';
import { useCreateConnectorFieldMapping } from '~app/api/fieldMappingService';
import {
  MAccordion,
  MAccordionCustomButton,
  MAccordionCustomButtonItem,
  MAlert,
  MButton,
  MCustomIconButton,
  MDivider,
  MFlex,
  MFormField,
  MIcon,
  MText,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { usePrompt } from '~app/hooks/usePrompt';
import { useRecordState } from '~app/hooks/useRecordState';
import {
  AccountingFieldMatch,
  FieldMappingCreateFieldType,
  FieldMappingCreateFormSchema,
  FieldMappingCreateFormType,
  FieldMappingCreateReqType,
  FieldMappingObjectType,
  FieldMappingSystemType,
} from '~app/types/fieldMappingTypes';
import { toTitleCase } from '~app/utils';
import { transformAccountingFields } from '~app/utils/accountingFieldMapping';
import { AccountingFieldMappingDropdown } from './AccountingFieldMappingDropdown';

type AccountingFieldMappingAccordionProps = {
  system: FieldMappingSystemType;
  matchedFields: AccountingFieldMatch;
  userDefinedFields: FieldMappingCreateReqType[];
  mnObjectType: FieldMappingObjectType;
  /** Human friendly label, defaults to {mnObjectType} (capitalized) */
  mnObjectTypeLabel?: string;
  accountingObjectType: FieldMappingObjectType;
  /** Human friendly label, defaults to {accountingObjectType} (capitalized) */
  otherSystemObjectTypeLabel?: string;
};

export const AccountingFieldMappingAccordion = ({
  system,
  matchedFields,
  userDefinedFields,
  mnObjectType,
  mnObjectTypeLabel = mnObjectType,
  accountingObjectType,
  otherSystemObjectTypeLabel = accountingObjectType,
}: AccountingFieldMappingAccordionProps) => {
  const formId =
    `${system}-${accountingObjectType}-${mnObjectType}-form`.toLowerCase();

  const accountingFields = transformAccountingFields(
    matchedFields,
    'accounting',
  );
  const mnFields = transformAccountingFields(matchedFields, 'monetize');
  const systemLabel = useMemo(() => startCase(system), []);

  const {
    setValue,
    watch,
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitSuccessful, dirtyFields, isValid },
  } = useForm<FieldMappingCreateFormType>({
    mode: 'onSubmit',
    resolver: zodResolver(FieldMappingCreateFormSchema),
    defaultValues: {
      [formId]: userDefinedFields,
    },
  });

  const {
    state: errorState,
    addItem: addError,
    removeItem: removeError,
  } = useRecordState<
    string,
    {
      source: {
        message?: string;
      };
      target: {
        message?: string;
      };
    }
  >({});

  const { fields, append, remove } = useFieldArray({
    name: formId,
    control,
  });

  const { mutate: createFieldMapping, isPending: isLoading } =
    useCreateConnectorFieldMapping(system, 'accounting', {
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  usePrompt(
    'There are unsaved changed, do you want to discard these changes?',
    isDirty,
  );
  const onSubmit = (data: FieldMappingCreateFormType) =>
    createFieldMapping({
      sourceEntity: accountingObjectType,
      targetEntity: mnObjectType,
      payloads: data[formId],
    });

  const onError = (err: any) => {
    const errors = err[formId];
    if (!isEmpty(errors) && isArray(errors)) {
      errors.forEach((error, idx) => {
        const errorMessage = {
          source: {},
          target: {},
        };
        if (error && error.source) {
          errorMessage.source = {
            message: `${
              system === 'hubspot' ? 'Hubspot' : 'Salesforce'
            } Field is required`,
          };
        }
        if (error && error.target) {
          errorMessage.target = {
            message: 'MonetizeNow Field is required',
          };
        }
        if (!isEmpty(errorMessage)) {
          addError(`${formId}_${idx}`, errorMessage);
        } else {
          removeError(`${formId}_${idx}`);
        }
      });
    }
  };
  const onAddMapping = () =>
    append(
      {
        mode: 'by_value',
        source: {},
        target: {},
      } as FieldMappingCreateReqType,
      {
        shouldFocus: true,
      },
    );

  const handleOnClear = (idx: number, side: 'source' | 'target') => {
    setValue(`${formId}.${idx}.${side}`, {} as FieldMappingCreateFieldType, {
      shouldValidate: true,
    });
    removeError(`${formId}_${idx}`);
  };

  const changeMode = (ev: any, idx: number) => {
    setValue(`${formId}.${idx}.mode`, 'by_value');
    setValue(`${formId}.${idx}.via`, null, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const formFields = watch(formId);

  const validateData = (idx: number) => {
    const { source, target } = formFields[idx];
    if (
      !isEmpty(source) &&
      !isEmpty(target) &&
      source.dataType !== target.dataType
    ) {
      addError(`${formId}_${idx}`, {
        source: {
          message: `Data type doesn't match: ${toTitleCase(source.dataType)}`,
        },
        target: {
          message: `Data type doesn't match: ${toTitleCase(target.dataType)}`,
        },
      });
    } else {
      removeError(`${formId}_${idx}`);
    }
  };

  const getVariant = (idx: number, side: 'source' | 'target') => {
    if (isEmpty(dirtyFields)) {
      return undefined;
    }
    const dirtyField = dirtyFields[formId];
    if (!dirtyField || !dirtyField[idx] || !hasValue(dirtyField[idx], side)) {
      return undefined;
    }
    if (isObject(dirtyField[idx][side]) && isEmpty(dirtyField[idx][side])) {
      return 'unsaved';
    }
    return (dirtyField[idx][side] as unknown as boolean) === true
      ? 'unsaved'
      : undefined;
  };

  const unsavedChangeAlert = (
    <MAlert
      type="warning"
      alertProps={{ px: 2, py: 1 }}
      alertIconProps={{ width: 4, height: 4 }}
      alertDescriptionProps={{ fontSize: 'sm' }}
    >
      Unsaved Changes
    </MAlert>
  );

  useEffect(() => {
    userDefinedFields.forEach((item, index) => {
      validateData(index);
    });
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const isFormValid = isValid && isEmpty(errorState);

  return (
    <MAccordion allowToggle reduceMotion w="100%">
      <MSettingAccordionItem
        renderAccordionButton={({ isExpanded }) => {
          return (
            <MAccordionCustomButton
              isExpanded={isExpanded}
              label=""
              customLabel={
                <MFlex align="center" gap={2} ml={1}>
                  <MText
                    fontWeight="semibold"
                    fontSize="lg"
                    textTransform="capitalize"
                  >
                    {otherSystemObjectTypeLabel}
                  </MText>
                  <MIcon as={BsArrowRight} />
                  <MText
                    fontWeight="semibold"
                    fontSize="lg"
                    textTransform="capitalize"
                  >
                    {mnObjectTypeLabel}
                  </MText>
                </MFlex>
              }
            >
              {isDirty && !isExpanded && unsavedChangeAlert}
              <MAccordionCustomButtonItem
                align="left"
                label="Fields Mapped"
                ml={4}
                value={userDefinedFields.length}
                isExpanded={isExpanded}
                hideWhenExpanded
              />
            </MAccordionCustomButton>
          );
        }}
      >
        <MFlex direction="column" align="flex-start">
          <MFlex
            as="form"
            direction="column"
            gap={4}
            id={formId}
            onSubmit={handleSubmit(onSubmit, onError)}
            w="full"
            mt={2}
          >
            {fields.map((ff, idx) => {
              return (
                <MFlex direction="column" key={ff.id} gap={2}>
                  <MFlex gap={4} align="flex-start" justify="space-between">
                    <MFormField
                      label={idx === 0 ? 'MonetizeNow Field' : undefined}
                      error={errorState[`${formId}_${idx}`]?.source}
                    >
                      <Controller
                        name={`${formId}.${idx}.source`}
                        control={control}
                        render={({ field: { onChange, ...rest } }) => (
                          <AccountingFieldMappingDropdown
                            placeholder="MonetizeNow Field"
                            fields={mnFields}
                            fieldProps={rest}
                            index={idx}
                            side="source"
                            handleOnClear={handleOnClear}
                            onChange={onChange}
                            validateData={validateData}
                            getVariant={getVariant}
                            changeMode={changeMode}
                          />
                        )}
                      />
                    </MFormField>
                    <MIcon as={BsArrowRight} mt={idx === 0 ? 9 : 2} />
                    <MFormField
                      label={idx === 0 ? `${systemLabel} Field` : undefined}
                      error={errorState[`${formId}_${idx}`]?.target}
                    >
                      <Controller
                        name={`${formId}.${idx}.target`}
                        control={control}
                        render={({ field: { onChange, ...rest } }) => (
                          <AccountingFieldMappingDropdown
                            placeholder={`${
                              system === 'netsuite' ? 'Netsuite' : 'QuickBooks'
                            } Field`}
                            fields={accountingFields}
                            fieldProps={rest}
                            index={idx}
                            side="target"
                            handleOnClear={handleOnClear}
                            onChange={onChange}
                            validateData={validateData}
                            getVariant={getVariant}
                            changeMode={changeMode}
                          />
                        )}
                      />
                    </MFormField>
                    <MCustomIconButton
                      type="button"
                      icon={MdClose}
                      onClick={() => {
                        remove(idx);
                        removeError(`${formId}_${idx}`);
                      }}
                      mt={idx === 0 ? 9 : 1.5}
                    />
                  </MFlex>
                </MFlex>
              );
            })}
          </MFlex>
          <MButton
            variant="tertiary"
            my={2}
            onClick={onAddMapping}
            minW="auto"
            type="button"
          >
            + Add Mapping
          </MButton>
        </MFlex>
        <MDivider />
        <MFlex direction="row" align="center" justify="right" gap={4} mt={4}>
          {isDirty && unsavedChangeAlert}
          <MButton
            variant="primary"
            type="submit"
            form={formId}
            isLoading={isLoading}
            isDisabled={!isFormValid || !isDirty || isLoading}
          >
            Save
          </MButton>
        </MFlex>
      </MSettingAccordionItem>
    </MAccordion>
  );
};
