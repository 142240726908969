import { useEffect, useState } from 'react';
import { z } from 'zod';
import { useGetListData, usePerformEntityAction } from '~app/api/queryUtils';
import {
  ApiListResponse,
  BillGroupRespSchema,
  DEFAULT_API_RESPONSE,
  DEFAULT_PAGER,
  FilterType,
  GetCreditSchema,
  IBillGroupResp,
  IGetCreditSchema,
} from '~app/types';
import { buildFilterParamsRequestObject } from '~app/utils';
import { arrayToObject } from '~app/utils/misc';

const CreditWithBillGroup = GetCreditSchema.extend({
  billGroup: BillGroupRespSchema,
});
export type ICreditWithBillGroup = z.infer<typeof CreditWithBillGroup>;

export const useCredits = (accountId: string, billGroupId?: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [pager, setPager] = useState(DEFAULT_PAGER);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchKey] = useState<string>('name');
  const [filterParams, setFilterParams] = useState(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const {
    data: listData,
    isLoading: isAccountCreditResLoading,
    isFetched,
    isRefetching,
  } = useGetListData<IGetCreditSchema>(
    'accountCredits',
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
    },
    { endpointArgs: { accountId } },
  );
  const { isLoading: isBillGroupResLoading, data: billGroupsById } =
    useGetListData<IBillGroupResp, Record<string, IBillGroupResp>>(
      'billGroups',
      { config: { first: 50, page: 0 } },
      {
        endpointArgs: { accountId },
        select: (data) => {
          return arrayToObject(data?.content || [], 'id');
        },
      },
    );

  const [creditsWithBillGroup, setCreditsWithBillGroup] =
    useState<ApiListResponse<ICreditWithBillGroup>>();

  useEffect(() => {
    if (listData && billGroupsById) {
      setCreditsWithBillGroup(() => {
        return {
          ...listData,
          content: listData.content.map((credit) => {
            return {
              ...credit,
              billGroup: billGroupsById[credit.billGroupId],
            };
          }),
        };
      });
    }
  }, [listData, billGroupsById]);

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IGetCreditSchema>('accountCredits', {
      endpointArgs: { accountId, skipNestedEndpoint: true },
      meta: { showErrorToast: true },
    });

  useEffect(() => {
    if (filters) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager, searchKey]);

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    totalRecords: listData?.totalElements || 0,
    loading:
      isBillGroupResLoading ||
      isAccountCreditResLoading ||
      (isRefetching && !isFetched),
    listData: creditsWithBillGroup || DEFAULT_API_RESPONSE,
    doActivateDeactivate,
  };
};
