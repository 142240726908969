import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { handleApiErrorToast } from '../../api/axios';
import {
  useApplyPaymentsAndCreditsToInvoices,
  useGetAggregatedTransactionApplicationsWithInvoices,
} from '../../api/transactableService';
import { logger } from '../../services/logger';
import {
  InvoiceApplicationFormData,
  InvoiceApplicationFormDataSchema,
  SelectionStateEnum,
  TransactableSourceType,
  TransactableTypeDisplay,
} from '../../types';
import { MBox, MCenterModal, MGrid, MSkeleton, MText } from '../Monetize';
import { TransactionApplicationModalFooter } from './TransactionApplicationModalFooter';
import { TransactionApplicationTable } from './TransactionApplicationTable';
import {
  calculateAmounts,
  calculateApplicationFromData,
  calculateApplications,
} from './TransactionApplicationUtils';

interface TransactionApplicationModalProps {
  modalTitle: string;
  accountId: string;
  sourceId: string;
  sourceType: TransactableSourceType;
  /**
   * Amount of Payment, Credit, or Credit Note.
   */
  sourceOriginalAmount: number;
  /**
   * Unpaid invoices will be limited to this currency. Mainly used for payments.
   */
  currency: string;
  /**
   * If provided, unpaid invoices will only be shown for the provided bill group.
   */
  billGroupId?: string;
  onClose: () => void;
}

export const TransactionApplicationModal = ({
  modalTitle,
  accountId,
  sourceId,
  sourceType: inputSourceType,
  sourceOriginalAmount,
  currency,
  billGroupId,
  onClose,
}: TransactionApplicationModalProps) => {
  /**
   * FIXME: we do not yet support credit notes applying directly to invoices,
   * so we use the related credit for now.
   */
  const sourceType =
    inputSourceType === TransactableSourceType.creditNote
      ? TransactableSourceType.credit
      : inputSourceType;

  const {
    data: aggregationResponse,
    isLoading,
    isError,
  } = useGetAggregatedTransactionApplicationsWithInvoices(
    {
      accountId,
      sourceId,
      sourceType,
      currency,
      billGroupId,
    },
    {
      refetchOnWindowFocus: false,
      meta: { showErrorToast: true },
    },
  );

  useEffect(() => {
    if (aggregationResponse) {
      reset(calculateApplicationFromData(aggregationResponse));
    } else if (isError) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregationResponse, isError]);

  const {
    mutateAsync: doApplyPaymentsAndCreditsToInvoices,
    isPending: isSaving,
  } = useApplyPaymentsAndCreditsToInvoices(sourceType, sourceId, accountId, {
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const formState = useForm<InvoiceApplicationFormData>({
    resolver: zodResolver(InvoiceApplicationFormDataSchema),
    defaultValues: aggregationResponse
      ? calculateApplicationFromData(aggregationResponse)
      : {
          checkedState: SelectionStateEnum.NONE,
          rows: [],
        },
  });
  const { handleSubmit, watch, reset } = formState;

  const rows = watch('rows');
  const aggregateTransactable = watch('aggregateTransactable');

  const {
    appliedAmount: totalAppliedAmount,
    availableAmount: totalAvailableAmount,
    totalAmount,
    refundedAmount,
  } = calculateAmounts(sourceOriginalAmount, rows, aggregateTransactable);

  const hasRowErrors = rows.some(
    ({ appliedAmount, amountDueWithoutApplied }) =>
      appliedAmount > amountDueWithoutApplied,
  );

  const handleSave = async (data: InvoiceApplicationFormData) => {
    try {
      const applications = calculateApplications(
        data,
        aggregationResponse?.aggregatedApplicationsById || {},
      );

      if (!applications.length) {
        onClose();
        return;
      }

      await doApplyPaymentsAndCreditsToInvoices({
        applications,
      });
      onClose();
    } catch (ex) {
      logger.error(ex);
    }
  };

  function onError(error: any) {
    logger.error('TransactionApplicationModal Form error', error);
  }

  const isFullyRefunded = totalAmount === refundedAmount;
  const hasInvoices =
    !!aggregationResponse && aggregationResponse?.allInvoices.length > 0;

  return (
    <MCenterModal
      size="6xl"
      isOpen
      onClose={onClose}
      modalTitle={modalTitle}
      modalFooterProps={{
        pt: 0,
        border: 0,
      }}
      renderFooter={() => (
        <>
          {!isLoading && (
            <TransactionApplicationModalFooter
              sourceType={sourceType}
              currency={currency}
              totalAmount={totalAmount}
              availableAmount={totalAvailableAmount}
              appliedAmount={totalAppliedAmount}
              refundedAmount={refundedAmount}
              isDisabled={isLoading || rows.length === 0 || hasRowErrors}
              isLoading={isSaving}
              onClose={onClose}
            />
          )}
        </>
      )}
    >
      <form
        id="apply-payments-credits-form"
        onSubmit={handleSubmit(handleSave, onError)}
      >
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            {isFullyRefunded && (
              <MBox
                display="flex"
                flexDir="column"
                margin="0 auto"
                alignItems="center"
                justifyContent="center"
              >
                <MText fontWeight="bold" fontSize="lg">
                  This {TransactableTypeDisplay[sourceType]} has been fully
                  refunded.
                </MText>
              </MBox>
            )}
            {!hasInvoices && !isFullyRefunded && (
              <MBox
                display="flex"
                flexDir="column"
                margin="0 auto"
                alignItems="center"
                justifyContent="center"
              >
                <MText fontWeight="bold" fontSize="lg">
                  There are no unpaid invoices available.
                </MText>
              </MBox>
            )}
            {hasInvoices && !isFullyRefunded && (
              <TransactionApplicationTable
                sourceType={sourceType}
                totalAvailableAmount={totalAvailableAmount}
                formState={formState}
                availableAmount={totalAvailableAmount}
                isDisabled={isSaving}
              />
            )}
          </>
        )}
      </form>
    </MCenterModal>
  );
};

const LoadingSkeleton = () => (
  <MBox padding="4" w="100%">
    <MGrid
      gridTemplateColumns="minmax(8rem, auto) repeat(2, minmax(auto, 1fr)) repeat(3, minmax(auto, 8rem))"
      columnGap={4}
      mb={2}
    >
      <MSkeleton height="39px" />
      <MSkeleton height="39px" />
      <MSkeleton height="39px" />
      <MSkeleton height="39px" />
      <MSkeleton height="39px" />
      <MSkeleton height="39px" />
    </MGrid>
    <MSkeleton height="39px" />
    <MSkeleton height="39px" mt="1" />
    <MSkeleton height="39px" mt="1" />
  </MBox>
);
