import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { apiGet, apiGetAllList } from '~app/api/axios';
import { useGetListData } from '~app/api/queryUtils';
import {
  settingsQueryKeys,
  useGetCrmConfiguration,
  useGetCurrencySettings,
} from '~app/api/settingsService';
import Sentry from '~app/services/sentry';
import { appGlobalDataState } from '~app/store/global.store';
import {
  CrmConnection,
  CustomFieldEntityEnum,
  DEFAULT_PAGER,
  ICustomFieldResSchema,
} from '~app/types';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';

/**
 * used to prefetch some data that solve the screen jump issue and
 * the queryKey of prefetches need to be exactly same of the respective components useQuery for using RQ cache
 *
 * Loading of this page blocks the entire loading of the application, so be mindful of what things happen here.
 * Anything added here should not retry failed queries and should also not show the user any error messages
 * because the user did not take action so we would never need to notify the user that something they were not aware of went wrong.
 *
 *
 * TICKET: https://monetizenow.atlassian.net/browse/BP-8260
 */
export const useConfigDataFetcher = ({ enabled }: { enabled: boolean }) => {
  const options = {
    enabled,
    refetchOnWindowFocus: false,
    placeholderData: (previousData, previousQuery) => previousData,
    retry: false,
    staleTime: 1000 * 60 * 60, // 1 hour,
  };

  // TODO: consider re-working this
  const setGlobalState = useSetRecoilState(appGlobalDataState);

  const { isLoading: isCurrencyLoading, data: currencyData } =
    useGetCurrencySettings({}, { ...options });

  const { isLoading: isLegalEntityLoading, data: legalEntityData } =
    useGetListData<ILegalEntityResponseSchema>(
      'legalEntities',
      { config: DEFAULT_PAGER },
      { ...options },
    );

  // ensure that we do not report error to react query and instead return dummy data
  const { isLoading: isCrmConfigurationLoading, data: crmData } =
    useGetCrmConfiguration({
      queryFn: async () =>
        apiGet<CrmConnection>(`connector/connections/crm`)
          .then((res) => res.data)
          .catch((err) => ({
            hubspot: null,
            salesforce: null,
          })),
      ...options,
    });

  useEffect(() => {
    if (currencyData) {
      setGlobalState((currVal) => ({
        ...currVal,
        hasMultipleCurrency: currencyData.totalElements > 1,
      }));
    }
  }, [currencyData, setGlobalState]);

  useEffect(() => {
    if (legalEntityData) {
      setGlobalState((currVal) => ({
        ...currVal,
        hasMultipleLegalEntity: legalEntityData.totalElements > 1,
      }));
    }
  }, [legalEntityData, setGlobalState]);

  useEffect(() => {
    if (crmData) {
      setGlobalState((currVal) => ({
        ...currVal,
        hasCrmConfigured: !!crmData.salesforce || !!crmData.hubspot,
      }));
    }
  }, [crmData, setGlobalState]);

  const { isLoading: isAccountCustomFieldLoading } = useQuery({
    queryKey: [
      ...settingsQueryKeys.customFieldsListByEntity(
        CustomFieldEntityEnum.ACCOUNT,
      ),
    ],
    queryFn: async () => {
      try {
        const response = await apiGetAllList<ICustomFieldResSchema>(
          '/api/configurations/customFields',
          {
            filters: { entity: CustomFieldEntityEnum.ACCOUNT },
          },
        );
        return response;
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            type: 'ACCOUNT_CUSTOM_FIELD_CONFIG_FETCHER',
          },
        });
        return [];
      }
    },
    ...options,
  });

  const { isLoading: isQuoteCustomFieldLoading } = useQuery({
    queryKey: [
      ...settingsQueryKeys.customFieldsListByEntity(
        CustomFieldEntityEnum.QUOTE,
      ),
    ],
    queryFn: async () => {
      try {
        const response = await apiGetAllList<ICustomFieldResSchema>(
          '/api/configurations/customFields',
          {
            filters: { entity: CustomFieldEntityEnum.QUOTE },
          },
        );
        return response;
      } catch (err) {
        Sentry.captureException(err, {
          tags: {
            type: 'QUOTE_CUSTOM_FIELD_CONFIG_FETCHER',
          },
        });
        return [];
      }
    },
    ...options,
  });

  return {
    isConfigLoading:
      isCurrencyLoading ||
      isLegalEntityLoading ||
      isCrmConfigurationLoading ||
      isAccountCustomFieldLoading ||
      isQuoteCustomFieldLoading,
  };
};
