import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

interface UseCustomSelectValuesProps<TValue, TEntity> {
  value: TValue[];
  setValue: (v: TValue[]) => void;
  getOneById?: (v: TValue) => Promise<TEntity>;
  getByIds?: (v: TValue[]) => Promise<TEntity[]>;
  label?: string;
  itemValue?: string;
  itemTitle?: string;
}

/**
 * This is a custom hook that accepts ids of the entities, and return data entities, methods to change the value.
 * For example, it accepts list of product ids, and returns the list of products and change it's value.
 *
 * @param value list of ids (values)
 * @param setValue  callback to set value
 * @param getOneById async method to each data entity by id, getByIds or getOneById should be valid. getByIds takes priority.
 * @param getByIds async method to all data entity by ids
 * @param label is used to generate the placeholder text
 * @param itemValue field to indiciate id of the data entity. 'id' by default
 * @param itemTitle field to indiciate name of the data entity. 'name' by default
 * @returns internalValue - list of data entities
 * @returns initialValueLoaded - whether the inital ids are loaded
 * @returns placeholder - default placeholer to show within the input
 * @returns onInternalValueChange - callback to change the internalValue and value
 */
export function useCustomSelectValues<TEntity, TValue = string>({
  value,
  getOneById,
  getByIds,
  setValue,
  label,
  itemTitle = 'name',
  itemValue = 'id',
}: UseCustomSelectValuesProps<TValue, TEntity>): {
  internalValue: TEntity[];
  isLoading: boolean;
  placeholder: string;
  onInternalValueChange: (newInternalValue: TEntity[]) => void;
} {
  const { data: _initialValue, isLoading } = useQuery({
    queryKey: ['useCustomSelectValues', value],
    queryFn: async () => {
      return getByIds
        ? getByIds(value)
        : Promise.all(value.map((v) => getOneById!(v)));
    },
    refetchOnWindowFocus: false,
    enabled: Array.isArray(value),
  });

  useEffect(() => {
    if (_initialValue) {
      setInternalValue(_initialValue);
    }
  }, [_initialValue]);

  const [internalValue, setInternalValue] = useState<TEntity[]>(
    _initialValue || [],
  );

  let placeholder = 'Select';
  if (label && internalValue && internalValue?.length > 0) {
    placeholder = `${internalValue.length} ${label}`;
    if (internalValue?.length === 1 && internalValue[0]) {
      placeholder = (internalValue[0] as any)[itemTitle];
    }
  }

  const onInternalValueChange = (newInternalValue: TEntity[]) => {
    setInternalValue(newInternalValue);

    setValue(newInternalValue.map((v) => (v as any)[itemValue]));
  };

  return {
    internalValue,
    isLoading,
    placeholder,
    onInternalValueChange,
  };
}

interface UseCustomSelectValueProps<TValue, TEntity> {
  value: TValue;
  setValue: (v: TValue) => void;
  getOneById: (v: TValue) => Promise<TEntity>;
  label?: string;
  itemValue?: string;
  itemTitle?: string;
}

/**
 * Exactly same as useCustomSelectValues but works for single value not an array
 * @param props
 * @returns
 */
export function useCustomSelectValue<TEntity, TValue = string>(
  props: UseCustomSelectValueProps<TValue, TEntity>,
): {
  internalValue: TEntity | null;
  isLoading: boolean;
  placeholder: string;
  onInternalValueChange: (newInternalValue: TEntity) => void;
} {
  const {
    value,
    getOneById,
    setValue,
    label,
    itemTitle = 'name',
    itemValue = 'id',
  } = props;

  const { data: _initialValue, isLoading } = useQuery({
    queryKey: ['useCustomSelectValue', value],
    queryFn: () => getOneById(value),
    refetchOnWindowFocus: false,
    enabled: !!value,
  });

  useEffect(() => {
    if (_initialValue) {
      setInternalValue(_initialValue ?? null);
    }
  }, [_initialValue]);

  useEffect(() => {
    if (!value) {
      setInternalValue(null);
    }
  }, [value]);

  const [internalValue, setInternalValue] = useState<TEntity | null>(
    _initialValue ?? null,
  );

  let placeholder = 'Select';
  if (label && internalValue) {
    placeholder = (internalValue as any)[itemTitle];
  }

  const onInternalValueChange = (newInternalValue: TEntity) => {
    setInternalValue(newInternalValue);

    setValue((newInternalValue as any)[itemValue]);
  };
  return {
    internalValue,
    isLoading,
    placeholder,
    onInternalValueChange,
  };
}
