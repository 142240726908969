import { BoxProps, FormLabel } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import {
  MBox,
  MButton,
  MFlex,
  MFormField,
  MInput,
  MSpinner,
  MText,
} from '~app/components/Monetize';
import { MFileUploadButton } from '~app/components/Monetize/MFileUploadButton';
import { QuoteDocumentLinkV2 } from '~app/components/Quotes/QuoteDocumentLinkV2';
import { ActiveDocumentType } from '~app/types/quoteDocumentsType';

interface QuoteDocumentsViewItemProps extends BoxProps {
  label: string;
  allowLink?: boolean;
  activeItem: ActiveDocumentType;
  linkUrl: string;
  savedItemText?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isReadOnly?: boolean;
  showControlButton?: boolean;
  dndLabel?: string;
  onSetActiveItem?: (type: ActiveDocumentType) => void;
  onAddItem?: (
    data: { type: 'file'; value: File } | { type: 'link'; value: string },
  ) => void;
  onRemoveItem?: () => void;
  onGotoSavedItem: () => void;
  saveBtnContent?: (data: { value: string; error?: string }) => React.ReactNode;
}

export const QuoteDocumentsViewItem: FC<QuoteDocumentsViewItemProps> = ({
  label,
  allowLink,
  activeItem,
  linkUrl,
  savedItemText,
  disabled = false,
  isLoading = false,
  isReadOnly = false,
  onSetActiveItem,
  onAddItem,
  onRemoveItem,
  onGotoSavedItem,
  showControlButton = false,
  dndLabel,
  saveBtnContent,
  ...rest
}: QuoteDocumentsViewItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [internalLinkUrlValue, setInternalLinkUrlValue] =
    useState<string>(linkUrl);
  const [inputError, setInputError] = useState<null | { message: string }>(
    null,
  );
  useEffect(() => {
    setInternalLinkUrlValue(linkUrl);
  }, [linkUrl]);

  // Show error message if input is not a proper URL (onBlur)
  function handleInputValidation() {
    if (linkUrl && inputRef.current) {
      const { typeMismatch, tooLong } = inputRef.current.validity;
      if (typeMismatch || tooLong) {
        setInputError({ message: 'A valid URL is required' });
        return;
      }
    }
    setInputError(null);
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (inputError) {
        return;
      }
      onAddItem && onAddItem({ type: 'link', value: inputRef.current?.value! });
    }
  };
  const shouldShowUploadControlButtons =
    (activeItem === 'none' || activeItem === 'file') &&
    !savedItemText &&
    !isReadOnly;

  return (
    <MFlex flexDir={'column'} w="100%" {...rest}>
      <FormLabel
        color="tGray.darkPurple"
        fontWeight="400"
        sx={{ textWrap: 'nowrap' }}
      >
        {label}
        {isLoading && <MSpinner size="xs" ml="2" />}
      </FormLabel>
      <MBox minWidth={0}>
        {shouldShowUploadControlButtons && (
          <MFlex alignItems="center">
            <MFileUploadButton
              accept={['pdf']}
              onFileUpload={(file) =>
                onAddItem && onAddItem({ type: 'file', value: file })
              }
              maxFileSize={10}
              onCancel={() => {
                onSetActiveItem && onSetActiveItem('none');
              }}
              renderBoxContent={({ open }) => {
                return (
                  <MButton
                    onClick={() => {
                      onSetActiveItem && onSetActiveItem('file');
                      open();
                    }}
                    variant="tertiary"
                    ml="-3"
                    size="sm"
                    isDisabled={isReadOnly || isLoading}
                  >
                    + Add Document
                  </MButton>
                );
              }}
            />

            {allowLink && (
              <>
                <MText color="tGray.darkPurple" mx="3">
                  or
                </MText>
                <MButton
                  leftIcon={<AiOutlineLink />}
                  onClick={() =>
                    !savedItemText && onSetActiveItem && onSetActiveItem('link')
                  }
                  variant="tertiary"
                  minW="auto"
                  px="2"
                  isDisabled={isReadOnly || isLoading}
                >
                  Add Link
                </MButton>
              </>
            )}
          </MFlex>
        )}

        {!savedItemText && !disabled && activeItem === 'link' && (
          <MFlex align="flex-start" gap={2}>
            <MFormField error={inputError}>
              <MInput
                ref={inputRef}
                placeholder="https://"
                maxLength={255}
                type="url"
                onBlur={handleInputValidation}
                onChange={(event) => {
                  if (saveBtnContent) {
                    setInternalLinkUrlValue(event.target.value);
                    if (inputRef.current) {
                      const { typeMismatch, tooLong } =
                        inputRef.current.validity;
                      if (typeMismatch || tooLong) {
                        setInputError({ message: 'A valid URL is required' });
                        return;
                      }
                    }
                    setInputError(null);
                  } else {
                    onAddItem &&
                      onAddItem({ type: 'link', value: event.target.value });
                  }
                }}
                onKeyDown={handleKeyDown}
                value={saveBtnContent ? internalLinkUrlValue : linkUrl}
              />
            </MFormField>
            {saveBtnContent &&
              saveBtnContent({
                error: inputError?.message,
                value: inputRef.current?.value ?? '',
              })}
          </MFlex>
        )}

        {!savedItemText && isReadOnly && (
          <MText fontStyle="italic" mb="2">
            No document added
          </MText>
        )}

        {savedItemText && (
          <MBox mr="auto" mt="1">
            <QuoteDocumentLinkV2
              showDeleteIcon
              fileName={savedItemText}
              isReadOnly={isReadOnly}
              disabled={disabled}
              onClick={onGotoSavedItem}
              onRemoveItem={onRemoveItem}
              showLinkIcon={!!linkUrl}
            />
          </MBox>
        )}
      </MBox>
    </MFlex>
  );
};
