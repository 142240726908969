import { useDisclosure } from '@chakra-ui/react';
import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { MdEdit, MdInfo } from 'react-icons/md';
import {
  Route,
  Routes,
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetAccountById } from '~api/accountsService';
import { ContactFormModal } from '~app/components/Contacts/ContactFormModal';
import ContractModal from '~app/components/Contracts/ContractModal';
import {
  MButton,
  MCustomIconButton,
  MDropdownActionItem,
  MDropdownActions,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MPageHeaderLeftSection } from '~app/components/Monetize/MPageHeaderLeftSection';
import { MSearchInput } from '~app/components/Monetize/MPageSearchInput/MSearchInput';
import MCustomLinkTab from '~app/components/Monetize/MTabs/MCustomLinkTab';
import {
  MBox,
  MFlex,
  MIcon,
  MTabList,
  MTabs,
} from '~app/components/Monetize/chakra';
import { NotesDrawer } from '~app/components/Notes/NotesDrawer';
import { AddPaymentMethodForm } from '~app/components/PaymentMethods/Form/AddPaymentMethodForm';
import {
  AccountStatusDisplayText,
  billGroupDetailsTabs,
} from '~app/constants/accounts';
import useCurrentPaymentGateways from '~app/hooks/useCurrentPaymentGateways';
import { ACLCheck } from '~app/services/acl/ACLCheck';
import { useACL } from '~app/services/acl/acl';
import { BillGroupTabTypes } from '~app/types';
import { ROUTES } from '~constants';
import { useDocumentHead } from '~services/documentHead';
import { FeatureFlag, useFlags } from '../../services/launchDarkly';
import AccountFormModal from '../Accounts/AccountFormModal';
import { PrivateRoute } from '../AppPrivateRoute';
import { AccountDetailsBillGroupFilter } from './AccountDetailsBillGroupFilter';
import AccountHistoryDrawer from './AccountHistory/AccountHistoryDrawer';
import { BillGroupListPage } from './BillGroups/BillGroupListPage';
import BillGroupModalPage from './BillGroups/BillGroupModalPage';
import { BillGroupPage } from './BillGroups/BillGroupPage';
import ContactList from './Contacts/ContactList';
import ContractListAccount from './Contracts/ContractListAccount';
import { CreditFormCreateModal } from './Credits/Credits/CreditFormCreateModal';
import { CreditsList } from './Credits/CreditsList';
import AccountInvoices from './Invoices/AccountInvoices';
import { OneTimeInvoiceModal } from './OneTimeInvoice/OneTimeInvoiceModal';
import AccountOverview from './Overview/AccountOverview';
import { PaymentMethodList } from './PaymentMethods/PaymentMethodList';
import { PaymentMethodModal } from './PaymentMethods/PaymentMethodModal';
import { PaymentList } from './Payments/PaymentList';
import QuoteListAccount from './Quotes/QuoteListAccount';
import { SubscriptionList } from './Subscriptions/SubscriptionList';
import { UsagePage } from './Usage/UsagePage';
import { ACCOUNT_TAB_ROUTES } from './constants';

const DropDownMenuItem = ({ children }: React.PropsWithChildren) => (
  <MText color="inherit">{children}</MText>
);
export const AccountDetailsPage: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { accountId = '' } = useParams();
  const { setDocTitle } = useDocumentHead();
  const { canDo } = useACL();
  const canUpdateAccount = canDo([['accounts', 'update']]);
  const {
    isOpen: isOneTimeInvoiceModalOpen,
    onOpen: onOneTimeInvoiceModalOpen,
    onClose: onOneTimeInvoiceModalClose,
  } = useDisclosure();

  const billGroupId = searchParams.get('billGroupId') || undefined;
  const featureFlags = useFlags();
  const {
    onetimeInvoice,
    allowBillGroupCreation,
    showUsageTabOnAccount,
    showSubscriptionOnAccount,
  } = featureFlags;
  const { currentPaymentGateway: paymentGateway } = useCurrentPaymentGateways();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { isLoading, data: accountDetails } = useGetAccountById(accountId, {
    enabled: !!accountId,
    meta: {
      showErrorToast: true,
      navigateTo: ROUTES.ACCOUNT_LIST,
    },
  });

  const [activeTabIndex, setActiveTabIndex] = useState<number>();
  const { onClose: onCloseUpdateAccountModal } = useDisclosure();

  useEffect(() => {
    setDocTitle(accountDetails?.accountName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetails]);

  useLayoutEffect(() => {
    const index = ACCOUNT_TAB_ROUTES.filter(
      ({ requiresFeatureFlag }) =>
        !requiresFeatureFlag ||
        featureFlags[requiresFeatureFlag as keyof FeatureFlag],
    ).findIndex(({ route }) => pathname.includes(route));
    setActiveTabIndex(index);
  }, [featureFlags, pathname]);

  const billGroupTabName = useMemo(() => {
    const currentPath = pathname.split('/').reverse()[0];
    return !currentPath || billGroupDetailsTabs.has(currentPath)
      ? 'details'
      : (currentPath as BillGroupTabTypes);
  }, [pathname]);

  if (isLoading || !accountDetails) {
    return <MPageLoader />;
  }

  const openNewBillGroup = () => {
    navigate(ROUTES.getBillGroupNewRoute(accountId));
  };
  const createSubscription = () => {
    navigate(ROUTES.getSubscriptionNewRoute(accountId));
  };
  const openNewQuote = () => {
    navigate(ROUTES.getAccountQuoteNewRoute(accountId));
  };
  const createCredit = () => {
    navigate(ROUTES.getAccountCreditCreate(accountId));
  };
  const createContact = () => {
    navigate(ROUTES.getAccountContactCreate(accountId));
  };
  const createPaymentMethod = () => {
    navigate(ROUTES.getAccountPaymentMethodCreate(accountId));
  };
  const editAccount = () => {
    navigate(ROUTES.getAccountEditModalRoute(accountId));
  };
  const viewAccount = () => {
    navigate(ROUTES.getAccountViewModalRoute(accountId));
  };

  return (
    <MPageContainer alignItems="stretch">
      <MPageHeader
        hasBackButton
        status={AccountStatusDisplayText[accountDetails?.status]}
        backButtonLink={ROUTES.ACCOUNT_LIST}
        title={accountDetails?.accountName}
        id={accountDetails?.id}
        copyUrl
        renderLeftSection={
          <MBox minH="53.8px">
            <MSearchInput
              apiKey="accountSearch"
              searchKey="query"
              searchOperator="equals"
              nameField="accountName"
              sortField="accountName"
              value={searchTerm || ''}
              onReset={() => {
                if (searchParams.has('billGroupId')) {
                  searchParams.delete('billGroupId');
                }
              }}
              popoverContentProps={{
                ml: 6,
              }}
              onChange={(query, isSubmitted) => {
                setSearchTerm(query);

                if (isSubmitted) {
                  navigate({
                    pathname: ROUTES.ACCOUNT_LIST,
                    search: createSearchParams({ search: query }).toString(),
                  });
                }
              }}
              generateRoute={ROUTES.getAccountDetailRoute}
              renderViewHeader={({ onToggle }) => {
                if (!accountDetails) {
                  return null;
                }
                return (
                  <MPageHeaderLeftSection
                    hasBackButton
                    status={AccountStatusDisplayText[accountDetails?.status]}
                    backButtonLink={ROUTES.ACCOUNT_LIST}
                    title={accountDetails?.accountName}
                    id={accountDetails?.id}
                    copyUrl
                    onToggle={onToggle}
                  />
                );
              }}
              clearable
            />
          </MBox>
        }
      >
        <MFlex align="center" gap="4">
          <AccountDetailsBillGroupFilter
            accountId={accountId}
            onChange={(value) => {
              if (value) {
                navigate(
                  ROUTES.getBillGroupPageRoute(`${value}`, billGroupTabName),
                );
              }
            }}
          />
          <MFlex gap="2" align="center">
            <MCustomIconButton
              variant="icon"
              btnSize={6}
              iconColor="tPurple.base"
              p={4}
              icon={MdEdit}
              data-testid="edit-account-btn"
              onClick={canUpdateAccount ? editAccount : viewAccount}
            />
            <AccountHistoryDrawer accountId={accountId} disabled={!accountId} />
            <NotesDrawer
              id={accountDetails.id}
              entity="accounts"
              disabled={!accountDetails.id}
              isReadOnly={!canUpdateAccount}
            />
          </MFlex>

          <ACLCheck
            acls={[
              ['billing', 'create'],
              ['account_contacts', 'create'],
              ['sales', 'create'],
            ]}
          >
            <MDropdownActions
              popOverContentProps={{ minW: '150px' }}
              renderTrigger={() => (
                <MButton variant="primary" data-testid="new-account-subcat-btn">
                  New
                </MButton>
              )}
            >
              {allowBillGroupCreation && (
                <ACLCheck acls={[['billing', 'create']]}>
                  <MDropdownActionItem onClick={openNewBillGroup}>
                    <DropDownMenuItem>Bill Group</DropDownMenuItem>
                  </MDropdownActionItem>
                </ACLCheck>
              )}
              <ACLCheck acls={[['account_contacts', 'create']]}>
                <MDropdownActionItem onClick={createContact}>
                  <DropDownMenuItem>Contact</DropDownMenuItem>
                </MDropdownActionItem>
              </ACLCheck>
              <ACLCheck acls={[['billing', 'create']]}>
                <MDropdownActionItem onClick={createCredit}>
                  <DropDownMenuItem>Credit</DropDownMenuItem>
                </MDropdownActionItem>
              </ACLCheck>
              {/* <ACLCheck acls={[['billing', 'create']]}>
                <MDropdownActionItem onClick={createSubscription}>
                  <DropDownMenuItem>Subscription</DropDownMenuItem>
                </MDropdownActionItem>
              </ACLCheck> */}
              {onetimeInvoice && (
                <ACLCheck acls={[['billing', 'create']]}>
                  <MDropdownActionItem onClick={onOneTimeInvoiceModalOpen}>
                    <DropDownMenuItem>Onetime Invoice</DropDownMenuItem>
                  </MDropdownActionItem>
                </ACLCheck>
              )}
              <ACLCheck acls={[['billing', 'create']]}>
                <MDropdownActionItem
                  onClick={paymentGateway ? createPaymentMethod : () => {}}
                  isDisabled={!paymentGateway}
                >
                  <MFlex
                    alignItems="center"
                    color="inherit"
                    position="relative"
                  >
                    <DropDownMenuItem>Payment Method</DropDownMenuItem>

                    {!paymentGateway && (
                      <MTooltip
                        label="You need to set a payment gateway up in your tenant settings before you can add a new payment method."
                        placement="bottom-start"
                      >
                        <MFlex position="relative">
                          <MIcon as={MdInfo} color="tPurple.base" ml="5" />
                        </MFlex>
                      </MTooltip>
                    )}
                  </MFlex>
                </MDropdownActionItem>
              </ACLCheck>
              <ACLCheck acls={[['sales', 'create']]}>
                <MDropdownActionItem onClick={openNewQuote}>
                  <DropDownMenuItem>Quote</DropDownMenuItem>
                </MDropdownActionItem>
              </ACLCheck>
            </MDropdownActions>
          </ACLCheck>
        </MFlex>
      </MPageHeader>
      <MFlex flex="1" ml={8} minH="0" flexDirection="column">
        <MTabs
          data-testid="account-detail-tabs"
          size="sm"
          index={activeTabIndex}
        >
          <MTabList alignItems="flex-end">
            {ACCOUNT_TAB_ROUTES.filter(
              ({ requiresFeatureFlag }) =>
                !(
                  requiresFeatureFlag &&
                  !featureFlags[requiresFeatureFlag as keyof FeatureFlag]
                ),
            ).map(({ route, title }, index) => {
              return (
                <MCustomLinkTab to={route} key={index}>
                  {title}
                </MCustomLinkTab>
              );
            })}
          </MTabList>
        </MTabs>

        <Routes>
          <Route
            path="/"
            element={
              <AccountOverview
                account={accountDetails}
                billGroupId={billGroupId}
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_OVERVIEW}
            element={
              <AccountOverview
                account={accountDetails}
                billGroupId={billGroupId}
              />
            }
          >
            {/* Nested under account overview */}
            <Route
              path={ROUTES.ACCOUNT_BILL_GROUP_NEW}
              element={
                <PrivateRoute
                  acls={[['billing', 'create']]}
                  component={<BillGroupModalPage />}
                />
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_CONTACTS}
            element={
              <PrivateRoute
                acls={[['account_contacts', 'read']]}
                component={<ContactList />}
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_CONTACTS_CREATE}
            element={
              <PrivateRoute
                acls={[['account_contacts', 'create']]}
                component={
                  <ContactFormModal
                    isOpen
                    accountId={accountId}
                    onClose={() =>
                      navigate(ROUTES.getAccountContactsRoute(accountId))
                    }
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_CONTACTS_EDIT}
            element={
              <PrivateRoute
                acls={[['account_contacts', 'update']]}
                component={
                  <ContactFormModal
                    isOpen
                    accountId={accountId}
                    onClose={() =>
                      navigate(ROUTES.getAccountContactsRoute(accountId))
                    }
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_CONTACTS_VIEW}
            element={
              <PrivateRoute
                acls={[['account_contacts', 'read']]}
                component={
                  <ContactFormModal
                    isOpen
                    isReadOnly
                    accountId={accountId}
                    onClose={() =>
                      navigate(ROUTES.getAccountContactsRoute(accountId))
                    }
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_BILL_GROUPS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<BillGroupListPage billGroupId={billGroupId} />}
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_BILL_GROUP_NEW}
            element={
              <PrivateRoute
                acls={[['billing', 'create']]}
                component={<BillGroupModalPage />}
              />
            }
          />

          <Route
            path={ROUTES.ACCOUNT_BILL_GROUP_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<BillGroupPage isReadOnly />}
              />
            }
          />
          {showSubscriptionOnAccount && (
            <Route
              path={ROUTES.ACCOUNT_SUBSCRIPTIONS}
              element={
                <PrivateRoute
                  acls={[['billing', 'read']]}
                  component={
                    <SubscriptionList
                      account={accountDetails}
                      billGroupId={billGroupId}
                    />
                  }
                />
              }
            />
          )}
          {showUsageTabOnAccount && (
            <Route
              path={ROUTES.ACCOUNT_USAGE}
              element={
                <PrivateRoute
                  acls={[['billing', 'read']]}
                  component={
                    <UsagePage
                      billGroupId={billGroupId}
                      account={accountDetails}
                    />
                  }
                />
              }
            />
          )}
          <Route
            path={ROUTES.ACCOUNT_PAYMENT_METHODS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={<PaymentMethodList />}
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_PAYMENT_METHODS_CREATE}
            element={
              <PrivateRoute
                acls={[['billing', 'create']]}
                component={
                  <AddPaymentMethodForm
                    isOpen
                    accountId={accountId}
                    onClose={() => {
                      navigate(ROUTES.getAccountPaymentMethodRoute(accountId));
                    }}
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_PAYMENT_METHODS_VIEW}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <PaymentMethodModal
                    isOpen
                    onClose={() =>
                      navigate(ROUTES.getAccountPaymentMethodRoute(accountId))
                    }
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_PAYMENTS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <PaymentList
                    account={accountDetails}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />

          <Route
            path={ROUTES.ACCOUNT_CREDITS}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <CreditsList
                    account={accountDetails}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_CREDITS_CREATE}
            element={
              <PrivateRoute
                acls={[['billing', 'create']]}
                component={
                  <CreditFormCreateModal
                    isOpen
                    account={accountDetails}
                    onClose={() =>
                      navigate(ROUTES.getAccountCreditsRoute(accountId))
                    }
                  />
                }
              />
            }
          />

          <Route
            path={ROUTES.ACCOUNT_QUOTES}
            element={
              <PrivateRoute
                acls={[['account_quotes', 'read']]}
                component={
                  <QuoteListAccount
                    account={accountDetails}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />
          <Route
            path={`${ROUTES.ACCOUNT_CONTRACTS}/*`}
            element={
              <PrivateRoute
                acls={[['account_quotes', 'read']]}
                component={
                  <ContractListAccount
                    account={accountDetails}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          >
            <Route
              path={ROUTES.CONTRACT_EDIT}
              element={
                <PrivateRoute
                  acls={[['account_quotes', 'read']]}
                  component={
                    <ContractModal
                      isOpen
                      onClose={() =>
                        navigate(ROUTES.getAccountContractsRoute(accountId))
                      }
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path={ROUTES.ACCOUNT_INVOICES}
            element={
              <PrivateRoute
                acls={[['billing', 'read']]}
                component={
                  <AccountInvoices
                    account={accountDetails}
                    billGroupId={billGroupId}
                  />
                }
              />
            }
          />

          {/* Edit Account Modal */}
          <Route
            path={ROUTES.ACCOUNT_EDIT_MODAL}
            element={
              <PrivateRoute
                acls={[['accounts', 'update']]}
                component={
                  <AccountFormModal
                    isOpen
                    onClose={() => {
                      onCloseUpdateAccountModal();
                      navigate(ROUTES.getAccountDetailRoute(accountId));
                    }}
                    account={accountDetails}
                  />
                }
              />
            }
          />
          <Route
            path={ROUTES.ACCOUNT_VIEW_MODAL}
            element={
              <PrivateRoute
                acls={[['accounts', 'read']]}
                component={
                  <AccountFormModal
                    isOpen
                    isReadOnly
                    onClose={() => {
                      onCloseUpdateAccountModal();
                      navigate(ROUTES.getAccountDetailRoute(accountId));
                    }}
                    account={accountDetails}
                  />
                }
              />
            }
          />
        </Routes>
      </MFlex>
      {accountDetails && (
        <OneTimeInvoiceModal
          isOpen={isOneTimeInvoiceModalOpen}
          onClose={onOneTimeInvoiceModalClose}
          accountDetails={accountDetails}
        />
      )}
    </MPageContainer>
  );
};
