import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { handleApiErrorToast } from '~app/api/axios';
import {
  doRecreateQuote,
  useChangeQuoteOwner,
  useGetContractById,
} from '~app/api/cpqService';
import { INITIAL_MANUAL_RENEWAL_VALUES } from '~app/constants/quotes';
import { getQuoteEditRoute } from '~app/constants/routes';
import { useProductOfferings, useQuoteSettings } from '~app/hooks';
import { useBackNavigate } from '~app/hooks/useBackNavigate';
import { useIsTenantEsignConfigured } from '~app/hooks/useIsTenantEsignConfigured';
import { QuoteDataTypes } from '~app/hooks/useQuote';
import useQuotePrices from '~app/hooks/useQuotePrices';
import { QuoteSettingDataTypes } from '~app/hooks/useQuoteSettings';
import { logger } from '~app/services/logger';
import {
  IContractWithQuotes,
  INewQuoteTypeReqSchema,
  IQuoteItemRespSchema,
  IQuotePrice,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  NewQuoteTypeEnum,
  NewQuoteTypeReqSchema,
  OrderableRecordsWithSequence,
  ProductTypeEnum,
  QuoteAmendmentVersionEnum,
  QuoteItemAmendmentStatusEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '~app/types';
import { debounce } from '~app/utils/debounce';
import { getQuoteRequestFromQuoteResponse } from '~app/utils/quotes';
import { useConfirmModal } from '../../../services/confirmModal';
import { useQuoteContacts } from './components/contacts/useQuoteContacts';
import {
  OpportunityLinkDataTypes,
  useOpportunityLinkData,
} from './OpportunityLink';
import { QuoteModalDataTypes } from './QuoteReviewModal';
import { updateQuoteQueryCache } from './quoteUtils';

type OfferingLoadingState = Record<
  string,
  {
    id: string;
    isLoading: boolean;
    isDeleting: boolean /** , changedFields: Set<string> */;
    modifiedItems?: Map<string, Set<string>>;
  }
>;

export interface QuoteStateDataTypes {
  /** Keeps track of which offerings are loading/deleting and which fields were modified */
  offeringLoadingState: OfferingLoadingState;
  isReviewBtnPressed: boolean;
  isSelectedOfferingAndRateIdsInactiveForInfo: boolean;
  isSelectedOfferingAndRateIdsInactiveForWarning: boolean;
  setIsSelectedOfferingAndRateIdsInactiveForWarning: (val: boolean) => void;
  setIsSelectedOfferingAndRateIdsInactiveForInfo: (val: boolean) => void;
  setOfferingLoadingState: React.Dispatch<
    React.SetStateAction<OfferingLoadingState>
  >;
  setIsReviewBtnPressed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useQuoteState = ({
  productOfferingsObj,
  quote,
  isLoading = false,
}: {
  productOfferingsObj: OrderableRecordsWithSequence;
  quote?: IQuoteRespSchema | null;
  isLoading?: boolean;
}): QuoteStateDataTypes => {
  const [
    isSelectedOfferingAndRateIdsInactiveForInfo,
    setIsSelectedOfferingAndRateIdsInactiveForInfo,
  ] = useState<boolean>(false);

  const [
    isSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForWarning,
  ] = useState<boolean>(false);

  // Keeps track of the review btn pressed
  const [isReviewBtnPressed, setIsReviewBtnPressed] = useState<boolean>(false);
  const [offeringLoadingState, setOfferingLoadingState] =
    useState<OfferingLoadingState>({});

  useEffect(() => {
    if (!isLoading && quote && productOfferingsObj.orderedIds.length > 0) {
      const selectedOfferingAndRateIdsAndItems: [
        string,
        string,
        IQuoteItemRespSchema[],
      ][] = quote?.quoteOfferings?.map(({ offeringId, rateId, items }) => [
        offeringId,
        rateId,
        items,
      ]);

      if (selectedOfferingAndRateIdsAndItems?.length) {
        setIsSelectedOfferingAndRateIdsInactiveForWarning(
          selectedOfferingAndRateIdsAndItems.some(
            ([offeringId, rateId, items]) => {
              const isOfferingOrRateActive: boolean =
                !!productOfferingsObj.byId[offeringId]?.ratesObj?.byId[rateId];
              return (
                !isOfferingOrRateActive &&
                items?.some(
                  ({ amendmentStatus }) =>
                    amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
                )
              );
            },
          ),
        );

        if (
          quote?.type === QuoteTypeEnum.AMENDMENT ||
          quote?.type === QuoteTypeEnum.RENEWAL
        ) {
          setIsSelectedOfferingAndRateIdsInactiveForInfo(
            selectedOfferingAndRateIdsAndItems.some(
              ([offeringId, rateId, items]) => {
                const isOfferingOrRateActive: boolean =
                  !!productOfferingsObj.byId[offeringId]?.ratesObj?.byId[
                    rateId
                  ];
                return (
                  !isOfferingOrRateActive &&
                  items?.some(
                    ({ amendmentStatus }) =>
                      amendmentStatus !== QuoteItemAmendmentStatusEnum.ADDED,
                  )
                );
              },
            ),
          );
        }
      }
    }

    // If there is selected quoteOfferings but no productOfferings is empty
    // then the banner should be shown
    if (
      !isLoading &&
      quote?.quoteOfferings &&
      quote?.quoteOfferings?.length > 0 &&
      !productOfferingsObj.orderedIds.length
    ) {
      setIsSelectedOfferingAndRateIdsInactiveForInfo(false);
    }
  }, [quote, productOfferingsObj, isLoading]);

  return {
    offeringLoadingState,
    isReviewBtnPressed,
    isSelectedOfferingAndRateIdsInactiveForInfo,
    isSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForWarning,
    setIsSelectedOfferingAndRateIdsInactiveForInfo,
    setOfferingLoadingState,
    setIsReviewBtnPressed,
  };
};

type QuoteContextTypes = {
  isReadOnly: boolean;
  isLoading: boolean;
  isError: boolean;
  methods: UseFormReturn<IQuoteRequestSchema, object>;
  manualRenewalFormMethods: UseFormReturn<INewQuoteTypeReqSchema, object>;
  quoteData: QuoteDataTypes;
  productOfferingsData: ReturnType<typeof useProductOfferings>;
  opportunityLinkData: OpportunityLinkDataTypes;
  isTenantEsignConfigured: boolean;
  quoteStateData: QuoteStateDataTypes;
  reviewQuoteModalData: QuoteModalDataTypes;
  showPrimaryContactRequiredBg: boolean;
  quoteSettingsData: QuoteSettingDataTypes;
  quotePrices: IQuotePrice[];
  isEsignEnabled: boolean;
  /** Update quote without using the quote form, the results of the update will re-initialize the form state. */
  handleUpdateQuoteWithFormReset: (quote: IQuoteRequestSchema) => Promise<void>;
  handleReview: (data?: IQuoteReviewReq) => void;
  /** Debounced form submission, only submits if form is flagged as dirty. */
  handleSubmitButton: () => void;
  /** Debounced form submission that applies even if form is not considered dirty. */
  handleSubmitButtonWithoutDirtyCheck: () => void;
  handleBackButton: () => void;
  handleChangeOwner: (ownerId: string) => void;
  isInternalView: boolean;
  setIsInternalView: (val: boolean) => void;
  handleEdit: () => void;
  actionRunning: boolean;
  handleSendQuote: () => void;
  handleRecreateQuote: () => void;
  useAmendmentV2: boolean;
  loadData: () => void;
  reviewQuoteDisplayWidth: number;
  setReviewQuoteDisplayWidth: (val: number) => void;
  handleBackButtonPress: () => void;
  quoteContacts: ReturnType<typeof useQuoteContacts>;
  isQuoteSaving: boolean;
  setIsQuoteSaving: (val: boolean) => void;
  contract?: IContractWithQuotes;
};

const initialContext = {
  isReadOnly: false,
  isLoading: false,
  isError: false,
  handleReview: (data?: IQuoteReviewReq) => {},
  handleSubmitButton: () => {},
  handleSubmitButtonWithoutDirtyCheck: () => {},
  handleBackButton: () => {},
  handleChangeOwner: () => {},
};

export const checkIfOneTime = (item: any) =>
  item?.productType === ProductTypeEnum.ONETIME;

export const QuoteContext = createContext<QuoteContextTypes>(
  initialContext as any,
);

export const useQuoteContext = () => {
  const context = useContext(QuoteContext);
  if (!context) {
    throw new Error(
      `useQuoteContext must be used rendered within the QuoteContextProvider`,
    );
  }
  return context;
};

interface QuoteContextProviderProps {
  initialQuote?: IQuoteRespSchema | null;
  isReadOnly: boolean;
  children?: React.ReactNode;
  isInternalView: boolean;
  quoteData: QuoteDataTypes;
  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  productOfferingsData: ReturnType<typeof useProductOfferings>;
  changeOwnerData: ReturnType<typeof useChangeQuoteOwner>;
  quoteStateData: ReturnType<typeof useQuoteState>;
  isLoading: boolean;
  onSubmit: (val: IQuoteRequestSchema) => Promise<IQuoteRespSchema | null>;
  handleReview: (extraData?: IQuoteReviewReq) => void;
  setIsInternalView: (val: boolean) => void;
}

export const QuoteContextProvider: FC<QuoteContextProviderProps> = ({
  isReadOnly,
  children,
  isInternalView,
  quoteData,
  quoteFormMethods,
  reviewQuoteModalData,
  productOfferingsData,
  changeOwnerData,
  quoteStateData,
  isLoading,
  onSubmit,
  handleReview,
  setIsInternalView,
}) => {
  const [reviewQuoteDisplayWidth, setReviewQuoteDisplayWidth] = useState(400);
  const [actionRunning, setActionRunning] = useState<boolean>(false);
  const [isQuoteSaving, setIsQuoteSaving] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const isMounted = useRef(true);
  const { data: contract, isLoading: isContractLoading } = useGetContractById(
    quoteData.quote?.contractId!,
    {
      enabled: !!quoteData.quote?.contractId,
    },
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const { quotePrices } = useQuotePrices(quoteData.quote?.id!);
  const { isError, quote, transitionQuoteStatus, fetchQuote } = quoteData;

  const { mutate: doChangeQuoteOwner, isPending: loadingChangeOwner } =
    changeOwnerData;

  const loadData = () => quote?.id && fetchQuote(quote.id);
  // useOpportunityLinkData hook for handling opportunityLink components states
  const opportunityLinkData = useOpportunityLinkData({ quote, loadData });
  const isTenantEsignConfigured = useIsTenantEsignConfigured();
  const quoteSettingsData = useQuoteSettings();
  const isEsignEnabled = isTenantEsignConfigured && !!quote?.requiresEsign;

  const params = useParams();
  const { navigate, navigateBack } = useBackNavigate();
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();

  const id = params.quoteId || '';

  const {
    reset,
    handleSubmit,
    formState: { isDirty },
  } = quoteFormMethods;

  //useForm hook for handling form states of manual renewal
  const manualRenewalFormMethods = useForm<INewQuoteTypeReqSchema>({
    mode: 'onChange',
    resolver: zodResolver(NewQuoteTypeReqSchema),
    defaultValues: INITIAL_MANUAL_RENEWAL_VALUES,
  });

  const showPrimaryContactRequiredBg = !!(
    quote?.quoteOfferings &&
    quote.quoteOfferings.length > 0 &&
    !quote.contacts.primary
  );

  const onError = (error: any, e: any) => {
    // Make sure we see this log when it fails
    logger.error(error);
  };

  useEffect(() => {
    (async () => {
      if (quote?.revenueMetrics.previousArr) {
        manualRenewalFormMethods.reset({
          type: NewQuoteTypeEnum.MANUAL_RENEWAL,
          previousArr: quote?.revenueMetrics.previousArr,
        });
      }
    })();
  }, [quote]);

  /**
   * Use for any quote updates that are made outside the context of the standard quote form (e.x. quote documents)
   * This ensures the form gets reset with the latest quote data do that subsequent updates
   * do not overwrite the previously saved data.
   */
  const handleUpdateQuoteWithFormReset = async (
    quoteRequest: IQuoteRequestSchema,
  ) => {
    const quoteResponse = await onSubmit(quoteRequest);
    quoteResponse && reset(getQuoteRequestFromQuoteResponse(quoteResponse));
  };

  const handleSubmitButton = debounce(
    () => isDirty && handleSubmit(onSubmit, onError)(),
    300,
  );
  const handleSubmitButtonWithoutDirtyCheck = debounce(
    () => handleSubmit(onSubmit, onError)(),
    300,
  );

  const handleChangeOwner = async (ownerId: string) => {
    await doChangeQuoteOwner({ quoteId: quote?.id!, ownerId });
  };

  const handleEdit = async () => {
    const isQuoteSentWithEsign =
      quote?.status === QuoteStatusEnum.SENT && isEsignEnabled;

    const quoteHasApprovals =
      [QuoteStatusEnum.APPROVED, QuoteStatusEnum.SENT].includes(
        quote?.status as QuoteStatusEnum,
      ) && (quote?.approvals || []).length > 0;

    if (quoteHasApprovals || isQuoteSentWithEsign) {
      const onYes = async () => {
        try {
          setModalLoading(true);
          const quoteResponse = await transitionQuoteStatus(id, {
            newState: QuoteStatusEnum.DRAFT,
          });

          if (quoteResponse) {
            updateQuoteQueryCache(queryClient, quoteResponse);
          }
          setIsInternalView(true);
        } catch (ex) {
          handleApiErrorToast(ex);
        } finally {
          closeModal();
          setModalLoading(false);
        }
      };

      const title = isQuoteSentWithEsign
        ? 'Cancel the eSign process?'
        : `Are you sure you want to edit this quote?`;

      const description = isQuoteSentWithEsign
        ? 'Editing this Quote will cancel the eSign process.  Any signatures received to date will be voided.'
        : `If you edit this quote, you will lose any approvals this quote has been given`;

      showConfirmModal({
        title,
        description,
        onYes,
        yesBtnProps: {
          variant: 'delete' as any,
        },
        noBtnProps: {
          variant: 'cancel' as any,
        },
      });
    } else {
      try {
        const quoteResponse = await transitionQuoteStatus(id, {
          newState: QuoteStatusEnum.DRAFT,
        });
        setIsInternalView(true);
        if (quoteResponse) {
          updateQuoteQueryCache(queryClient, quoteResponse);
        }
      } catch (ex) {
        handleApiErrorToast(ex);
      }
    }
  };

  const handleSendQuote = useCallback(async () => {
    try {
      if (!quote?.id) {
        return;
      }
      setActionRunning(true);
      await transitionQuoteStatus(quote.id, { newState: QuoteStatusEnum.SENT });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setActionRunning(false);
    }
  }, [quote?.id, transitionQuoteStatus]);

  const handleRecreateQuote = useCallback(async () => {
    try {
      if (!quote?.id) {
        return;
      }
      setActionRunning(true);
      const newQuote = await doRecreateQuote(quote.id);
      navigate({
        pathname: getQuoteEditRoute(newQuote.id),
      });
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      setActionRunning(false);
    }
  }, [navigate, quote?.id]);

  const handleBackButtonPress = () => navigate(-1);

  const quoteContacts = useQuoteContacts({
    initialQuoteContacts: quote?.contacts,
    quoteId: quote?.id,
    quote,
    isReadOnly,
    accountId: quote?.accountId,
    evaluateQuoteRules: quoteData.evaluateQuoteRules,
  });

  const providerValue: QuoteContextTypes = {
    isLoading,
    isError,
    methods: quoteFormMethods,
    manualRenewalFormMethods,
    opportunityLinkData,
    isTenantEsignConfigured,
    quoteData,
    productOfferingsData,
    quoteStateData,
    reviewQuoteModalData,
    showPrimaryContactRequiredBg,
    quoteSettingsData,
    quotePrices,
    isEsignEnabled,
    handleUpdateQuoteWithFormReset,
    handleReview,
    handleSubmitButton,
    handleSubmitButtonWithoutDirtyCheck,
    handleBackButton: navigateBack,
    handleChangeOwner,
    isReadOnly,
    isInternalView,
    setIsInternalView,
    handleEdit,
    actionRunning,
    handleSendQuote,
    handleRecreateQuote,
    useAmendmentV2:
      quote?.amendmentVersion === QuoteAmendmentVersionEnum.v2 &&
      quote?.type === QuoteTypeEnum.AMENDMENT,
    loadData,
    reviewQuoteDisplayWidth,
    setReviewQuoteDisplayWidth,
    handleBackButtonPress,
    quoteContacts,
    isQuoteSaving:
      isQuoteSaving || loadingChangeOwner || quoteContacts.isLoading,
    setIsQuoteSaving,
    contract,
  };

  return (
    <QuoteContext.Provider value={providerValue}>
      <FormProvider {...quoteFormMethods}>{children}</FormProvider>
    </QuoteContext.Provider>
  );
};
