import { zodResolver } from '@hookform/resolvers/zod';
import { FunctionComponent as FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  MButton,
  MCenterModal,
  MPageLoader,
  MRadio,
  MStack,
  MText,
} from '~components/Monetize';
import {
  IInvoiceRespSchema,
  ISharedInvoicePaymentUI,
  SharedInvoicePaymentExistingMethod,
  SharedInvoicePaymentExistingMethodSchema,
  SharedInvoicePaymentSchemaUI,
} from '~types';
import { handleApiErrorToast } from '../../../api/axios';
import {
  useGetPaymentMethodsByInvoiceShareId,
  usePayInvoiceWithPaymentMethodForInvoiceShare,
} from '../../../api/shareService';
import { logger } from '../../../services/logger';
import { usePayInvoiceWithStripeAnonymous } from '../usePayInvoiceWithStripeAnonymous';
import { PendingVerificationBanner } from './PendingVerificationBanner';
import { SharedInvoiceFormExistingPaymentMethod } from './SharedInvoiceFormExistingPaymentMethod';
import { SharedInvoiceFormNewPaymentMethod } from './SharedInvoiceFormNewPaymentMethod';

interface SharedInvoiceFormFieldsProps {
  allowPartialPayment: boolean;
  invoice: IInvoiceRespSchema;
  invoiceSecretId: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  publicKey: string;
  setupIntent: string;
  tenantId: string;
  onClose: () => void;
}

export const SharedInvoiceFormFields: FC<SharedInvoiceFormFieldsProps> = ({
  allowPartialPayment,
  invoice,
  invoiceSecretId,
  isDisabled,
  isLoading,
  isOpen,
  publicKey,
  tenantId,
  onClose,
}: SharedInvoiceFormFieldsProps) => {
  // Fetch existing payment methods, default to first if available
  const { data: paymentMethods, isLoading: isPaymentMethodsLoading } =
    useGetPaymentMethodsByInvoiceShareId(
      { tenantId, invoiceSecretId },
      {
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    if (paymentMethods && paymentMethods?.length > 0) {
      setPaymentType('existing');
    }
  }, [paymentMethods]);

  const { mutateAsync: createPaymentExistingPaymentMethod } =
    usePayInvoiceWithPaymentMethodForInvoiceShare();

  const hasPaymentMethods = paymentMethods && paymentMethods?.length > 0;
  const [paymentType, setPaymentType] = useState<'existing' | 'new'>(() =>
    hasPaymentMethods ? 'existing' : 'new',
  );
  const [isSaving, setIsSaving] = useState(() => false);

  const {
    handleFormSubmit: savePaymentMethodAndPayment,
    isSavePaymentLoading,
    requiresVerification,
    stripeLoading,
  } = usePayInvoiceWithStripeAnonymous({
    allowPartialPayment,
    amountDue: invoice.amountDue,
    invoiceSecretId,
    onSuccess: onClose,
    tenantId,
  });

  const existingPaymentMethodFormState =
    useForm<SharedInvoicePaymentExistingMethod>({
      resolver: zodResolver(SharedInvoicePaymentExistingMethodSchema),
      mode: 'onChange',
      defaultValues: {
        amount: invoice.amountDue,
        paymentMethodId: paymentMethods?.[0]?.id || '',
      },
    });

  const newPaymentMethodFormState = useForm<ISharedInvoicePaymentUI>({
    resolver: zodResolver(SharedInvoicePaymentSchemaUI),
    mode: 'onChange',
    defaultValues: {
      amount: invoice.amountDue,
      stripePublicKey: publicKey,
      stripeData: {
        empty: true,
        complete: false,
        type: 'card',
      },
    },
  });

  const onError = (errs: any, event: any) => {
    logger.error('Error submitting payment form', errs, event);
  };

  const handleExistingPaymentMethodFormSubmit = async (
    body: SharedInvoicePaymentExistingMethod,
  ) => {
    try {
      setIsSaving(true);
      await createPaymentExistingPaymentMethod({
        tenantId,
        invoiceSecretId,
        body,
      });
      onClose();
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setIsSaving(false);
    }
  };

  const handleNewPaymentMethodFormSubmit = async (
    body: ISharedInvoicePaymentUI,
  ) => {
    // Parent has try catch, so this call-site should never fail
    setIsSaving(true);
    await savePaymentMethodAndPayment(body);
    setIsSaving(false);
  };

  return (
    <MCenterModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Pay Invoice"
      size="sm"
      renderFooter={() => (
        <MStack
          spacing={4}
          direction="row"
          align="center"
          justify="right"
          flex={1}
        >
          {requiresVerification && (
            <MButton
              type="button"
              onClick={onClose}
              variant="cancel"
              minW="auto"
            >
              Close
            </MButton>
          )}

          {!requiresVerification && (
            <>
              <MButton onClick={onClose} variant="cancel" minW="auto">
                Cancel
              </MButton>
              <MButton
                type="submit"
                form="payment-form"
                variant="primary"
                isLoading={
                  stripeLoading || isSavePaymentLoading || isLoading || isSaving
                }
                isDisabled={
                  stripeLoading || isSavePaymentLoading || isLoading || isSaving
                }
                minW="auto"
              >
                Pay
              </MButton>
            </>
          )}
        </MStack>
      )}
    >
      {isPaymentMethodsLoading && <MPageLoader maxH={100} />}
      {!isPaymentMethodsLoading && (
        <form
          id="payment-form"
          onSubmit={
            paymentType === 'existing'
              ? existingPaymentMethodFormState.handleSubmit(
                  handleExistingPaymentMethodFormSubmit,
                  onError,
                )
              : newPaymentMethodFormState.handleSubmit(
                  handleNewPaymentMethodFormSubmit,
                  onError,
                )
          }
        >
          <PendingVerificationBanner
            requiresVerification={requiresVerification}
          />

          {!requiresVerification && (
            <>
              {/* EXISTING PAYMENT METHOD */}
              {hasPaymentMethods && (
                <>
                  <MRadio
                    onChange={(value) => setPaymentType('existing')}
                    isChecked={paymentType === 'existing'}
                  >
                    <MText fontSize="sm" ml={2} fontWeight="bold">
                      Existing Payment Method
                    </MText>
                  </MRadio>
                  {paymentType === 'existing' && (
                    <SharedInvoiceFormExistingPaymentMethod
                      allowPartialPayment={allowPartialPayment}
                      formState={existingPaymentMethodFormState}
                      invoice={invoice}
                      isDisabled={isDisabled}
                      paymentMethods={paymentMethods || []}
                    />
                  )}
                  <MRadio
                    onChange={(value) => setPaymentType('new')}
                    isChecked={paymentType === 'new'}
                  >
                    <MText fontSize="sm" ml={2} fontWeight="bold">
                      New Payment Method
                    </MText>
                  </MRadio>
                </>
              )}
              {(!hasPaymentMethods || paymentType === 'new') && (
                <SharedInvoiceFormNewPaymentMethod
                  allowPartialPayment={allowPartialPayment}
                  formState={newPaymentMethodFormState}
                  invoice={invoice}
                  isDisabled={isDisabled}
                />
              )}
            </>
          )}
        </form>
      )}
    </MCenterModal>
  );
};
