import { atom, selector } from 'recoil';
import { Maybe } from '../../types';

export type RecordShareStateNonNull = {
  [K in keyof RecordShareState]-?: string;
};

interface RecordShareState {
  tenantId?: Maybe<string>;
  recordId?: Maybe<string>;
  token?: Maybe<string>;
}

export const recordShareState = atom<RecordShareState | null>({
  key: 'recordShareState',
  default: null,
});

// Warning - only use this in children of AnonymousRecordShareWithCodeVerifierLayout where it is guaranteed to be initialized
export const recordShareSelector = selector<RecordShareStateNonNull>({
  key: 'recordShareSelector',
  get: ({ get }) => get(recordShareState) as RecordShareStateNonNull,
});
