import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { apiDelete, apiGet, apiPost, apiPut, apiUpload } from '~api/axios';
import {
  AccountingConnectionsResponse,
  INetsuite,
  QuickBookEnvEnum,
} from '~app/types/accountingTypes';
import { IInvoiceSettings } from '~app/types/InvoiceSettingsTypes';
import {
  IQuoteApprovalMessageSchema,
  IQuoteSettingsConfigSchema,
} from '~app/types/quoteSettingsTypes';
import { ITaxConnectionVerifyReqSchema } from '~app/types/taxTypes';
import {
  ApiListResponse,
  CrmConnection,
  CrmHealthCheck,
  CrmResyncResponse,
  CrmType,
  CustomFieldEntityEnum,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  IApiKey,
  IBillPricing,
  ICurrencyResp,
  ICurrencySchema,
  IDunningCreateSchema,
  IDunnings,
  IEsign,
  IEsignSchema,
  IHistory,
  INotificationItemSchema,
  INotificationSchema,
  IPaymentCollectAutoConfigReqSchema,
  IPaymentCollectAutoConfigResSchema,
  IPaymentGateway,
  IQuoteTemplateConfigRes,
  ITeam,
  ITeamOwnerUpdateSchema,
  ITeamResponseSchema,
  ITeamUpdateSchema,
  QuoteExpirationReminderSchema,
} from '~types';
import { ApiQueryItem } from './queryUtils';
import { updateListCacheWithUpdatedItem } from './queryUtilsHelpers';
import { asQueryUtil, composeGetQuery } from './utils';

export const settingsQueryKeys = {
  base: ['settings'] as const,
  quoteExpirationReminder: () =>
    [...settingsQueryKeys.base, 'quoteExpirationReminders'] as const,
  companySetting: () => [...settingsQueryKeys.base, 'companySetting'] as const,
  companyLogo: () => [...settingsQueryKeys.base, 'companyLogo'] as const,
  customContract: () => [...settingsQueryKeys.base, 'custom-contract'] as const,
  quoteTemplate: () => [...settingsQueryKeys.base, 'quote-template'] as const,
  quoteSettings: () => [...settingsQueryKeys.base, 'quote-settings'] as const,

  crm: () => [...settingsQueryKeys.base, 'crm'] as const,
  crmSalesforce: () => [...settingsQueryKeys.crm(), 'salesforce'] as const,

  currency: () => [...settingsQueryKeys.base, 'currency'] as const,
  currencyList: () => [...settingsQueryKeys.currency(), 'list'] as const,

  dunning: () => [...settingsQueryKeys.base, 'dunning'] as const,
  dunningList: () => [...settingsQueryKeys.dunning(), 'list'] as const,
  dunningDetail: (id: string) => [...settingsQueryKeys.dunning(), id] as const,

  team: () => [...settingsQueryKeys.base, 'team'] as const,
  teamList: () => [...settingsQueryKeys.team(), 'list'] as const,
  teamDetail: (teamId: string) =>
    [...settingsQueryKeys.team(), teamId] as const,
  teamHistory: (teamId: string) =>
    [...settingsQueryKeys.team(), 'history', teamId] as const,
  pricing: () => [...settingsQueryKeys.base, 'pricing'] as const,
  apiKeyList: () => [...settingsQueryKeys.base, 'apiKeyList'] as const,
  salesQuoteApprovalMessage: () =>
    [...settingsQueryKeys.base, 'salesQuoteApprovalMessage'] as const,
  invoiceSettings: () =>
    [...settingsQueryKeys.base, 'invoiceSettings'] as const,
  earliestManualPaymentDateSettings: () =>
    [...settingsQueryKeys.base, 'earliestManualPaymentDateSettings'] as const,

  // eSignContacts
  esignConfig: () => [...settingsQueryKeys.base, 'esignConfig'] as const,
  eSignContact: () => [...settingsQueryKeys.base, 'eSignContact'] as const,
  eSignContactList: () =>
    [...settingsQueryKeys.eSignContact(), 'list'] as const,
  billGroup: () => [...settingsQueryKeys.base, 'billGroup'] as const,
  billGroupConfig: () => [...settingsQueryKeys.billGroup(), 'config'] as const,

  // Notifications
  notifications: () => [...settingsQueryKeys.base, 'notifications'] as const,

  // Accounting
  accounting: () => [...settingsQueryKeys.base, 'accounting'] as const,

  // Invoices
  invoiceTemplate: () =>
    [...settingsQueryKeys.base, 'invoice-template'] as const,
  // CustomFields

  customFieldsList: () =>
    [...settingsQueryKeys.base, 'customFieldsList'] as const,
  customFieldsById: (entity: string, key: string) =>
    [...settingsQueryKeys.customFieldsList(), entity, key] as const,

  customFieldsListByEntity: (
    entity: CustomFieldEntityEnum | Array<CustomFieldEntityEnum>,
  ) => [...settingsQueryKeys.base, 'customFieldsList', entity] as const,

  tax: () => [...settingsQueryKeys.base, 'tax'] as const,
  taxByProvider: (provider: string) =>
    [...settingsQueryKeys.tax(), provider] as const,
};

const customFieldQueryKeys: ApiQueryItem = {
  byId: {
    endpoint: (id: string, args) =>
      `/api/configurations/customFields/${args.entity}/${args.key}`,
    queryKey: (id: string, args) =>
      settingsQueryKeys.customFieldsById(args.entity, args.key),
  },
  update: {
    endpoint: (id: string, args) =>
      `/api/configurations/customFields/${args.entity}/${args.key}`,
    invalidateKeys: [settingsQueryKeys.customFieldsList()],
    setDataKey: (id: string, args) =>
      settingsQueryKeys.customFieldsById(args.entity, args.key),
  },
  create: {
    endpoint: () => `/api/configurations/customFields`,
    invalidateKeys: [settingsQueryKeys.customFieldsList()],
    setDataKey: (id: string, args) =>
      settingsQueryKeys.customFieldsById(args.entity, args.key),
  },
  list: {
    endpoint: `/api/configurations/customFields`,
    queryKey: settingsQueryKeys.customFieldsList(),
  },
};

const taxQueryKeys: ApiQueryItem = {
  list: {
    endpoint: (args) => `/api/configurations/integrations/taxes`,
    queryKey: settingsQueryKeys.tax(),
  },
  create: {
    endpoint: () => `/api/configurations/integrations/taxes`,
    invalidateKeys: [settingsQueryKeys.tax()],
    setDataKey: (id: string, args) => settingsQueryKeys.taxByProvider(id),
  },
  byId: {
    endpoint: (id: string) => `/api/configurations/integrations/taxes/${id}`,
    queryKey: (id: string) => settingsQueryKeys.taxByProvider(id),
  },
  update: {
    endpoint: (id) => `/api/configurations/integrations/taxes/${id}`,
    invalidateKeys: (id: string) => {
      return [settingsQueryKeys.tax(), settingsQueryKeys.taxByProvider(id)];
    },
    setDataKey: (id: string) => settingsQueryKeys.taxByProvider(id),
  },
  delete: {
    endpoint: (id) => `/api/configurations/integrations/taxes/${id}`,
    invalidateKeys: (id: string) => {
      return [settingsQueryKeys.tax(), settingsQueryKeys.taxByProvider(id)];
    },
    setDataKey: (id: string) => settingsQueryKeys.taxByProvider(id),
  },
};

const dunningQueryKeys: ApiQueryItem = {
  list: {
    endpoint: (args) => `/api/dunning/dunningProcess`,
    queryKey: settingsQueryKeys.dunningList(),
  },
  create: {
    endpoint: () => `/api/dunning/dunningProcess`,
    invalidateKeys: [settingsQueryKeys.dunningList()],
    setDataKey: (id: string, args) => settingsQueryKeys.dunningDetail(id),
  },
  byId: {
    endpoint: (id: string) => `/api/dunning/dunningProcess/${id}`,
    queryKey: (id: string) => settingsQueryKeys.dunningDetail(id),
  },
  update: {
    endpoint: (id) => `/api/dunning/dunningProcess/${id}`,
    invalidateKeys: (id: string) => {
      return [
        settingsQueryKeys.dunningList(),
        settingsQueryKeys.dunningDetail(id),
      ];
    },
    setDataKey: (id: string) => settingsQueryKeys.dunningDetail(id),
  },
  delete: {
    endpoint: (id) => `/api/dunning/dunningProcess/${id}`,
    invalidateKeys: (id: string) => {
      return [
        settingsQueryKeys.dunningList(),
        settingsQueryKeys.dunningDetail(id),
      ];
    },
    setDataKey: (id: string) => settingsQueryKeys.dunningDetail(id),
  },
};

export const SETTING_SERVICE_API = asQueryUtil({
  customFields: customFieldQueryKeys,
  tax: taxQueryKeys,
  dunnings: dunningQueryKeys,
});

// Dunning
export function useGetDunningProcesses<T = ApiListResponse<IDunnings>>({
  config = {},
  filters,
  options,
}: {
  config?: GetListApiConfig;
  filters?: GetListApiFilter;
  options?: Partial<UseQueryOptions<ApiListResponse<IDunnings>, unknown, T>>;
} = {}) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...settingsQueryKeys.dunningList(), params],
    queryFn: () =>
      apiGet<ApiListResponse<IDunnings>>('/api/dunning/dunningProcess', {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

export function useGetDunningById(
  dunningProcessId: string | undefined,
  options: Partial<UseQueryOptions<IDunningCreateSchema>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.dunningDetail(dunningProcessId!)],
    queryFn: () =>
      apiGet<IDunningCreateSchema>(
        `/api/dunning/dunningProcess/${dunningProcessId}`,
      ).then((res) => res.data),
    ...options,
    enabled: !!dunningProcessId,
    refetchOnWindowFocus: false,
  });
}

// Teams API

export function useCreateTeam(
  options: Partial<
    UseMutationOptions<ITeamResponseSchema, unknown, ITeam>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<ITeamResponseSchema, unknown, ITeam>({
    mutationFn: (payload) => {
      return apiPost<ITeamResponseSchema>(`/api/teams`, payload).then(
        (res) => res.data,
      );
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        [...settingsQueryKeys.teamDetail(data.id)],
        data,
      );
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.teamHistory(data.id)],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useUpdateTeam(
  { teamId }: { teamId: string | undefined },
  options: Partial<
    UseMutationOptions<ITeamResponseSchema, unknown, ITeamUpdateSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<ITeamResponseSchema, unknown, ITeamUpdateSchema>({
    mutationFn: (payload) => {
      return apiPut<ITeamResponseSchema>(`/api/teams/${teamId}`, payload).then(
        (res) => res.data,
      );
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        [...settingsQueryKeys.teamDetail(data.id)],
        data,
      );
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.teamHistory(data.id)],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useChangeTeamOwner(
  options: Partial<
    UseMutationOptions<ITeamResponseSchema, unknown, ITeamOwnerUpdateSchema>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<ITeamResponseSchema, unknown, ITeamOwnerUpdateSchema>({
    mutationFn: (paylod) => {
      return apiPost<ITeamResponseSchema>(
        `/api/teams/${paylod?.id}/changeOwner/${paylod.userId}`,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(
        [...settingsQueryKeys.teamDetail(data.id)],
        data,
      );
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.teamHistory(data.id)],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function doGetTeamById(
  teamId: string | undefined,
): Promise<ITeamResponseSchema> {
  return apiGet<ITeamResponseSchema>(`/api/teams/${teamId}`).then(
    (res) => res.data,
  );
}

export function useGetTeamsById<SelectData = ITeamResponseSchema>(
  teamId: string | undefined,
  options: Partial<
    UseQueryOptions<ITeamResponseSchema, unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.teamDetail(teamId!)],
    queryFn: () => doGetTeamById(teamId),
    ...options,
    enabled: !!teamId,
    refetchOnWindowFocus: false,
  });
}

export function useGetTeams<SelectData = ApiListResponse<ITeamResponseSchema>>(
  { config, filters }: { config: GetListApiConfig; filters?: GetListApiFilter },
  options: Partial<
    UseQueryOptions<ApiListResponse<ITeamResponseSchema>, unknown, SelectData>
  > = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...settingsQueryKeys.teamList(), params],
    queryFn: () =>
      apiGet<ApiListResponse<ITeamResponseSchema>>('/api/teams', {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

/** @deprecated doGetTeams, instead use useGetTeams*/
export const doGetTeams = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<ITeam>> => {
  const url = `/api/teams`;

  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<ITeam>>(url, {
    params,
  });
  return res.data;
};

export function useGetTeamHistory(
  {
    config,
    filters,
    teamId,
  }: { config: GetListApiConfig; filters?: GetListApiFilter; teamId: string },
  options: Partial<UseQueryOptions<ApiListResponse<IHistory>>> = {},
) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...settingsQueryKeys.teamHistory(teamId), params],
    queryFn: () =>
      apiGet<ApiListResponse<IHistory>>(`/api/teams/${teamId}/history`, {
        params,
      }).then((res) => res.data),
    ...options,
    enabled: !!teamId,
  });
}

export function useDeleteTeam(
  options: Partial<
    UseMutationOptions<ITeamResponseSchema, unknown, string>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<ITeamResponseSchema, unknown, string>({
    mutationFn: (teamId) => {
      return apiDelete<ITeamResponseSchema>(`/api/teams/${teamId}`).then(
        (res) => res.data,
      );
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.team()],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}
// End  of Teams api

export function useGetTenantApiKeys({
  config,
  filters,
  options = {},
}: {
  config: GetListApiConfig;
  filters?: GetListApiFilter;
  options: Partial<UseQueryOptions<{ apiKeys: IApiKey[] }>>;
}) {
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...settingsQueryKeys.apiKeyList()],
    queryFn: () =>
      apiGet<{ apiKeys: IApiKey[] }>('/api/configurations/tenantApiKeys', {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

export function useAddTenantApiKey(
  options: Partial<UseMutationOptions<{ apiKey: string }, unknown, any>> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<{ apiKey: string }, unknown, any>({
    mutationFn: (payload) => {
      return apiPost<{ apiKey: string }>(
        `/api/configurations/tenantApiKeys`,
        payload,
      ).then((res) => res.data);
    },

    onSuccess: (...data) => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.apiKeyList()],
      });
      onSuccess && onSuccess(...data);
    },
    ...restOptions,
  });
}

export function useRevokeTenantApiKey(
  options: Partial<UseMutationOptions<void, unknown, string>> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<void, unknown, string>({
    mutationFn: (apiKeyId) => {
      return apiPost(
        `/api/configurations/tenantApiKeys/${apiKeyId}/revoke`,
      ).then((res) => res.data);
    },

    onSuccess: (...data) => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.apiKeyList()],
      });
      onSuccess && onSuccess(...data);
    },
    ...restOptions,
  });
}

export function useGetConfigurationPricing(
  options: Partial<UseQueryOptions<IBillPricing>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.pricing()],
    queryFn: () =>
      apiGet<IBillPricing>('/api/configurations/billing/pricing').then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useUpdateConfigurationPricing(
  options: Partial<
    UseMutationOptions<IBillPricing, unknown, IBillPricing>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<IBillPricing, unknown, IBillPricing>({
    mutationFn: (payload) => {
      return apiPut<IBillPricing>(
        `/api/configurations/billing/pricing`,
        payload,
      ).then((res) => res.data);
    },

    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(settingsQueryKeys.pricing(), data);
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useGetConfigurationEsign(
  options: Partial<UseQueryOptions<IEsign>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.esignConfig()],
    queryFn: () =>
      apiGet<IEsign>(`/api/configurations/integrations/esign`).then(
        (res) => res.data,
      ),

    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    ...options,
  });
}

/**
 * @deprecated use useGetConfigurationEsign instead
 */
export async function doGetConfigurationEsign(): Promise<IEsign> {
  const res = await apiGet<IEsign>(`/api/configurations/integrations/esign`);
  return res.data;
}

export async function doDeleteConfigurationEsign() {
  const res = await apiDelete(`/api/configurations?category=eSIGN`);
  return res.data;
}

export async function doPostConfigurationEsign(data: IEsignSchema) {
  const res = await apiPost(`/api/configurations/integrations/esign`, data);
  return res.data;
}

export async function doPutConfigurationEsign(
  data: IEsignSchema,
): Promise<IEsignSchema> {
  const res = await apiPut<IEsignSchema>(
    `/api/configurations/integrations/esign`,
    data,
  );
  return res.data;
}

export function useGetCrmConfiguration<SelectData = CrmConnection>(
  options: Partial<UseQueryOptions<CrmConnection, unknown, SelectData>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.crm()],
    queryFn: async () =>
      apiGet<CrmConnection>(`connector/connections/crm`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export async function doGetCrmAuthorizeRedirectUrl(
  type: CrmType,
  customUrl?: string | null | undefined,
) {
  const params = customUrl ? { customUrl } : {};
  const res = await apiGet<{ url: string }>(
    `connector/auth/crm/${type}/authorize`,
    { params },
  );
  return res.data;
}

export function useResyncSalesforceProductCatalog(
  options: Partial<UseMutationOptions<CrmResyncResponse, unknown, void>> = {},
) {
  return useMutation({
    mutationFn: () =>
      apiPost<CrmResyncResponse>('/connector/crm/salesforce/sync', {
        type: 'catalog',
      }).then((res) => res.data),
    ...options,
  });
}

export function useGetAccountingConnections<
  SelectData = AccountingConnectionsResponse,
>(
  options: Partial<
    UseQueryOptions<AccountingConnectionsResponse, unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.accounting()],
    queryFn: () =>
      apiGet<AccountingConnectionsResponse>(
        `/connector/connections/accounting`,
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export async function doGetAccountingAuthorizeRedirectUrl(
  type: 'quickbooks',
  env: QuickBookEnvEnum,
) {
  const res = await apiGet<{ url: string }>(
    `connector/auth/accounting/${type}/authorize?environment=${env}`,
  );
  return res.data;
}

export function useSaveNetsuiteConfiguration(
  options: Partial<UseMutationOptions<INetsuite, unknown, INetsuite>> = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation({
    mutationFn: (data) =>
      apiPost<INetsuite>(
        `connector/auth/accounting/netsuite/authorize`,
        data,
      ).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      const cache = queryClient.getQueryData<AccountingConnectionsResponse>(
        settingsQueryKeys.accounting(),
      );
      if (cache) {
        queryClient.setQueryData(settingsQueryKeys.accounting(), {
          ...cache,
          netsuite: data,
        });
      }
      queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.accounting(),
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

// notifications
export function useGetNotificationSettings(
  options: Partial<UseQueryOptions<INotificationSchema>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.notifications()],
    queryFn: () =>
      apiGet<INotificationSchema>(`/api/configurations/notifications`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export async function doVerifyConnection() {
  const res = await apiGet<CrmHealthCheck>(
    `connector/connections/crm/healthcheck`,
  );
  return res.data;
}

// api/configurations/notifications
export function useConfigureNotification(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      INotificationSchema,
      { previousNotifications?: unknown }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, onError, ...restOptions } = options;

  return useMutation({
    mutationFn: (payload) => {
      return apiPost<void>('/api/configurations/notifications', payload).then(
        (res) => res.data,
      );
    },

    onMutate: async (payload: INotificationSchema) => {
      await queryClient.cancelQueries({
        queryKey: [...settingsQueryKeys.notifications()],
      });

      const previousNotifications = queryClient.getQueryData([
        ...settingsQueryKeys.notifications(),
      ]);
      queryClient.setQueryData(settingsQueryKeys.notifications(), payload);

      return { previousNotifications, payload };
    },
    onError: (err, variables, context) => {
      onError?.(err, variables, context);
      if (context?.previousNotifications) {
        queryClient.setQueryData(
          settingsQueryKeys.notifications(),
          context.previousNotifications,
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.notifications()],
      });
    },
    ...restOptions,
  });
}

export function useConfigNotificationEmailOrDomain(
  options: Partial<
    UseMutationOptions<
      INotificationItemSchema,
      unknown,
      INotificationItemSchema
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;

  return useMutation<INotificationItemSchema, unknown, INotificationItemSchema>(
    {
      mutationFn: (payload) => {
        return apiPost<INotificationItemSchema>(
          `/api/configurations/notifications/types/${payload.type}`,
          payload.emailOrDomains,
        ).then((res) => res.data);
      },
      onSuccess: (res, payload, context) => {
        const notificationConfig: INotificationSchema | undefined =
          queryClient.getQueryData([...settingsQueryKeys.notifications()]);

        const isTypeExists = notificationConfig?.notifications.some(
          (item) => item.type === payload.type,
        );

        const updatedNotifications = notificationConfig?.notifications.map(
          (item) => {
            if (item.type === payload.type) {
              if (res) {
                return res;
              }
              return payload;
            }
            return item;
          },
        );
        if (res && !isTypeExists) {
          updatedNotifications?.push(res);
        }

        queryClient.setQueryData([...settingsQueryKeys.notifications()], {
          ...notificationConfig,
          notifications: updatedNotifications,
        });
        onSuccess && onSuccess(res, payload, context);
      },
      ...restOptions,
    },
  );
}

// Quote Email Reminders

export function useGetQuoteExpirationReminder() {
  return useQuery({
    queryKey: [...settingsQueryKeys.quoteExpirationReminder()],
    queryFn: () =>
      apiGet<QuoteExpirationReminderSchema>(
        `/api/configurations/notifications/quoteEmail`,
      ).then((res) => res.data),
  });
}

export const useSaveQuoteExpirationReminder = (
  options: Partial<
    UseMutationOptions<void, unknown, QuoteExpirationReminderSchema>
  > = {},
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<void, unknown, QuoteExpirationReminderSchema>({
    mutationFn: (payload) => {
      return apiPost<void>(
        `/api/configurations/notifications/quoteEmail`,
        payload,
      ).then((res) => res.data);
    },

    onSuccess: (res, payload, context) => {
      queryClient.setQueryData(
        [...settingsQueryKeys.quoteExpirationReminder()],
        payload,
      );
      onSuccess && onSuccess(res, payload, context);
    },
    ...restOptions,
  });
};

export const useGetCurrencySettings = <
  SelectData = ApiListResponse<ICurrencyResp>,
>(
  {
    config,
    filters,
  }: { config?: GetListApiConfig; filters?: GetListApiFilter } = {},
  options: Partial<
    UseQueryOptions<ApiListResponse<ICurrencyResp>, unknown, SelectData>
  > = {
    refetchOnWindowFocus: false,
  },
) => {
  config = config || {
    first: 0,
    rows: 1000,
    page: 0,
    sortField: 'defaultCurrency:desc,code:asc',
    sortOrder: null, // use sort orders specified in sortField
  };
  const params = composeGetQuery(config, filters);
  return useQuery({
    queryKey: [...settingsQueryKeys.currencyList(), params],
    queryFn: () =>
      apiGet<ApiListResponse<ICurrencyResp>>(`/api/currencies`, {
        params,
      }).then((res) => res.data),
    ...options,
  });
};

export const useCreateOrUpdateCurrencySettings = (
  options: Partial<
    UseMutationOptions<
      ICurrencyResp,
      unknown,
      | { action: 'create'; payload: ICurrencySchema }
      | { action: 'update'; payload: ICurrencyResp }
    >
  > = {},
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    ICurrencyResp,
    unknown,
    | { action: 'create'; payload: ICurrencySchema }
    | { action: 'update'; payload: ICurrencyResp }
  >({
    mutationFn: ({ action, payload }) => {
      if (action === 'create') {
        return apiPost<ICurrencyResp>(`/api/currencies`, payload).then(
          (res) => res.data,
        );
      } else {
        return apiPut<ICurrencyResp>(
          `/api/currencies/${payload.id}`,
          payload,
        ).then((res) => res.data);
      }
    },
    onSuccess: (data, variables, context) => {
      // TODO: ideally it would be nice if changing to default changed all other stuff to not default
      updateListCacheWithUpdatedItem(
        queryClient,
        [...settingsQueryKeys.currencyList()],
        data,
      );
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.currency()],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
};

export const useDeleteCurrencySettings = (
  options: Partial<UseMutationOptions<void, unknown, { id: string }>> = {},
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<void, unknown, { id: string }>({
    mutationFn: ({ id }) =>
      apiDelete<void>(`/api/currencies/${id}`).then((res) => res.data),

    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.currency()],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
};

export const doGetPaymentGatewaysForTenant = async (
  signal: AbortSignal,
  tenantId: string,
  config: GetListApiConfig = {},
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IPaymentGateway>> => {
  const url = `/api/paymentGateways`;
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IPaymentGateway>>(url, {
    params,
    signal,
  });
  return res.data;
};

// Quote Settings

export function useGetQuoteSettings<SelectData = IQuoteSettingsConfigSchema>(
  options: Partial<
    UseQueryOptions<IQuoteSettingsConfigSchema, unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.quoteSettings()],
    queryFn: () =>
      apiGet<IQuoteSettingsConfigSchema>(
        `/api/configurations/quoteSettings`,
      ).then((res) => res.data),
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useSaveQuoteSettings(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      IQuoteSettingsConfigSchema,
      { previousPayload: unknown }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, onError, ...restOptions } = options;
  return useMutation({
    mutationFn: (payload) => {
      return apiPost<void>('/api/configurations/quoteSettings', payload).then(
        (res) => res.data,
      );
    },
    onMutate: async (payload: IQuoteSettingsConfigSchema) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: settingsQueryKeys.quoteSettings(),
      });

      // Snapshot the previous value
      const previousPayload = queryClient.getQueryData(
        settingsQueryKeys.quoteSettings(),
      );

      // Optimistically update to the new value
      queryClient.setQueryData(settingsQueryKeys.quoteSettings(), payload);

      // Return a context with the previous and new payload
      return { previousPayload, payload };
    },
    onError: (err, variables, context) => {
      onError?.(err, variables, context);

      if (context?.previousPayload) {
        queryClient.setQueryData(
          settingsQueryKeys.quoteSettings(),
          context.previousPayload,
        );
      }
    },
    onSettled: (_) => {
      queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.quoteSettings(),
      });
    },
    ...restOptions,
  });
}

// quote Template Config
export function useGetConfigQuoteTemplate(
  options: Partial<UseQueryOptions<IQuoteTemplateConfigRes>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.quoteTemplate()],
    queryFn: () =>
      apiGet<IQuoteTemplateConfigRes>(
        `/api/configurations/quoteTemplates`,
      ).then((res) => res.data),
    ...options,
  });
}

export function useConfigQuoteTemplate(
  options: Partial<
    UseMutationOptions<
      IQuoteTemplateConfigRes,
      unknown,
      IQuoteTemplateConfigRes
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IQuoteTemplateConfigRes, unknown, IQuoteTemplateConfigRes>(
    {
      mutationFn: (payload) =>
        apiPut<IQuoteTemplateConfigRes>(`/api/configurations/quoteTemplates`, {
          defaultTemplate: 'QUOTE_DEFAULT_1', // FALLBACK TO DEFAULT TEMPLATE UNTIL BE HAS THIS DATA
          ...payload,
        }).then((res) => res.data),
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData([...settingsQueryKeys.quoteTemplate()], data);
        onSuccess && onSuccess(data, variables, context);
      },
      ...restOptions,
    },
  );
}

export function useConfigurationSalesApprovalMessage(
  options: Partial<UseQueryOptions<IQuoteApprovalMessageSchema>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.salesQuoteApprovalMessage()],
    queryFn: () =>
      apiGet<IQuoteApprovalMessageSchema>(
        `/api/configurations/sales/quoteApprovalMessage`,
      ).then((res) => res.data),

    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useGetInvoiceSettings(
  options: Partial<UseQueryOptions<IInvoiceSettings>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.invoiceSettings()],
    queryFn: () =>
      apiGet<IInvoiceSettings>(`/api/configurations/invoiceSettings`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useUpdateInvoiceSettings(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      IInvoiceSettings,
      { previousPayload: unknown }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, onError, ...restOptions } = options;

  return useMutation({
    mutationFn: (payload) => {
      return apiPut<void>(`/api/configurations/invoiceSettings`, payload).then(
        (res) => res.data,
      );
    },
    onMutate: async (payload: IInvoiceSettings) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: settingsQueryKeys.invoiceSettings(),
      });

      // Snapshot the previous value
      const previousPayload = queryClient.getQueryData(
        settingsQueryKeys.invoiceSettings(),
      );

      // Optimistically update to the new value
      queryClient.setQueryData(settingsQueryKeys.invoiceSettings(), payload);

      // Return a context with the previous and new payload
      return { previousPayload, payload };
    },
    onError: (err, variables, context) => {
      onError?.(err, variables, context);

      if (context?.previousPayload) {
        queryClient.setQueryData(
          settingsQueryKeys.quoteSettings(),
          context.previousPayload,
        );
      }
    },
    onSettled: (_) => {
      queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.invoiceSettings(),
      });
    },
    ...restOptions,
  });
}

export function useGetEarliestManualPaymentDate(
  options: Partial<UseQueryOptions<{ earliestManualPaymentDate: string }>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.earliestManualPaymentDateSettings()],
    queryFn: () =>
      apiGet<{ earliestManualPaymentDate: string }>(
        `/api/configurations/payments/earliestManualPaymentDate`,
      ).then((res) => res.data),
    ...options,
  });
}

export function useUpdateEarliestManualPaymentDate(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      { earliestManualPaymentDate: string },
      { previousPayload: unknown }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, onError, ...restOptions } = options;

  return useMutation({
    mutationFn: (payload) => {
      return apiPut<void>(
        `/api/configurations/payments/earliestManualPaymentDate`,
        payload,
      ).then((res) => res.data);
    },
    onMutate: async (payload: any) => {
      await queryClient.cancelQueries({
        queryKey: settingsQueryKeys.earliestManualPaymentDateSettings(),
      });

      const previousPayload = queryClient.getQueryData(
        settingsQueryKeys.earliestManualPaymentDateSettings(),
      );

      queryClient.setQueryData(
        settingsQueryKeys.earliestManualPaymentDateSettings(),
        payload,
      );

      return { previousPayload, payload };
    },
    onError: (err, variables, context) => {
      onError?.(err, variables, context);

      if (context?.previousPayload) {
        queryClient.setQueryData(
          settingsQueryKeys.earliestManualPaymentDateSettings(),
          context.previousPayload,
        );
      }
    },
    onSettled: (_) => {
      queryClient.invalidateQueries({
        queryKey: settingsQueryKeys.earliestManualPaymentDateSettings(),
      });
    },
    ...restOptions,
  });
}

// default payment collect automatically config
export function useGetPaymentCollectAutoConfig(
  options: Partial<UseQueryOptions<IPaymentCollectAutoConfigResSchema>> = {},
) {
  return useQuery({
    queryKey: [...settingsQueryKeys.billGroupConfig()],
    queryFn: () =>
      apiGet<IPaymentCollectAutoConfigResSchema>(
        `/api/configurations/billGroups`,
      ).then((res) => res.data),
    ...options,
  });
}

export function useConfigurePaymentCollectAutoConfig(
  options: Partial<
    UseMutationOptions<
      GenericApiResponse,
      unknown,
      IPaymentCollectAutoConfigReqSchema
    >
  > = {},
) {
  const queryClient = useQueryClient();
  return useMutation<
    GenericApiResponse,
    unknown,
    IPaymentCollectAutoConfigReqSchema
  >({
    mutationFn: (payload) => {
      return apiPost<GenericApiResponse>(
        `/api/configurations/billGroups`,
        payload,
      ).then((res) => res.data);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.billGroupConfig()],
      });
    },
    ...options,
  });
}

export const useDefaultDocumentUploader = (
  options: Partial<
    UseMutationOptions<GenericApiResponse, unknown, FormData>
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<GenericApiResponse, unknown, FormData>({
    mutationFn: async (payload) => {
      const res = await apiUpload(
        '/api/configurations/quotes/documents/upload',
        payload,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.quoteSettings()],
      });
    },
    ...options,
  });
};

export const useDefaultDocumentRemover = (
  options: Partial<
    UseMutationOptions<GenericApiResponse, unknown, string>
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation<GenericApiResponse, unknown, string>({
    mutationFn: async (payload) => {
      const res = await apiDelete(
        `/api/configurations/quotes/documents/${payload}`,
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [...settingsQueryKeys.quoteSettings()],
      });
    },
    ...options,
  });
};

/** Tax Configuration APIs */
export async function doTestTaxConnection(data: ITaxConnectionVerifyReqSchema) {
  const res = await apiPost(
    `/api/configurations/integrations/taxes/test`,
    data,
  );
  return res.data;
}
