import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  useDeletePaymentGatewayByPaymentGatewayId,
  useGetPaymentGatewaysByTenant,
  useSetDefaultPaymentGateway,
} from '~api/paymentsService';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MButton,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  MSkeleton,
  MText,
} from '~app/components/Monetize';
import { ButtonCard } from '~app/components/Monetize/Cards/ButtonCard';
import { MDataTableActions } from '~app/components/Monetize/DataTable';
import { MEntityStatusBadge } from '~app/components/Monetize/MEntityStatusBadge';
import { PaymentMethodTypeEnumDisplay } from '~app/constants/paymentMethods';
import { IPaymentGateway, PaymentGatewayStatusDisplayText } from '~app/types';
import { useDocumentHead } from '~services/documentHead';
import { StripeIcon } from '../../../../assets/icons/StripeIcon';
import StripeForm from './components/StripeForm';

const PaymentProcessor = () => {
  const [formVisible, setFormVisible] = useState<boolean>(false);
  const [gatewayId, setGatewayID] = useState<string>('');

  const { setDocTitle } = useDocumentHead();
  useEffect(() => {
    setDocTitle('Settings', 'Payment Gateway');
  }, []);

  const { isLoading, data } = useGetPaymentGatewaysByTenant({
    meta: { showErrorToast: true },
  });
  const { mutateAsync: deletePaymentGateway } =
    useDeletePaymentGatewayByPaymentGatewayId({
      onSuccess: () => {
        setGatewayID('');
      },
      onError: (err) => {
        handleApiErrorToast(err);
      },
    });

  const doSetDefaultGateway = useSetDefaultPaymentGateway({
    onSuccess: () => {
      resetFormVisible();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });
  const setDefaultPaymentGateway = (gateway: IPaymentGateway) => {
    doSetDefaultGateway.mutate({
      gatewayId: gateway.id!,
    });
  };

  const gateways = (data?.content || []) as IPaymentGateway[];

  const handleFormVisible = () => {
    setGatewayID('');
    setFormVisible(true);
  };

  const resetFormVisible = () => {
    setFormVisible(false);
  };

  const handleOpenEdit = (id: string) => {
    setGatewayID(id);
    setFormVisible(true);
  };

  const actionMenu = (rowData: IPaymentGateway) => {
    const actions = [
      {
        title: 'Set as Default',
        enabled: !rowData.defaultGateway,
        action: () => setDefaultPaymentGateway(rowData),
      },
      {
        title: 'Delete',
        color: 'danger',
        enabled: !rowData.locked,
        action: () => deletePaymentGateway(rowData),
      },
    ];

    return <MDataTableActions actions={actions} />;
  };

  const getAllowedPaymentMethodsText = (gateway: IPaymentGateway) => {
    return gateway.allowedPaymentMethods
      .filter(({ enabled }) => enabled)
      .map(({ method }) => PaymentMethodTypeEnumDisplay[method])
      .join(', ');
  };

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Payment Gateways" />

      <MBox maxW="600px">
        <MBox mb="4">
          <MText color="tGray.darkPurple">
            Add Payment Gateways here to allow processing of electronic payment
            methods like credit cards and ACH. If you do not see your specific
            gateway here, please contact support to get it added.
          </MText>
        </MBox>
        {isLoading ? (
          <MSkeleton height={24} w="full" />
        ) : (
          <MBox>
            {gateways?.length === 0 && !formVisible && (
              <ButtonCard
                h="82px"
                titleLeftContent={<StripeIcon />}
                title={'Stripe'}
                description={`Connect to Stripe`}
                isDisabled={false}
                titleRightContent={undefined}
                onClick={handleFormVisible}
              />
            )}
            {gateways?.length > 0 && !formVisible && (
              <MBox>
                {gateways?.map((item) => (
                  <Card key={item.id} variant="edit" width="37.5rem" mt="2">
                    <CardHeader>
                      <MFlex justify="space-between" align="center">
                        <MFlex alignItems="center">
                          <StripeIcon />

                          <MText color="tPurple.dark" fontSize="sm" ml="2">
                            Stripe
                          </MText>
                          {item.status && (
                            <MEntityStatusBadge
                              status={
                                PaymentGatewayStatusDisplayText[item.status]
                              }
                            />
                          )}
                        </MFlex>
                        <MFlex align="center">
                          <MBox>
                            <MButton
                              variant="tertiary"
                              size="sm"
                              color="tIndigo.base"
                              onClick={() => handleOpenEdit(item.id)}
                            >
                              Edit
                            </MButton>
                          </MBox>
                          {actionMenu(item)}
                        </MFlex>
                      </MFlex>
                    </CardHeader>
                    <CardBody>
                      <MFlex gap={8}>
                        <MFlex direction="column">
                          <MText fontWeight="bold" color="tPurple.dark">
                            Name
                          </MText>
                          <MText>{item.description}</MText>
                        </MFlex>
                        <MFlex direction="column">
                          <MText fontWeight="bold" color="tPurple.dark">
                            Payment Method Support
                          </MText>
                          <MText>{getAllowedPaymentMethodsText(item)}</MText>
                        </MFlex>
                      </MFlex>
                    </CardBody>
                  </Card>
                ))}
              </MBox>
            )}
            {formVisible && (
              <StripeForm
                gatewayId={gatewayId}
                resetFormVisible={resetFormVisible}
              />
            )}
          </MBox>
        )}
      </MBox>
    </MPageContainer>
  );
};

export default PaymentProcessor;
