import { zodResolver } from '@hookform/resolvers/zod';
import pick from 'lodash/pick';
import { ChangeEvent, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Controller, FieldPath, useForm } from 'react-hook-form';
import { MdConstruction } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MCustomSelect,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInput,
  MPageContainer,
  MPageLoader,
  MSettingsPageHeader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { MainSearchInputV2 } from '~app/components/Monetize/MCustomSelect/components/MainSearchInputV2';
import { CUSTOM_FIELDS, ROUTES } from '~app/constants';
import { SELECT_PLACEHOLDER } from '~app/constants/placeholders';
import {
  CustomFieldPermissionsEnum,
  CustomFieldPermissionsUiEnum,
  CustomFieldReqSchema,
  CustomFieldReqUiSchema,
  CustomFieldStatusEnum,
  CustomFieldTypeEnum,
  ICustomFieldReqSchema,
  ICustomFieldReqUiSchema,
  ICustomFieldResSchema,
} from '~app/types';
import { getKeyFromLabel } from '~app/utils';
import {
  nullifyEmptyStrings,
  objectToSelectOptions,
  orderObjectsBy,
  stringifyNulls,
} from '~app/utils/misc';
import { handleApiErrorToast } from '../../../../api/axios';
import { logger } from '../../../../services/logger';
import { CustomFieldsFormDropdownValues } from './CustomFieldsFormDropdownValues';

const comingSoonTypes = [
  'Multi-line Text (Text Area)',
  'Date and Time',
  'Formula',
];
const comingSoonTypesSets = comingSoonTypes.map((key) => ({
  value: key,
  title: key,
}));

const renderTypeItemContent = ({
  title,
  item,
  isSubtitle,
  isSelected,
  isHighlight,
  isDisabled,
}: {
  title: string;
  item: any;
  isSubtitle: boolean;
  isSelected: boolean;
  isHighlight: boolean;
  isDisabled: boolean;
}) => {
  return (
    <MBox
      display="flex"
      alignItems="center"
      w="100%"
      h="22px"
      position="relative"
      role="group"
    >
      <MText
        color="inherit"
        noOfLines={1}
        fontSize={isSubtitle ? '12px' : 'sm'}
      >
        {item.title}
      </MText>
      {isDisabled && (
        <MTooltip label="Coming soon" placement="bottom-start">
          <MBox position="relative" ml="2" display="flex" alignItems="center">
            <MIcon as={MdConstruction} color="tGray.disabledText" />
          </MBox>
        </MTooltip>
      )}
    </MBox>
  );
};

const getPermissionForUi = (
  permissions?: ICustomFieldResSchema['permissions'],
): CustomFieldPermissionsUiEnum => {
  if (!permissions || permissions.includes(CustomFieldPermissionsEnum.WRITE)) {
    return CustomFieldPermissionsUiEnum.EDITABLE;
  }
  if (permissions.includes(CustomFieldPermissionsEnum.READ)) {
    return CustomFieldPermissionsUiEnum.READ_ONLY;
  }
  return CustomFieldPermissionsUiEnum.HIDDEN;
};

const getPermissionForApi = (
  permissions?: CustomFieldPermissionsUiEnum,
): ICustomFieldResSchema['permissions'] => {
  if (permissions === CustomFieldPermissionsUiEnum.EDITABLE) {
    return [CustomFieldPermissionsEnum.READ, CustomFieldPermissionsEnum.WRITE];
  }
  if (permissions === CustomFieldPermissionsUiEnum.READ_ONLY) {
    return [CustomFieldPermissionsEnum.READ];
  }
  return [];
};

export const CustomFieldsForm = () => {
  const navigate = useNavigate();
  const params = useParams();
  const customFieldEntity = params.entity || '';
  const customFieldKey = params.key || '';

  const isEdit = !!customFieldEntity && !!customFieldKey;

  const {
    isLoading: isCustomFieldLoading,
    data: customField,
    isError: loadingError,
  } = useGetById<ICustomFieldResSchema>('customFields', '', {
    enabled: isEdit,
    meta: { showErrorToast: true },
    refetchOnWindowFocus: false,
    endpointArgs: { entity: customFieldEntity, key: customFieldKey },
  });

  useEffect(() => {
    if (customField) {
      setExistingFieldValues(
        new Set(customField.values?.map(({ value }) => value)),
      );
      const formData = pick(
        customField,
        Object.keys(CustomFieldReqSchema.shape),
      );
      reset({
        ...stringifyNulls({
          ...formData,
          permissions: getPermissionForUi(formData.permissions),
          values: Array.isArray(formData.values)
            ? orderObjectsBy(formData.values, ['order'])
            : [],
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customField]);

  useEffect(() => {
    if (loadingError) {
      navigate(ROUTES.CUSTOM_FIELDS_LIST);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingError]);

  const [existingFieldValues, setExistingFieldValues] = useState(
    () => new Set(customField?.values?.map(({ value }) => value)),
  );

  const onSuccess = () => {
    navigate(-1);
  };

  const onError = (err: unknown) => {
    logger.error(err);
    handleApiErrorToast(err);
  };

  const mutateUpdateCustomField = useUpdateEntity<
    ICustomFieldResSchema,
    ICustomFieldReqSchema
  >('customFields', { onSuccess, onError, endpointArgs: customField });

  const mutateCreateCustomField = useCreateEntity<
    ICustomFieldResSchema,
    ICustomFieldReqSchema
  >('customFields', { onSuccess, onError });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    setError,
    formState: { errors, isDirty },
  } = useForm<ICustomFieldReqUiSchema>({
    resolver: zodResolver(CustomFieldReqUiSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: customField
      ? {
          ...stringifyNulls(customField),
          permissions: getPermissionForUi(customField.permissions),
        }
      : {
          key: '',
          description: '',
          displayLabel: '',
          status: CustomFieldStatusEnum.ACTIVE,
          permissions: CustomFieldPermissionsUiEnum.EDITABLE,
          type: CustomFieldTypeEnum.SINGLE_LINE_TEXT,
        },
  });

  const onSave = (data: ICustomFieldReqUiSchema) => {
    const payloadUi = nullifyEmptyStrings({
      ...data,
      values:
        data.type !== CustomFieldTypeEnum.DROPDOWN
          ? []
          : data.values?.map((item, index) => ({
              ...item,
              label: item.label.trim(),
              value: item.value.trim(),
              order: index,
            })),
    });

    const parseResults = CustomFieldReqUiSchema.safeParse(payloadUi);

    if (!parseResults.success) {
      logger.warn(parseResults);
      parseResults.error.issues.forEach((issue) => {
        setError(issue.path.join('.') as FieldPath<ICustomFieldReqUiSchema>, {
          message: issue.message,
        });
      });
      return;
    }

    const payload: ICustomFieldReqSchema = {
      ...parseResults.data,
      permissions: getPermissionForApi(data.permissions),
    };

    if (isEdit) {
      return mutateUpdateCustomField.mutateAsync({
        id: '',
        payload,
      });
    }
    return mutateCreateCustomField.mutateAsync(payload);
  };

  if (isCustomFieldLoading) {
    return <MPageLoader />;
  }

  const isDropdown = watch('type') === CustomFieldTypeEnum.DROPDOWN;
  // We are keeping the button enabled if there are errors since some custom validation errors do not clear correctly
  const isDisableSaveButton = !isDirty;

  return (
    <MPageContainer
      alignItems="stretch"
      data-testid="custom-field-form"
      overflowX="scroll"
    >
      <MSettingsPageHeader
        divider={false}
        hasBackButton
        backButtonTitle="Back to Custom Fields"
        backButtonLink={ROUTES.CUSTOM_FIELDS_LIST}
        title={!isEdit ? 'New Custom Field' : 'Edit Custom Field'}
        id={customField?.id}
      >
        <MButton
          data-testid="cusom-field-form-submit-btn"
          variant="primary"
          isLoading={
            mutateUpdateCustomField.isPending ||
            mutateCreateCustomField.isPending
          }
          isDisabled={isDisableSaveButton}
          form="custom-field-form"
          type="submit"
        >
          Save
        </MButton>
      </MSettingsPageHeader>
      <MBox>
        <form id="custom-field-form" onSubmit={handleSubmit(onSave, onError)}>
          <MGrid maxWidth="512px" templateColumns="repeat(12, 1fr)" gap={4}>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.entity}
                label="Object"
                isRequired
                tooltip="The location where the custom field appears"
              >
                <Controller
                  name="entity"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      useMainInputAsSearchInput
                      MainInputComponent={MainSearchInputV2}
                      placeholder={SELECT_PLACEHOLDER}
                      items={objectToSelectOptions(
                        CUSTOM_FIELDS.CUSTOM_FIELD_ENTITY_DISPLAY,
                      )}
                      isDisabled={isEdit}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={4}>
              <MFormField error={errors.status} label="Status" isRequired>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder={SELECT_PLACEHOLDER}
                      items={objectToSelectOptions(
                        CUSTOM_FIELDS.CUSTOM_FIELD_STATUS_DISPLAY,
                      )}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={2} />

            <MGridItem colSpan={6}>
              <MFormField
                error={errors.displayLabel}
                label="Label"
                isRequired
                tooltip="This will be the name of the field you create and will be displayed above the from"
              >
                <Controller
                  name="displayLabel"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, ...rest } }) => (
                    <MInput
                      placeholder="Field Label"
                      onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                        onChange(ev);
                        !isEdit &&
                          setValue('key', getKeyFromLabel(ev.target.value), {
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                      }}
                      {...rest}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.key}
                label="API Name"
                isRequired
                tooltip="Name in code. Used to reference this field in MonetizeNow API."
              >
                <Controller
                  name="key"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <MInput
                      placeholder="API Name"
                      isDisabled={isEdit}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField error={errors.description} label="Description">
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...field } }) => (
                    <MInput
                      placeholder="Internal Description"
                      value={value || ''}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                error={errors.tooltip}
                label="Tooltip"
                tooltip='Additional information about the field that will displayed when the user hovers on the "i" icon'
              >
                <Controller
                  name="tooltip"
                  control={control}
                  defaultValue=""
                  render={({ field: { value, ...field } }) => (
                    <MInput
                      placeholder="Additional field info"
                      value={value || ''}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField error={errors.type} label="Type" isRequired>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder={SELECT_PLACEHOLDER}
                      items={objectToSelectOptions(
                        CUSTOM_FIELDS.CUSTOM_FIELD_TYPE_DISPLAY,
                      ).concat(comingSoonTypesSets as any)}
                      disabledItems={comingSoonTypes}
                      renderItemContent={renderTypeItemContent}
                      isDisabled={isEdit}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={6}>
              <MFormField
                error={errors.permissions}
                label="Visibility"
                tooltip="Determines how this field will be displayed on records"
                isRequired
              >
                <Controller
                  name="permissions"
                  control={control}
                  render={({ field }) => (
                    <MCustomSelect
                      placeholder={SELECT_PLACEHOLDER}
                      items={objectToSelectOptions(
                        CUSTOM_FIELDS.CUSTOM_FIELD_PERMISSION_UI_DISPLAY,
                      )}
                      {...field}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
          {isDropdown && (
            <MGridItem colSpan={12}>
              <DndProvider backend={HTML5Backend}>
                <CustomFieldsFormDropdownValues
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  existingFieldValues={existingFieldValues}
                />
              </DndProvider>
            </MGridItem>
          )}
        </form>
      </MBox>
    </MPageContainer>
  );
};
