import { ColumnProps } from 'primereact/column';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
  useDeleteEntity,
  useGetListData,
  usePerformEntityAction,
} from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MPageContainer,
  MSettingsPageHeader,
  shouldOpenInNewWindow,
} from '~app/components/Monetize';
import DataTableActions, {
  ActionType,
} from '~app/components/Monetize/DataTable/MDataTableActions';
import { ROUTES } from '~app/constants';
import { GuidedQuotingStatusDisplayText } from '~app/constants/guidedQuoting';
import { getGuidedQuotingRoute } from '~app/constants/routes';
import { useDocumentHead } from '~app/services/documentHead';
import { tablePagerSelector } from '~app/store/global.store';
import { FilterStateKeys } from '~app/store/store.types';
import { GuidedQuotingStatusTagStyle, IGuidedQuoting } from '~app/types';
import {
  dateBodyTemplate,
  statusBodyTemplate,
  textBodyTemplate,
} from '~app/utils/tableUtils';
import { useFlags } from '../../../../services/launchDarkly';

const GuidedQuoting = () => {
  const navigate = useNavigate();
  const { setDocTitle } = useDocumentHead();
  const { guidedSellingViaRulesService } = useFlags();

  const [pager, setPager] = useRecoilState(
    tablePagerSelector(FilterStateKeys.GUIDED_QUOTING_LIST),
  );

  const redirectToCreate = () =>
    navigate(ROUTES.SETTINGS_GUIDED_QUOTING_CREATE);

  const { data: guidedQuotingList, isLoading } = useGetListData<IGuidedQuoting>(
    guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting',
    {
      config: pager,
    },
    { meta: { showErrorToast: true } },
  );

  const loading = isLoading;

  const { mutateAsync: doActivateDeactivate } =
    usePerformEntityAction<IGuidedQuoting>(
      guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting',
      {
        endpointArgs: { skipNestedEndpoint: true },
        meta: { showErrorToast: true },
      },
    );

  const { mutateAsync: doDeleteQuestionById } = useDeleteEntity<IGuidedQuoting>(
    guidedSellingViaRulesService ? 'guidedQuoting_v2' : 'guidedQuoting',
    {
      endpointArgs: { skipNestedEndpoint: true },
      meta: { showErrorToast: true },
    },
  );

  const actionBodyTemplate = (rowData: IGuidedQuoting) => {
    const actions: ActionType[] = [
      {
        title: 'Activate',
        enabled: rowData.status === 'INACTIVE',
        confirmProps: {
          title: `Are you sure you want to activate this Guided Quoting configuration?`,
          description: 'All other configurations will be deactivated.',
        },
        action: async () => {
          doActivateDeactivate({
            id: rowData.id,
            action: 'activate',
          });

          // TODO: remove this code after we fully transition to guidedSellingViaRulesService
          if (!guidedSellingViaRulesService && guidedQuotingList) {
            for (const config of guidedQuotingList.content) {
              if (config.id !== rowData.id && config.status === 'ACTIVE') {
                doActivateDeactivate({
                  id: config.id,
                  action: 'deactivate',
                });
              }
            }
          }
        },
      },
      {
        title: 'Deactivate',
        enabled: rowData.status === 'ACTIVE',
        color: 'danger',
        confirmProps: {
          title: `Are you sure you want to deactivate this Guided Quoting configuration?`,
          description:
            'You will no longer be able to create quotes using this configuration.',
        },
        action: () => {
          doActivateDeactivate({
            id: rowData.id,
            action: 'deactivate',
          });
        },
      },
      {
        title: 'Delete',
        enabled: rowData.status !== 'ACTIVE',
        color: 'danger',
        confirmProps: {
          title: `Are you sure you want to delete this Guided Quoting configuration?`,
          description: 'This action is not reversible.',
        },
        action: () => {
          doDeleteQuestionById({
            id: rowData.id,
          });
        },
      },
    ];

    return <DataTableActions actions={actions} />;
  };

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden',
      field: 'name',
      header: 'Name',
      body: textBodyTemplate<IGuidedQuoting>('name'),
    },

    {
      className: 'overflow-hidden',
      field: 'status',
      header: 'Status',
      body: statusBodyTemplate<IGuidedQuoting, string>(
        'status',
        GuidedQuotingStatusDisplayText,
        GuidedQuotingStatusTagStyle,
      ),
    },
    {
      className: 'overflow-hidden',
      field: 'createDate',
      header: 'Date Created',
      body: dateBodyTemplate<IGuidedQuoting>('createDate'),
    },

    {
      className: 'overflow-hidden table-cell-sm',
      field: 'action',
      header: '',
      body: actionBodyTemplate,
    },
  ];

  useEffect(() => {
    setDocTitle('Settings', 'Guided Quoting');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MPageContainer alignItems="stretch">
      <MSettingsPageHeader title="Guided Quoting">
        {guidedQuotingList?.content.length !== 0 && !loading && (
          <MFlex gap={4}>
            <MButton
              variant="primary"
              onClick={redirectToCreate}
              data-testid="new-approval-btn"
            >
              New
            </MButton>
          </MFlex>
        )}
      </MSettingsPageHeader>
      <MBox width="100%">
        <MDataTable
          value={guidedQuotingList?.content}
          totalRecords={guidedQuotingList?.totalElements}
          totalPages={guidedQuotingList?.totalPages}
          pager={pager}
          setPager={setPager}
          className="p-datatable-responsive"
          rowHover
          emptyProps={{
            mainMessage:
              'Looks like there are no Guided Quoting configurations added yet.',
            btnLabel: 'New Guided Quoting Configuration',
            onClick: () => redirectToCreate(),
          }}
          onRowClick={(e) => {
            shouldOpenInNewWindow(e)
              ? window.open(getGuidedQuotingRoute(e.data.id), '_blank')
              : navigate(getGuidedQuotingRoute(e.data.id));
          }}
          loading={isLoading}
          columns={columns}
          paginator={
            guidedQuotingList
              ? guidedQuotingList.totalElements > 20
                ? true
                : false
              : false
          }
        />
      </MBox>
    </MPageContainer>
  );
};

export default GuidedQuoting;
