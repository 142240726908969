import { useCallback, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { doPrintQuoteToPdf } from '~app/api/cpqService';
import { MButton, MFlex } from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import { IQuoteRespSchema } from '~app/types';
import { getSafeFilename } from '~app/utils';
import { downloadBlobAsFile } from '~app/utils/download';

export const QuotePageCustomerBar = ({
  quote,
}: {
  quote: IQuoteRespSchema;
}) => {
  const { useQuotePdfV2 } = useFlags();
  const [loading, setLoading] = useState(false);

  const fetchDocument = useCallback(async (): Promise<ArrayBuffer | null> => {
    if (!quote) {
      return null;
    }

    return doPrintQuoteToPdf(quote.id, useQuotePdfV2);
  }, [quote, useQuotePdfV2]);

  const downloadPdf = async () => {
    setLoading(true);
    try {
      const pdfDocument = await fetchDocument();
      if (!pdfDocument) return;

      downloadBlobAsFile(
        pdfDocument,
        `${getSafeFilename(quote.description || 'quote-pdf')}.pdf`,
      );
    } catch (err) {
      handleApiErrorToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MFlex alignItems="center" justifyContent="end" my={4}>
      <MButton
        variant="tertiary"
        size="sm"
        onClick={downloadPdf}
        isLoading={loading}
      >
        Download PDF
      </MButton>
    </MFlex>
  );
};
