import { Controller, useFormContext } from 'react-hook-form';
import { MdOutlinePeople } from 'react-icons/md';
import { MAccordion, MCheckbox, MFormField } from '../../components/Monetize';
import { QuoteShareForm } from '../../types/recordShareTypes';
import { QuoteShareAccordion } from './QuoteShareAccordion';
import { QuoteShareContact } from './QuoteShareContact';

export const QuoteShareContacts = () => {
  const { control, watch } = useFormContext<QuoteShareForm>();

  const useAsShippingContact = watch('contacts.useBillingForShippingContact');

  return (
    <MAccordion
      w="100%"
      mt={4}
      mb={2}
      allowToggle
      variant="outline"
      defaultIndex={[0]}
    >
      <QuoteShareAccordion icon={MdOutlinePeople} title="Contact Details">
        <QuoteShareContact
          label="Billing Contact"
          formKeyPrefix="contacts.billingContact"
        />

        <MFormField label="" skipLabel>
          <Controller
            name="contacts.useBillingForShippingContact"
            control={control}
            defaultValue
            render={({ field: { value, ...rest } }) => (
              <MCheckbox isChecked={value} {...rest}>
                Use as Shipping Contact
              </MCheckbox>
            )}
          />
        </MFormField>

        {!useAsShippingContact && (
          <QuoteShareContact
            label="Shipping Contact"
            formKeyPrefix="contacts.shippingContact"
            mt={4}
          />
        )}
      </QuoteShareAccordion>
    </MAccordion>
  );
};
