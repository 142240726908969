import {
  UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosHeaders } from 'axios';
import groupBy from 'lodash/groupBy';
import api, { apiGet, apiPost, apiPut } from '~api/axios';
import {
  ICreditNoteReqSchema,
  ICreditNoteSchema,
} from '~app/types/creditNoteTypes';
import { sortByAlphabetically } from '~app/utils';
import {
  arrayToObject,
  nullifyEmptyStrings,
  orderObjectsBy,
} from '~app/utils/misc';
import {
  ApiListInfiniteScrollResponse,
  ApiListResponse,
  CreateInvoicePaymentRequest,
  DEFAULT_PAGER,
  GenericApiResponse,
  GetListApiConfig,
  GetListApiFilter,
  IAccount,
  IAccountDetails,
  IAccountHistoryResp,
  IAccountOverviewStat,
  IAccountRespSchema,
  IBillGroupResp,
  IBillGroupStats,
  IInvoiceContactUpdateSchema,
  IInvoiceRespSchema,
  IInvoiceUpdateSchema,
  IManualPayment,
  ImportablePaymentMethodResp,
  ImportPaymentMethodResp,
  InvoiceSummaryResp,
  IOverviewItem,
  IOverviewResponse,
  IPayment,
  IPaymentGatewayAccountSchema,
  IPaymentMethodReq,
  IPaymentMethodResp,
  IUsageEvent,
  IUsageEventListRequestSchema,
  IUsageEventRequestSchema,
  IUsageEventResSchema,
  IUsageEventUpdateReqSchema,
  IVoidCreditSchema,
  Maybe,
  PaymentGatewayClientSecretResponse,
  SetupIntentResponse,
} from '~types';
import { getGroupedAndSortedPaymentMethodOptions } from '../utils/payment.utils';
import { invoiceServiceQueryKeys } from './invoiceService';
import {
  accountServiceQueryKeys,
  subscriptionServiceQueryKeys,
} from './queryKeysService';
import { updateListCacheWithUpdatedItem } from './queryUtilsHelpers';
import { composeGetQuery } from './utils';

// accounts
/** TODO: @deprecate */
export const getAccountById = async (accountId: string) => {
  const res = await apiGet<IAccountDetails>(`/api/accounts/${accountId}`);
  return res.data;
};

export const doGetBillGroupById = async (billGroupId: string) => {
  const res = await apiGet<IBillGroupResp>(`/api/billGroups/${billGroupId}`);
  return res.data;
};
export function useGetBillGroupStatsById(
  billGroupId: string,
  options: Partial<
    UseQueryOptions<IBillGroupStats, unknown, IBillGroupStats>
  > = {},
) {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.billGroups.billGroupStatsById(billGroupId),
    ],
    queryFn: () =>
      apiGet<IBillGroupStats>(`/api/billGroups/${billGroupId}/stats`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useGetAccountById(
  accountId: string,
  options: Partial<
    UseQueryOptions<IAccountRespSchema, unknown, IAccountRespSchema>
  > = {},
) {
  return useQuery({
    queryKey: [...accountServiceQueryKeys.accounts.accountDetail(accountId)],
    queryFn: () =>
      apiGet<IAccountRespSchema>(`/api/accounts/${accountId}`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

/** TODO: @deprecate */
export const getAccountList = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IAccount>> => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IAccount>>('/api/accounts', {
    params,
  });
  return res.data;
};

export function useGetAccountList({
  config,
  filters,
  options,
  useSearchApi = false,
}: {
  config: GetListApiConfig;
  filters?: GetListApiFilter;
  options?: Partial<UseQueryOptions<ApiListResponse<IAccountDetails>, unknown>>;
  /** If true, will call the search API, which expects query=searchTerm parameter */
  useSearchApi?: boolean;
}) {
  const params = composeGetQuery(config, filters);
  const endpoint = useSearchApi ? '/api/accounts/search' : '/api/accounts';
  return useQuery({
    queryKey: [...accountServiceQueryKeys.accounts.accountList(), params],
    queryFn: () =>
      apiGet<ApiListResponse<IAccountDetails>>(endpoint, {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

export function useCreateAccount(
  options: Partial<
    UseMutationOptions<IAccount, unknown, Partial<IAccount>>
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload) =>
      apiPost<IAccount>('/api/accounts', payload).then((res) => res.data),
    onSuccess: (...rest) => {
      queryClient.invalidateQueries({
        queryKey: accountServiceQueryKeys.accounts.accountList(),
      });
      queryClient.setQueryData(
        [...accountServiceQueryKeys.accounts.base, rest[0]?.id],
        rest[0],
      );
      onSuccess && onSuccess(...rest);
    },
    ...restOptions,
  });
}
export function useUpdateAccount(
  options: Partial<
    UseMutationOptions<
      IAccount,
      unknown,
      { id: string; data: Partial<IAccount> }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) =>
      apiPut<IAccount>(`/api/accounts/${id}`, nullifyEmptyStrings(data)).then(
        (res) => res.data,
      ),
    onSuccess: (...rest) => {
      queryClient.setQueryData(
        [...accountServiceQueryKeys.accounts.base, rest?.[0].id],
        rest[0],
      );
      onSuccess && onSuccess(...rest);
    },
    ...restOptions,
  });
}

export const doDeactivateAccount = async (
  id: string,
): Promise<GenericApiResponse> => {
  const res = await apiPut<GenericApiResponse>(`/api/accounts/${id}/cancel`);
  return res.data;
};

export const doActivateAccount = async (id: string): Promise<IAccount> => {
  const res = await apiPut<IAccount>(`/api/accounts/${id}/activate`);
  return res.data;
};

// Sample ends

// TODO-BREN remove the function below once it's unused
export const fetchAccountsService = async (): Promise<IAccount[]> =>
  api.get('/api/accounts').then((res) => res.data.content);

export const createAccountService = async (payload: any): Promise<any> =>
  api.post('/api/accounts/', payload).then((res) => res.data);

export const updateAccountService = async (
  payload: any,
  accountId: string,
): Promise<any> =>
  api
    .put(`/api/accounts/${accountId}`, { ...payload, status: payload.status })
    .then((res) => res.data);

export const fetchAccountDetailsByAccountId = async (
  accountId: string,
): Promise<IAccountDetails[]> =>
  api.get(`/api/accounts/${accountId}`).then((res) => res.data);

export const doGetAccountHistory = async (
  accountId: string,
  config: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IAccountHistoryResp>> => {
  const url = `/api/account/${accountId}/history`;
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IAccountHistoryResp>>(url, {
    params,
  });
  return res.data;
};

export function useGetAccountOverview(
  accountId: string,
  billGroupId?: string,
  options: Partial<
    UseQueryOptions<IOverviewResponse, unknown, IOverviewItem[]>
  > = {},
) {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.accounts.accountDetailOverview(accountId),
    ],
    queryFn: () =>
      apiGet<IOverviewResponse>(
        `/api/v2/accounts/${accountId}/subscriptions/overview`,
      ).then(({ data }) => data),

    select: ({ subscriptionOverviewsByBillGroup }) => {
      const filteredData = billGroupId
        ? subscriptionOverviewsByBillGroup.filter(
            ({ billgroup }) => billgroup.id === billGroupId,
          )
        : subscriptionOverviewsByBillGroup;
      const orderedData = orderObjectsBy(filteredData, [
        'billgroup.name',
        'billgroup.id',
      ] as any);

      return orderedData.map(({ subscriptionOverviews, ...rest }) => {
        return {
          ...rest,
          subscriptionOverviews: subscriptionOverviews.sort(
            sortByAlphabetically('name'),
          ),
        };
      });
    },
    ...options,
  });
}
export const useGetAccountOverviewStats = (
  accountId: string,
  options: Partial<UseQueryOptions<IAccountOverviewStat>> = {},
) => {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.accounts.accountDetailOverview(accountId),
      'stat',
    ],
    queryFn: () =>
      apiGet<IAccountOverviewStat>(`/api/accounts/${accountId}/overview`).then(
        ({ data }) => data,
      ),
    ...options,
  });
};

export function useGetUsageRecordsInfiniteLoad(
  query: IUsageEventListRequestSchema,
  config: GetListApiConfig,
  options: Partial<
    Partial<
      UndefinedInitialDataInfiniteOptions<
        ApiListInfiniteScrollResponse<IUsageEvent>
      >
    >
  > = {},
) {
  const params = composeGetQuery(config, {
    ...query,
    usageTypeIds: query.usageTypeIds ? query.usageTypeIds.join(',') : '',
  });

  return useInfiniteQuery<ApiListInfiniteScrollResponse<IUsageEvent>>({
    queryKey: accountServiceQueryKeys.usage.usageList(query),
    queryFn: ({ pageParam = undefined }) =>
      apiGet<ApiListInfiniteScrollResponse<IUsageEvent>>(`/usage/events`, {
        params: {
          ...params,
          nextPageToken: pageParam,
        },
      }).then((res) => res.data),
    initialPageParam: null,
    getNextPageParam: (lastPage) => lastPage.pageable?.nextPageToken,
    retry: 0,
    ...options,
  });
}

export function useCreateUsageRecord(
  options: Partial<
    UseMutationOptions<
      IUsageEventResSchema,
      unknown,
      IUsageEventRequestSchema[]
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<IUsageEventResSchema, unknown, IUsageEventRequestSchema[]>(
    {
      mutationFn: (payload) =>
        apiPost<IUsageEventResSchema>(`/usage/events`, payload).then(
          (res) => res.data,
        ),

      onSuccess: (data, payload, context) => {
        queryClient.invalidateQueries({
          queryKey: [...accountServiceQueryKeys.usage.usageListBase()],
        });
        payload.forEach((usageRecord) => {
          queryClient.invalidateQueries({
            queryKey: [
              ...subscriptionServiceQueryKeys.subscriptionUsageConsumption({
                subscriptionId: usageRecord.subscriptionId,
              }),
            ],
          });
        });
        onSuccess && onSuccess(data, payload, context);
      },

      ...restOptions,
    },
  );
}

export function useEditUsageRecord(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      { id: string; payload: IUsageEventUpdateReqSchema }
    >
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    { id: string; payload: IUsageEventUpdateReqSchema }
  >({
    mutationFn: ({ id, payload }) =>
      apiPut<void>(`/usage/events/${id}`, payload).then((res) => res.data),

    onSuccess: (data, variables, context) => {
      const { id, payload } = variables;
      updateListCacheWithUpdatedItem(
        queryClient,
        [...accountServiceQueryKeys.usage.usageListBase()],
        { id, ...payload },
        true,
      );
      queryClient.invalidateQueries({
        queryKey: [...accountServiceQueryKeys.usage.usageListBase()],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

// recent payments apis
export function useGetPaymentsByAccount<SelectData = ApiListResponse<IPayment>>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<IPayment>, unknown, SelectData>
  > = {},
) {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.payments.paymentByAccount(accountId),
      params,
    ],
    queryFn: async () =>
      apiGet<ApiListResponse<IPayment>>(`/api/accounts/${accountId}/payments`, {
        params,
      }).then((res) => res.data),
    ...options,
  });
}

export function useGetPaymentById(
  accountId: string,
  paymentId: string,
  options: Partial<UseQueryOptions<IPayment>> = {},
) {
  return useQuery({
    queryKey: [...accountServiceQueryKeys.payments.paymentById(paymentId)],
    queryFn: () =>
      apiGet<IPayment>(`/api/accounts/${accountId}/payments/${paymentId}`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useInvoiceListByAccount<
  SelectData = ApiListResponse<InvoiceSummaryResp>,
>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<InvoiceSummaryResp>, unknown, SelectData>
  > = {},
) {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  return useQuery({
    queryKey: [accountServiceQueryKeys.accounts.invoiceList(accountId), params],
    queryFn: async () =>
      apiGet<ApiListResponse<InvoiceSummaryResp>>(
        `/api/accounts/${accountId}/invoices`,
        {
          params,
        },
      ).then((res) => res.data),
    ...options,
  });
}

// payment methods apis

export const doCreatePaymentMethodByAccount = async (
  accountId: string,
  data: IPaymentMethodReq,
) => {
  const res = await apiPost<IPaymentMethodResp>(
    `/api/accounts/${accountId}/paymentMethods`,
    data,
  );
  return res.data;
};

/** @deprecated, use useGetPaymentMethodsByAccount instead */
export const doGetPaymentMethodsByAccount = async (
  signal: AbortSignal,
  accountId: string,
  config?: GetListApiConfig,
  filters?: GetListApiFilter,
): Promise<ApiListResponse<IPaymentMethodResp>> => {
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  const res = await apiGet<ApiListResponse<IPaymentMethodResp>>(
    `/api/accounts/${accountId}/paymentMethods`,
    {
      params,
      signal,
    },
  );
  return res.data;
};

export function useGetPaymentMethodsByAccount<
  SelectData extends ApiListResponse<IPaymentMethodResp>,
>(
  {
    accountId,
    config,
    filters,
  }: {
    accountId: string;
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
  },
  options: Partial<
    UseQueryOptions<ApiListResponse<IPaymentMethodResp>, unknown, SelectData>
  > = {},
) {
  const queryClient = useQueryClient();
  let params = {};
  if (config) {
    params = composeGetQuery(config, filters);
  }
  const response = useQuery({
    queryKey: [
      ...accountServiceQueryKeys.paymentMethods.paymentMethodList(accountId),
      params,
    ],
    queryFn: ({ signal }) =>
      apiGet(`/api/accounts/${accountId}/paymentMethods`, {
        params,
        signal,
      }).then((res) => res.data),
    meta: {
      setByIdCacheFromReturnedList: {
        byIdQueryKey: (id) =>
          accountServiceQueryKeys.paymentMethods.paymentMethod(id),
      },
    },
    ...options,
  });

  return response;
}

/**
 * Get payment methods by account where items are grouped for easy lookup
 *
 */
export const useGetPaymentMethodsByAccountWithGroupedItems = ({
  accountId,
  filters,
  filterFn,
}: {
  accountId: string;
  filters: GetListApiFilter;
  filterFn?: (data: IPaymentMethodResp) => boolean;
}) => {
  return useGetPaymentMethodsByAccount<
    ApiListResponse<IPaymentMethodResp> & {
      paymentMethodsByGatewayId: Record<
        string,
        ReturnType<
          typeof getGroupedAndSortedPaymentMethodOptions<IPaymentMethodResp>
        >
      >;
      paymentMethodsById: Record<string, IPaymentMethodResp>;
    }
  >(
    {
      accountId: accountId,
      config: DEFAULT_PAGER,
      filters,
    },
    {
      select: (data) => {
        // Since we do not support a NOT IN query, filter out manually
        const _paymentMethods = data.content.filter((item) =>
          filterFn ? filterFn(item) : true,
        );

        const paymentMethodsByGatewayId = groupBy(
          _paymentMethods,
          'paymentGateway.id',
        );

        return {
          ...data,
          paymentMethodsByGatewayId: Object.keys(
            paymentMethodsByGatewayId,
          ).reduce(
            (
              acc: Record<
                string,
                ReturnType<
                  typeof getGroupedAndSortedPaymentMethodOptions<IPaymentMethodResp>
                >
              >,
              gatewayId,
            ) => {
              acc[gatewayId] = getGroupedAndSortedPaymentMethodOptions(
                paymentMethodsByGatewayId[gatewayId],
              );
              return acc;
            },
            {},
          ),
          paymentMethodsById: arrayToObject(_paymentMethods, 'id'),
        };
      },
    },
  );
};

function doCreatePaymentFromPaymentMethod(
  accountId: string,
  invoiceId: string,
  payload: { paymentMethodId: string; amount: number },
) {
  return apiPost<IPayment>(
    `/api/accounts/${accountId}/payments/invoice/${invoiceId}/pay`,
    payload,
  ).then((res) => res.data);
}

function doCreatePaymentFromAchPaymentMethod(
  invoiceId: string,
  payload: { paymentMethodId: string; amount: number },
) {
  return apiPost<IPayment>(
    `/api/invoices/${invoiceId}/achCreditPayments`,
    payload,
  ).then((res) => res.data);
}

function doCreateManualPayment(
  accountId: string,
  invoiceId: string,
  payload: IManualPayment,
) {
  return apiPost<IPayment>(
    `/api/accounts/${accountId}/payments/invoice/${invoiceId}/pay/manual`,
    payload,
  ).then((res) => res.data);
}

export function useCreateInvoicePayment(
  options: Partial<
    UseMutationOptions<IPayment, unknown, CreateInvoicePaymentRequest>
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<IPayment, unknown, CreateInvoicePaymentRequest>({
    mutationFn: ({ type, accountId, invoiceId, payload }) => {
      switch (type) {
        case 'electronic': {
          return doCreatePaymentFromPaymentMethod(
            accountId,
            invoiceId,
            payload,
          );
        }
        case 'achCredit': {
          return doCreatePaymentFromAchPaymentMethod(invoiceId, payload);
        }
        case 'usBankTransfer': {
          return doCreatePaymentFromAchPaymentMethod(invoiceId, payload);
        }
        case 'manual': {
          return doCreateManualPayment(accountId, invoiceId, payload);
        }
      }
    },
    onSuccess: (data, variables, context) => {
      const { accountId, invoiceId } = variables;
      queryClient.invalidateQueries({
        queryKey: ['invoices', 'list'],
      });
      queryClient.invalidateQueries({
        queryKey: ['invoices', invoiceId],
      });
      queryClient.invalidateQueries({
        queryKey: [...accountServiceQueryKeys.accounts.invoiceList(accountId)],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ...accountServiceQueryKeys.payments.paymentByAccount(accountId),
        ],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export const doGetCreditNotesForInvoice = async (invoiceId: string) => {
  const res = await apiGet<ApiListResponse<ICreditNoteSchema>>(
    `/api/invoices/${invoiceId}/creditNotes`,
  );
  return res.data;
};

export const doCreateCreditNoteForInvoice = async (
  invoiceId: string,
  data: ICreditNoteReqSchema,
) => {
  const res = await apiPost<ICreditNoteSchema>(
    `/api/invoices/${invoiceId}/creditNotes`,
    data,
  );
  return res.data;
};

export const doDownloadInvoiceAsCsv = async (invoiceId: string) => {
  const res = await apiGet(`/api/invoices/${invoiceId}/exportCsv`);
  return res.data;
};

export const doPrintInvoiceToPdf = async (
  invoiceId: string,
  invoiceNumber: Maybe<string>,
) => {
  const res = await apiGet<ArrayBuffer>(`/api/invoices/${invoiceId}/print`, {
    responseType: 'arraybuffer',
    headers: new AxiosHeaders({
      Accept: 'application/pdf',
    }),
  });
  return {
    data: res.data,
    fileName: `invoice-${invoiceNumber || invoiceId}.pdf`,
  };
};

export const usePrintInvoiceToHtml = (
  {
    invoiceId,
    lastModifiedTimestamp,
  }: {
    invoiceId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: Partial<UseQueryOptions<string>> = {},
) => {
  return useQuery({
    queryKey: [
      ...invoiceServiceQueryKeys.htmlTemplate(invoiceId),
      lastModifiedTimestamp,
    ],
    queryFn: () =>
      apiGet<string>(`/api/invoices/${invoiceId}/print`, {
        responseType: 'text',
        headers: new AxiosHeaders({
          accept: 'text/html',
        }),
      }).then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: (previousData, previousQuery) => previousData,
    ...options,
  });
};

export const usePrintCreditToHtml = (
  {
    creditId,
    lastModifiedTimestamp,
  }: {
    creditId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: Partial<UseQueryOptions<string>> = {},
) => {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.credits.htmlTemplate(creditId),
      lastModifiedTimestamp,
    ],
    queryFn: () =>
      apiGet<string>(`/api/credits/${creditId}/print`, {
        responseType: 'text',
        headers: new AxiosHeaders({
          accept: 'text/html',
        }),
      }).then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: (previousData, previousQuery) => previousData,
    ...options,
  });
};

export const doPrintCreditToPdf = async (creditId?: string) => {
  const res = await apiGet<ArrayBuffer>(`/api/credits/${creditId}/print`, {
    responseType: 'arraybuffer',
    headers: new AxiosHeaders({
      accept: 'application/pdf',
    }),
  });
  return { data: res.data, fileName: `credit-${creditId}.pdf` };
};

export function useGetCreditNotesById(
  creditNoteId: string,
  options: Partial<UseQueryOptions<ICreditNoteSchema>> = {},
) {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.creditNotes.creditNoteById(creditNoteId),
    ],
    queryFn: () =>
      apiGet<ICreditNoteSchema>(`/api/creditNotes/${creditNoteId}`).then(
        (res) => res.data,
      ),
    enabled: !!creditNoteId,
    refetchOnWindowFocus: false,
    ...options,
  });
}

export const usePrintCreditNoteToHtml = (
  {
    creditNoteId,
    lastModifiedTimestamp,
  }: {
    creditNoteId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: Partial<UseQueryOptions<string>> = {},
) => {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.creditNotes.htmlTemplate(creditNoteId),
      lastModifiedTimestamp,
    ],
    queryFn: () =>
      apiGet<string>(`/api/creditNotes/${creditNoteId}/print`, {
        responseType: 'text',
        headers: new AxiosHeaders({
          accept: 'text/html',
        }),
      }).then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: (previousData, previousQuery) => previousData,
    ...options,
  });
};

export const doPrintCreditNoteToPdf = async (creditNoteId?: string) => {
  const res = await apiGet<ArrayBuffer>(
    `/api/creditNotes/${creditNoteId}/print`,
    {
      responseType: 'arraybuffer',
      headers: new AxiosHeaders({
        accept: 'application/pdf',
      }),
    },
  );
  return { data: res.data, fileName: `creditNote-${creditNoteId}.pdf` };
};

// payment gateways apis

export const doGetGatewayAccountsByAccount = async (
  accountId: string,
  gatewayId: string,
) => {
  const res = await apiGet<IPaymentGatewayAccountSchema>(
    `/api/accounts/${accountId}/paymentGateways/${gatewayId}/gatewayAccount`,
  );
  return res.data;
};

export const doCreatePaymentGatewayByAccount = async (
  accountId: string,
  type: string,
  gatewayId: string,
) => {
  const res = await apiPost<IPaymentGatewayAccountSchema>(
    `/api/accounts/${accountId}/paymentGateways/${gatewayId}/gatewayAccount`,
    {
      gatewayType: type,
    },
  );
  return res.data;
};

export const doCreateOrGetPaymentGatewayByAccount = async (
  accountId: string,
  paymentGatewayType: string,
  paymentGatewayId: string,
) => {
  try {
    const gateway = await doGetGatewayAccountsByAccount(
      accountId,
      paymentGatewayId,
    );
    return gateway;
  } catch (err) {
    const gateway = await doCreatePaymentGatewayByAccount(
      accountId,
      paymentGatewayType,
      paymentGatewayId,
    );
    return gateway;
  }
};

export const doCreateGatewayAccountSetupIntent = async (
  gatewayId: string,
  gatewayAccountId: string,
) => {
  // Swagger shows the incorrect response type for this endpoint: https://monetizenow.atlassian.net/browse/BP-6308
  const res = await apiPost<SetupIntentResponse>(
    `/api/paymentGateways/${gatewayId}/gatewayAccounts/${gatewayAccountId}/setupIntent`,
  );
  return res.data;
};

export const doGetGatewayAccountClientSecret = async (
  gatewayId: string,
  gatewayAccountId: string,
) => {
  const res = await apiGet<PaymentGatewayClientSecretResponse>(
    `/api/paymentGateways/${gatewayId}/gatewayAccounts/${gatewayAccountId}/clientSecret`,
  );
  return res.data;
};

// dunning process apis

export function dunningProcessPath(): string {
  return `/api/dunning/dunningProcess`;
}

// End

export function useSetContactOnInvoice(
  options: Partial<
    UseMutationOptions<
      IInvoiceContactUpdateSchema,
      unknown,
      { invoiceId: string; data: IInvoiceContactUpdateSchema }
    >
  > = {},
) {
  return useMutation<
    IInvoiceContactUpdateSchema,
    unknown,
    { invoiceId: string; data: IInvoiceContactUpdateSchema }
  >({
    mutationFn: ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoice/${invoiceId}/contacts`, data).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useUpdatePoNumberOnQuote(options = {}) {
  return useMutation<
    void,
    unknown,
    { quoteId: string; poNumber: Maybe<string> }
  >({
    mutationFn: ({ quoteId, poNumber }) =>
      apiPut(`/api/quotes/${quoteId}/purchaseOrderNumber`, {
        purchaseOrderNumber: poNumber || null,
      }).then((res) => res.data),
    ...options,
  });
}

export function useSendEmail(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      { invoiceId: string; overrideCcEmails: Maybe<string[]> }
    >
  > = {},
) {
  return useMutation<
    void,
    unknown,
    { invoiceId: string; overrideCcEmails: Maybe<string[]> }
  >({
    mutationFn: ({ invoiceId, overrideCcEmails }) =>
      apiPost(`/api/invoices/${invoiceId}/sendBillingEmail`, {
        overrideCcEmails,
      }).then((res) => res.data),
    ...options,
  });
}

export const doPreviewInvoice = async (billGroupId: string) => {
  return await apiGet<ArrayBuffer>(
    `/api/billGroups/${billGroupId}/invoices/preview`,
    {
      responseType: 'arraybuffer',
      headers: new AxiosHeaders({
        accept: 'application/pdf',
      }),
    },
  ).then((res) => res.data);
};

export const usePreviewInvoiceJson = <SelectData = IInvoiceRespSchema>(
  { accountId, billGroupId }: { accountId: string; billGroupId: string },
  options: Partial<
    UseQueryOptions<IInvoiceRespSchema, unknown, SelectData>
  > = {},
) => {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.accounts.invoicePreview(
        accountId,
        billGroupId,
      ),
    ],
    queryFn: () =>
      apiGet<IInvoiceRespSchema>(
        `/api/billGroups/${billGroupId}/invoices/preview`,
      ).then((res) => res.data),
    ...options,
  });
};

export function useGeneratePastInvoice(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      { accountId: string; billGroupId: string }
    >
  > = {},
) {
  return useMutation<void, unknown, { accountId: string; billGroupId: string }>(
    {
      mutationFn: ({ accountId, billGroupId }) =>
        apiPost(
          `/api/accounts/${accountId}/billGroups/${billGroupId}/manualBatchRun`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useInvokeManualBillRun(
  options: Partial<
    UseMutationOptions<void, unknown, { billGroupIds: string[] }>
  > = {},
) {
  return useMutation<void, unknown, { billGroupIds: string[] }>({
    mutationFn: ({ billGroupIds }) =>
      apiPost(`/api/billGroups/manualBatchRun`, billGroupIds).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useSearchForImportablePaymentMethods(
  options?: UseMutationOptions<
    ImportablePaymentMethodResp,
    AxiosError<GenericApiResponse>,
    any
  >,
) {
  return useMutation({
    mutationFn: ({ gatewayId, gatewayAccountId, accountId }) =>
      apiGet<ImportablePaymentMethodResp>(
        `/api/paymentGateways/${gatewayId}/gatewayAccounts/search?gatewayAccountId=${gatewayAccountId}&accountId=${accountId}`,
      ).then((res) => res.data),
    ...options,
  });
}

export function useImportPaymentMethods(
  options: UseMutationOptions<
    ImportPaymentMethodResp,
    AxiosError<GenericApiResponse>,
    any
  >,
) {
  const { onSuccess, ...rest } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      accountId,
      gatewayId,
      gatewayAccountId,
      externalPaymentMethodIds,
    }) =>
      apiPost<ImportPaymentMethodResp>(
        `/api/accounts/${accountId}/paymentMethods/import`,
        {
          gatewayId,
          gatewayAccountId,
          externalPaymentMethodIds,
        },
      ).then((res) => res.data),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [
          ...accountServiceQueryKeys.paymentMethods.paymentMethodList(
            variables.accountId,
          ),
        ],
      });
      options.onSuccess && options.onSuccess(data, variables, context);
    },
    ...rest,
  });
}

export function useUpdateInvoiceCustomFields(
  options: Partial<
    UseMutationOptions<
      void,
      unknown,
      { invoiceId: string; customFields: IInvoiceUpdateSchema['customFields'] }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    void,
    unknown,
    { invoiceId: string; customFields: IInvoiceUpdateSchema['customFields'] }
  >({
    mutationFn: ({ invoiceId, customFields }) =>
      apiPut<any>(`/api/invoices/${invoiceId}/customFields`, customFields).then(
        (res) => res.data,
      ),

    onSuccess: (response, variables, context) => {
      const { invoiceId } = variables;
      queryClient.invalidateQueries({
        queryKey: [...invoiceServiceQueryKeys.invoiceDetail(invoiceId)],
      });

      onSuccess && onSuccess(response, variables, context);
    },
    ...restOptions,
  });
}

export const useVoidCredit = (
  sourceType = 'credits',
  creditId: string,
  accountId: string,
  options: Partial<
    UseMutationOptions<IVoidCreditSchema, unknown, Partial<IVoidCreditSchema>>
  > = {},
) => {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation<IVoidCreditSchema, unknown, Partial<IVoidCreditSchema>>({
    mutationFn: (payload) =>
      apiPost<IVoidCreditSchema>(
        `/api/${sourceType}/void/${creditId}`,
        payload,
      ).then((res) => {
        return res.data;
      }),
    onSuccess: (...rest) => {
      queryClient.invalidateQueries({
        queryKey:
          accountServiceQueryKeys.credits.creditListByAccount(accountId),
      });
      if (sourceType === 'credits') {
        queryClient.invalidateQueries({
          queryKey: accountServiceQueryKeys.credits.htmlTemplate(creditId),
        });
      }

      if (sourceType === 'creditNotes') {
        queryClient.invalidateQueries({
          queryKey: accountServiceQueryKeys.creditNotes.htmlTemplate(creditId),
        });
      }
      onSuccess && onSuccess(...rest);
    },
    ...restOptions,
  });
};
