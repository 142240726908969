import { IContactRequestSchema, IContactRespSchema } from '../types';
import {
  QuoteShareContact,
  QuoteShareContactRequiredAddress,
} from '../types/recordShareTypes';
import { nullifyEmptyStrings } from './misc';

/**
 * Compare two contacts to see if they are the same
 * This does not include every field, such as status, but includes all user-editable fields
 */
export function areContactsSame(
  existingContact: IContactRespSchema,
  newContact:
    | IContactRequestSchema
    | QuoteShareContact
    | QuoteShareContactRequiredAddress,
) {
  // This sets null and trims strings to ensure comparison is accurate
  existingContact = nullifyEmptyStrings(existingContact);
  newContact = nullifyEmptyStrings(newContact);

  return (
    existingContact.fullName === newContact.fullName &&
    existingContact.email === newContact.email &&
    existingContact.phone === newContact.phone &&
    existingContact.address &&
    newContact.address &&
    existingContact.address.line1 === newContact.address.line1 &&
    existingContact.address.line2 === newContact.address.line2 &&
    existingContact.address.city === newContact.address.city &&
    existingContact.address.state === newContact.address.state &&
    existingContact.address.postalCode === newContact.address.postalCode &&
    existingContact.address.country === newContact.address.country
  );
}
