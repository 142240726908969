import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiDelete, handleApiErrorToast } from '~app/api/axios';
import {
  MButton,
  MFlex,
  MHStack,
  MPageContainer,
  MPageLoader,
  MPageSearchInput,
  MSettingsPageHeader,
} from '~app/components/Monetize';
import MDataTableFilter from '~app/components/Monetize/DataTable/MDataTableFilter';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { ROUTES } from '~app/constants';
import { DUNNING_STATUS_DISPLAY } from '~app/constants/dunnings';
import { useConfirmModal } from '~app/services/confirmModal';
import { useToast } from '~app/services/toast';
import {
  buildFilterParamsRequestObject,
  getIsTrulyEmptyList,
} from '~app/utils';
import { objectToObjArray } from '~app/utils/misc';
import {
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  GetListApiFilter,
  IDunnings,
  TDataTablePager,
} from '~types';

import { useQueryClient } from '@tanstack/react-query';
import { MPaginator } from '~app/components/Monetize/DataTable';
import { useRestPaginationIfInvalid } from '~app/hooks/useRestPaginationIfInvalid';
import { useDocumentHead } from '~app/services/documentHead';
import {
  useDeleteEntity,
  useGetListData,
  usePerformEntityAction,
} from '../../../../api/queryUtils';
import DunningItemCards from './DunningItemCards';

const defaultPager: TDataTablePager = {
  ...DEFAULT_PAGER,
  sortField: 'name',
  sortOrder: 1,
};

const Dunning = () => {
  const navigate = useNavigate();
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const { setDocTitle } = useDocumentHead();
  const [searchKey] = useState<string>('name');
  const [filters, setFilters] = useState<FilterType[] | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, globalFilter, searchKey),
  );
  const filterComponentReset = React.useRef<any>(null);
  const { showConfirmModal, setModalLoading, closeModal } = useConfirmModal();
  const [currentDunningId, setCurrentDunningId] = useState<string>();

  const [pager, setPager] = useState<TDataTablePager>(defaultPager);

  const { data: dunnings, isLoading: loading } = useGetListData<IDunnings>(
    'dunnings',
    {
      config: pager,
      filters: filterParams,
    },
  );

  useRestPaginationIfInvalid(loading, dunnings, pager, setPager, defaultPager);

  const { mutate: deleteDunningProcess } =
    useDeleteEntity<Response>('dunnings');
  const {
    mutate: updateDefaultDunningProcess,
    isPending: isUpdateDefaultDunningProcessLoading,
  } = usePerformEntityAction<IDunnings>('dunnings', {
    onSuccess: (data) => {
      setCurrentDunningId(undefined);
    },
    meta: { showErrorToast: true },
  });
  const {
    mutate: deleteDefaultDunningProcess,
    isPending: isDeleteDefaultDunningProcessLoading,
  } = usePerformEntityAction<IDunnings>('dunnings', {
    apiMethodOverride: apiDelete,
    onSuccess: (data) => {
      setCurrentDunningId(undefined);
    },
    meta: { showErrorToast: true },
  });

  useEffect(() => {
    setFilterParams(
      buildFilterParamsRequestObject(filters, globalFilter, searchKey),
    );
  }, [pager, filters, globalFilter, searchKey]);

  useEffect(() => {
    setDocTitle('Settings', 'Dunning');
  }, []);

  const onResetFilter = () => {
    setFilters([]);
  };

  const redirectToCreate = () => navigate(ROUTES.SETTINGS_DUNNING_NEW);
  const handleDeleteDunning = (payload: IDunnings) => {
    const onYes = () => {
      setModalLoading(true);
      const startTime = new Date().getTime();
      deleteDunningProcess(
        { id: payload.id },
        {
          onSuccess: (data) => {
            if (data.status === 200) {
              addToast({
                summary: 'Dunning Process Deleted',
                detail: `${payload.name} has successfully been deleted.`,
                severity: 'info',
                timeTaken: new Date().getTime() - startTime,
              });
              closeModal();
              setModalLoading(false);
            }
          },
          onError: (err) => {
            setModalLoading(false);
            err && handleApiErrorToast(err);
          },
        },
      );
    };

    showConfirmModal({
      title: `Are you sure you want to delete this dunning process?`,
      description:
        'Once deleted, this dunning process will be removed from any Bill Groups it is currently assigned.',
      onYes,
      yesBtnProps: {
        variant: 'delete' as any,
      },
      noBtnProps: {
        variant: 'cancel' as any,
      },
    });
  };

  const handleUpdateDefaultDunningProcess = (payload: IDunnings) => {
    setCurrentDunningId(payload.id);
    updateDefaultDunningProcess({ id: payload.id, action: 'default' });
  };

  const handleDeleteDefaultDunningProcess = (payload: IDunnings) => {
    setCurrentDunningId(payload.id);
    deleteDefaultDunningProcess({ id: payload.id, action: 'default' });
  };

  const statusItems = objectToObjArray(
    DUNNING_STATUS_DISPLAY,
    'value',
    'label',
  );

  const filterOptions = [
    {
      title: 'Status',
      key: 'status',
      operator: FilterTypeOperator.IN,
      items: statusItems,
    },
  ];

  const isTrulyEmptyList = getIsTrulyEmptyList({
    loading,
    totalElements: dunnings?.totalElements || 0,
    filters,
    searchTerm: globalFilter,
    page: pager.page,
  });

  return (
    <MPageContainer alignItems="stretch" data-testid="dunning-list-page">
      <MSettingsPageHeader title="Dunning">
        <MHStack spacing="4">
          {!isTrulyEmptyList && (
            <>
              <MPageSearchInput
                placeholderKey="Name"
                value={globalFilter}
                onChange={(e: any) => setGlobalFilter(e)}
                count={dunnings?.content.length}
              />
              <MDataTableFilter
                filters={filters}
                filterOptions={filterOptions}
                setFilters={(f) => setFilters && setFilters(f)}
                onResetFilter={onResetFilter}
                resetFilter={filterComponentReset}
              />
              <MButton
                variant="primary"
                onClick={redirectToCreate}
                data-testid="new-dunning-btn"
              >
                New
              </MButton>
            </>
          )}
        </MHStack>
      </MSettingsPageHeader>

      <DunningItemCards
        dunnings={dunnings?.content}
        handleDeleteDunning={handleDeleteDunning}
        handleUpdateDefaultDunningProcess={handleUpdateDefaultDunningProcess}
        handleDeleteDefaultDunningProcess={handleDeleteDefaultDunningProcess}
        currentDunningId={currentDunningId}
        isDefaultProcessLoading={
          isDeleteDefaultDunningProcessLoading ||
          isUpdateDefaultDunningProcessLoading
        }
      >
        <MFlex justify="center" w="full" mt={6}>
          <MPaginator
            pager={pager}
            totalRecords={dunnings?.totalElements!}
            onPageChange={(newPager) => setPager({ ...pager, ...newPager })}
          />
        </MFlex>
      </DunningItemCards>

      {loading && <MPageLoader />}
      {!loading && !dunnings?.content.length && (
        <MEmptyDataPlaceholder
          mainMessage="Looks like there are no dunning processes here."
          btnLabel="New Dunning Process"
          to={ROUTES.SETTINGS_DUNNING_NEW}
        />
      )}
    </MPageContainer>
  );
};

export default Dunning;
