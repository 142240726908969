import { Switch, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import {
  MCustomSelect,
  MFlex,
  MFormField,
  MFormFieldProps,
  MText,
} from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import { IQuoteTemplateConfigRes } from '~app/types';
import { useQuoteTemplatesList } from '../../../hooks/useQuoteTemplatesList';

interface IQuoteTemplateFormProps {
  source: 'tenant' | 'quote';
  control: Control<IQuoteTemplateConfigRes>;
  errors: FormState<IQuoteTemplateConfigRes>['errors'];
  isDisabled?: boolean;
  requireSigningPage?: boolean;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  isAmendmentV2Quote?: boolean;
}
export const QuoteTemplateForm = ({
  source = 'tenant',
  control,
  errors,
  isDisabled,
  requireSigningPage,
  handleSubmit,
  isAmendmentV2Quote,
}: IQuoteTemplateFormProps) => {
  const baseFieldProps: MFormFieldProps = {
    as: MFlex,
    alignItems: 'center',
    justifyContent: 'space-between',
    labelProps: { m: 0, fontWeight: 'normal' },
  };

  const fieldProps: MFormFieldProps = {
    ...baseFieldProps,
    py: 3,
    borderBottom: '1px',
    borderBottomColor: 'tGray.back',
  };
  const { hideBillingSchedule, useWhatsChanged } = useFlags();
  const { quoteTemplates } = useQuoteTemplatesList();

  return (
    <form
      id="customize-quoteTemplate-form"
      data-testid="customize-quoteTemplate-form"
      style={{ marginTop: '0' }}
      onSubmit={handleSubmit}
    >
      <MFormField label="Template" maxW={56} pt={3}>
        <Controller
          name="defaultTemplate"
          control={control}
          render={({ field: { ...rest } }) => (
            <MCustomSelect
              items={quoteTemplates}
              isDisabled={source !== 'tenant' || isDisabled}
              {...rest}
            />
          )}
        />
      </MFormField>
      <MText fontWeight="bold" mt="4" mb="2">
        Included Sections in Quote
      </MText>

      <MFormField
        label="Full Latest Contract"
        {...fieldProps}
        error={errors?.hideUpdatedContractWhenDeltaViewIsSuccessfulOnAmendments}
      >
        <Controller
          name="hideUpdatedContractWhenDeltaViewIsSuccessfulOnAmendments"
          control={control}
          render={({ field: { value, onChange, ...rest } }) => (
            <Switch
              isChecked={!value}
              onChange={(ev) => onChange(!ev.target.checked)}
              {...rest}
              isDisabled={isDisabled}
            />
          )}
        />
      </MFormField>

      {!hideBillingSchedule && (
        <MFormField
          label="Billing Schedule"
          {...fieldProps}
          error={errors?.showBillingSchedule}
          tooltip="Hides 'Billing Schedule' and 'Billing Frequency' from display on Quote Documents"
        >
          <Controller
            name="showBillingSchedule"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
            )}
          />
        </MFormField>
      )}
      <MFormField
        label="Pricing Details"
        {...fieldProps}
        error={errors?.showPricing}
      >
        <Controller
          name="showPricing"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>

      <MFormField
        label="Discounting Details"
        {...fieldProps}
        error={errors?.showDiscount}
      >
        <Controller
          name="showDiscount"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>

      {/* Show as true if quote.requiresEsign regardless of actual value */}
      <Tooltip
        label={
          requireSigningPage
            ? 'Signing Sheet will always show because eSign is configured.'
            : undefined
        }
        size="sm"
      >
        <MFormField {...fieldProps} label="Signing Page">
          <Controller
            name="showSigningPage"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Switch
                isChecked={requireSigningPage ? true : value}
                {...rest}
                isDisabled={isDisabled || requireSigningPage}
              />
            )}
          />
        </MFormField>
      </Tooltip>

      {isAmendmentV2Quote && (
        <MFormField
          label="Prior Contract (Amendments Only)"
          {...fieldProps}
          error={errors?.showPriorContractOnAmendments}
        >
          <Controller
            name="showPriorContractOnAmendments"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
            )}
          />
        </MFormField>
      )}

      {useWhatsChanged && isAmendmentV2Quote && (
        <MFormField
          label="Differences from Prior Contract (Amendments Only)"
          {...fieldProps}
          error={errors?.showDeltaViewOnAmendments}
          tooltip="Note: the Full Latest Contract will still show, regardless of this setting, if the Differences From Prior Contract is not available for this Quote"
        >
          <Controller
            name="showDeltaViewOnAmendments"
            control={control}
            render={({ field: { value, ...rest } }) => (
              <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
            )}
          />
        </MFormField>
      )}

      <MText fontWeight="bold" mt="4" mb="2">
        Included Details for Offerings
      </MText>

      <MFormField {...fieldProps} label="Rate Description">
        <Controller
          name={
            source === 'tenant'
              ? 'showRateDescription'
              : 'showOfferingDescription'
          }
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch
              data-testid={
                source === 'tenant'
                  ? 'showRateDescription-switch'
                  : 'showOfferingDescription-switch'
              }
              isChecked={value}
              {...rest}
              isDisabled={isDisabled}
            />
          )}
        />
      </MFormField>

      <MFormField
        label="Product Description"
        {...fieldProps}
        error={errors?.showProductDescription}
      >
        <Controller
          name="showProductDescription"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch
              data-testid="showProductDescription-switch"
              isChecked={value}
              {...rest}
              isDisabled={isDisabled}
            />
          )}
        />
      </MFormField>

      <MFormField
        label="Price per Unit"
        {...fieldProps}
        error={errors?.showPricePerUnit}
      >
        <Controller
          name="showPricePerUnit"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>

      <MFormField
        label="Credits"
        {...fieldProps}
        error={errors?.showCredits}
        tooltip="Show the credits that are created as part of a upgrade, downgrade, removal of an offering, or other actions that create credits"
      >
        <Controller
          name="showCredits"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>

      <MFormField
        label="Proration"
        {...fieldProps}
        error={errors?.showProration}
        tooltip="Show the prorated period for each offering, and scheduled change"
        tooltipSize="sm"
      >
        <Controller
          name="showProration"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>

      <MFormField
        label="Unused Quote Items"
        {...baseFieldProps}
        pt={3}
        error={errors?.showUnusedItems}
        tooltip="Display Quote Items with 0 quantity and 0 amount."
        tooltipSize="xs"
      >
        <Controller
          name="showUnusedItems"
          control={control}
          render={({ field: { value, ...rest } }) => (
            <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
          )}
        />
      </MFormField>
    </form>
  );
};
