/** @deprecated, getPaymentGatewaysByTenant should be used */
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosHeaders } from 'axios';
import { apiDelete, apiGet, apiPost, apiPut } from '~api/axios';
import Sentry from '~app/services/sentry';
import { nullifyEmptyStrings } from '~app/utils/misc';
import {
  ApiListResponse,
  GenericApiResponse,
  IPayment,
  IPaymentGateway,
  IPaymentGatewayAccountSchema,
  IPaymentGatewayReqSchema,
  ManualPaymentUpdateReq,
  PaymentGatewayClientSecretResponse,
  SetupIntentResponse,
} from '~types';
import {
  doCreateGatewayAccountSetupIntent,
  doCreateOrGetPaymentGatewayByAccount,
  doGetGatewayAccountClientSecret,
} from './accountsService';
import { accountServiceQueryKeys } from './queryKeysService';

export const paymentGatewayQueryKeys = {
  paymentGateway: {
    base: ['paymentGateways'] as const,
    list: () =>
      [...paymentGatewayQueryKeys.paymentGateway.base, 'list'] as const,
    byId: (paymentGatewayId: string) =>
      [
        ...paymentGatewayQueryKeys.paymentGateway.base,
        paymentGatewayId,
      ] as const,
  },
};

export function useUpdateManualPayment(
  options: Partial<
    UseMutationOptions<
      IPayment,
      unknown,
      { accountId: string; paymentId: string; payload: ManualPaymentUpdateReq }
    >
  > = {},
) {
  return useMutation({
    mutationFn: ({ accountId, paymentId, payload }) =>
      apiPut<IPayment>(
        `/api/accounts/${accountId}/payments/manual/${paymentId}`,
        payload,
      ).then((res) => res.data),
    ...options,
  });
}

export function useGetPaymentGatewaysByTenant<
  SelectData = ApiListResponse<IPaymentGateway>,
>(
  options: Partial<
    UseQueryOptions<ApiListResponse<IPaymentGateway>, unknown, SelectData>
  > = {},
) {
  return useQuery({
    queryKey: [...paymentGatewayQueryKeys.paymentGateway.list()],
    queryFn: () =>
      apiGet<ApiListResponse<IPaymentGateway>>(`/api/paymentGateways`, {
        params: { sort: 'description' },
      }).then((res) => res.data),
    meta: {
      setByIdCacheFromReturnedList: {
        byIdQueryKey: (id) => paymentGatewayQueryKeys.paymentGateway.byId(id),
      },
    },
    ...options,
  });
}

/**
 * Setup everything required to collect a payment via Stripe.
 *
 * 1. Get tenant payment gateway
 * 2. Create or get account payment gateway
 * 3. Create setup intent
 * 4. get clientSecret for account payment gateway
 *
 */
export function useSetupPaymentGatewayForPayment(
  options: Partial<
    UseMutationOptions<
      {
        paymentGateway: IPaymentGateway;
        gatewayAccount: IPaymentGatewayAccountSchema;
        setupIntent: SetupIntentResponse;
        clientSecret: PaymentGatewayClientSecretResponse;
      },
      unknown,
      { accountId: string; paymentGateway: IPaymentGateway }
    >
  > = {},
) {
  const { onError } = options;

  return useMutation({
    mutationFn: async ({ accountId, paymentGateway }) => {
      const gatewayAccount = await doCreateOrGetPaymentGatewayByAccount(
        accountId,
        paymentGateway.type,
        paymentGateway.id,
      );

      if (!gatewayAccount) {
        throw new Error('Failed to create gateway account.');
      }

      const [setupIntentResult, clientSecretResult] = await Promise.allSettled([
        doCreateGatewayAccountSetupIntent(
          paymentGateway.id,
          gatewayAccount.gatewayCustomerId,
        ),
        doGetGatewayAccountClientSecret(
          paymentGateway.id,
          gatewayAccount.gatewayCustomerId,
        ),
      ]);

      if (setupIntentResult.status !== 'fulfilled') {
        throw new Error('Failed to setup the payment.');
      }

      if (clientSecretResult.status !== 'fulfilled') {
        throw new Error('Failed to setup the payment credentials.');
      }

      return {
        paymentGateway,
        gatewayAccount,
        setupIntent: setupIntentResult.value,
        clientSecret: clientSecretResult.value,
      };
    },
    ...options,
    onError: (error, variables, recover) => {
      Sentry.captureException(error, {
        tags: {
          type: 'PAYMENT_SETUP',
        },
      });
      onError && onError(error, variables, recover);
    },
  });
}

// FIXME: why are we passing in tenantId?
export function useGetPaymentGatewayById<SelectData = IPaymentGateway>(
  { id }: { id: string },
  options: Partial<UseQueryOptions<IPaymentGateway, unknown, SelectData>> = {},
) {
  return useQuery<IPaymentGateway, unknown, SelectData>({
    queryKey: paymentGatewayQueryKeys.paymentGateway.byId(id),
    queryFn: () =>
      apiGet<IPaymentGateway>(`/api/paymentGateways/${id}`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useDeletePaymentGatewayByPaymentGatewayId(
  options: Partial<
    UseMutationOptions<GenericApiResponse, unknown, IPaymentGateway>
  > = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<GenericApiResponse, unknown, IPaymentGateway>({
    mutationFn: (payload) => {
      return apiDelete<GenericApiResponse>(`/api/gateways/${payload.id}`).then(
        (res) => res.data,
      );
    },
    onSuccess: (payload, variables, context) => {
      queryClient.removeQueries({
        queryKey: [paymentGatewayQueryKeys.paymentGateway.byId(variables.id)],
      });
      queryClient.invalidateQueries({
        queryKey: [...paymentGatewayQueryKeys.paymentGateway.list()],
      });
      onSuccess && onSuccess(payload, variables, context);
    },
    ...restOptions,
  });
}

export function useVerifyStripeKey(
  options: Partial<
    UseMutationOptions<
      unknown,
      unknown,
      { paymentGatewayId?: string; stripeSecretKey?: string }
    >
  > = {},
) {
  return useMutation({
    mutationFn: (payload) =>
      apiPost(`/api/gateways/validateStripeApiKey`, payload).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useCreateOrUpdatePaymentGateway(
  options: Partial<
    UseMutationOptions<
      GenericApiResponse,
      unknown,
      {
        gatewayId?: string;
        data: IPaymentGatewayReqSchema;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation({
    mutationFn: (payload) => {
      const data = nullifyEmptyStrings(payload.data);
      return (
        payload.gatewayId
          ? apiPut<GenericApiResponse>(
              `/api/gateways/${payload.gatewayId}`,
              data,
            )
          : apiPost<GenericApiResponse>(
              `/api/gateways/payments/integrations/stripe`,
              data,
            )
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...paymentGatewayQueryKeys.paymentGateway.base],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useSetDefaultPaymentGateway(
  options: Partial<
    UseMutationOptions<
      GenericApiResponse,
      unknown,
      {
        gatewayId?: string;
      }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation({
    mutationFn: (payload) => {
      return apiPut<GenericApiResponse>(
        `/api/gateways/${payload.gatewayId}/setDefault`,
      ).then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [...paymentGatewayQueryKeys.paymentGateway.base],
      });
      onSuccess && onSuccess(data, variables, context);
    },
    ...restOptions,
  });
}

export function useGetStripeAuthUrl(options: Partial<UseMutationOptions> = {}) {
  return useMutation({
    mutationFn: () =>
      apiPost(`/api/gateways/payments/integrations/stripe/oauth`).then(
        (res) => res.data,
      ),
    ...options,
  });
}

export function useConnectStripeAuthUrl(
  options: Partial<
    UseMutationOptions<any, unknown, { code: string; status: string }>
  > = {},
) {
  return useMutation({
    mutationFn: ({ code, status }) =>
      apiPost(
        `/api/gateways/payments/integrations/stripe/oauth/callback?state=${status}&code=${code}`,
      ).then((res) => res.data),
    ...options,
  });
}

export const usePrintPaymentToHtml = (
  {
    paymentId,
    lastModifiedTimestamp,
  }: {
    paymentId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
  },
  options: Partial<UseQueryOptions<string>> = {},
) => {
  return useQuery({
    queryKey: [
      ...accountServiceQueryKeys.payments.htmlTemplate(paymentId),
      lastModifiedTimestamp,
    ],
    queryFn: () =>
      apiGet<string>(`/api/payments/${paymentId}/print`, {
        responseType: 'text',
        headers: new AxiosHeaders({
          accept: 'text/html',
        }),
      }).then((res) => res.data),
    refetchOnWindowFocus: false,
    retry: false,
    placeholderData: (previousData, previousQuery) => previousData,
    ...options,
  });
};

export const doPrintPaymentToPdf = async (paymentId: string) => {
  const res = await apiGet<ArrayBuffer>(`/api/payments/${paymentId}/print`, {
    responseType: 'arraybuffer',
    headers: new AxiosHeaders({
      accept: 'application/pdf',
    }),
  });
  return {
    data: res.data,
    fileName: `payment-${paymentId}.pdf`,
  };
};
