import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { apiGetAllList } from '~app/api/axios';
import { useGetListData } from '~app/api/queryUtils';
import {
  MBox,
  MButton,
  MCustomMultiSelect,
  MFlex,
  MFormField,
  MRadio,
  MRadioGroup,
  MText,
} from '~app/components/Monetize';
import { MCustomMultiSelectRef } from '~app/components/Monetize/MCustomSelect/MCustomMultiSelect';
import { useCustomSelectValues } from '~app/components/Monetize/MCustomSelect/useCustomSelectValue';
import {
  IProduct,
  IProductResSchema,
  PercentOfTotalConfigTypeEnum,
  ProductStatusEnum,
  ProductTypeEnum,
} from '~app/types';
import { PRODUCTS_ITEM_DEFAULT_PAGER } from './RatePriceUtils';

interface RatePercentOfTotalConfigProps {
  product: IProduct;
  isLoading?: boolean;
  isLocked?: boolean;
  isDisabled?: boolean;
  /** True if the rate is already saved in database */
  isExistingRate?: boolean;
}
export const RatePercentOfTotalConfig = React.forwardRef<
  HTMLInputElement | null,
  RatePercentOfTotalConfigProps
>(
  (
    {
      product,
      isLoading,
      isLocked,
      isDisabled = false,
      isExistingRate = false,
    }: RatePercentOfTotalConfigProps,
    forwardedRef: React.ForwardedRef<HTMLInputElement | null>,
  ) => {
    const {
      control,
      formState: { errors, dirtyFields },
      watch,
      setValue,
    } = useFormContext();
    const watchPercentOfTotalConfig = watch(`percentOfTotalConfig`);
    const isSpecificProductsTypeSelected =
      watchPercentOfTotalConfig?.type ===
      PercentOfTotalConfigTypeEnum.SPECIFIED_PRODUCTS;

    const [query, setQuery] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const { data: productList, isLoading: productListLoading } =
      useGetListData<IProduct>(
        'productCatalogProducts',
        {
          config: PRODUCTS_ITEM_DEFAULT_PAGER,
          filters: {
            name: { contains: searchTerm },
            status: ProductStatusEnum.ACTIVE,
            productType: {
              in: [ProductTypeEnum.ADVANCE, ProductTypeEnum.ONETIME],
            },
            sort: 'name',
          },
        },
        {
          placeholderData: (previousData, previousQuery) => previousData,
          refetchOnWindowFocus: false,
          enabled: isSpecificProductsTypeSelected,
        },
      );

    const setQueryDebounced = useMemo(() => debounce(setSearchTerm, 300), []);

    const setFormValue = (value: string[]) => {
      setValue(`percentOfTotalConfig.productIds`, value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    };

    const customSelectRef = useRef<MCustomMultiSelectRef>();

    const {
      internalValue,
      isLoading: isLoadingSavedValue,
      placeholder,
      onInternalValueChange,
    } = useCustomSelectValues<IProductResSchema>({
      value: watchPercentOfTotalConfig?.productIds,
      setValue: setFormValue,
      getByIds: async (ids: string[]) =>
        ids.length > 0
          ? apiGetAllList<IProductResSchema>('/api/products', {
              filters: { id: { in: ids } },
            })
          : [],
      label: 'Products',
    });

    useEffect(() => {
      // This ensures that MCustomMultiSelect knows about the date change
      // otherwise it had stale data when opened the first time
      customSelectRef.current?.updateItems(internalValue);
    }, [internalValue]);

    const onChangeQuery = (q: string): void => {
      if (q !== query) {
        setQuery(q);
        setQueryDebounced(q);
      }
    };

    const onClearAll = () => {
      customSelectRef.current?.clearAll();
      onInternalValueChange([]);
    };

    if (isLocked) {
      const sortedProducts = internalValue.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      if (isSpecificProductsTypeSelected) {
        return (
          <Popover placement="bottom-end" trigger="hover" isLazy>
            <PopoverTrigger>
              <MButton variant="tertiary" size="sm">
                Specific Products
              </MButton>
            </PopoverTrigger>
            <PopoverContent maxWidth="260px" p="2">
              <PopoverArrow />
              <PopoverBody p="2">
                <MBox
                  maxHeight="200px"
                  overflow="auto"
                  className="custom-scroll-bar-v1"
                >
                  {sortedProducts.map(({ name, id }, index) => (
                    <MText
                      key={id}
                      title={name}
                      noOfLines={1}
                      isTruncated
                      mb={internalValue.length - 1 == index ? '0' : '2'}
                      display="block"
                      mr="2"
                    >
                      {name}
                    </MText>
                  ))}
                </MBox>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        );
      }
      return <MText ml="4">All Subscription and Onetime Products</MText>;
    }

    return (
      <MFlex gap="4" ml="4">
        <MFormField
          error={(errors.percentOfTotalConfig as any)?.type}
          alignSelf="center"
        >
          <Controller
            name="percentOfTotalConfig.type"
            defaultValue={PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE}
            control={control}
            render={({ field }) => (
              <MRadioGroup
                display="flex"
                alignItems="center"
                minH="8"
                gap="4"
                isDisabled={isLoading || isLocked}
                {...field}
              >
                <MRadio value={PercentOfTotalConfigTypeEnum.ALL_ELIGIBLE}>
                  All Subscription and Onetime Products
                </MRadio>
                <MRadio value={PercentOfTotalConfigTypeEnum.SPECIFIED_PRODUCTS}>
                  Specific Products
                </MRadio>
              </MRadioGroup>
            )}
          />
        </MFormField>
        {isSpecificProductsTypeSelected && (
          <MFormField
            w="auto"
            minW="8rem"
            isRequired={!isLocked}
            error={(errors.percentOfTotalConfig as any)?.productIds}
            justifyContent="flex-end"
          >
            <Controller
              name="percentOfTotalConfig.productIds"
              control={control}
              render={({ field: { onChange, ref, ...rest } }) => (
                <MCustomMultiSelect
                  ref={(refEvent: any) => {
                    customSelectRef.current = refEvent;
                  }}
                  isLazy
                  items={productList?.content}
                  itemTitle="name"
                  itemValue="id"
                  showQueryInput
                  multiple
                  onChange={(val) => {
                    onInternalValueChange(val as IProductResSchema[]);
                  }}
                  useExternalQuery
                  returnItem
                  externalQuery={query}
                  onChangeQuery={onChangeQuery}
                  {...rest}
                  value={internalValue}
                  isDisabled={isDisabled}
                  loading={productListLoading || isLoadingSavedValue}
                  closeButtonText="Apply"
                  placeholder={placeholder}
                  inputProps={{
                    variant:
                      isExistingRate &&
                      dirtyFields?.percentOfTotalConfig?.productIds
                        ? 'unsaved'
                        : 'primary',
                    _placeholder: { color: 'tPurple.dark' },
                    _disabled: {
                      // By default we hide placeholder when disabled, but here we want it visible
                      _placeholder: {
                        color: 'tGray.darkPurple',
                      },
                    },
                  }}
                  popOverProps={{ matchWidth: false, placement: 'bottom-end' }}
                  popOverContentProps={{
                    minWidth: '15rem',
                  }}
                  renderPreContent={() => (
                    <MFlex justifyContent="space-between" mx="2" mt="2">
                      <MText fontStyle="italic" fontSize="xs"></MText>
                      <MButton
                        variant="tertiary"
                        size="xs"
                        role="button"
                        onClick={onClearAll}
                      >
                        Clear All
                      </MButton>
                    </MFlex>
                  )}
                />
              )}
            />
          </MFormField>
        )}
      </MFlex>
    );
  },
);
