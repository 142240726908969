import { zodResolver } from '@hookform/resolvers/zod';
import { addDays } from 'date-fns/addDays';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MdArrowDropDown } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetOfferingById } from '~app/api/productCatalogService';
import { CustomFieldDataForm } from '~app/components/CustomFields/CustomFieldDataForm';
import {
  MBox,
  MButton,
  MCustomSelect,
  MDivider,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MIcon,
  MInput,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
  MTooltip,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import MAlert from '~app/components/Monetize/MAlert';
import {
  OFFERING_STATUS_DISPLAY,
  OFFERING_STATUS_FORM_OPTIONS,
  defaultOfferingCreateValues,
} from '~app/constants/offerings';
import { STATUS_PLACEHOLDER } from '~app/constants/placeholders';
import {
  ONE_TIME_PRODUCT_TYPES,
  SUBSCRIPTION_PRODUCT_TYPES,
} from '~app/constants/products';
import { OFFERING_LIST, getOfferingEditRoute } from '~app/constants/routes';
import { useOffering, useQuery } from '~app/hooks';
import { useCtrlEnterHotkey } from '~app/hooks/useHotkeys';
import { usePrompt } from '~app/hooks/usePrompt';
import { useSettingsReadOnlyRule } from '~app/hooks/useSettingsReadOnlyRule';
import {
  CustomFieldEntityEnum,
  IOfferingProductSchema,
  IOfferingReqSchema,
  IProductResSchema,
  IRateReqSchemaUI,
  IRateResBaseSchema,
  OfferingReqSchema,
  OfferingStatusEnum,
  OfferingTypesEnum,
  ProductResWithMandatory,
  ProductTypeEnum,
  RateStatusEnum,
  RateTypeEnum,
} from '~app/types';
import { sortAlphabetically, sortByProductType } from '~app/utils';
import { convertFormValuesToReq } from '~app/utils/customFields';
import OfferingTypeInput from './components/OfferingTypeInput';
import ProductsTable from './components/ProductsTable';
import RateFormProvider from './components/Rate/RateFormProvider';

export const OfferingForm: FC = () => {
  const navigate = useNavigate();
  const { offeringId: id = '' } = useParams();
  const {
    offering,
    error,
    createOffering,
    updateOffering,
    loading,
    isFetching,
  } = useOffering(id);

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    getValues,
    reset,
    register,
    formState: { errors, isDirty, dirtyFields, isSubmitted, isValid },
  } = useForm<IOfferingReqSchema>({
    mode: 'onChange',
    resolver: zodResolver(OfferingReqSchema),
    defaultValues: {
      ...defaultOfferingCreateValues,
    },
  });

  const startDateField = watch('startDate');
  const offeringNameField = watch('name');
  const minEndDate = useMemo(
    () =>
      startDateField
        ? addDays(startOfDay(parseISO(startDateField)), 1)
        : undefined,
    [startDateField],
  );
  register('offeringProducts');

  const [products, setProducts] = useState<ProductResWithMandatory[]>([]);
  const [rateIds, setRateIds] = useState<string[]>([]);
  const [inactiveRateIds, setInactiveRateIds] = useState<string[]>([]);
  const [rateTempUUIds, setRateTempUUIds] = useState<string[]>([]); // to handle unsaved tempUUIds because we can't update rateIds all the time. Changes of rateIds causes rates loading
  const [editable, setEditable] = useState<boolean>(true);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [rateCloneData, setRateCloneData] = useState<IRateReqSchemaUI>();
  const [dirtyRateIds, setDirtyRateIds] = useState<string[]>([]);
  const [showInactiveRate, setShowInactiveRate] = useState<boolean>(false);
  const [isExpandedAll, setIsExpandedAll] = useState<boolean>(false);
  const [isAllProductMandatory, setIsAllProductMandatory] =
    useState<boolean>(false);

  usePrompt(
    'There are unsaved changes, do you want to discard these changes?',
    !loading && !isFetching && (isDirty || !!dirtyRateIds.length),
  );

  const offeringType = getValues('type');

  const productsMatchOfferingType = (
    prods: IProductResSchema[],
    type: OfferingTypesEnum,
  ) => {
    if (prods.length === 0) {
      return false;
    }
    const hasSubscriptionProducts = prods.some(({ productType }) => {
      return SUBSCRIPTION_PRODUCT_TYPES.has(productType);
    });

    const onlyOneTime = prods.every(({ productType }) => {
      return ONE_TIME_PRODUCT_TYPES.has(productType);
    });

    const onlyMinCommitProduct = prods.every(
      ({ productType }) => productType === ProductTypeEnum.MIN_COMMIT,
    );

    const onlyPoTProduct =
      prods.length === 1 &&
      (prods[0].productType === ProductTypeEnum.ADVANCE ||
        prods[0].productType === ProductTypeEnum.ONETIME);

    switch (type) {
      case OfferingTypesEnum.SUBSCRIPTION:
        return hasSubscriptionProducts && !onlyOneTime;
      case OfferingTypesEnum.ONETIME:
        return onlyOneTime;
      case OfferingTypesEnum.MIN_COMMIT:
        return onlyMinCommitProduct;
      case OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL:
        return onlyPoTProduct;
      default:
        return false;
    }
  };

  const handleSaveButtonPress = async () => {
    if (offering?.id) {
      handleSubmit((data) => {
          data.offeringProducts = data.offeringProducts.map((product, index) => {
            return { 
              ...product,
              position: index + 1
            }
          });
        updateOffering({
          id: offering.id,
          payload: {
            ...data,
            customFields: convertFormValuesToReq(data.customFields),
          },
        })
      },
      )();
    } else {
      handleSubmit(async (data) => {
        data.offeringProducts = data.offeringProducts.map((product, index) => {
          return {
            ...product,
            position: index + 1
          }
        });
        const res = await createOffering(data);
        if (res) {
          navigate(getOfferingEditRoute(res.id), { replace: true });
        }
      })();
    }
  };

  const checkIfAllProductsAreMandatory = (
    productsToCheck: ProductResWithMandatory[],
  ) => {
    setIsAllProductMandatory(
      productsToCheck.every(({ isMandatory }) => isMandatory),
    );
  };

  const handleProductSelect = (currentProduct: ProductResWithMandatory) => {
    const doesProductExist = products.find(
      (product) => product.id === currentProduct.id,
    );
    if (!doesProductExist) {
      const nextProducts = [
        ...products,
        { ...currentProduct, isMandatory: true },
      ];
      checkIfAllProductsAreMandatory(nextProducts);
      setProducts(sortByProductType(nextProducts, 'name'));
      setValue(
        'offeringProducts',
        nextProducts.map((product: ProductResWithMandatory) => ({
          productId: product.id,
          isMandatory: product.isMandatory ?? true,
        })),
        {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        },
      );
      setEditable(false);
    }
  };

  const handleProductRemove = (currentProduct: IProductResSchema) => {
    const nextProducts = products.filter(
      (product: IProductResSchema) => product.id !== currentProduct.id,
    );
    setProducts(sortByProductType(nextProducts, 'name'));
    checkIfAllProductsAreMandatory(nextProducts);
    setValue(
      'offeringProducts',
      nextProducts.map((product: ProductResWithMandatory) => ({
        productId: product.id,
        isMandatory: product.isMandatory,
      })),
      {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      },
    );
  };

  /**
   * Formats and updates the products for the form state.
   * @param productsToUpdate Array of products to be formatted and set in the form.
   */
  const transformMandatoryProducts = (
    productsToUpdate: ProductResWithMandatory[],
  ) => {
    const formattedProducts = productsToUpdate.map((product) => ({
      productId: product.id,
      isMandatory: product.isMandatory,
    }));

    setValue('offeringProducts', formattedProducts, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  /**
   * Handles the mandatory checkbox for a product.
   * @param productsToUpdate Array of products to be formatted and set in the form.
   */
  const handleMandatoryCheckbox = (
    productToUpdate?: ProductResWithMandatory,
  ) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.map(
        (product: ProductResWithMandatory) => {
          if (productToUpdate && product.id === productToUpdate.id) {
            return {
              ...product,
              isMandatory: !product.isMandatory,
            };
          }
          return {
            ...product,
            isMandatory: productToUpdate
              ? product.isMandatory
              : !isAllProductMandatory,
          };
        },
      );
      checkIfAllProductsAreMandatory(updatedProducts);
      transformMandatoryProducts(updatedProducts);
      return updatedProducts;
    });
  };

  const handleAddNewRate = () => {
    setEditable(false);
    if (
      isDirty &&
      isValid &&
      productsMatchOfferingType(products, offeringType)
    ) {
      handleSaveButtonPress();
    }
    if (isValid && !errors.name) {
      const uid = `new-${uuidv4()}`;
      setRateIds([...rateIds, uid]);
      setRateTempUUIds([...rateTempUUIds, uid]);
    }
  };
  const handleCloneRate = (data: IRateReqSchemaUI) => {
    const uid = `clone-${uuidv4()}`;
    setRateCloneData({ ...data, id: uid });
    setRateIds([...rateIds, uid]);
    setRateTempUUIds([...rateTempUUIds, uid]);
  };

  const handleDirtyRateIds = (rateId: string, type: 'add' | 'remove') => {
    if (type === 'add') {
      setDirtyRateIds((prevRateIds) => {
        if (!prevRateIds.includes(rateId)) {
          return prevRateIds.concat(rateId);
        }
        return prevRateIds;
      });
    }

    if (type === 'remove') {
      setDirtyRateIds((prevRateIds) => {
        if (prevRateIds.includes(rateId)) {
          return prevRateIds.filter((currId) => currId !== rateId);
        }
        return prevRateIds;
      });
    }
  };
  const { isReadOnly, inputVariant } = useSettingsReadOnlyRule();
  useEffect(() => {
    if (id) {
      setPageTitle(isReadOnly ? 'View Offering' : 'Edit Offering');
    } else {
      setPageTitle('New Offering');
    }
  }, [id]);

  useEffect(() => {
    if (offering?.id) {
      setEditable(false);
      if (offering.offeringProducts.length) {
        const tempProducts = offering.offeringProducts.map(
          (product: IOfferingProductSchema) => ({
            ...product.product,
            isMandatory: product.isMandatory,
          }),
        );
        checkIfAllProductsAreMandatory(tempProducts);
        setProducts(sortByProductType(tempProducts, 'name'));
      }

      const offeringRateIds = offering.rates
        ?.filter(
          (rate: IRateResBaseSchema) =>
            rate.rateType !== RateTypeEnum.ACCOUNT &&
            rate.status !== RateStatusEnum.INACTIVE,
        )
        ?.sort(sortAlphabetically('name'))
        ?.map((rate: IRateResBaseSchema) => rate.id as string);
      const offeringProducts = offering.offeringProducts?.map(
        (product: IOfferingProductSchema) => ({
          productId: product?.product?.id,
          isMandatory: product?.isMandatory,
        }),
      );
      reset({
        ...offering,
        // Since description field can be null/undefined setting it as empty string
        // to make it easier to handle dirty state. Eventually it will be null if empty when we make api calls
        description: offering.description ?? '',
        offeringProducts,
      });

      // add with existing rateIds with the response rate ids
      setRateIds([...offeringRateIds, ...rateTempUUIds]);
      const offeringInactiveRateIds = offering.rates
        ?.filter(
          (rate: IRateResBaseSchema) =>
            rate.rateType !== RateTypeEnum.ACCOUNT &&
            rate.status === RateStatusEnum.INACTIVE,
        )
        ?.sort(sortAlphabetically('name'))
        ?.map((rate: IRateResBaseSchema) => rate.id as string);
      setInactiveRateIds(offeringInactiveRateIds);
    }
  }, [offering]);

  useEffect(() => {
    if (error) {
      if (offering?.products?.length) {
        setProducts(
          sortByProductType(
            offering.products.map(
              (product): ProductResWithMandatory => ({
                ...product,
                isMandatory: true,
              }),
            ),
            'name',
          ),
        );
      }
    }
  }, [error]);

  useCtrlEnterHotkey(() => {
    handleSaveButtonPress();
  });
  const isOfferingArchived = offering?.status === OfferingStatusEnum.ARCHIVED;

  const query = useQuery();
  // Copy offering
  const copyOfferingId = query.get('copyOfferingId');
  const [loadingCopyOffering, setLoadingCopyOffering] = useState(false);
  useEffect(() => {
    (async () => {
      if (copyOfferingId) {
        setLoadingCopyOffering(true);
        try {
          const { rates, customId, status, ...offering } =
            await doGetOfferingById(copyOfferingId);

          const offeringProductsById = offering.offeringProducts.reduce(
            (
              acc: Record<string, IOfferingProductSchema>,
              current: IOfferingProductSchema,
            ) => {
              acc[current.product.id] = current;
              return acc;
            },
            {},
          );

          if (offering.products?.length) {
            setProducts(
              sortByProductType(
                offering.products.map(
                  (product): ProductResWithMandatory => ({
                    ...product,
                    isMandatory:
                      offeringProductsById[product.id]?.isMandatory ?? true,
                  }),
                ),
                'name',
              ),
            );
          }
          reset(offering);
          setTimeout(() => {
            setValue('name', `Copy of ${offering.name}`, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }, 10);
        } catch (err) {
          handleApiErrorToast(err);
        }
        setLoadingCopyOffering(false);
        setEditable(false);
      }
    })();
  }, [copyOfferingId]);

  const loadingData = loading || loadingCopyOffering;

  const isProductsTableEditable =
    [...rateIds, ...inactiveRateIds].length === 0 && !isOfferingArchived;

  if (isFetching) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer data-testid="offering-form">
      <MPageHeader
        hasBackButton
        backButtonCallback={() => navigate(OFFERING_LIST)}
        title={pageTitle}
        id={offering?.id}
        copyUrl
        status={
          offering?.status ? OFFERING_STATUS_DISPLAY[offering.status] : ''
        }
      >
        <MFlex>
          {!!offering?.id && !isSubmitted && isDirty && (
            <MAlert
              type="warning"
              alertProps={{ px: 2, py: 1 }}
              alertIconProps={{ width: 4, height: 4 }}
              alertDescriptionProps={{ fontSize: 'sm' }}
              mr="4"
            >
              Unsaved Changes
            </MAlert>
          )}

          {!isReadOnly && (
            <MButton
              variant="primary"
              isLoading={loadingData}
              isDisabled={
                !isValid ||
                !isDirty ||
                (products.length > 0 &&
                  !productsMatchOfferingType(products, offeringType))
              }
              onClick={() => handleSaveButtonPress()}
              type="submit"
              data-testid="save-offering-btn"
            >
              Save
            </MButton>
          )}
        </MFlex>
      </MPageHeader>
      <form>
        <MGrid
          w="full"
          templateColumns="repeat(12, 1fr)"
          pr={144}
          gap={4}
          mt={10}
          mb={10}
          pl={8}
        >
          <MGridItem colSpan={3}>
            <MFormField error={errors.name} label="Name" isRequired>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <MInput
                    variant={
                      isReadOnly
                        ? inputVariant
                        : !!offering?.id && dirtyFields.name
                        ? 'unsaved'
                        : 'primary'
                    }
                    maxLength={80}
                    placeholder="New Offering"
                    isDisabled={isOfferingArchived}
                    isReadOnly={isReadOnly}
                    {...field}
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={3}>
            <MFormField error={errors.description} label="Description">
              <Controller
                name="description"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <MInput
                    variant={
                      isReadOnly
                        ? inputVariant
                        : !!offering?.id && dirtyFields.description
                        ? 'unsaved'
                        : 'primary'
                    }
                    maxLength={80}
                    isDisabled={isOfferingArchived}
                    isReadOnly={isReadOnly}
                    placeholder="Offering description"
                    value={value || ''}
                    {...rest}
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={2}>
            <MFormField error={errors.status} label="Status" isRequired>
              <Controller
                name="status"
                control={control}
                render={({ field }) => (
                  <MCustomSelect
                    isDisabled={isOfferingArchived}
                    isReadOnly={isReadOnly}
                    placeholder={STATUS_PLACEHOLDER}
                    items={OFFERING_STATUS_FORM_OPTIONS}
                    inputProps={{
                      variant: isReadOnly
                        ? inputVariant
                        : !!offering?.id && dirtyFields.status
                        ? 'unsaved'
                        : 'primary',
                    }}
                    {...field}
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={2}>
            <MFormField error={errors.startDate} label="Start Date">
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    isDisabled={isOfferingArchived}
                    isReadOnly={isReadOnly}
                    variant={
                      isReadOnly
                        ? inputVariant
                        : !!offering?.id && dirtyFields.startDate
                        ? 'unsaved'
                        : 'primary'
                    }
                    clearable
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={2}>
            <MFormField error={errors.endDate} label="End Date">
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    minDate={minEndDate}
                    isDisabled={isOfferingArchived}
                    isReadOnly={isReadOnly}
                    variant={
                      isReadOnly
                        ? inputVariant
                        : !!offering?.id && dirtyFields.endDate
                        ? 'unsaved'
                        : 'primary'
                    }
                    clearable
                  />
                )}
              />
            </MFormField>
          </MGridItem>

          <MGridItem colSpan={12}>
            <Controller
              name="customFields"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomFieldDataForm
                  entity={CustomFieldEntityEnum.OFFERING}
                  value={value || {}}
                  setValue={(val) => {
                    onChange(val);
                  }}
                  fieldColSpan={3}
                  mode="accordion"
                  isDisabled={isOfferingArchived}
                  isReadOnly={isReadOnly}
                  showDivider
                />
              )}
            />
          </MGridItem>
        </MGrid>
      </form>
      <OfferingTypeInput
        control={control}
        products={products}
        rateIds={rateIds}
        offeringType={offeringType}
        editable={editable}
        setEditable={setEditable}
        handleSaveButtonPress={handleSaveButtonPress}
        isReadOnly={isReadOnly}
      />
      <ProductsTable
        isValid={isValid}
        products={products}
        handleSelect={handleProductSelect}
        handleRemove={handleProductRemove}
        isEditable={isProductsTableEditable}
        offeringType={offeringType}
        isReadOnly={isReadOnly}
        onMandatoryCheckboxChange={handleMandatoryCheckbox}
        isAllProductMandatory={isAllProductMandatory}
      />

      {products.length > 0 && (
        <>
          <MBox mt={8} mb={4} w="full" pl={8}>
            <MText fontSize="lg" fontWeight="bold">
              Rates
            </MText>
            {products.length > 0 &&
              offeringType === OfferingTypesEnum.SUBSCRIPTION &&
              !productsMatchOfferingType(
                products,
                OfferingTypesEnum.SUBSCRIPTION,
              ) && (
                <MAlert
                  data-testid="offering-type-mismatch-warning"
                  type="warning"
                  maxW="55rem"
                  mb={2}
                >
                  You need to add a Subscription Product Type before you can add
                  Rates, or change the Offering Type to Onetime.
                </MAlert>
              )}
            <MFlex align="center" justifyContent="space-between">
              <MText color="tGray.darkPurple" mt={0.5}>
                {products.length > 0
                  ? 'A rate is a different way to price products and model an offering, you can create multiple rates of different currencies and billing frequencies.'
                  : 'There are no rates available yet. Select a few products to setup rates.'}
              </MText>
              {rateIds.length > 0 && !loadingData && (
                <MButton
                  variant="tertiary"
                  rightIcon={
                    <MIcon
                      as={MdArrowDropDown}
                      w="4"
                      h="4"
                      transform={isExpandedAll ? 'rotate(180deg)' : undefined}
                      transition="all ease 0.3s"
                    />
                  }
                  onClick={() => setIsExpandedAll((prevState) => !prevState)}
                >
                  {isExpandedAll ? 'Collapse all' : 'Expand all'}
                </MButton>
              )}
            </MFlex>
          </MBox>

          {offering?.id &&
            rateIds.map((rateId: string) => (
              <RateFormProvider
                key={rateId}
                id={rateId}
                archivedOffering={isOfferingArchived}
                products={products}
                type={offeringType}
                typeId={offering.id}
                rateCloneData={rateCloneData}
                setRateCloneData={setRateCloneData}
                removeRateTempUUId={(savedTempRateId: string) => {
                  setRateTempUUIds(
                    rateTempUUIds.filter(
                      (tempRateId: string) => tempRateId !== savedTempRateId,
                    ),
                  );
                  setRateIds((prevIds) => {
                    const nextIds = prevIds.filter(
                      (tempRateId) => tempRateId !== savedTempRateId,
                    );
                    return nextIds;
                  });
                }}
                onRemove={() => {
                  setRateIds(rateIds.filter((x: string) => x !== rateId));
                  setRateTempUUIds(
                    rateTempUUIds.filter(
                      (tempRateId: string) => tempRateId !== rateId,
                    ),
                  );
                }}
                onChange={(rateId) => {
                  setRateIds((prevIds) => {
                    const nextIds = prevIds.filter((id) => !id.match('new-'));
                    return [...nextIds, rateId];
                  });
                }}
                handleCloneRate={handleCloneRate}
                handleDirtyRateIds={handleDirtyRateIds}
                isReadOnly={isReadOnly}
                isExpandedAll={isExpandedAll}
                offering={offering}
              />
            ))}

          {!isReadOnly && (
            <MFlex
              w="full"
              gridColumn="2/-1"
              pb={3}
              pl={8}
              align="center"
              justifySelf="flex-start"
              justifyContent="space-between"
            >
              <MTooltip
                label={'First, fill in Offering Name'}
                placement="bottom-end"
                isDisabled={!!offeringNameField}
              >
                <MButton
                  isDisabled={
                    !isValid ||
                    !productsMatchOfferingType(products, offeringType) ||
                    isOfferingArchived
                  }
                  variant="tertiary"
                  fontSize="sm"
                  onClick={handleAddNewRate}
                  p={0}
                  m={0}
                  data-testid="add-new-rate-btn"
                >
                  + Add New Rate
                </MButton>
              </MTooltip>
              {isExpandedAll && (
                <MButton
                  variant="tertiary"
                  _hover={{
                    bg: 'transparent',
                  }}
                  _focus={{
                    bg: 'transparent',
                  }}
                  rightIcon={
                    <MIcon
                      as={MdArrowDropDown}
                      w="4"
                      h="4"
                      transform={isExpandedAll ? 'rotate(180deg)' : undefined}
                      transition="all ease 0.3s"
                    />
                  }
                  onClick={() => setIsExpandedAll((prevState) => !prevState)}
                >
                  {isExpandedAll ? 'Collapse all' : 'Expand all'}
                </MButton>
              )}
            </MFlex>
          )}
          {offering?.id && inactiveRateIds.length > 0 && (
            <>
              <MDivider />
              <MBox
                w="full"
                gridColumn="2/-1"
                py={3}
                pl={8}
                justifySelf="flex-start"
              >
                <MButton
                  data-testid="show-inactive-rate-btn"
                  variant="tertiary"
                  fontSize="sm"
                  p={2}
                  m={0}
                  onClick={() => setShowInactiveRate(!showInactiveRate)}
                >
                  {showInactiveRate ? 'Hide' : 'Show'} Inactive Rates
                </MButton>
              </MBox>
              {showInactiveRate &&
                inactiveRateIds.map((rateId: string) => (
                  <RateFormProvider
                    key={rateId}
                    id={rateId}
                    archivedOffering={isOfferingArchived}
                    products={products}
                    type={offeringType}
                    typeId={offering.id}
                    rateCloneData={rateCloneData}
                    setRateCloneData={setRateCloneData}
                    removeRateTempUUId={(savedTempRateId: string) =>
                      //remove tempUUId after save/clone
                      setRateTempUUIds(
                        rateTempUUIds.filter(
                          (tempRateId: string) =>
                            tempRateId !== savedTempRateId,
                        ),
                      )
                    }
                    onRemove={() => {
                      setInactiveRateIds(
                        inactiveRateIds.filter((x: string) => x !== rateId),
                      );
                      setRateTempUUIds(
                        rateTempUUIds.filter(
                          (tempRateId: string) => tempRateId !== rateId,
                        ),
                      );
                    }}
                    handleCloneRate={handleCloneRate}
                    handleDirtyRateIds={handleDirtyRateIds}
                    isReadOnly={isReadOnly}
                    isExpandedAll={isExpandedAll}
                    offering={offering}
                  />
                ))}
            </>
          )}
        </>
      )}
    </MPageContainer>
  );
};
