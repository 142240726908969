import { ROUTES } from '~app/constants';
import { ITabRoute } from '../../types';

export const ACCOUNT_TAB_ROUTES: ITabRoute[] = [
  {
    title: 'Overview',
    route: ROUTES.ACCOUNT_OVERVIEW,
    id: 'overview',
  },
  {
    title: 'Contacts',
    route: ROUTES.ACCOUNT_CONTACTS,
    id: 'contacts',
  },
  {
    title: 'Bill Groups',
    route: ROUTES.ACCOUNT_BILL_GROUPS,
    id: 'bill-groups',
  },
  {
    title: 'Subscriptions',
    route: ROUTES.ACCOUNT_SUBSCRIPTIONS,
    id: 'subscriptions',
    requiresFeatureFlag: 'showSubscriptionOnAccount',
  },
  {
    title: 'Usage',
    route: ROUTES.ACCOUNT_USAGE,
    id: 'usage',
    requiresFeatureFlag: 'showUsageTabOnAccount',
  },
  {
    title: 'Payment Methods',
    route: ROUTES.ACCOUNT_PAYMENT_METHODS,
    id: 'payment-methods',
  },
  {
    title: 'Payments',
    route: ROUTES.ACCOUNT_PAYMENTS,
    id: 'payments',
  },
  {
    title: 'Credits',
    route: ROUTES.ACCOUNT_CREDITS,
    id: 'credits',
  },
  {
    title: 'Quotes',
    route: ROUTES.ACCOUNT_QUOTES,
    id: 'quotes',
  },
  {
    title: 'Contracts',
    route: ROUTES.ACCOUNT_CONTRACTS,
    id: 'contracts',
  },
  {
    title: 'Invoices',
    route: ROUTES.ACCOUNT_INVOICES,
    id: 'invoices',
  },
];
