import { useEffect } from 'react';
import { useTrackRecordShareAction } from '../../api/shareService';
import {
  RecordShareEngagementAction,
  RecordShareEntity,
} from '../../types/recordShareTypes';

const getRecordViewedStorageKey = (token: string) => `record_viewed_${token}`;

export const useRecordShareTrackView = (
  tenantId: string,
  recordId: string,
  token: string,
) => {
  const { mutate: trackRecordShareAction } = useTrackRecordShareAction({
    onSuccess: () => {
      sessionStorage.setItem(getRecordViewedStorageKey(token), 'true');
    },
  });

  useEffect(() => {
    const hasAlreadyTrackedView = !!sessionStorage.getItem(
      getRecordViewedStorageKey(token),
    );
    if (hasAlreadyTrackedView) {
      return;
    }
    trackRecordShareAction({
      action: RecordShareEngagementAction.VIEWED_RECORD,
      entity: RecordShareEntity.QUOTE,
      recordId,
      tenantId,
      token,
      /** email */
    });
  }, [recordId, tenantId, token, trackRecordShareAction]);
};
