import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { apiGet } from '~app/api/axios';
import { composeGetQuery } from '~app/api/utils';
import { ApiListResponse, GetListApiConfig, IAccountHistoryResp } from '~types';

const useAccountHistory = (
  accountId: string,
  config: GetListApiConfig,
  options: Partial<UseQueryOptions<ApiListResponse<IAccountHistoryResp>>> = {},
) => {
  const params = composeGetQuery(config);
  return useQuery({
    queryKey: ['history', accountId, params],
    queryFn: async () => {
      const { data } = await apiGet<ApiListResponse<IAccountHistoryResp>>(
        `/api/account/${accountId}/history`,
        {
          params,
        },
      );
      return data;
    },
    ...options,
  });
};
export default useAccountHistory;
