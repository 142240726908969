import { useBreakpoint, useDisclosure } from '@chakra-ui/react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { MdEdit } from 'react-icons/md';
import {
  Link,
  Location,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useGetInvoiceUnpaidInvoice } from '~app/api/invoiceService';
import { useGetById, usePerformEntityAction } from '~app/api/queryUtils';
import { InvoiceContent } from '~app/components/Invoices/InvoiceContent';
import { PreviewInvoicePdfDrawer } from '~app/components/Invoices/PreviewInvoicePdfDrawer';
import {
  MBox,
  MButton,
  MCustomIconButton,
  MFlex,
  MLink,
  MPageContainer,
  MPageHeader,
  MText,
} from '~app/components/Monetize';
import { AddPaymentMethodForm } from '~app/components/PaymentMethods/Form/AddPaymentMethodForm';
import { ROUTES } from '~app/constants';
import { InvoiceStatusEnumDisplay } from '~app/constants/invoice';
import { PAPER_PAGE_MAX_WIDTH } from '~app/constants/quotes';
import { getAccountInvoicesRoute } from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import { useFlags } from '~app/services/launchDarkly';
import { useToast } from '~app/services/toast';
import { IInvoiceRespSchema, InvoiceStatusEnum } from '~types';
import { doPrintInvoiceToPdf } from '../../api/accountsService';
import { DateDisplay } from '../../components/Monetize/DateDisplay';
import { downloadAndSaveToPDF } from '../../utils';
import { usePaymentMethodsData } from '../AccountDetails/PaymentMethods/PaymentMethodList';
import { PayInvoiceModal } from './createPayment/CreatePaymentModal';
import { CreditNoteCreateModal } from './CreditNoteCreateModal';
import { EditInvoiceModal } from './EditInvoiceModal';
import { EditInvoiceSection } from './EditInvoiceSection';
import { InvoiceActions } from './InvoiceActions';
import InvoiceCreditNotes from './InvoiceCreditNotes';

interface InvoicePageLocation extends Location {
  state: {
    openPaymentModal?: boolean;
    source?: 'dashboard';
  };
}

export const InvoicePage: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation() as InvoicePageLocation;
  const invoiceId = params.invoiceId || '';
  const paymentModalState = useDisclosure();
  const creditNoteModalState = useDisclosure();
  const { canDo } = useACL();
  const canUpdateBilling = canDo([['billing', 'update']]);
  const { addToast } = useToast();
  const breakpoint = useBreakpoint();
  const isSmallScreen = ['base', 'sm'].includes(breakpoint);
  const editContactModalState = useDisclosure();
  const { lockFinalizedInvoiceData } = useFlags();
  const {
    isOpen: isOPenEditInvoiceModal,
    onOpen: onOpenEditInvoiceModal,
    onClose: onCloseEditInvoiceModal,
  } = useDisclosure();
  const {
    isLoading,
    data: invoice,
    isError,
    refetch,
  } = useGetById<IInvoiceRespSchema>('invoices', invoiceId, {
    enabled: !!invoiceId,
    meta: { showErrorToast: true },
  });

  useEffect(() => {
    if (isError) {
      navigate(ROUTES.SALES_QUOTES_ROUTE, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const { data: unpaidInvoices } = useGetInvoiceUnpaidInvoice(invoiceId, {
    enabled: !!invoiceId,
  });

  const {
    isPending: isTaxRecalculateLoading,
    mutateAsync: performInvoiceAction,
  } = usePerformEntityAction<IInvoiceRespSchema>('invoices', {
    onSuccess: async (_, { action }) => {
      const result = await refetch();
      if (action === 'recalculateTax') {
        if (result.data?.taxError) {
          addToast({
            summary: 'Failed',
            detail: 'Tax could not be recalculated.',
            severity: 'error',
          });
        } else {
          addToast({
            summary: 'Success',
            detail: 'Tax was successfully recalculated.',
            severity: 'success',
          });
        }
      }
    },
    meta: { showErrorToast: true },
  });

  const paymentMethodsData = usePaymentMethodsData(invoice?.account?.id ?? '');
  const [enableCreditAndRebill, setEnableCreditAndRebill] =
    useState<boolean>(false);

  useEffect(() => {
    if (state?.openPaymentModal) {
      // clear location.state
      navigate(location.pathname, { replace: true });
      paymentModalState.onOpen();
    }
  }, [state, location.pathname, navigate, paymentModalState]);

  const handlePaymentModalClose = (reload: boolean) => {
    reload && refetch();
    paymentModalState.onClose();
  };

  const handleCreditNoteModalClose = (reload: boolean) => {
    // TODO: creaditModal API's should invalidate invoice query
    reload && refetch();
    creditNoteModalState.onClose();
  };

  const handleCreateCreditNote = () => {
    creditNoteModalState.onOpen();
  };

  const handleBackNavigation = () => {
    const billGroupId = searchParams.get('billGroupId');

    if (state?.source === 'dashboard') {
      navigate(ROUTES.DASHBOARD_BILLING);
    } else if (billGroupId) {
      navigate(ROUTES.getBillGroupPageRoute(billGroupId!, 'invoices'));
    } else {
      navigate(ROUTES.getAccountInvoicesRoute(invoice?.account?.id!));
    }
  };

  let extraSubtitleParts: ReactNode[] = [];
  if (invoice?.lastSent) {
    extraSubtitleParts.push(
      <MFlex>
        <MText fontWeight="semibold" mr={1}>
          Sent:
        </MText>
        <DateDisplay date={invoice?.lastSent} />
      </MFlex>,
    );
  }

  if (!!invoice?.creditRebilledSourceInvoiceId) {
    extraSubtitleParts.push(
      <MLink
        variant="link"
        as={Link}
        fontSize="sm"
        fontWeight="400"
        _hover={{
          textDecoration: 'underline',
        }}
        pl="2"
        to={ROUTES.getInvoiceDetailRoute(invoice.creditRebilledSourceInvoiceId)}
      >
        View Original Invoice
      </MLink>,
    );
  }
  if (!!invoice?.creditRebilledTargetInvoiceId) {
    extraSubtitleParts.push(
      <MLink
        variant="link"
        as={Link}
        fontSize="sm"
        fontWeight="400"
        _hover={{
          textDecoration: 'underline',
        }}
        pl="2"
        to={ROUTES.getInvoiceDetailRoute(invoice.creditRebilledTargetInvoiceId)}
      >
        View Rebilled Invoice
      </MLink>,
    );
  }

  return (
    <MPageContainer height="auto">
      <MPageHeader
        hasBackButton
        backButtonCallback={handleBackNavigation}
        title="View Invoice"
        tag={!!invoice?.creditRebilledSourceInvoiceId && 'Rebilled Invoice'}
        tagStyle={{
          color: 'tOrange.base',
          bg: 'tCreamsicle.base',
          px: 2,
          borderRadius: '3px',
        }}
        status={
          invoice?.status ? InvoiceStatusEnumDisplay[invoice?.status] : ''
        }
        parentLink={getAccountInvoicesRoute(
          invoice ? invoice?.account?.id : '',
        )}
        parentLinkTitle={invoice && invoice.account.accountName}
        copyUrl={!invoice?.creditRebilledSourceInvoiceId}
        id={!invoice?.creditRebilledSourceInvoiceId ? invoice?.id : undefined}
        extraSubtitleParts={extraSubtitleParts}
      >
        <MFlex
          alignContent="center"
          alignItems="center"
          columnGap={3}
          display={['none', 'none', 'flex']}
        >
          {!!invoice?.id && !lockFinalizedInvoiceData && (
            <MCustomIconButton
              variant="icon"
              btnSize={6}
              iconColor="tPurple.base"
              p={3}
              icon={MdEdit}
              data-testid="edit-invoice-btn"
              onClick={onOpenEditInvoiceModal}
            />
          )}
          {!!invoice?.id && (
            <PreviewInvoicePdfDrawer
              invoiceId={invoice.id}
              invoiceNumber={invoice.invoiceNumber}
            />
          )}
          {invoice?.status === InvoiceStatusEnum.DRAFT &&
            !invoice?.taxError &&
            canUpdateBilling && (
              <MButton
                data-testid="pay-invoice-button"
                variant="primary"
                isLoading={isTaxRecalculateLoading || isLoading}
                disabled={isTaxRecalculateLoading || isLoading}
                onClick={() => {
                  performInvoiceAction({
                    id: invoiceId,
                    action: 'finalize',
                  });
                }}
              >
                Finalize
              </MButton>
            )}
          {invoice?.status === InvoiceStatusEnum.DRAFT &&
            invoice?.taxError &&
            canUpdateBilling && (
              <MButton
                data-testid="pay-invoice-button"
                variant="primary"
                isLoading={isTaxRecalculateLoading}
                disabled={isTaxRecalculateLoading}
                onClick={() => {
                  performInvoiceAction({
                    id: invoiceId,
                    action: 'recalculateTax',
                  });
                }}
              >
                Recalculate Tax
              </MButton>
            )}
          {invoice?.status === InvoiceStatusEnum.UNPAID &&
            !invoice?.paid &&
            invoice.amount > 0 &&
            canUpdateBilling && (
              <MButton
                data-testid="pay-invoice-button"
                variant="primary"
                onClick={paymentModalState.onOpen}
              >
                Pay Invoice
              </MButton>
            )}
          {invoice && (
            <InvoiceActions
              editContactModalState={editContactModalState}
              isReadOnly={!canUpdateBilling}
              invoice={invoice}
              onCreateCreditNote={handleCreateCreditNote}
              refreshData={refetch}
              onOpenCreditAndRebillModal={() => {
                onOpenEditInvoiceModal();
                setEnableCreditAndRebill(true);
              }}
              recalculateTax={() =>
                performInvoiceAction({
                  id: invoice.id,
                  action: 'recalculateTax',
                })
              }
              source={state?.source}
            />
          )}
        </MFlex>
      </MPageHeader>

      <MBox
        width="100%"
        px={[1, 1, 8]}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <MBox maxW={PAPER_PAGE_MAX_WIDTH} w={'full'}>
          {isSmallScreen && invoice && (
            <MFlex>
              <MCustomIconButton
                variant="icon"
                btnSize={breakpoint === 'base' ? 8 : 6}
                p={3}
                _focus={{ bg: 'none' }}
                icon={FiDownload}
                color="tPurple.dark"
                onClick={() =>
                  downloadAndSaveToPDF(() =>
                    doPrintInvoiceToPdf(invoice.id, invoice.invoiceNumber),
                  )
                }
              />
            </MFlex>
          )}
          {invoice?.id && invoice?.status !== InvoiceStatusEnum.DRAFT && (
            <EditInvoiceSection
              isLoading={isLoading}
              invoice={invoice}
              customFiledValues={invoice?.customFields}
              onOpenEditInvoiceModal={onOpenEditInvoiceModal}
            />
          )}

          {invoice?.id &&
            invoice?.status === InvoiceStatusEnum.DRAFT &&
            lockFinalizedInvoiceData && (
              <EditInvoiceSection
                isDraftInvoice={invoice?.status === InvoiceStatusEnum.DRAFT}
                isLoading={isLoading}
                invoice={invoice}
                customFiledValues={invoice?.customFields}
                onOpenEditInvoiceModal={onOpenEditInvoiceModal}
              />
            )}
          <InvoiceContent
            loadingInvoice={isLoading}
            unpaidInvoices={unpaidInvoices}
            invoice={invoice}
            handleOpenEditContactModal={() => editContactModalState.onOpen()}
          />
        </MBox>
        {isOPenEditInvoiceModal && invoice && (
          <EditInvoiceModal
            isDraftInvoice={invoice?.status === InvoiceStatusEnum.DRAFT}
            isCreditAnRebillPossible={!invoice?.creditRebilledTargetInvoiceId}
            enableCreditAndRebill={enableCreditAndRebill}
            invoice={invoice}
            isOpen={isOPenEditInvoiceModal}
            handleClose={() => {
              onCloseEditInvoiceModal();
              refetch();
              setEnableCreditAndRebill(false);
            }}
          />
        )}
        {paymentMethodsData.isModalOpen && !!invoice && (
          <>
            <InvoiceCreditNotes invoice={invoice} />
            <AddPaymentMethodForm
              isOpen={paymentMethodsData.isModalOpen}
              onClose={paymentMethodsData.onCloseModal}
              accountId={invoice.account.id}
            />
          </>
        )}
        {paymentModalState.isOpen && !!invoice && (
          <PayInvoiceModal
            isOpen={paymentModalState.isOpen}
            invoice={invoice}
            onClose={handlePaymentModalClose}
          />
        )}
        {creditNoteModalState.isOpen && !!invoice && (
          <CreditNoteCreateModal
            isOpen={creditNoteModalState.isOpen}
            invoice={invoice}
            onClose={handleCreditNoteModalClose}
          />
        )}
      </MBox>
    </MPageContainer>
  );
};
